/**
 * @fileoverview Filter for SAT page.
 * @version 1.0.0
 * @author Carlos Emilio Blanco Lopez
 * @date 23/01/2024
 * @copyright 2024 RESSER S.A de C.V
 */

import React, { useContext, useState } from 'react';
import { TextField, Chip, FormControl, Autocomplete } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

// Icons
import { IconContext } from 'react-icons';
import { AiOutlineFilter } from "react-icons/ai";
import { MdOutlineSearch } from "react-icons/md";
import { MdClose, MdRefresh } from "react-icons/md";

import { useTranslation } from 'react-i18next';
// styles
import styles from './styles';
import PopperFilter from './PopperFilter';
import { Context } from './Table';

const useStyles = makeStyles(styles);

const Status = {
    approved: "AGREEMENT_STATUS_APPROVED",
    rejected: "AGREEMENT_STATUS_REJECTED",
    pending: "AGREEMENT_STATUS_AWAITING_FM_AUTHORIZATION",
};

const Filter = ({ setPlateData, PlateData, GetData, Clients, SelectClient, setSelectClient, setPage }) => {

    const { dispatch } = useContext(Context);
    const [anchorEl, setAnchorEl] = useState(null);
    const [Placa, setPlaca] = useState('');
    const [Selected, setSelected] = useState({
        pending: false,
        approved: false,
        rejected: false,
    })
    const classes = useStyles();
    const { t } = useTranslation('common');

    /**
     * @function onFilter
     * @description Función que se encarga de filtrar los datos
     */

    const onFilter = () => {
        GetData(PlateData);
    }

    const onChangeStatus = (link = "NEW_AGREEMENTS") => {
        GetData(PlateData, link);
    }

    /**
     * @function handleOpenPopper
     * @description Función que se encarga de manejar el estado del Popper para el filtro
     */
    const handleOpenPopper = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * @function handlePlacaChange
     * @description Función que se encarga de manejar el estado de la placa
     */

    const handlePlacaChange = (event) => {
        if (event.key === 'Enter') {
            setSelected({ pending: false, approved: false, rejected: false });
            GetData(PlateData);
            return;
        }
        const inputValue = event.target.value;
        // Utiliza una expresión regular para permitir solo letras y números
        const regex = /^[a-zA-Z0-9]*$/;

        if (regex.test(inputValue)) {
            setPlateData(inputValue);
            setPlaca(inputValue);
        }
    };
    return (
        <section className={classes.Main}>
            <section id='Filter-Campos' style={{ width: 550, display: 'flex', alignItems: 'center' }} >

                {Selected.pending &&
                    <Chip
                        label={t('ModuleAgreements.Filters.pedding')}
                        style={{ color: '#828282', marginRight: 10 }}
                        className={classes.Chip}
                        onDelete={() => { }}
                        deleteIcon={
                            <IconContext.Provider value={{ size: '18px', color: '#828282' }}>
                                <MdClose onClick={() => {
                                    setPage(1);
                                    GetData(PlateData, "");
                                    setSelected({ ...Selected, pending: false })
                                }} style={{ cursor: 'pointer' }} />
                            </IconContext.Provider>
                        }
                    />
                }

                {Selected.approved &&
                    <Chip
                        label={t('ModuleAgreements.Filters.approved')}
                        style={{ color: '#828282', marginRight: 10 }}
                        className={classes.Chip}
                        onDelete={() => { }}
                        deleteIcon={
                            <IconContext.Provider value={{ size: '18px', color: '#828282' }}>
                                <MdClose onClick={() => {
                                    setPage(1);
                                    GetData(PlateData, "");
                                    setSelected({ ...Selected, approved: false })
                                }} style={{ cursor: 'pointer' }} />
                            </IconContext.Provider>
                        } />
                }

                {Selected.rejected &&
                    <Chip
                        label={t('ModuleAgreements.Filters.rejected')}
                        style={{ color: '#828282', marginRight: 10 }}
                        className={classes.Chip}
                        onDelete={() => { }}
                        deleteIcon={
                            <IconContext.Provider value={{ size: '18px', color: '#828282' }}>
                                <MdClose onClick={() => {
                                    setPage(1);
                                    GetData(PlateData, "");
                                    setSelected({ ...Selected, rejected: false })
                                }} style={{ cursor: 'pointer' }} />
                            </IconContext.Provider>
                        } />}

                {
                    (Selected.pending || Selected.approved || Selected.rejected) &&
                    (
                        <div onClick={() => {
                            setPage(1);
                            setSelected({ pending: false, approved: false, rejected: false });
                            GetData(PlateData, "");
                        }} style={{ display: 'flex', cursor: 'pointer' }}>
                            <IconContext.Provider value={{ size: '24px', color: '#828282' }}>
                                <MdRefresh />
                            </IconContext.Provider>
                            <p className={classes.RefreshFilter}>{t('ModuleAgreements.Filters.clean')}</p>
                        </div>
                    )
                }

            </section>
            <section style={{ width: 650, display: 'flex', justifyContent: 'space-between' }}>
                <FormControl>
                    <Autocomplete
                        id="client"
                        options={Clients === undefined || Clients === null ? [] : Clients}
                        getOptionLabel={(option) => {
                            return option.client}}
                        value={SelectClient}
                        size='small'    
                        onChange={(e, data) => {
                            setPage(1);
                            setSelected({ pending: false, approved: false, rejected: false });
                            setSelectClient(data)
                            dispatch({ type: 'setClient', value: data });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="client"
                                label={t('ModuleAgreements.Filters.clients')}
                                sx={CustomInput}
                                style={{ backgroundColor: '#F2F2F2', borderRadius: '20px', width: 300 }} />)}
                    />
                </FormControl>
                <TextField
                    size='small'
                    variant="outlined"
                    id="outlined-basic"
                    placeholder={t('ModuleAgreements.Filters.plates')}
                    style={{ backgroundColor: '#F2F2F2', borderRadius: '20px', width: 270 }}
                    value={PlateData}
                    onChange={handlePlacaChange}
                    sx={CustomInput}
                    InputProps={{
                        endAdornment: (
                            <IconContext.Provider value={{ size: '24px', color: '#828282', style: { cursor: 'pointer' } }}>
                                <MdOutlineSearch onClick={onFilter} />
                            </IconContext.Provider>
                        )
                    }}
                />
                <section aria-label='Buttons-Filter' className={classes.Buttons}>
                    <div
                        className={classes.ButtonsFilter}
                        onClick={handleOpenPopper}>
                        <IconContext.Provider value={{ size: '24px', color: anchorEl ? '#050B7F' : '#828282' }}>
                            <AiOutlineFilter />
                        </IconContext.Provider>
                    </div>
                </section>
            </section>
            <PopperFilter
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setSelect={(selected) => {
                    setPage(1);
                    setSelected(selected);
                    if (selected.approved === true) return onChangeStatus(Status['approved']);
                    if (selected.rejected === true) return onChangeStatus(Status['rejected']);
                    if (selected.pending === true) return onChangeStatus(Status['pending']);
                    if (selected.pending === false && selected.approved === false && selected.rejected === false) return GetData(PlateData);
                }}
                Selected={Selected} />
        </section>
    )
}

export default Filter;

/**
 * @description Estilos personalizados para el componente TextField de Material UI  
 * @type {Object} CustomInput
 */

const CustomInput = {

    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: 'none',
            borderRadius: '20px',

        },
    },
    "& .MuiInputBase-input": {
        color: '#828282',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
    },
};