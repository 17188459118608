/**
 * @fileoverview GloveBoxFilter
 * @version 2.0
 * @author Carlos Emilio Blanco Lopex
 * @date 01/06/2023
 * @copyright 2023 Industrias RESSER S.A de C.V
 */
// core components
import React from 'react';
import CustomModal from 'components/CustomModal/CustomModal';
import { GloveBoxFilter } from 'components/Filter/Filter';


const cleanObj = {
    contract: '',
    contractObj: {},
    plate: '',
    plateObj: {},
    customer: '',
    customerObj: {}
};

const GlobeBoxFilter = ({rows, FilterModal, setFilterModal, getDocuments, FilterObj, setFilterObj, clients}) => {

    /**
     * @description Funcion para el flujo del filtro
     * @param {string} name 
     * @param {object} value 
     */

    const onChange = (name, value) => {
        if(name === 'plates') {
            setFilterObj({
                ...FilterObj,
                plateObj: value,
                plate: value !== null ? value.plateNumber : ''
            });
        }
        else if(name === 'contract'){
            setFilterObj({
                ...FilterObj,
                contractObj: value,
                [name]: value !== null ? value.contractNumber : '' 
            });
        }
        else if(name === 'customer'){
            setFilterObj({
                ...FilterObj,
                customerObj: value,
                [name]: value !== null ? value.clientNumber : '' 
            });
        }
    }

    /**
     * @title onSubmit
     * @description Funcion principal para filtrar informacion del GloveBox
     */

    const onSubmit = () => {
        getDocuments(true, FilterObj);
        setFilterModal(false);
    }

    /**
     * @title onClean 
     * @description Funcion principal para la limpieza de filtro
     */

    const onClean = () => {
        setFilterModal(false);
        setFilterObj(cleanObj);
        getDocuments(true, cleanObj);
    }

    /* Fragment */

    return (
        <CustomModal
            open={FilterModal}
            onClose={() => setFilterModal(false)}>
            <div style={{ width: '90%' }} >
                <GloveBoxFilter 
                    filters={FilterObj}
                    onChange={onChange}
                    clients={clients}
                    options={rows}
                    onSubmit={onSubmit}
                    onClean={onClean}
                    />
            </div>
        </CustomModal>
    )
}

export default GlobeBoxFilter