/**
 * @fileoverview AlertMessage
 * @version 1.0
 * @author César Paul Hernández Camacho
 * @date 14/01/2021
 * @copyright 2021 RESSER S.A de C.V
 */
import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import CustomModal from 'components/CustomModal/CustomModal';
import {
    Button,
    Typography
} from '@material-ui/core';
import Item from 'components/Grid/GridItem';
import Container from 'components/Grid/GridContainer';
import {onHideAlertMessage, logoutUser, setProfileInfo} from 'redux/slice';
//Translations
import {useTranslation} from "react-i18next";

const AlertMessage = ({history}) => {
    
    //Translations
    const [t, i18n] = useTranslation('common');
    //redux
    const showAlertMessage = useSelector(state => state.loadingMask.showAlertMessage);
    const dispatch = useDispatch();

    const onHideClick = () => {
        dispatch(onHideAlertMessage());
        dispatch(logoutUser());
        dispatch(setProfileInfo(false));
        history.push("/login");
    }

    return (
        <>
            <CustomModal
                open={showAlertMessage}
                onClose={onHideClick}
            >
                <Container direction="column" justify="center" alignItems="center">
                    <Item xs={12} style={{margin: 30}}>
                        <Typography 
                            color="textPrimary"
                            variant="h5"
                        >
                            {t('alertMessage.message')}
                        </Typography>
                    </Item>
                    <Item xs={12} style={{margin: 10}}>
                    <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        onClick={onHideClick}
                    >
                        {t("generalText.accept")}
                    </Button>
                    </Item>
                </Container>
            </CustomModal>
        </>
    )
}

export default AlertMessage;
