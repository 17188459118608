/**
 * @fileoverview Contracts view
 * @version 1.0
 * @author César Paul Hernández Camacho
 * @date 14/11/2020
 * @copyright 2020 RESSER S.A de C.V
 * @update 30/11/2020
 */
import React, { useState, useRef, useEffect } from 'react';
import swal from 'sweetalert';
//@material-ui components
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { onShowAlertMessage, showCircularProgress, closeCircularProgress } from 'redux/slice';
// @material-ui/icons
import {MdExpandMore}  from 'react-icons/md';
import { getAnalytics, logEvent } from 'firebase/analytics';
//Translations
import { useTranslation } from "react-i18next";
// core components
import Item from "components/Grid/GridItem.js";
import Container from "components/Grid/GridContainer.js";
import Table from './ContractTable';
import CRLVView from './CRLVView';

//axios
import axios from 'libs/AxiosHelper';
//utils
//messages
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from 'libs/Snackbar';
//styles
import styles from './Contracts.styles';
import { Typography } from '@material-ui/core';


var CryptoJS = require("crypto-js");

const useStyles = makeStyles(styles);


const Contracts = (props) => {
    const analytics = getAnalytics();
    //styles
    const classes = useStyles();
    //Reference
    const fileInputRef = useRef(null);
    const TableRef = useRef();
    //redux
    const dispatch = useDispatch();
    const regionId = useSelector(state => state.auth.loginData.regionId);
    //translate
    const [t, i18n] = useTranslation('common');
    //state
    const [reRender, setReRender] = useState(false);
    const [file, setFile] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showKilometerModal, setKilometerModal] = useState(false);
    const [CRLV, setCRLV] = useState(null);

    useEffect(() => {
    }, [reRender]);

    /**
     * Function that downloads de xlsx base
     * @memberof Contracts
     */
    const onDownloadClickHandler = () => {
        switch (regionId) {
            case 0:
                window.open('https://ald.blob.core.windows.net/layouts/Modelo_Cadastro_Condutores_v6_BR%20(1).xlsx');
                break;
            case 1:
                window.open('https://ald.blob.core.windows.net/layouts/UPL_ADDR_DB.xlsx');
                break;
            case 2:
                window.open('https://ald.blob.core.windows.net/layouts/Layout%20ABC%20Fleet%20management%20Peru.xlsx');
                break;
            case 3:
                window.open('https://ald.blob.core.windows.net/layouts/CHILE_Layout%20ABC%20Fleet%20management.xlsx');
                break;
            case 4:
                window.open('https://ald.blob.core.windows.net/layouts/Actualización%20datos%20de%20flota.xlsx');
                break;
            default:
                break;
        }
    }

    /**
     * Function to validate the name of a file and its extension
     * @param {string} filename the name of the file to validate
     * @memberof DocumentsTable
     * @returns true if the filename it's in the security parameters
     */
    const validateFile = (filename, fileSize) => {
        if (filename === '') {
            enqueueErrorSnackbar(dispatch, t('documents.alertMessages.2'));
            return false;
        }

        if (!filename.match("^[A-Za-z0-9\._]+$")) {
            enqueueErrorSnackbar(dispatch, t('documents.alertMessages.6'));
            return false;
        }

        if (!filename.match("^.*\.(?:[xX][lL][sS][xX])$")) {
            enqueueErrorSnackbar(dispatch, t('documents.alertMessages.5'));
            return false;
        }

        if (fileSize >= 6291456) {
            enqueueErrorSnackbar(dispatch, t('documents.alertMessages.7'));
            return false;
        }

        return true;
    }

    /**
     * Function that handle when a file is added to the input
     * @param {e} event
     * @memberof Contracts
     */
    const onFileChange = async (e) => {
        if (e.target.files.length > 0) {
            var fileName = e.target.files[0].name;
            var fileSize = e.target.files[0].size;
            if (validateFile(fileName, fileSize)) {
                var aux = await toBase64(e.target.files[0]);
                aux = aux.split(",")[1];
                setFile(aux);
                swal(`${t('contracts.uploadConfirmation')} \n ${fileName}`, {
                    buttons: {
                        cancel: t('generalText.cancel'),
                        send: t('generalText.send')
                    },
                })
                    .then((value) => {
                        switch (value) {
                            case "send":
                                axios.post('DocumentInventoryFMV2', {
                                    type: 'xlsx',
                                    name: fileName,
                                    file: aux
                                })
                                    .then(response => {
                                        enqueueSuccessSnackbar(dispatch, t('contracts.success.0'));
                                    })
                                    .catch(error => {
                                        dispatch(onShowAlertMessage())
                                    });
                                break;
                            default:
                                fileInputRef.current.value = '';
                                break;
                        }
                    });
            }
            else {
                setFile('');
            }
        }
        else {
            setFile('');
        }
    }

    /**
     * Function to get the base64 from a file input
     * @param {array} file
     * @return base64 Base64 extracted from the file
     * @memberof Contracts
     */
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    /**
     * Function to show filter modal
     * @memberof Contracts
     */
    const onShowModal = () => {
        setShowModal(true);
    }

    /**
     * Function to hide filter modal
     * @memberof Contracts
     */
    const onHideModal = () => {
        setShowModal(false);
    }

    /**
     * Function to hide kilometer update modal
     * @memberof Contract
     */
    const onHideKilometerModal = () => {
        setKilometerModal(false);
    }

    /**
     * Function to show kilometer update modal
     * @memberof Contract
     */
    const onShowKilometerModal = () => {
        setKilometerModal(true);
    }

    /**
     * Function to handle CRLV Request
     * @memberof Contract
     */
    const handleCRLV = async (plate) => {
        dispatch(showCircularProgress());
        const url = `/CRLVFMV2?query=${plate}`;
        axios.get(url)
            .then(async response => {
                if (response.data.success) {
                    setCRLV({
                        plate,
                        base64: CryptoJS.AES.decrypt(response.data.CRLV, 'MyALD@Document').toString(CryptoJS.enc.Utf8)
                    })
                    dispatch(closeCircularProgress());
                }
                else {
                    switch (response.data.code) {
                        case 1:
                            swal(t('contracts.CRLV.notEligible'), {
                                buttons: {
                                    ok: t('generalText.confirm')
                                },
                            })
                                .then((value) => {
                                    switch (value) {
                                        default:
                                            break;
                                    }
                                });
                            break;
                        case 2:
                            swal(t('contracts.CRLV.request'), {
                                buttons: {
                                    cancel: t('generalText.cancel'),
                                    request: t('generalText.request')
                                },
                            })
                                .then((value) => {
                                    switch (value) {
                                        case "request":
                                            requestCRLV(plate);
                                            break;
                                        default:
                                            break;
                                    }
                                });
                            break;
                        case 4:
                            swal(t('contracts.CRLV.notEligible'), {
                                buttons: {
                                    ok: t('generalText.confirm')
                                },
                            })
                                .then((value) => {
                                    switch (value) {
                                        default:
                                            break;
                                    }
                                });
                            break;
                        default:
                            enqueueErrorSnackbar(dispatch, t('contracts.CRLV.error'));
                            break;
                    }
                    dispatch(closeCircularProgress());
                }
            }).catch(error => {
                dispatch(closeCircularProgress());
                enqueueErrorSnackbar(dispatch, t('contracts.CRLV.error'));
            })
    }

    /**
     * Function to request CRLV
     * @memberof Contract
     */
    const requestCRLV = (plate) => {
        dispatch(showCircularProgress());
        const url = `/CRLVFMV2Request?query=${plate}`;
        axios.get(url)
            .then(async response => {
                if (response.data.success) {
                    swal(t('contracts.CRLV.requested'), {
                        buttons: {
                            ok: t('generalText.confirm')
                        },
                    })
                        .then((value) => {
                            switch (value) {
                                default:
                                    break;
                            }
                        });
                    dispatch(closeCircularProgress());
                }
                else {
                    dispatch(closeCircularProgress());
                    enqueueErrorSnackbar(dispatch, t('contracts.CRLV.requestError'));
                }
            }).catch(error => {
                dispatch(closeCircularProgress());
                enqueueErrorSnackbar(dispatch, t('contracts.CRLV.requestError'));
            })
    }

    /**
     * Function to clear CRLV
     * @memberof Contract
     */
    const clearCRLV = () => {
        setCRLV(null);
    }

    return (
        <>
            <Container>
                <Item xs={12} md={12} >
                    {/* CRLV View*/}
                    {
                        CRLV ? 
                        <CRLVView 
                        CRLV={CRLV} 
                        clearCRLV={clearCRLV}
                        /> :
                        <Accordion defaultExpanded>
                            <AccordionSummary
                            expandIcon={<MdExpandMore  />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                                <Container item xs={12} direction="column">
                                    <p style={{paddingLeft: 10, color: "#050B7F", fontWeight: 'bold', fontSize: '1.5rem'}}> {t('dashboardNames.3')} </p>
                                    {(regionId === 4) &&
                                        <Typography  color="textPrimary" variant="body3" style={{paddingLeft: 10, paddingRight: 10}}> {t('contracts.subtitle')} </Typography>
                                    }
                                </Container>
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordion} >
                                <Container item xs={12} direction="column" alignItems="center" justify="flex-start">
                                    <Item xs={12} style={{width: '100%'}} >
                                        <Table
                                            ref={TableRef}
                                            onHideModal={onHideModal}
                                            showModal={showModal}
                                            onShowModal={onShowModal}
                                            onRerender={() => {setReRender(!reRender)}}
                                            onDownloadClickHandler={onDownloadClickHandler}
                                            onUploadFileClick={() => {
                                                logEvent(analytics, "Fleet_update");
                                                fileInputRef.current.click();
                                            }}
                                            showKilometerModal={showKilometerModal}
                                            onShowKilometerModal={onShowKilometerModal}
                                            onHideKilometerModal={onHideKilometerModal}
                                            handleCRLV={handleCRLV}
                                        />
                                    </Item>
                                </Container>
                            </AccordionDetails>
                        </Accordion>
                    }
                </Item>
            </Container>
            <input
                style={{ overflow: 'hidden', width: 0, height: 0 }}
                ref={fileInputRef}
                onChange={onFileChange}
                type="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
        </>
    );
}

export default Contracts;