/**
 * @fileoverview ALDSAT
 * @version 1.0
 * @author César Paul Hernández Camacho 
 * @date 1/12/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 * @update 4/12/2020
 */
import React, { useState, useEffect } from 'react';
import ReactDOM from "react-dom";
import axios from 'libs/AxiosHelper';
//redux actions
import { useSelector, useDispatch } from 'react-redux'
import { onShowAlertMessage } from 'redux/slice';
//Translations
import { useTranslation } from "react-i18next";
// core components
import Item from "components/Grid/GridItem.js";
import Container from "components/Grid/GridContainer.js";

const ALDSAT = (props) => {
    const dispatch = useDispatch();
    //redux
    const loginData = useSelector(state => state.auth.loginData)
    //State
    const [iFrameKey, setIFrameKey] = useState(0);
    const [url, setUrl] = useState('');
    const [tokenSAT, setTokenSAT] = useState("");

    //translationos
    const [t, i18n] = useTranslation('common');

    useEffect(() => {
        loadALDSATData()
        setInterval(() => loadALDSATData(true), 600000)
    }, [])

    /**
     * Function to load the iframe on ALDFMV2
     * @memberof ALDSAT
     */
    const loadALDSATData = async (refreshToken = false) => {
        var aux = iFrameKey;
        await axios.get("ALDSATToken")
            .then(response => {
                if (response.data.success) {
                    var auxtoken = { token: (process.env.NODE_ENV === 'development') ? response.data.tokenAcceptance : response.data.token };
                    localStorage.setItem('jwttokenmyald', JSON.stringify(auxtoken));
                    if (!refreshToken) {
                        setUrl(`https://www.myald-latam.com/${response.data.satUrlProd.split('/')[3]}/`);
                    }
                    setIFrameKey(aux++);
                }
            })
            .catch(error => {
                dispatch(onShowAlertMessage());
            });
    }

    const renderIFrame = (
        <iframe key={iFrameKey} src={url} height="80%" width="100%" />
    );

    return (
        <>
            <Container>
                <Item xs={12} md={12} >
                    <p style={{ color: "#050B7F", fontWeight: 'bold', fontSize: '1.5rem' }}> {t('dashboardNames.4')} </p>
                </Item>
                <Item id="example" xs={12} md={12} style={{ minHeight: '90vh' }} >
                    {renderIFrame}
                </Item>
            </Container>
        </>
    );
};

export default ALDSAT;