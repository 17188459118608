/**
 * @fileoverview Table Component for Notifications
 * @version 1.0.0
 * @author Carlos Emilio Blanco Lopez
 * @date 13/12/2022
 * @copyright 2022 Industrias RESSER S.A de C.V
 */

import React, { useState, useRef, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
// core components

import Item from "components/Grid/GridItem.js";
import Container from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import { Autocomplete } from "@material-ui/lab";
import Paper from "@material-ui/core/Paper";
import { Grid, TextField } from "@material-ui/core";
import { MdCancel, MdOutlinePublish } from "react-icons/md";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FormControl } from "@material-ui/core";
// Styles
import styles from "./Notifications.styles";
//translate
import { useTranslation } from "react-i18next";
import DnD_Notification from "components/DragAndDrop/DnD_Notification";
import {
  showCircularProgress,
  closeCircularProgress,
  onShowAlertMessage,
} from "redux/slice";
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from "libs/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import Rssr from "libs/Rssr";
import axios from "libs/AxiosHelper";
import { DataModulo } from "./NotificationsTable";
import CustomDatePicker from "components/CustomInput/CustomDatePicker";
var CryptoJS = require("crypto-js");

const formDataObj = {
  client: {},
  clientID: "",
  category: {},
  categoryID: "",
  title: "",
  description: "",
  notificationType: "", //Number
  releaseDate: "",
  additionalLink: "",
  documentFile: "",
  releaseHour: "",
};

const useStyles = makeStyles(styles);

const labelProps = {
  style: {
    fontFamily: "Source Sans Pro !important",
    fontSize: "13px",
    fontWeight: 400,
    color: "#828282",
  },
};

const NotificationModal = ({ onClose, ...props }) => {
  const { ReducerState, customDispatch } = useContext(DataModulo);

  //Redux
  const userRole = useSelector((state) => state.auth.loginData.userRole);
  const language = useSelector((state) => state.configurations.language);
  const dispatch = useDispatch();

  // Styles
  const classes = useStyles();
  //translations
  const [t, i18n] = useTranslation("common");
  //States
  const [clients, setClients] = useState([]);
  const [FormData, setFormData] = useState(formDataObj);
  const [isDrivers, setisDrivers] = useState(true);
  const [isFM, setisFM] = useState(userRole === 7 ? true : false);
  const [FileName, setFileName] = useState("");
  const [FileSize, setFileSize] = useState("");
  const [File, setFile] = useState();
  const [SendCantItems, setSendCantItems] = useState(0);
  const [start, setStart] = useState(1);
  const [StartSend, setStartSend] = useState(1);
  const [ItemsID, setItemsID] = useState([]);
  const [selectAll, setselectAll] = useState(false);
  const [FilterName, setFilterName] = useState("");
  const [FilterNameD, setFilterNameD] = useState("");
  // Errors
  const [ErrorsDocuments, setErrorsDocuments] = useState("");
  const [ErrorUsers, setErrorUsers] = useState(false);
  const [ErrorTitle, setErrorTitle] = useState(false);
  const [ErrorDescription, setErrorDescription] = useState(false);
  const [ErrorCategory, setErrorCategory] = useState(false);
  const [ErrorAdditionalLink, setErrorAdditionalLink] = useState(false);

  useEffect(() => {
    getClient();
  }, []);

  useEffect(() => {
    if (ReducerState.itemsID.length > 0)
      onSendNotification(ReducerState.itemsID);
  }, [ReducerState.itemsID]);

  useEffect(() => {
    if (FilterName === "") {
      props.GetUsers(isFM, isDrivers, start !== 1 ? start - 1 : 0);
      return;
    }
  }, [FilterName]);

  useEffect(() => {
    if (selectAll && FilterNameD === "") {
      props.GetUsers(isFM, isDrivers, start !== 1 ? start - 1 : 0);
      return;
    }
  }, [FilterNameD]);

  useEffect(() => {
    if (ReducerState.isUpdate) {
      const notificationType = ReducerState.Categories.filter(
        (item) => item.idCategory === ReducerState.dataUpdate.notificationType
      );
      const fileName = CryptoJS.AES.decrypt(
        ReducerState.dataUpdate.documentFile,
        "MyALD@Document"
      ).toString(CryptoJS.enc.Utf8);
      const sizeURl = fileName.split("/").length;
      setFileName(fileName.split("/")[sizeURl - 1]);
      setFile(ReducerState.UpdateFile);
      setItemsID(ReducerState.dataUpdate.users.map(String));
      setFormData({
        ...FormData,
        title: ReducerState.dataUpdate.title,
        additionalLink: ReducerState.dataUpdate.additionalLink,
        releaseDate: Rssr.formatDateNotifications(
          ReducerState.dataUpdate.releaseDate
        )[0],
        releaseHour: Rssr.formatDateNotifications(
          ReducerState.dataUpdate.releaseDate
        )[1],
        category: notificationType[0],
        categoryID: notificationType[0].idCategory,
        description: ReducerState.dataUpdate.description,
      });
    } else {
      const date = new Date();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      setFileName("");
      setFile("");
      setFormData({
        ...formDataObj,
        releaseDate: `${date.getFullYear()}-${month
          .toString()
          .padStart(2, "0")}-${day.toString().padStart(2, "0")}`,
        releaseHour: `${date
          .getHours()
          .toString()
          .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`,
      });
      setItemsID([]);
    }
  }, [ReducerState.isUpdate, ReducerState.openModal]);

  useEffect(() => {
    if (!ReducerState.FirstGet) {
      props.GetUsers(isFM, isDrivers, 0, FormData.clientID);
      setStart(1);
    }
  }, [isFM, isDrivers, FormData.clientID]);

  useEffect(() => {
    if (selectAll) {
      props.GetUsers(isFM, isDrivers, StartSend !== 1 ? StartSend - 1 : 0);
    } else {
      props.GetUsers(isFM, isDrivers, start !== 1 ? start - 1 : 0);
    }
  }, [start, StartSend]);

  /**
   * Función que permite obtener los clientes del usuario logeado para realizar la solicitud del reporte IFRS16
   *
   */
  const getClient = () => {
    if (userRole === 7) {
      axios
        .get(`clientsFMV2/Catalog?customerName=&start=0&limit=12000`)
        .then((response) => {
          if (response.data.success) {
            setClients(response.data.items);
          }
        })
        .catch((error) => {
          dispatch(onShowAlertMessage());
        });
    } else {
      axios
        .get(`clientsFMV2`)
        .then((response) => {
          if (response.data.success) {
            setClients(response.data.items);
          }
        })
        .catch((error) => {
          dispatch(onShowAlertMessage());
        });
    }
  };

  //Reference
  const fileInputRef = useRef(null);

  /**
   * Function to get the base64 from a file input
   * @param {array} file
   * @return base64 Base64 extracted from the file
   * @memberof Documents
   */
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  /**
   * Function that handle when a file is added to the input
   * @param {e} event
   * @memberof Documents
   */

  const onFileChange = async (e) => {
    setErrorsDocuments("");
    if (e.target.files.length > 0) {
      const name = e.target.files[0].name;
      const size = e.target.files[0].size;
      setFileName(name);
      setFileSize(size);
      const extend = name.split(".");
      const lengthExtend = extend.length;
      if (extend[lengthExtend - 1].toLowerCase() !== "pdf") {
        enqueueErrorSnackbar(
          dispatch,
          (dispatch, t("gloveBox.alertMessages.3"))
        );
      } else {
        const validation = Rssr.validateDocumentName(false, name, size);
        if (validation.defaultValid) {
          var aux = await toBase64(e.target.files[0]);
          aux = aux.split(",")[1];
          setFile(aux);
        } else {
          ErrorLabel(validation);
        }
      }
    } else {
      setFileName("");
      setFile("");
    }
    fileInputRef.current.value = "";
  };

  /**
   * Function to validate the name of a file and its extension
   * @param {string} filename the name of the file to validate
   * @memberof DocumentsTable
   * @returns true if the filename it's in the security parameters
   */

  const ErrorLabel = (validate) => {
    if (!validate.documentValid)
      setErrorsDocuments(t("documents.alertMessages.2"));
    else if (!validate.expresionValid)
      setErrorsDocuments(t("documents.alertMessages.6"));
    else if (!validate.extensionValid)
      setErrorsDocuments(t("documents.alertMessages.4"));
    else if (!validate.sizeValid)
      setErrorsDocuments(t("documents.alertMessages.7"));
  };

  /**
   * Function para borrar el archivo seleccionado
   * @title onhandleCancel
   * @memberof NotificationModal
   */

  const onHandleCancel = () => {
    fileInputRef.current.value = "";
    setFile("");
    setFileName("");
    setErrorsDocuments("");
  };

  /**
   * Function para cerrar el Modal
   * @title onCloseModal
   * @memberof NotificationModal
   */

  const onCloseModal = () => {
    onChangeAllErrors();
    onHandleCancel();
    setFormData(formDataObj);
    setItemsID([]);
    onClose();
  };

  /**
   * Function de soporte para el Autocomplete
   * @title onChangeAutocomplete
   * @memberof NotificationModal
   */

  const onChangeAutocomplete = (label, data) => {
    switch (label) {
      case "category":
        setFormData({
          ...FormData,
          category: data,
          categoryID: data?.idCategory ? data.idCategory : "",
        });
        break;

      case "client":
        setFormData({
          ...FormData,
          client: data,
          clientID: data?.id ? data.id : "",
        });
        break;
      default:
        break;
    }
  };

  /**
   * Function to validate the name of a file and its extension
   * @param {string} filename the name of the file to validate
   * @memberof DocumentsTable
   * @returns true if the filename it's in the security parameters
   */
  const validateFile = () => {
    const validate = Rssr.validateDocumentName(false, FileName, FileSize);
    if (!validate.documentValid)
      enqueueErrorSnackbar(dispatch, t("documents.alertMessages.2"));
    else if (!validate.expresionValid)
      enqueueErrorSnackbar(dispatch, t("documents.alertMessages.6"));
    else if (!validate.extensionValid)
      enqueueErrorSnackbar(dispatch, t("documents.alertMessages.4"));
    else if (!validate.sizeValid)
      enqueueErrorSnackbar(dispatch, t("documents.alertMessages.7"));
    return validate.defaultValid;
  };

  /**
   * Function principal para el envio de Notificaciones
   * @title onSend
   * @memberof NotificationModal
   */

  const onSend = () => {
    if (selectAll) {
      props.GetUsers(isFM, isDrivers, 0, "", ReducerState.CantItems);
    } else {
      onSendNotification();
    }
  };

  /**
   * Function principal para el envio de Notificaciones
   * @title FilterForName
   * @memberof NotificationModal
   */

  const FilterForName = () => {
    if (FilterName === "" && FilterNameD === "") {
      props.GetUsers(isFM, isDrivers, start !== 1 ? start - 1 : 0);
    } else {
      props.GetUsersFilter(selectAll ? FilterNameD : FilterName);
    }
  };

  /**
   * Function para la obtencion de todos los IDs del Cusmtomer
   * @title onSendNotification
   * @memberof NotificationModal
   */

  const onSendNotification = (IDs = []) => {
    let intItemsID = IDs.length === 0 ? ItemsID.map(Number) : IDs;
    const category = isDrivers && isFM ? 2 : isDrivers ? 15 : isFM ? 8 : 2;
    if (
      FormData.title !== "" &&
      FormData.description !== "" &&
      FormData.categoryID !== "" &&
      FormData.releaseDate !== "" &&
      FormData.releaseHour !== "" &&
      intItemsID.length > 0 &&
      File !== "" &&
      validateFile()
    ) {
      if (ReducerState.isUpdate) {
        dispatch(showCircularProgress());
        axios
          .put("Notifications", {
            idNotification: ReducerState.dataUpdate.notificationId,
            title: FormData.title,
            description: FormData.description,
            notificationType: FormData.categoryID,
            releaseDate: `${FormData.releaseDate} ${FormData.releaseHour}`,
            additionalLink: FormData.additionalLink,
            documentFile: File,
            category: category,
            users: intItemsID,
          })
          .then((response) => {
            if (response.data.success) {
              onCloseModal();
              enqueueSuccessSnackbar(
                dispatch,
                t("Notification.AlertsMassage.2")
              );
            } else {
              enqueueErrorSnackbar(dispatch, t("Notification.AlertsMassage.0"));
            }
            dispatch(closeCircularProgress());
          })
          .catch((error) => {
            enqueueErrorSnackbar(
              dispatch,
              t("maintenance.errors.failConnection")
            );
            dispatch(closeCircularProgress());
          });
      } else {
        dispatch(showCircularProgress());
        axios
          .post("Notifications", {
            title: FormData.title,
            description: FormData.description,
            notificationType: FormData.categoryID,
            releaseDate: `${FormData.releaseDate} ${FormData.releaseHour}`,
            additionalLink: FormData.additionalLink,
            documentFile: File,
            category: category,
            users: intItemsID,
          })
          .then((response) => {
            if (response.data.success) {
              onCloseModal();
              enqueueSuccessSnackbar(
                dispatch,
                t("Notification.AlertsMassage.2")
              );
            } else {
              enqueueErrorSnackbar(dispatch, t("Notification.AlertsMassage.0"));
            }
            dispatch(closeCircularProgress());
          })
          .catch((error) => {
            enqueueErrorSnackbar(
              dispatch,
              t("maintenance.errors.failConnection")
            );
            dispatch(closeCircularProgress());
          });
      }
    } else {
      if (FormData.title === "") setErrorTitle(true);
      if (FormData.description === "") setErrorDescription(true);
      if (FormData.categoryID === "") setErrorCategory(true);
      if (FormData.additionalLink === "") setErrorAdditionalLink(true);
      if (intItemsID.length <= 0) setErrorUsers(!ErrorUsers);
      enqueueErrorSnackbar(dispatch, t("Notification.AlertsMassage.4"));
    }
  };

  //OnChange Title
  const onChangeTitle = (e) => {
    setFormData({
      ...FormData,
      title: e.target.value,
    });
    setErrorTitle(false);
  };

  //OnChange Description
  const onChangeDescription = (e) => {
    if (e.target.value.length <= 90)
      setFormData({ ...FormData, description: e.target.value });
    setErrorDescription(false);
  };

  //OnChange AdditionalLink
  const onChangeAdditionalLink = (e) => {
    setFormData({
      ...FormData,
      additionalLink: e.target.value,
    });
    setErrorAdditionalLink(false);
  };

  //OnChange Category
  const onChangeCategory = (e, data) => {
    onChangeAutocomplete("category", data);
    setErrorCategory(false);
  };

  //OnChange AllErrors

  const onChangeAllErrors = () => {
    setErrorTitle(false);
    setErrorDescription(false);
    setErrorCategory(false);
    setErrorAdditionalLink(false);
    setErrorUsers(false);
  };

  //Fragment

  return (
    <>
      <CustomModal
        type="AddNotification"
        open={ReducerState.openModal}
        onClose={onCloseModal}
      >
        <Paper elevation={0} className={classes.containerModalUploadDocuments}>
          <PerfectScrollbar
            style={{ position: "relative", height: "80%", maxHeight: "100vh" }}
          >
            <Container>
              <Item xs={12} md={10}>
                <p
                  style={{
                    color: "#050B7F",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    marginTop: 20,
                  }}
                >
                  {t("Notification.addNotification.addNotification")}
                </p>
              </Item>
              <Item container xs={12} alignItems="center" justify="center">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      InputLabelProps={labelProps}
                      label={t("Notification.addNotification.title")}
                      style={{ width: "100%" }}
                      value={FormData.title}
                      error={ErrorTitle}
                      onChange={onChangeTitle}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth style={{ position: "relative" }}>
                      {ReducerState.Categories && (
                        <Autocomplete
                          id="category"
                          options={ReducerState.Categories}
                          getOptionLabel={(option) => {
                            if (Object.keys(option).length > 0) {
                              switch (language) {
                                case "es":
                                  return option.descriptionEs;
                                case "en":
                                  return option.descriptionEn;
                                case "pt":
                                  return option.descriptionPt;
                                case "fr":
                                  return option.descriptionFr;
                                default:
                                  break;
                              }
                            }
                          }}
                          value={FormData.category}
                          onChange={(e, data) => onChangeCategory(e, data)}
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="category"
                              label={t("Notification.addNotification.type")}
                              error={ErrorCategory}
                            />
                          )}
                        />
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomInput
                      error={ErrorDescription}
                      InputLabelProps={labelProps}
                      labelText={t("Notification.addNotification.description")}
                      id="plates"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        name: "notes",
                        multiline: true,
                        maxLength: 10,
                        placeholder: t(
                          "Notification.addNotification.labelMaxLenght"
                        ),
                        rows: 5,
                        onChange: onChangeDescription,
                        value: FormData.description,
                      }}
                    />
                  </Grid>
                  <Grid container item xs={12} md={6} spacing={2}>
                    <Grid item xs={12} md={6}>
                      <CustomDatePicker
                        id="date"
                        labelText={t("Notification.addNotification.date")}
                        value={FormData.releaseDate}
                        onChange={(value) =>
                          setFormData({ ...FormData, releaseDate: value })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        id="endDate"
                        labelText={t("Notification.addNotification.hourDate")}
                        labelProps={{ shrink: true }}
                        inputProps={{
                          value: FormData.releaseHour,
                          type: "time",
                          name: "requestDateEnd",
                          onChange: ({ target }) => {
                            setFormData({
                              ...FormData,
                              releaseHour: target.value,
                            });
                          },
                        }}
                        formControlProps={{ fullWidth: true }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={ErrorAdditionalLink}
                        InputLabelProps={labelProps}
                        label={t("Notification.addNotification.link")}
                        margin="normal"
                        style={{
                          width: "100%",
                          position: "relative",
                          bottom: 13,
                        }}
                        onChange={onChangeAdditionalLink}
                        value={FormData.additionalLink}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Item xs={12} alignSelf="flex-start">
                      <TextField
                        id="Document"
                        label={t("Notification.addNotification.document")}
                        error={ErrorsDocuments !== ""}
                        helperText={ErrorsDocuments}
                        disabled
                        margin="normal"
                        value={FileName}
                        style={{ width: "100%" }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: (
                            <MdCancel
                              style={{ color: "#828282", cursor: "pointer" }}
                              onClick={onHandleCancel}
                            />
                          ),
                        }}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      onClick={() => fileInputRef.current.click()}
                      className={classes.uploadDocumentButtonStyle}
                    >
                      <MdOutlinePublish fontSize="large" />{" "}
                      {t("gloveBox.upload")}
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl
                      fullWidth
                      style={{ position: "relative", top: 15 }}
                    >
                      <Autocomplete
                        id="client"
                        options={
                          clients === undefined || clients === null
                            ? []
                            : clients
                        }
                        getOptionLabel={(option) =>
                          userRole === 7 ? option.clientName : option.client
                        }
                        value={FormData.client}
                        onChange={(e, data) => {
                          onChangeAutocomplete("client", data);
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="client"
                            label={t(
                              "Notification.addNotification.selectCompany"
                            )}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div style={{ marginTop: 12 }}>
                      <p className="userType">
                        {t("Notification.addNotification.userType")}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          width: "80%",
                        }}
                      >
                        <label
                          style={{ color: "rgb(130,130,130)", display: "flex" }}
                        >
                          <input
                            type="checkbox"
                            checked={isFM}
                            onChange={() => setisFM(!isFM)}
                          />
                          <p className="userTypeCheckBox">
                            {t("Notification.addNotification.userFM")}
                          </p>
                        </label>
                        <label
                          style={{ color: "rgb(130,130,130)", display: "flex" }}
                        >
                          <input
                            type="checkbox"
                            checked={isDrivers}
                            onChange={() => setisDrivers(!isDrivers)}
                          />
                          <p className="userTypeCheckBox">
                            {t("Notification.addNotification.userDriver")}
                          </p>
                        </label>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <DnD_Notification
                      {...props}
                      FilterForName={FilterForName}
                      start={start}
                      setStart={setStart}
                      StartSend={StartSend}
                      setStartSend={setStartSend}
                      SendCantItems={SendCantItems}
                      setSendCantItems={setSendCantItems}
                      setItemsID={setItemsID}
                      isFM={isFM}
                      isDrivers={isDrivers}
                      ItemsID={ItemsID}
                      setselectAll={setselectAll}
                      selectAll={selectAll}
                      customerID={FormData.clientID}
                      setFilterNameD={setFilterNameD}
                      FilterNameD={FilterNameD}
                      setFilterName={setFilterName}
                      FilterName={FilterName}
                      ErrorUsers={ErrorUsers}
                      setErrorUsers={setErrorUsers}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} />
                  <Grid container item xs={12} md={6}>
                    <Grid item xs={12} md={6}>
                      <Button
                        onClick={onCloseModal}
                        className={classes.cancelButtonStyle}
                        size="lg"
                      >
                        {t("generalText.cancel")}
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        className={classes.acceptButtonStyle}
                        size="lg"
                        onClick={() => onSend()}
                      >
                        {t("Notification.addNotification.accept")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Item>
            </Container>
          </PerfectScrollbar>
        </Paper>
      </CustomModal>
      <input
        aria-labelledby="document"
        placeholder="document"
        style={{ overflow: "hidden", width: 0, height: 0 }}
        ref={fileInputRef}
        onChange={onFileChange}
        type="file"
        accept="application/pdf"
      />
    </>
  );
};

export default NotificationModal;
