/**
 * @fileoverview Tabla para mostrar los productos de la cotización
 * @version 1.0.0
 * @author Carlos Emilio Blanco Lopez
 * @date 29/01/2024
 * @copyright 2024 RESSER S.A de C.V
 */

import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';

import { useTranslation } from 'react-i18next';

import { CheckBoxChecked } from 'assets/img/Icons/Icons';
import Rssr from 'libs/Rssr';

function EnhancedTableHead({ data, checked, ...props }) {
    const { t } = useTranslation('common');

    const { onSelectAllClick, order, orderBy, numSelected, onRequestSort } =
        props;

        const headCells = [
            {
                id: 'CrepCode',
                numeric: false,
                disablePadding: true,
                label: 'IDCrepCode',
            },
            {
                id: 'Quantity',
                disablePadding: false,
                label: t('ModuleAgreements.budget.quantity'),
            },
            {
                id: 'Description',
                disablePadding: false,
                label: t('ModuleAgreements.budget.description'),
            },
            {
                id: 'TotalGross',
                disablePadding: false,
                label: t('ModuleAgreements.budget.price'),
            },
            {
                id: 'Priority',
                disablePadding: false,
                label: t('ModuleAgreements.budget.priority'),
            },
        ];

    const createSortHandler = (property) => (event) => onRequestSort(event, property);

    return (
        <TableHead style={{ backgroundColor: '#F2F2F2' }}>
            <TableRow>
                <TableCell padding="checkbox">
                    {
                        !checked ? (
                            <Checkbox
                        color="primary"
                        checkedIcon={<CheckBoxChecked />}
                        checked={numSelected}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    /> ) : null
                    }
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            style={{
                                color: '#828282',
                                fontFamily: 'Monteserrat, sans-serif',
                                fontSize: '12px',
                                fontWeight: 600,
                            }}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({ data, onSelectedItem, section, checked }) {

    const { t } = useTranslation('common');
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [selected, setSelected] = React.useState([]);
    const [PriceTotal, setPriceTotal] = useState(0);

    const Priority = {
        '0': t('ModuleAgreements.budget.low'),
        '1': t('ModuleAgreements.budget.medium'),
        '2': t('ModuleAgreements.budget.high'),
    }

    useEffect(() => {
        if (checked) return;
        let total = 0;
        selected.forEach((id) => { total += data.find((item) => item.NumLine === id).TotalGross; });
        setPriceTotal(total);
    }, [data, selected]);

    useEffect(() => {
        let total = 0;
        if (checked) {
            data.forEach((item) => {
                if(item.IsFmApproved) total += item.TotalGross;
            });
            setPriceTotal(total);
        }
    }, [checked, data]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n.NumLine);
            onSelectedItem(newSelected, section);
            setSelected(newSelected);
            return;
        }
        onSelectedItem([], section);
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        onSelectedItem(newSelected, section);
        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    return (
        <Box sx={{ width: '100%', marginTop: 5 }}>
            <p>{section === 'parts' ? t('ModuleAgreements.budget.parts') : t('ModuleAgreements.budget.manoObra')}</p>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        stickyHeader
                        aria-labelledby="tableTitle"
                    >
                        <EnhancedTableHead
                            checked={checked}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={data.length}
                        />
                        <TableBody>
                            {data.map((row, index) => {

                                const isItemSelected = checked ? row.IsFmApproved : isSelected(row.NumLine);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => checked ? {} : handleClick(event, row.NumLine)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checkedIcon={<CheckBoxChecked />}
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                        >
                                            {row.CrepCode}
                                        </TableCell>
                                        <TableCell align="right">{row.Quantity}</TableCell>
                                        <TableCell>
                                            <Tooltip title={row.Description}>

                                                <p style={{
                                                    width: '12vw',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                }}>
                                                    {row.Description}
                                                </p>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="right">{Rssr.formatPrice(row.TotalGross)}</TableCell>
                                        <TableCell align='right'>{Priority[row.Priority]}</TableCell>
                                    </TableRow>
                                );
                            })}

                        </TableBody>
                    </Table>
                </TableContainer>
                <section style={{
                    backgroundColor: '#F4F4F4',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    padding: 15,
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 16,
                    fontWeight: 600,
                    color: '#644AF3',
                }}>
                    <p style={{ marginRight: 10 }}>Total</p>
                    <p >{Rssr.formatPrice(PriceTotal)}</p>
                </section>
            </Paper>
        </Box>
    );
}