/**
 * Styles for Modal SAT view
 * @fileoverview Modal SAT view styles
 */


const styles = theme => ({
    sectionHistory: {
        minHeight: '80px',
        paddingBlock: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderBottom: '1px solid #828282',
    },
    HistoryTitle: {
        color: 'var(--azul, #80B4FF)',
        fontFamily: "Source Sans Pro, sans-serif",
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '157.15 %' /* 18.858px */
    },
    HistoryContent: {
        color: '#828282',
        fontFamily: "Source Sans Pro",
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '157.15%' /* 22.001px */
    },
    saveButton: {
        "&.MuiIconButton-root": {
            backgroundColor: "#0BCBFB",
            color: "#050B7F",
        }
    }
});

export default styles;
