/**
 * @file Custom Notistack.
 * @author Dworak Esteban <esteban.cervantes@ressser.com>
 */

// NPM Packages Imports.
import Button from '@material-ui/core/Button';
import {MdClose} from 'react-icons/md';
import React from 'react';

// Local Imports.
import { enqueueSnackbar, closeSnackbar } from 'redux/slice/snackBar';

export function enqueueGenericSnackbar(dispatch, message, variant) {
  const enque = (...args) => dispatch(enqueueSnackbar(...args));
  const close = (...args) => dispatch(closeSnackbar(...args));

  const action = (key) => (
    <>

      <Button onClick={() => close(key)}>
        <MdClose style={{ color: 'white' }} />
      </Button>
    </>
  );

  enque({
    message,
    options: {
      key: new Date().getTime() + Math.random(),
      variant,
      autoHideDuration: 8000,
      action,
    },
  });
}

export function enqueueWarningSnackbar(dispatch, message) {
  enqueueGenericSnackbar(dispatch, message, 'warning');
}

export function enqueueSuccessSnackbar(dispatch, message) {
  enqueueGenericSnackbar(dispatch, message, 'success');
}

export function enqueueErrorSnackbar(dispatch, message) {
  enqueueGenericSnackbar(dispatch, message, 'error');
}

export function enqueueInfoSnackbar(dispatch, message) {
  enqueueGenericSnackbar(dispatch, message, 'info');
}


export default { enqueueWarningSnackbar };
