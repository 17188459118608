/**
 * @fileoverview Modal component to use in the project
 * @version 1.0
 * @author César Paul Hernández Camacho
 * @date 30/11/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 * @update 30/11/2020
 */

import React from "react";
import Proptypes from "prop-types";
//@material-ui core
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
//@material-ui icons
import { MdClose } from "react-icons/md";
//Core Components
import Container from "components/Grid/GridContainer";
import Item from "components/Grid/GridItem";

import styles from "./CustomModal.styles";

const useStyles = makeStyles(styles);

export default function CustomModal(props) {
  const classes = useStyles();

  const classCard =
    props.type === "AcceptCookies"
      ? classes.cardPoliticas
      : props.type === "AddNotification"
      ? classes.cardNotification
      : classes.card;

  const close =
    props.type === "AcceptCookies" || props.type === "AddNotification";

  return (
    <>
      <Modal open={props.open} onClose={() => props.onClose()}>
        <Container
          item
          xs={12}
          alignItems={"center"}
          justify={"center"}
          className={classes.container}
        >
          <Container
            item
            xs={12}
            direction="row"
            alignItems="flex-start"
            justify="flex-start"
            className={`${classCard} ${props.cardClass}`}
          >
            {!close ? (
              <Item container alignSelf="flex-end" justify="flex-end" xs={12}>
                <IconButton
                  style={{ alignself: "flex-end" }}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={props.onClose}
                >
                  <MdClose />
                </IconButton>
              </Item>
            ) : undefined}
            <Item
              container
              alignItems="center"
              justify="center"
              xs={12}
              direction="column"
            >
              {props.children}
            </Item>
          </Container>
        </Container>
      </Modal>
    </>
  );
}

CustomModal.defaultProps = {
  open: false,
};

CustomModal.propTypes = {
  open: Proptypes.bool,
  onClose: Proptypes.func,
  cardClass: Proptypes.object,
};
