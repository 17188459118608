/**
 * @fileoverview Contexto de los modales de la pantalla de SAT
 * @version 1.1.0
 * @author Carlos Emilio Blanco Lopez
 * @date 23/01/2024
 * @update 03/07/24
 * @copyright 2024 RESSER S.A de C.V
 */


export const initialValue = {
    ModalInfo: false,
    ModalHistory: false,
    ModalComments: false,
    ModalAttachment: false,
    Agreements: [],
    AgreementData: {},
    Comments: [],
    History: [],
    itemsSelected: [],
    itemsSelectedLabours: [],
    Attachment:[],
    client: '',
};

export const reducer = (state, action) => {
    switch (action.type) {
        case 'ModalInfo':
            return {
                ...state,
                ...action.value,
                ModalHistory: false,
                ModalComments: false,
                ModalAttachment:false,
            };
        case 'ModalHistory':
            return {
                ...state,
                ...action.value,
                ModalInfo: false,
                ModalComments: false,
                ModalAttachment:false,
            };
        case 'ModalComments':
            return {
                ...state,
                ...action.value,
                ModalInfo: false,
                ModalHistory: false,
                ModalAttachment: false,
            };
        case 'ModalAttachment':
            return {
                ...state,
                ...action.value,
                ModalInfo: false,
                ModalHistory: false,
                ModalComments: false,
            }
        case 'Agreements':
            return {
                ...state,
                Agreements: action.value,
            };
        case 'closeAll':
            return { ...initialValue, Agreements: state.Agreements, client: state.client };
        case 'setSelected':
            return {
                ...state,
                itemsSelected: action.value,
            };
        case 'setSelectedLabours':
            return {
                ...state,
                itemsSelectedLabours: action.value,
            };
        case 'setClient':
            return {
                ...state,
                client: action.value,
            };
        default:
            return state;
    }
};