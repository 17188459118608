/**
 * @fileoverview MaintenanceTimelineBR componente para el muestreo de la timeline de Brasil
 * @version 1.0.0
 * @author Mártin Duran Anguiano
 * @date 25/10/2021
 * @copyright 2020 Industrias RESSER S.A de C.V
 */
import React, { useState, useEffect } from 'react';
import PropTypes, { element } from 'prop-types';
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography';
import swal from 'sweetalert';
import axios from 'libs/AxiosHelper';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

//translate
import { useTranslation } from 'react-i18next';

//redux actions
import { showCircularProgress, closeCircularProgress } from 'redux/slice';
import { enqueueErrorSnackbar, enqueueInfoSnackbar } from 'libs/Snackbar';
import { useDispatch } from 'react-redux';
import { enqueueSuccessSnackbar } from 'libs/Snackbar';
import Rssr from 'libs/Rssr';

let activeCheckpoint = 0;
let someCanceled = false;


const MaintenanceTimelineBR = ({ ticket, onClose, open, currentTicket, onShowReschedulingForm, onTicketClickHandler }) => {
const [t] = useTranslation('common');
const dispatch = useDispatch();

const closeClickHandler = () => {
  onClose();
}

  useEffect(() => {
  }, [])
  /**
   * Función encargada de dar formato a las fechas del 
   * @param {*} date Fecha a formatear para mostrar en un punto de control actual
   * @param {*} item Objeto con la información del punto de control actual
   * @param {*} key Index del punto de control actual
   * @param {*} isCancelled Bandera que indica si el punto de control actual tiene un estatus cancelado
   * @returns 
   */
  function formatDate(date, item, key, isCancelled) {
    let { appointmentDate, deliveryDate, approximateDeliveryDate} = item;
    let newFormatDate = '';

    if(isCancelled === true) return newFormatDate = Rssr.formatingDate(date, 1, t);

    switch (key) {
      case 1:
        newFormatDate = Rssr.formatingDate(appointmentDate, 1, t);
        return newFormatDate;

      case 2:
        let hourDate = date.split('T')[1];
        if(hourDate == '00:00:00' || hourDate == '00:00')return ''
        return newFormatDate = Rssr.formatingDate(date, 1, t)
        
      case 5:
        //Muestra el mensaje de que aun no se tiene un horario previsto de entrega esto es por si no se nos proporciona la fecha de entrega
        if(deliveryDate == null || deliveryDate == '') {
          return t('AldSacTimeLine.generalMessages.6');

      }else{
          newFormatDate = Rssr.formatingDate(deliveryDate, 1, t)
          //Muestra de fecha prevista de entrega de vehiculo
          if( isCancelled == false && approximateDeliveryDate == true) {
              return t('AldSacTimeLine.generalMessages.4') + " " + newFormatDate;
          }
          
          //Muestra de fecha final para entrega de vehiculo
          else if( isCancelled == false && approximateDeliveryDate == false) {
              return(newFormatDate);
          }
      }
      break;
    
      default:
        return newFormatDate = Rssr.formatingDate(date, 1, t);
    }
  }

  /**
   * Función encargada de retornar los titulos de cada punto de control en la timeline
   * @param {*} item Objeto con la información del punto de control actual
   * @param {*} key Index del punto de control actual
   * @param {*} activeCheckpoint Punto de control activo
   * @param {*} isApproved Bandera que indica si el estado del presupuesto (solo se usa para el punto de control 4)
   * @param {*} isCheckPoint Bandera que indica si el punto de control actual es un CheckPoint
   * @param {*} isCancelled Bandera que indica si el punto de control actual tiene un estatus cancelado
   * @returns 
   */
  const renderTitle = (item, key, activeCheckpoint, isApproved, isCheckPoint, isCancelled) => {
    if(!isCancelled) {
      switch (item.CheckPointId) {
          case 0:
              return(t('AldSacTimeLine.titles.0'));
          case 1:
              return(t('AldSacTimeLine.titles.1'));
          case 2:
              //El cliente todavia no hace checkIn o aun no llega a este checkpoint
              if(activeCheckpoint < item.CheckPointId || activeCheckpoint === item.CheckPointId  ) {
                  return(t('AldSacTimeLine.titles.2'));
              }
              //El cliente realizo su checkin o ya paso este checkpoint
              return(t('AldSacTimeLine.titles.7'));
          case 3:
              if(activeCheckpoint < item.active || activeCheckpoint === item.active) {
                  return(t('AldSacTimeLine.titles.3'));
              }
              return(t('AldSacTimeLine.titles.8'));
          case 4:
              // Ornamento en aprovación
              if(isApproved == 0) {
                  return(t('AldSacTimeLine.titles.4'));
              }
              // Ornamento aprovado
              else if (isApproved == 1) {
                  return(t('AldSacTimeLine.titles.9'));
              }
              // Ornamento reprobado
              else if (isApproved == 2) {
                  return(t('AldSacTimeLine.titles.11'));
              }
          case 5:
            return(t('AldSacTimeLine.titles.5'));
        case 6:
          //El cliente todavia no hace checkOut o aun no llega a este checkpoint
          if(item.DateTime == "" || item.DateTime == null) {
              return(t('AldSacTimeLine.titles.6'));
          }
          //El cliente realizo su checkOut y ya se tiene fecha del checkout
          return(t('AldSacTimeLine.titles.10'));
        default:
          break;
      }
    }
    return(t('AldSacTimeLine.generalMessages.9'));
  }

  /**
   * Función que renderea los mensajes generales de los puntos de control según el punto de control activo
   * @param {*} item Objeto con la información del punto de control actual
   * @param {*} key Index del punto de control actual
   * @param {*} activeCheckpoint Punto de control activo
   * @returns 
   */
  const renderGeneralMessage = (item, key, activeCheckpoint) => {
    var aux = parseInt(item.CheckPointId, 10);
      switch (aux) {
          case 0:
              break;
          //Texto de Agendamento.
          case 1:
              if(activeCheckpoint === 1) {
                  return(t('AldSacTimeLine.generalMessages.0'));
              }
              return (item.fornecedor);
          //Texto de Check in.
          case 2:
          if(item.active === 2 && activeCheckpoint === 2) {
              return(t('AldSacTimeLine.generalMessages.10'));
          }
          return(t('AldSacTimeLine.generalMessages.1'));
          //Texto de Ornamento en elaboración
          case 3:
              if(activeCheckpoint === 3) {
                  return(t('AldSacTimeLine.generalMessages.2'));
              }
              return ("");
          case 4:
              if(activeCheckpoint >= 4) {
                  //Texto para ornamento en aprovación
                  if(item.approved == 0) {
                      return(t('AldSacTimeLine.generalMessages.3'));
                  }

                  //Texto para ornamento cancelado
                  else if (item.approved == 2) {
                      return(t('AldSacTimeLine.generalMessages.8'));
                  }
              }
              return ("");
          case 5:
              if(activeCheckpoint === 5) {
                  return(t('AldSacTimeLine.generalMessages.6'));
              }
              return("");
          case 6:
              if(activeCheckpoint < 6) {
                  return(t('AldSacTimeLine.generalMessages.12'));    
              }
              return(t('AldSacTimeLine.generalMessages.5'));
          default:
              break;
      }
  }

  /**
   * Punto de controll informativo
   * @param {*} item Objeto con la información del punto de control actual
   * @param {*} key Index del punto de control actual
   * @param {*} lastItem Ultimo punto de control
   * @param {*} isCheckPoint Bandera para indicar si el punto de control actual es un checkpoint
   * @param {*} isCancelled Bandera para indicar si el putno de control actual tiene un estatus cancelado
   * @param {*} activeCheckpoint Punto de control activo
   * @param {*} isApproved Bandera que indica si el estado del presupuesto (solo se usa para el punto de control 4)
   * @returns 
   */
  const timeItem = (item, key, lastItem, isCheckPoint, isCancelled, activeCheckpoint, isApproved) => (
    <TimelineItem key={key}>
      <TimelineOppositeContent style={{maxWidth: 0}}>
      </TimelineOppositeContent>
      <TimelineSeparator>
      {/* Generación de dots y seteo de color */}
      {/* Puntos de control pasados */}
      {
        (key < activeCheckpoint  && isCancelled == false) &&
        <TimelineDot color="primary"></TimelineDot> 
      }
      {/* Punto de control activo */}
      {
        (key === activeCheckpoint && isCancelled == false) &&
        <TimelineDot color="secondary"></TimelineDot> 
      }
      {/* Punto de control inactivo */}
      {
        (key > activeCheckpoint && isCancelled == false) &&
        <TimelineDot color="grey"></TimelineDot>
      }
      {/* Punto de control cancelado */}
      {
        (key === activeCheckpoint && isCancelled == true) &&
        <TimelineDot color="secondary"></TimelineDot>
      }
      {/* CheckIn/CheckOut */}
      {/* TODO: realizar dot para checkin y checkout EUF4183*/}

      {!lastItem && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
      {/* Contenido para punto de control normal */}

      {/* Titulo de punto de control */}
      {
        (isCancelled === false) &&
        <Typography color={key == activeCheckpoint || key < activeCheckpoint ?  "primary": "textSecondary"}>
          {renderTitle(item, key, activeCheckpoint, isApproved, false, isCancelled)}
        </Typography>
      }

      {/* Mensaje general */}
      {
        ((item.DateTime === "" || item.DateTime === null) && isCancelled === false) &&
        <Typography variant="body2" color="textSecondary">
          {renderGeneralMessage(item, key, activeCheckpoint)}
        </Typography>
      }

      {/* Fecha de la solicitud */}
      {
        (item.DateTime != "" && item.DateTime != null && isCancelled == false && isCheckPoint === false) &&
        <Typography variant="body2" color="textSecondary">
          {formatDate(item.DateTime, item, key, isCancelled)}
        </Typography>
      }

      {/* Mensaje para entrega prevista */}
      {
        (item.DateTime != "" && item.DateTime != null && isCancelled == false && isCheckPoint === false && item.approximateDeliveryDate === true && item.CheckPointId === 5) &&
        <Typography variant="body2" color="textSecondary">
          {t('AldSacTimeLine.generalMessages.11') }
        </Typography>
      }

      {/* Mensaje de reprobación */}
      {
        (item.CheckPointId === 4 && isCancelled === false && isCheckPoint === false && item.DateTime != "" && item.DateTime != null) &&
        <Typography color={key == activeCheckpoint || key < activeCheckpoint ?  "primary": "textSecondary"}>
          {renderGeneralMessage(item, key, activeCheckpoint)}
        </Typography>
      }

      {/* Nombre del supplier */}
      {
        (item.fornecedor != "" && item.fornecedor != null && isCancelled === false && isCheckPoint === false && item.CheckPointId === 1) &&
        <Typography variant="body2" color="textSecondary">
          {item.fornecedor}
        </Typography>
      }

      {/* Dirección del proveedor */}
      {
        (item.local != "" && item.local != null && isCancelled === false && isCheckPoint === false && item.CheckPointId === 1) &&
        <Typography variant="body2" color="textSecondary">
          {item.local}
        </Typography>
      }

      {/* CEP del proveedor */}
      {
        (item.zipCode != "" && item.zipCode != null && isCancelled === false && isCheckPoint === false && item.CheckPointId === 1) &&
        <Typography variant="body2" color="textSecondary">
          {item.zipCode}
        </Typography>
      }

      {/* Button to CheckIn/CheckOut */}
      {
        (isCheckPoint === true && item.CheckPointId === 2 && isCancelled === false && item.Check === 0) &&
        <Button onClick={() => {onCheckPressHandler(1)}} style={{ backgroundColor: "#0BCBFB", color: "#050B7F", fontWeight: 'bold', borderRadius: '1.5rem', marginTop: 15, minWidth: 200}}>
          {t('AldSacTimeLine.Button.3')}
        </Button>
      }

      {
        (isCheckPoint === true && item.CheckPointId === 6 && isCancelled === false && item.Check === 0) &&
        <Button onClick={() => {onCheckPressHandler(2)}}  style={{ backgroundColor: "#0BCBFB", color: "#050B7F", fontWeight: 'bold', borderRadius: '1.5rem', marginTop: 15, minWidth: 200}}>
          {t('AldSacTimeLine.Button.4')}
        </Button>
      }

      {/* Contenido para punto de control cancelado */}

      {/* Titulo de servicio cancelado */}
      {
        (isCancelled === true) &&
        <Typography color={"textSecondary"}>
            {renderTitle(item, key, activeCheckpoint, isApproved, false, isCancelled)}
        </Typography>
      }

      {/* fecha de cancelación */}
      {
        (item.DateTime != "" && item.DateTime != null && isCancelled == true) &&
        <Typography variant="body2" color="textSecondary">
          {formatDate(item.DateTime, item, key, isCancelled)}
        </Typography>
      }
      </TimelineContent>
    </TimelineItem>
  )

  /**
   * Función que renderea la timeline en base a la información obtenida de un ticket de mantenimiento
   * @returns timeItemComponent/CheckPointItemComponent
   */
  const renderTimeline = () => {
      var timelineToRender = [];

      if(ticket.length > 0) {
        let ticketInfo = ticket;
        activeCheckpoint = ticket.findIndex(x => x.active === true);
        
        //Validación de si el ultimo punto de controll contiene una cancelación
        if(ticketInfo[ticketInfo.length-1].cancelled === true) {
          for (let index = 0; index < ticketInfo.length -1 ; index++) {
            ticketInfo[index].cancelled = false;
          }
        }

        //Validación para validar que la solicitud de ticket tiene su date time desploqueando el punto de control Agendamiento.
        if (activeCheckpoint === 0 && ticketInfo[0].DateTime != null && ticketInfo[0].cancelled == false) {
          activeCheckpoint = 1;
          ticketInfo[0].active = false;
          ticketInfo[1].active = true;
        }

        //Validación de que el punto de control Agendamiento ya tiene una fecha brindada por ALD y podemos desbloquear el botón Check-In.
      if (activeCheckpoint === 1 && ticketInfo[1].appointmentDate != null && ticketInfo[1].cancelled == false) {
        activeCheckpoint = 2;
        ticketInfo[1].active = false;
        ticketInfo[2].active = true;
      }

      //Validación de que el punto de control Check-In ya se realizo y podemos desbloquear el punto de control Ornamento en elaboración.
      if (activeCheckpoint === 2 && ticketInfo[2].DateTime != null && ticketInfo[2].cancelled == false) {
        activeCheckpoint = 3;
        ticketInfo[2].active = false;
        ticketInfo[3].active = true;
      }

      //Validación de que el punto de control Ornamento en elaboración ya se realizo y podemos desbloquear el punto de control Ornamento en aprovación.
      if (activeCheckpoint === 3 && ticketInfo[3].DateTime != null && ticketInfo[3].cancelled == false) {
        activeCheckpoint = 4;
        ticketInfo[3].active = false;
        ticketInfo[4].active = true;
      }

      //Validación de que el punto de control Ornamento en aprovación ya fue aprovado ,con una fecha de actualización y podemo pasar al punto de control entrega de vehiculo.
      if (activeCheckpoint === 4 && ticketInfo[4].DateTime != null && ticketInfo[4].approved != 2 && ticketInfo[4].cancelled == false) {
        activeCheckpoint = 5;
        ticketInfo[4].active = false;
        ticketInfo[5].active = true;
      }

      //Validación de que el punto de control entrega de vehiculo ya tiene una fecha de entrega final y podemos desbloquear el Check-Out.
      if (activeCheckpoint === 5 && ticketInfo[5].deliveryDate != null && ticketInfo[5].cancelled == false) {
        activeCheckpoint = 6;
        ticketInfo[5].active = false;
        ticketInfo[6].active = true;
      }

      ticketInfo.map((element, key) => {
        var active = element.active;
        var first = key===0? true: false;
        var isChecked = ( (element.CheckPointId === 2 || element.CheckPointId === 6) && active && element.Check === 0) ? true : false;
        var isCancelled = element.cancelled;
        let isApproved = element.approved;
        let isCheckIn = false;
        var lastItem = ((parseInt(key, 10) === (ticket.length - 1))) ? true : false;

        
        if(element.CheckPointId === 2 && element.active && element.Check == 0 && isCancelled == false || element.CheckPointId === 6 && element.active && element.Check == 0 && isCancelled == false) {
          isCheckIn = true;
        }

        if(first == true) someCanceled = false;

        //Validación para generar puntos de control normales y el cancelado
        if (someCanceled === false) {
          //Validación para caso donde termina el ticket de forma correcta
          if(isCancelled == true && element.CheckPointId == 6 && element.Check == 2) {
            lastItem = true;
            isCancelled = false;
            someCanceled = false;
          }
          else if(isCancelled == true){
            lastItem = true;
            someCanceled = true
          }
          
         
          timelineToRender.push(timeItem(element, key, lastItem, isCheckIn, isCancelled, activeCheckpoint, isApproved));
        }
      });
      return timelineToRender
    }
  }

  /**
   * Función que permite la cancelación y reagendamiento de un ticket
   */
  const onCancelTicket = () => {
    
    swal(`${t('generalText.advice')}`, `${t('AldSacTimeLine.alerts.1')}`, 'info', {
      buttons: {
        cancel: t('generalText.no'),
        accept: t('generalText.yes')
      },
    })
    .then((value) => {
      switch (value) {
          case "accept":
            swal(`${t('generalText.advice')}`, `${t('AldSacTimeLine.alerts.3')}`, 'info', {
              buttons: {
                cancel: t('generalText.cancel'),
                accept: t('AldSacTimeLine.alerts.2')
              },
            })
            .then((value) => {
              switch (value) {
                case "accept":
                  onShowReschedulingForm();
                    break;
                  default:
                    dispatch(showCircularProgress());
                    axios.put(`ALDSACReagendamientoFMV2/Cancelacion?service=${currentTicket.ticketId}`, {"reagendamiento": false, "comentario": "Favor cancelar o agendamento para revisão do meu veículo"})
                    .then(response => {
                      if(response.data.success){
                        enqueueSuccessSnackbar(dispatch, t('AldSacTimeLine.alerts.9'));
                      }
                      else{
                        enqueueErrorSnackbar(dispatch, t('AldSacTimeLine.alerts.10'));
                      }
                      dispatch(closeCircularProgress());
                    })
                    .catch(error => {
                      dispatch(closeCircularProgress());
                    });
                    break;
              }
            });
            break;
          default:
              break;
      }
    });
  }

  /**
   * Función que muestra un mensaje en relación a la acción solicitada (Check In, Check Out)
   * @param {*} id 
   */
  const onCheckPressHandler = (id) => {
    let titleAlert = '';
    let messageAlert = '';

    switch (id) {
      //Check in
      case 1:
        titleAlert = t('AldSacTimeLine.alerts.15');
        messageAlert =t('AldSacTimeLine.alerts.16');
        break;
      //Check out
      case 2:
        titleAlert = t('AldSacTimeLine.alerts.17');
        messageAlert =t('AldSacTimeLine.alerts.18');
        break;
      default:
        break;
    }

    swal(titleAlert, messageAlert, 'info', {
      buttons: {
        cancel: t('AldSacTimeLine.alerts.8'),
        accept: t('AldSacTimeLine.alerts.6')
      },
    })
    .then((value) => {
      switch (value) {
        case "accept":
          dispatch(showCircularProgress());
          onCHeckInOut(id);
          break;
        default:
          break;
      }
    });
  }

  /**
   * Función que realiza la petición del checkIn o checkOut según sea el caso
   * @param {*} id: número que representa la función efectuada por el usuario 1(CheckIn) y 2(Checkout)
   * 
   */
  const onCHeckInOut = (id) => {
    let { ticketId } = currentTicket;
    let data = { "check": id, "ticketId": ticketId, "comentario": "" };

    axios.put(`ALDSACCheckInOut`, data)
    .then(response => {
      if(response.data.success) {
        dispatch(closeCircularProgress());
        enqueueSuccessSnackbar(dispatch, t('AldSacTimeLine.alerts.13'));
        onTicketClickHandler(currentTicket);
      }else{
        dispatch(closeCircularProgress());
        enqueueErrorSnackbar(dispatch, t('AldSacTimeLine.alerts.19'));
      }
    })
  }

  /**
   * Función que renderea el botón de cancelación en la timeline mientras este no pasa del punto de control 5 o este cancelado
   * @returns CancelButton Component
   * 
   */
  const renderCancelButton = () => {
    if(ticket.length > 0){
      let activeCheckpoint = ticket.findIndex(x => x.active === true);
      let CanceledService = ticket.findIndex(x => x.cancelled === true);
  
      if(activeCheckpoint === null) return
      if(activeCheckpoint < 5 && CanceledService === -1) {
        return (
          <Button onClick={onCancelTicket} color="default">
            {t('AldSacTimeLine.Button.0')}
          </Button>
        );
      }
    }
    return
  }

  return (
    <>
      <Dialog open={open} onClose={closeClickHandler} aria-labelledby="">
        <DialogTitle id=""></DialogTitle>
        <DialogContent>
          <Timeline align="left">
            <Typography variant="h5" color="primary"> {t('maintenance.modal.0')} </Typography>
            <Typography color="textSecondary"> {`${t('maintenance.modal.1')}: ${ticket.length > 0 ? ticket[0].numberALDSAC: ''}`} </Typography>
            <Typography color="textSecondary" style={{marginBottom: 15}}> {`${t('contracts.filters.plates')}: ${currentTicket.plateNumber}`} </Typography>
            {renderTimeline()}
          </Timeline>
        </DialogContent>
        <DialogActions>
        {renderCancelButton()}
          <Button onClick={closeClickHandler} color="default">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
 

MaintenanceTimelineBR.propTypes = {
  ticket: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired
}

export default MaintenanceTimelineBR;