// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCrd_C1xtGxV9muYDcVBMbNa4QCo2RVO94",
  authDomain: "myaldfleet.firebaseapp.com",
  projectId: "myaldfleet",
  storageBucket: "myaldfleet.appspot.com",
  messagingSenderId: "543756786960",
  appId: "1:543756786960:web:b6cf19c67e2b0943f5d995",
  measurementId: "G-DKEX1PF8VW"
};