/**
 * @fileoverview IFRS16
 * @version 1.0
 * @author César Paul Hernández Camacho
 * @date 10/11/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 * @update 18/11/2020
 */
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Hidden from "@material-ui/core/Hidden";
import InputLabel from "@material-ui/core/InputLabel";
//redux actions
import { useDispatch } from "react-redux";
import { onShowAlertMessage } from "redux/slice";
// @material-ui/icons
import { MdExpandMore } from "react-icons/md";
//Translations
import { useTranslation } from "react-i18next";
// Estilos
import styles from "./IFRS16.styles";
// core components
import Item from "components/Grid/GridItem.js";
import Container from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
//axios
import axios from "libs/AxiosHelper";
//messages
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from "libs/Snackbar";

//Component Styles
const useStyles = makeStyles(styles);

const axiosB = require("axios");

const IFRS16 = (props) => {
  //redux
  const dispatch = useDispatch();

  //translationos
  const [t, i18n] = useTranslation("common");

  //state
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reportType, setEventReportType] = useState(1);
  const [clients, setClients] = useState([]);

  const classes = useStyles();
  useEffect(() => {
    getClient();
  }, []);

  /**
   * Función que realiza la solicitud del reporte IFRS16 en base al tipo y las fechas seleccionadas.
   *
   */
  const onSendClickHandler = () => {
    var aux = [];
    clients.map((client) => {
      aux.push(client.clientNumber);
    });
    const dataToSend = {
      reportType,
      cutOffDate: startDate,
      endDate: reportType === 1 ? null : endDate,
      clients: aux.join(),
    };
    axios
      .post(`ifrs16FMV2`, dataToSend)
      .then((response) => {
        if (response.data.success) {
          enqueueSuccessSnackbar(dispatch, t("ifrs16.success.0"));
        }
      })
      .catch((error) => {
        dispatch(onShowAlertMessage());
        enqueueErrorSnackbar(dispatch, t("ifrs16.errors.0"));
      });
  };

  /**
   * Función que permite obtener los clientes del usuario logeado para realizar la solicitud del reporte IFRS16
   *
   */
  const getClient = () => {
    axios
      .get(`clientsFMV2`)
      .then((response) => {
        if (response.data.success) {
          setClients(response.data.items);
        }
      })
      .catch((error) => {
        dispatch(onShowAlertMessage());
      });
  };

  return (
    <>
      <Container>
        <Item xs={12} md={12}>
          <Accordion defaultExpanded>
            {/* Titulo del modulo */}
            <AccordionSummary
              expandIcon={<MdExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p className={classes.titleModule}> {t("dashboardNames.1")} </p>
            </AccordionSummary>

            <AccordionDetails>
              <Container xs={12} md={12} direction="column" alignItems="center">
                <Container
                  style={{ width: "100%" }}
                  xs={12}
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  {/* Selector tipo de reporte IFRS16 */}
                  <Item xs={12} md={4}>
                    <FormControl fullWidth className={classes.ifrs16Form}>
                      <InputLabel> {t("ifrs16.inputs.0")} </InputLabel>
                      <Select
                        labelId="type"
                        id="type"
                        className={classes.ifrs16Selector}
                        value={reportType}
                        onChange={(e) => {
                          setEventReportType(e.target.value);
                        }}
                      >
                        <MenuItem value={1}> {t("ifrs16.types.0")} </MenuItem>
                        <MenuItem value={2}> {t("ifrs16.types.1")} </MenuItem>
                      </Select>
                    </FormControl>
                  </Item>

                  {/* Date time 1 */}
                  <Item xs={12} md={4}>
                    <CustomInput
                      labelText={
                        reportType === 1
                          ? t("ifrs16.inputs.2")
                          : t("ifrs16.inputs.1")
                      }
                      id="startDate"
                      labelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        value: startDate,
                        type: "date",
                        name: "startDate",
                        onChange: (e) => {
                          setStartDate(e.target.value);
                        },
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                    />
                  </Item>

                  {/* Date time 2 */}
                  <Hidden xsUp={reportType === 1}>
                    <Item xs={12} md={4}>
                      <CustomInput
                        id="endDate"
                        labelText={t("ifrs16.inputs.3")}
                        labelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          value: endDate,
                          type: "date",
                          name: "endDate",
                          onChange: (e) => {
                            setEndDate(e.target.value);
                          },
                        }}
                        formControlProps={{
                          fullWidth: true,
                          stye: { margin: 0 },
                        }}
                      />
                    </Item>
                  </Hidden>
                </Container>
              </Container>
            </AccordionDetails>

            <Container
              xs={12}
              md={12}
              direction="column"
              alignItems="center"
              justify="center"
              style={{ padding: 10 }}
            >
              {/* botón de request */}
              <Item Container xs={12} alignItems="center" justify="center">
                <Button
                  onClick={onSendClickHandler}
                  className={classes.ifrs16Button}
                  aria-label="edit"
                >
                  {t("ifrs16.sendMessage")}
                </Button>
              </Item>
            </Container>
          </Accordion>
        </Item>
      </Container>
    </>
  );
};

export default IFRS16;
