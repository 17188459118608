/**
 * @fileoverview Custom hook for SAT page.
 * @version 1.0.0
 * @author Carlos Emilio Blanco Lopez
 * @date 23/01/2024
 * @copyright 2024 RESSER S.A de C.V
 */


import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'libs/AxiosHelper';
import { useDispatch } from 'react-redux';
import { showCircularProgress, closeCircularProgress, onShowAlertMessage } from 'redux/slice';
import { enqueueSuccessSnackbar, enqueueInfoSnackbar } from 'libs/Snackbar';
import { enqueueErrorSnackbar } from 'libs/Snackbar';

const useSAT = () => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();

    // States of the component
    const [Page, setPage] = useState(1);
    const [Agreements, setAgreements] = useState([]);
    const [PlateData, setPlateData] = useState('');
    const [Status, setStatus] = useState({});//[t('ModuleAgreements.table.status')
    const [SelectClient, setSelectClient] = useState();
    const [Clients, setClients] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getClient();
        return () => { }
    }, []);

    useEffect(() => {
        if (PlateData === '') return;
        if (PlateData.length === 3) {
            GetData();
        }
    }, [PlateData]);

    useEffect(() => {
        if (SelectClient === null || SelectClient === undefined || SelectClient === '')
            return;

        GetData();

    }, [SelectClient])

    /**
     * @Function onChangePage
     * @description Función que cambia la página de la tabla
     */

    const onChangePage = (event, page) => {

        if(event === 'back' && Page === 1) return;
        if(event === 'next' && Agreements.length < 50) return;
        
        const newPage = event === 'next' ? Page + 1 : event === 'back' && Page > 1 ? Page - 1 : page;
        GetData(PlateData, Status, newPage);
        setPage(newPage);
    }

    /**
     * @Function GetData
     * @description Función que obtiene los datos del Presupuestos
     */

    const GetData = async (plate = '', status = '', page = 1) => {
        setStatus(status);
        setAgreements([]);
        dispatch(showCircularProgress());
        await axios.get(`SATAgreements?customerFilter=${SelectClient?.clientNumber}&statusFilter=${status}&plateFilter=${plate}&page=${page}`)
            .then(async response => {

                if (response?.data?.code) return enqueueErrorSnackbar(dispatch, 'Ocurrió un error al obtener los presupuestos, Intente nuevamente');
                setAgreements(response?.data.Message ? [] : response.data);
            })
            .catch((error) => {
                enqueueErrorSnackbar(dispatch, 'Ocurrio un error al obtener los presupuestos.');
                dispatch(closeCircularProgress());
            }).finally(() => dispatch(closeCircularProgress()));
    }


    /**
     * @Function GetAgreement
     * @description Función que obtiene los datos del Presupuesto
     */

    const GetAgreement = async (id, dispatchView, versionId) => {
        dispatch(showCircularProgress());
        axios.get(`SATAgreements/Details?query=${id}&version=${versionId}&queryCustomer=${SelectClient?.clientNumber}`)
            .then(async response => {
                dispatchView({
                    type: 'ModalInfo', value: {
                        ModalInfo: true,
                        AgreementData: response.data,
                        AgreementId: id
                    }
                });
                dispatch(closeCircularProgress());
            }).catch((error) => {
                enqueueErrorSnackbar(dispatch, 'Ocurrio un error al obtener el presupuesto.');
                dispatch(closeCircularProgress());
            })
    }

    /**
     * @Function GetComments
     * @description Función que obtiene los comentarios del Presupuesto
     */

    const GetComments = async (id, dispatchView) => {
        dispatch(showCircularProgress());
        axios.get(`/SATComments?query=${id}&queryCustomer=${SelectClient?.clientNumber}`)
            .then(async response => {
                dispatchView({
                    type: 'ModalComments', value: {
                        ModalComments: true,
                        Comments: response.data,
                        AgreementId: id
                    }
                })
                dispatch(closeCircularProgress());
            }).catch((error) => {
                enqueueErrorSnackbar(dispatch, 'Ocurrio un error al obtener los comentarios.');
                dispatch(closeCircularProgress());
            })
    }

    /**
     * @Function GetHistory
     * @description Función que obtiene el historial del Presupuesto
     */

    const GetHistory = async (id, dispatchView) => {
        dispatch(showCircularProgress());
        axios.get(`SATStoryLine?query=${id}&category=BOOKINGSTORYLINEBYAGREEMENT&queryCustomer=${SelectClient?.clientNumber}`)
            .then(async response => {
                dispatchView({
                    type: 'ModalHistory', value: {
                        ModalHistory: true,
                        History: response.data.sort((a, b) => b.$id - a.$id),
                        AgreementId: id
                    }
                })
                dispatch(closeCircularProgress());
            }).catch((error) => {
                enqueueInfoSnackbar(dispatch, 'Aun no se encuetra disponible este historial');
                dispatch(closeCircularProgress());
            })
    }

    /**
     * @Function GetAttachment
     * @description Función que obtiene los archivos/Attachment del Presupuesto
     */

    const GetAttachment = async (id, dispatchView, versionId) => {
        dispatch(showCircularProgress());
        axios.get(`SATAgreements/Details?query=${id}&version=${versionId}&queryCustomer=${SelectClient?.clientNumber}`)
            .then(async response => {
                if (response.data.Attachments != null) {

                    dispatchView({
                        type: 'ModalAttachment', value: {
                            ModalAttachment: true,
                            AgreementData: response.data,
                            Attachment: response.data.Attachments,
                            AgreementId: id
                        }
                    });
                } else {
                    enqueueInfoSnackbar(dispatch,  t('ModuleAgreements.alerts.emptyAttachment') );
                }
                dispatch(closeCircularProgress());
            }).catch((error) => {
                enqueueErrorSnackbar(dispatch, t('ModuleAgreements.alerts.errorAttachment'));
                dispatch(closeCircularProgress());
            })
    }

    /**
     * @Function PostComment
     * @description Función para agregar un comentario al Presupuesto
     */

    const PostComment = async (data, dispatchView) => {
        dispatch(showCircularProgress());
        axios.post(`SATComments?queryCustomer=${SelectClient?.clientNumber}`, data)
            .then(async response => {
                enqueueSuccessSnackbar(dispatch, 'Comentario agregado');
                dispatch(closeCircularProgress());
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    dispatch(onShowAlertMessage());
                    return;
                }
                enqueueErrorSnackbar(dispatch, 'Ocurrio un error al agregar el comentario.');
                dispatch(closeCircularProgress());
            })
            .finally(() => {
                GetComments(data.AgreementId, dispatchView);
            })
    }

    /**
     * @Function PutAgreement
     * @description Función para actualizar el Presupuesto
     */

    const PutAgreement = async (data, dispatchView, version) => {
        dispatch(showCircularProgress());
        axios.put(`SATAgreements?queryCustomer=${SelectClient?.clientNumber}`, {
            "VersionId": version,
            "Category": "AGREEMENT_STATUS_UPDATE",
            ...data,
        })
            .then(async () => {
                dispatchView({ type: 'ModalInfo', value: { ModalInfo: false } });
                enqueueSuccessSnackbar(dispatch, data.Comment);
                dispatch(closeCircularProgress());
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    dispatch(onShowAlertMessage());
                    return;
                }
                enqueueErrorSnackbar(dispatch, 'Ocurrio un error al actualizar el presupuesto.');
                dispatch(closeCircularProgress());
            })
            .finally(() => {
                GetData();
            })
    };

    /**
     * Función que permite obtener los clientes del usuario logeado para realizar la solicitud del reporte IFRS16
     *
     */
    const getClient = () => {
        const url = `clientsFMV2`;
        dispatch(showCircularProgress());
        axios.get(url)
            .then(response => {
                if (response.data.success) {
                    setClients(response.data.items);
                }
                dispatch(closeCircularProgress());
            })
            .catch(error => {
                dispatch(onShowAlertMessage());
            });
    }

    const GetAttachmentId = (customerNumber, attachmentId, setBase64Attachment) => {
        setLoading(true);
        dispatch(showCircularProgress());
        axios.get(`SATAttachments?query=${attachmentId}&queryCustomer=${customerNumber}`)
            .then(async response => {

                setBase64Attachment(response.data);

            }).catch((error) => {
                enqueueErrorSnackbar(dispatch, 'Ocurrió un error al obtener el presupuesto.');

            }).finally(() => setLoading(false), dispatch(closeCircularProgress()));
    }

    return {
        Agreements,
        PlateData,
        Clients,
        SelectClient,
        Page,
        GetAgreement,
        GetComments,
        GetHistory,
        GetAttachment,
        PostComment,
        PutAgreement,
        setPlateData,
        GetData,
        setSelectClient,
        onChangePage,
        GetAttachmentId,
        loading,
        setLoading,
        setPage
    }
}

export default useSAT