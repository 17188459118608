/**
 * @fileoverview Maintenance view
 * @version 1.0.0
 * @author Martin Duran Anguiano
 * @date 22/12/2020
 * @copyright 2020 RESSER S.A de C.V
 */

import React, { useState, useRef, useEffect } from "react";
import Cookies from "js-cookie";
//@material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
//redux
import { useDispatch, useSelector } from "react-redux";
// @material-ui/icons
import { MdExpandMore } from "react-icons/md";
//Translations
import { useTranslation } from "react-i18next";
// core components
import Item from "components/Grid/GridItem.js";
import Container from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import ModalTicketBr from "../../components/UI/ModalTicket/ModalTicketBr/ModalTicketBr";
import ModalTicketGnr from "../../components/UI/ModalTicket/ModalTicketGnr/ModalTicketGnr";
import MaintenanceTable from "./MaintenanceTable";
import swal from "sweetalert2";

//styles
import styles from "./Maintenance.styles";
import animationData from "../../assets/lotties/teamwork-gears.json";

//axios
import axios from "libs/AxiosHelper";
//redux actions
import {
  showCircularProgress,
  closeCircularProgress,
  onShowAlertMessage,
} from "redux/slice";

const useStyles = makeStyles(styles);

const Contracts = (props) => {
  //styles
  const classes = useStyles();
  //Reference
  const fileInputRef = useRef(null);
  const TableRef = useRef();
  //redux
  const dispatch = useDispatch();
  const regionId = useSelector((state) => state.auth.loginData.regionId);
  const userRole = useSelector((state) => state.auth.loginData.userRole);
  //translate
  const [t, i18n] = useTranslation("common");

  //state
  const [reRender, setReRender] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showFilterModal, setshowFilterModal] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [clients, setClients] = useState([]);
  const [reloadTickets, setReloadTickets] = useState(false);
  const [rescheduleTicket, setRescheduleTicket] = useState(null);
  const [showGroupModal, setGroupModal] = useState(false);

  useEffect(() => {
    getContracts();
    getClient();
  }, [reRender]);

  /**
   * Función encargada de obtener los contratos de un FM
   * @memberof Maintenance
   */
  const getContracts = () => {
    dispatch(showCircularProgress());
    axios
      .get("ContractsFleetManager")
      .then(async (response) => {
        if (response.data.success) {
          await setContracts(response.data.contracts);
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(onShowAlertMessage());
        }
      });
  };

  /**
   * Función que permite obtener los clientes del usuario logeado para realizar la solicitud del reporte IFRS16
   *
   */
  const getClient = () => {
    if (userRole === 7) {
      axios
        .get(`clientsFMV2/Catalog?customerName=&start=0&limit=12000`)
        .then((response) => {
          if (response.data.success) {
            setClients(response.data.items);
          }
        })
        .catch((error) => {
          dispatch(onShowAlertMessage());
        });
    } else {
      axios
        .get(`clientsFMV2`)
        .then((response) => {
          if (response.data.success) {
            setClients(response.data.items);
          }
        })
        .catch((error) => {
          dispatch(onShowAlertMessage());
        });
    }
  };

  /**
   * Función que muestra el formulario de mantenimiento
   * @memberof Maintenance
   */
  const onRequestNewService = () => {
    swal
      .fire({
        title: t("modalTicketBr.alertMessage.title"),
        text: t("modalTicketBr.alertMessage.description"),
        icon: "warning",
        confirmButtonText: t("generalText.continue"),
      })
      .then((result) => {
        if (result.value) setShowModal(true);
      });
  };

  /**
   * Función que cierra el modal con el formulario de servicio y limpia el objeto de mantenimiento.
   * @memberof Maintenance
   */
  const closeModal = () => {
    setShowModal(false);
  };

  /**
   * Function to hide filter modal
   * @memberof Maintenance
   */
  const onHideModal = () => {
    setshowFilterModal(false);
  };

  /**
   * Function to show filter modal
   * @memberof Maintenance
   */
  const onShowModal = () => {
    setshowFilterModal(true);
  };

  const onShowReschedulingForm = (ticket) => {
    setRescheduleTicket(ticket);
    setShowModal(true);
  };

  /**
   * Function to hide group maintenance modal
   * @memberof Maintenance
   */
  const onHideGroupModal = () => {
    setGroupModal(false);
  };

  /**
   * Function to show group maintenance modal
   * @memberof Maintenance
   */
  const onShowGroupModal = () => {
    swal
      .fire({
        title: t("modalTicketBr.alertMessage.title"),
        text: t("modalTicketBr.alertMessage.description"),
        icon: "warning",
        confirmButtonText: t("generalText.continue"),
      })
      .then((result) => {
        if (result.value) setGroupModal(true);
      });
  };

    return (
        <>
            <Container>
                <Item xs={12} md={12}>
                    <Accordion defaultExpanded>

                        {/* Nombre del modulo */}
                        <AccordionSummary
                            expandIcon={<MdExpandMore />}
                            aria-controls="module-content"
                            id="module-header"
                        >
                            <p className={classes.titles}> {t('dashboardNames.2')}</p>
                            {(regionId === 4) &&
                                <Typography color="textPrimary" variant="body3" style={{ paddingLeft: 10, paddingRight: 10 }}> {t('maintenance.titles.3')} </Typography>
                            }
                        </AccordionSummary>

            {/* Contenedor de la tabla servicios */}
            <AccordionDetails className={classes.accordion}>
              <div className={classes.content}>
                <p className={classes.description}>
                  {" "}
                  {t("maintenance.subtitle")}{" "}
                </p>
                <Container item xs={12} className={classes.containerTable}>
                  <Item xs={12}>
                    <MaintenanceTable
                      onRequestNewService={onRequestNewService}
                      ref={TableRef}
                      onHideModal={onHideModal}
                      showModal={showFilterModal}
                      onShowModal={onShowModal}
                      onRerender={() => {
                        setReRender(!reRender);
                      }}
                      contracts={contracts}
                      clients={clients}
                      reloadTicket={reloadTickets}
                      onReloadTickets={() => {
                        setReloadTickets(false);
                      }}
                      showGroupModal={showGroupModal}
                      onShowGroupModal={onShowGroupModal}
                      onHideGroupModal={onHideGroupModal}
                      onShowReschedulingForm={(ticket) =>
                        onShowReschedulingForm(ticket)
                      }
                    />
                  </Item>
                </Container>
              </div>
            </AccordionDetails>
          </Accordion>
        </Item>
      </Container>

            {/* Modal para nuevo servicio */}
            <CustomModal
                open={showModal}
                onClose={closeModal}
            >
                {
                    regionId === 0 ? (
                        <ModalTicketBr 
                            contracts={contracts} 
                            closeModal={closeModal} 
                            rescheduleTicket={rescheduleTicket} 
                            onReloadTickets={() => { setReloadTickets(true) }} />
                    ) : (
                        <ModalTicketGnr contracts={contracts} closeModal={closeModal} onReloadTickets={() => { setReloadTickets(true) }} />
                    )
                }
            </CustomModal>
        </>
    );
}

export default Contracts;
