/**
 * @fileoverview Configuration Slice by Notifications
 * @version 1.0
 * @author Carlos Emilio Blanco Lopez
 * @date 15/08/2023
 * @copyright 2023 Industrias RESSER S.A de C.V
 **/

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    notifications: [],
    infoNotification: {}
};

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addNotification: (state, action) => {
            state.notifications = action.payload;
        },
        addInfoNotification: (state, action) => {
            state.infoNotification = action.payload;
        },
        deleteNotifications: (state, action) => {
            state.notifications = [];
        },
        deleteInfoNotification: (state, action) => {
            state.infoNotification = {};
        }
    }
});

export const {
    addNotification,
    addInfoNotification,
    deleteNotifications,
    deleteInfoNotification
} = notificationsSlice.actions;

export default notificationsSlice.reducer;