/**
 * @fileoverview Custom Modal Styles
 * @version 1.0
 * @author César Paul Hernández Camacho
 * @date 30/11/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 */

const styles = theme => ({
    container: {
        border: '2px solid #000',
        padding: theme.spacing(2, 4, 3),
        minHeight: "100vh",
        margin: "0px !important"
    },
    cardPoliticas: {
        backgroundColor: "white",
        minHeight: "30vh",
        borderRadius: '1rem',
        boxShadow: "0 0 10px 0px black",
        [theme.breakpoints.down('md')]: {
            width: '95vw',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: "40vw",
        }
    },
    card: {
        backgroundColor: "white",
        minHeight: "40vh",
        borderRadius: '1rem',
        boxShadow: "0 0 10px 0px black",
        [theme.breakpoints.down('md')]: {
            width: '95vw',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: "80vw",
        }
    },
    cardNotification: {
        backgroundColor: "white",
        minHeight: "100vh",
        boxShadow: "0 0 10px 0px black",
        position: "relative",
        bottom: 15,
        [theme.breakpoints.down('md')]: {
            width: '95vw',
            left: 30,
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: "60vw",
            left: "20.5%",
        },
    }
});

export default styles;