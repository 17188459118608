import React from "react";
import LanguageButton from '../../components/LanguageButton';

// Layout Login

const LayoutLogin = ({ children }) => {
    return (
        <div>
            <div className="divImg">
                <ul className="cb-slideshow">
                    <li><span>Image 01</span></li>
                    <li><span>Image 02</span></li>
                    <li><span>Image 03</span></li>
                    <li><span>Image 04</span></li>
                    <li><span>Image 05</span></li>
                    <li><span>Image 06</span></li>
                    <li><span>Image 07</span></li>
                    <li><span>Image 08</span></li>
                    <li><span>Image 09</span></li>
                    <li><span>Image 10</span></li>
                    <li><span>Image 11</span></li>
                    <li><span>Image 12</span></li>
                </ul>
            </div>
            <div className="divCont">
                <LanguageButton />
                {children}
            </div>
        </div>
    )
}

export default LayoutLogin;