export default {
    generalText: {
        loadingSidebar: "Loading modules",
        welcome: "Welcome",
        header: "MOBILITY, FLEET MANAGEMENT AND LEASING EXPERTS",
        skip: "Skip",
        next: "Next",
        cancel: "Cancel",
        call: "Call",
        send: "Send",
        confirm: "Ok",
        type: "Type",
        goToModule: "go to the module",
        soon: "Soon",
        continue: "Continue",
        accept: "Accept",
        error: "Error",
        advice: "Advice",
        correct: "Correct",
        return: "Return",
        other: "Other",
        delete: "Delete",
        yes: "Yes",
        no: "No",
        na: "N/A",
        edit: "Update",
        gender: "Gender",
        telephone: "Telephone",
        cellphone: "Cellphone",
        zipCode: "Zip Code",
        help: "Help (24h Assitance)",
        helpInfoTitle: "Attention",
        helpInfoBody: "This phone should only be used for 24-hour assistance and calls in the event of a crash, breakdown or accident.",
        novelties: "Novelties",
        vehicle: "Vehicle",
        genders: ["Male", "Female"],
        km: "Update Mileage",
        bad: "Bad",
        excellent: "Excellent",
        phonePlaceHolderBra: "DDD + Phone",
        phonePlaceHolderMx: "Phone",
        searchPlaceHolder: "Search",
        update: "Update",
        register: "Request Registration",
        clic: "Click here",
        helpButtonPremium: "Premium Attention",
        updateButton: "Update",
        updateMessage: "!Update available! \n Upgrade to the new version of My ALD so you can enjoy all the improvements and changes that ALD Latam makes available to you \n (The update is required to use My ALD",
        timeLineAvaliable: "You will soon have your applications returned here! \n If in doubt, call our Driver Assistance Center.",
        corregir: "Redo",
        forgotPassword: "Forgot my password",
        active: "Active",
        finalized: "Finalized",
        logout: "Logout",
        at: ' at ',
        aldAutomotive: "ALD Automotive",
        download: 'Download',
        request: "REQUEST"
    },
    NavBar: {
        notifications: "Notifications",
        language: "Language",
        profile: "Profile",
        settings: "Settings",
        logout: "Logout"
    },
    AcceptCookies: {
        advice: "WARNING",
        content: 'Before start using this site, please read and agree to our current Policies and Terms:',
        return: "Go back",
        continue: "Continue"
    },
    resetSenha: {
        title: "Warning!",
        expiredToken_1: "Your temporary password has a duration of 48 hours, and this time has already expired.",
        expiredToken_2: "Please reply to the email from which you received this password informing that you need a new one as the current has expired.",
        titleSuccess: "Success!",
        titleWarning: "Warning!",
        succesToken: "Your password was successfully updated!",
        continue: "OK",
        formFieldsMessages: {
            requiredEmail: "The email field is required",
            errorEmail: "The email entered is not valid",
            shortPass: "The password must contain at least 8 and up to 15 characters",
            longPass: "The password entered is too long",
            fieldRequired: "Field is required",
            samePass: "Passwords must be the same",
            passwordCriteria: "The password must contain at least one lowercase letter, one uppercase letter, one number, one special character (!@#$%^&*) and no spaces."
        },
    },
    login: {
        title: "Hello, we are happy to see you here!",
        userPlaceholder: "Insert your username",
        userInput: "User",
        passwordPlaceholder: "Insert your password",
        passwordInput: "Password",
        passwordInput_2: "New password",
        rememeberSession: "Remember me",
        rememberPassword: "Recover password",
        signIn: "Log in",
        correct: "Correct authentication",
        infoMessages: "For security reasons, it is mandatory to change your password.",
        passwordLabel_2: "Write your new password",
        passwordLabel_3: "Confirm your new password",
        continue: "CONTINUE",
        tokenInfo: "Insert the TOKEN we sent for you by e-mail ",
        tokenHelp: "If you didn't receive the email, check your spam folder.",
        resendToken: "Resend the TOKEN",
        confirm: "Confirm",
        receivedToken: "Didn't receive the TOKEN?",
        tokenSuccess:"The token has been sent by mail, check your inbox.",
        confirmHelpToken: "Your support request was sent successfully, our support team will contact you to assist you with accessing your account.",
        help: "Need help?",
        errors: {
            auth: [
                "Authentication error",
                "Network error",
                "Your account does not contain assigned units, \n for more information contact your fleet administration",
                "There was an error loading your information, please try again later",
                "We have encountered a problem with your user. Please contact your ALD Operational Customer Management for support!",
                "We didn’t find this user active in My ALD Manager. Please request your access with your Account Manager.",
                "You are trying to log in with a user type Driver. This application is intended for fleet managers only. Please use the mobile application (My ALD) available in your cell phone's online store.",
                "An error occurred while sending the token",
                "The token is invalid",
                "The token has expired, please generate a new one.",
            ],
            fields: "Fields can't be empty",
            userField: "Username field can't be empty",
            passField: "Password field can't be empty",
            tokenField: "Please enter the token before confirming"
        }
    },
    categoriesNames: [
        /*01*/ "My Fleet",
        /*04*/ "My Reports",
        /*05*/ "My services",
        /*06*/ "My drivers",
        /*04*/ "MyALD Suppliers",
    ],
    dashboardNames: [
        /*00*/ "ALD Net",
        /*01*/ "IFRS16 Reports",
        /*02*/ "Maintenance Request",
        /*03*/ "Fleet Management",
        /*04*/ "ALD SAT",
        /*05*/ "Profile configuration",
        /*06*/ "ALDNET",
        /*07*/ "BI Report",
        /*08*/ "My Documents",
        /*09*/ "Home",
        /*10*/ "Notifications"
    ],
    profileConfiguration: {
        profile: {
            title: "Profile",
            message: "This field will only modify the e-mail address where the messages will arrive.",
            inputs: {
                name: "Name",
                email: "Email",
                cellphone: "Cellphone",
                birthdate: "Birthdate",
                rfc: "RFC",
                postalCode: "Postal Code",
                adress: "Adress"
            },
            success: [
                /*00*/ "Image loaded successfully",
                /*01*/ "Profile data updated successfully"
            ],
            errors: [
                /*00*/ "An error ocurred while loading the image",
                /*01*/ "An error ocurred while updating the profile data",
                /*02*/ "The email provided is not valid.",
                /*03*/ "The username or email provided does not exist",
                /*04*/ "The RFC entered is not valid"
            ]
        },
        account: {
            title: "Account configuration",
            textDescription: "Enter your new password, your password must have at least one capital letter and one number",
            currentPassword: "Current password",
            password: "Enter your password",
            confirmPassword: "Confirm your password",
            selectLanguage: "Select your preferred language",
            forgotPassMessage: "Insert your email or username",
            languages: [
                { value: "es", text: "Spanish" },
                { value: "en", text: "English" },
                { value: "pt", text: "Portuguese" },
                { value: "fr", text: "French" }
            ],
            success: [
                /*00*/ "Password updated successfully",
                /*01*/ "Language changed successfully",
                /*02*/ "Password recovery email sent successfully"
            ],
            errors: [
                /*00*/ "The was an error while updating the password",
                /*01*/ "An error ocurred while changing the language",
                /*02*/ "The recovery username or email cannot be empty",
                /*03*/ "The password cannot contain more than 10 characters",
                /*04*/ "Password cannot contain special characters"
            ]
        },
        portal: {
            title: "Portal configuration",
            lateralMenu: "Fixed side menu",
            toolTip: "If active: the side menu on the left will always be visible",
        },
        alerts: {
            title: "Alerts configuration",
            textDescription: "Activate and deactivate your notifications, you can receive them through email",
            checkbox: "Receive through email",
            slider1: "Finished services",
            slider2: "Delivery",
            slider3: "Documents",
            success: [
                /*00*/ "Alerts configured successfully",
                /*01*/ "Your password was successfully updated!",
            ],
            errors: [
                /*00*/ "An error occurred while configuring alerts",
                /*01*/ "The time to reset your password using this link has expired. Go back to My ALD and request a new link by clicking on I forgot my password. \n\n Attention: each link expires every 30 minutes after send!",
                /*02*/ "Password change could not be done",
                /*03*/ "Your password must be different from the last 5"
            ]
        }
    },
    header: {
        placeholderInput: "Vehicle, ID, Client ...",
        search: "Search",
    },
    ifrs16: {
        types: [
            /*00*/ "IFRS16 Main Report",
            /*01*/ "IFRS16 Event Report"
        ],
        inputs: [
            /*00*/ "Reports",
            /*01*/ "Start Date",
            /*02*/ "Cutoff date",
            /*03*/ "End Date"
        ],
        sendMessage: "Send report through email ",
        success: [
            /*00*/ "Report sent successfully"
        ],
        errors: [
            /*00*/ "An error occurred while sending report"
        ]
    },
    contracts: {
        downloadButton: "Download update file",
        uploadButton: "Upload update file",
        titleSoon: "Cooming Soon",
        subtitle: 'In this module you can view and manage the updating of your vehicles data.',
        messageSoon: "Very soon in your Fleet Manager portal, ALD will make this new functionality available to you where you can request the updating of information, cancellation and registration of new contracts. All this from the comfort of your Fleet Manager portal.",
        slogan: "Wait for it very soon, mobility is changing ...",
        uploadConfirmation: 'Are you sure you want to upload the following file',
        filters: {
            title: "Filters",
            contract: "Contract",
            client: "Client",
            date: "Date",
            plates: "Plates",
            button: "Filter",
            noSerie: "Serial number",
            status: "Status",
            clean: "Clean filters",
            select: "Select"
        },
        tableColumns: {
            Brand: "Make",
            Client: "Client",
            ClientNumber: "Client Nº",
            ContractId: "Contract ID",
            ContractNumber: "Contract number",
            ContractStatus: "Status",
            ContractStatusColombia: "Contract Status",
            ContractStatusString: "",
            ContractType: "Contract type",
            ContractedMonths: "Months hired",
            CostCenter: "Cost center",
            Description: "Description",
            DescriptionUO: "Description UO",
            DirectionArea: "Area",
            EndDate: "Expected End Date",
            FleetGroup: "Group",
            FuelConsumption: "Fuel consume",
            KMContracted: "KM contracted",
            KMDateUpdate: "km update",
            KMStart: "Last KM",
            KMUpdate: "Last updated km",
            LicensePlate: "Plates",
            Management: "Maintenance",
            Model: "Model",
            NoEco: "",
            PriceOption: "Price option",
            PriceWithOption: "Price with option",
            Register: "Registry",
            SellPrice: "Sale price",
            SerialNumber: "Serial N° (VIN/Chassis)",
            StartDate: "Start date",
            Tyre: "",
            UserName: "Driver",
            VP: "",
            VehiclePrice: "Vehicle price",
            VehicleType: "Vehicle type",
            VehicleTypeDescription: "Description of vehicle type",
            id: "ID",
            Active: "Active",
            Inactive: "Inactive",
            DateNotRegistered: "Unregistered Date",
            DateNotRegisteredKM: "No update has been made",
            CRLV: "CRLV"
        },
        success: [
            /*00*/ "File upload successfully"
        ],
        errors: [
            /*00*/ "An error ocurred while uploading the update file",
            /*01*/ "An error ocurred while loading the contract"
        ],
        updateKM: {
            updateKM: "Update Kilometers",
            buttonUpdateKM: "Send KM update",
            currentKM: "Last KM",
            newKM: "New KM",
            placeholderNewKM: "Insert New KM",
            continue: "Do you want to continue?",
            alertKMBelow: "The following plates have their New KM below than the Last KM",
            alertKMGreater: "The following plates have a value grater than 450000",
            alertKMSuccess: "The data has been sent successfully to our team and will be revised and updated in the system the fastest possible. If a problem happens with the update we will contacting you. Thank you so much!",
        },
        updateKMerrors: {
            emptyPlate: "A row without plate has been found",
            emptyCurrentKM: "The field Last KM is empty at plate",
            emptyCurrentKMHint: "This is obtained by selecting the plate from the list",
            emptyNewKM: "The field New KM is empty at plate",
            invalidKMZero: "New KM cannot be zero at plate",
            invalidKMEqual: "New KM has to be different than Last KM at plate",
            failConnectionGetKM: "An error has occurred to get Last KM, please retry later",
        },
        CRLV: {
            title: "Circulation Vehicle Document",
            request: "The circulation vehicle document is not available. To request them please click on REQUEST.",
            requested: "Your request was sent. As an immediate solution within 48 hours the CRLV will be sent for you by email and after will be available in MyALD Manager.",
            notEligible: "The circulation vehicle document (CRLV) is not part of your contract. To include new services please consult your ALD Account Manager.",
            error: "An error ocurred while loading CRLV",
            requestError: "An error ocurred while sending your request"
        }
    },
    maintenance: {
        titles: [
            /*00*/"New maintenance",
            /*01*/"Responsible",
            /*02*/"Inform here the driver or person who will accompany the maintenance with the workshop and ALD",
            /*03*/ 'Below you will find the record of previously requested maintenance. To request new maintenance click on the button "Request new service".',
            /*04*/ 'Details of the person who will take the vehicle to the workshop'
        ],
        subtitle: "Find maintenance requests opened into this module or through  the mobile application. Management information’s available on BI Report",
        table: [
            /*00*/"Contract",
            /*01*/"Plate",
            /*02*/"Service",
            /*03*/"Status",
            /*04*/"Application date",
            /*05*/"State",
            /*06*/"City",
            /*07*/"service manager",
            /*08*/"Responsible phone",
            /*09*/"Customer",
            /*10*/"State",
            /*11*/"State",
            /*12*/"Plate"
        ],
        buttons: [
            /*00*/"New service",
            /*01*/"Request service",
            /*02*/ "Request new service"
        ],
        filters: [
            /*00*/"Customer",
            /*01*/"Start date",
            /*02*/"End date",
            /*03*/"Filter",
            /*04*/"Clean filters"
        ],
        infoMessages: [
            /*00*/"Select an start date to filter",
            /*01*/"Select an end date to filter",
            /*02*/"No services were found based on the specified filters",
            /*03*/"No services found",
            /*04*/"The current ticket has no checkpoints to display",
        ],
        modal: [
            /*00*/ "Timeline",
            /*01*/ "Ticket SAC",
            /*02*/ "Service type"
        ],
        groupTable: {
            headerServiceType: 'Service Type',
            headerPlate: 'Plate',
            headerPhone: 'Phone',
            headerKilometer: 'Kilometer',
            headerCountrystate: 'Estate',
            headerCity: 'City',
            headerDateOne: 'Date1',
            headerDateTwo: 'Date2',
            headerDateThree: 'Date3',
            headerRegion: 'Region',
            headerArmored: 'Armored',
            headerRoundTrip: 'Door to Door',
            headerDetails: 'Additional Info',
            headerResponsalName: 'Responsal Name',
            headerResponsalPhone: 'Responsal Phone',
            headerResponsalPhoneChile: 'Driver phone',
            headerResponsalEmail: 'Responsal Email',
            roundTrip: 'Door to Door',
            pickupDir: 'Door to Door Address',
            armored: 'Armored',
            groupService: 'New Mass Service',
            groupMaintenance: 'Group Maintenance',
            addRow: 'Add Row',
            requestService: 'Request Service',
            selected: 'Selected',
            yes: 'Yes',
            no: 'No',
            maintenance: 'Maintenance',
            tires: 'Tires',
            battery: 'Battery',
            revision: 'Revision',
            repairs: 'Repairs',
            technicalRevision: 'Tecnical Revision',
            technicomechanicalRevision: 'Technicomechanical Revision',
            placeholderPhoneMexico: "Fleet Manager's Phone",
            placeholderResponsalPhoneMexico: "Driver's Phone",
            placeholderResponsalPhoneChile: "(56+) 9 to 11 digit phone",
            PlaceholderResponsalPhonePeru: "Driver cell (9 digits)",
            placeholderPhoneColombia: "10-digit Phone"
        },
        errors: {
            emptyFields: "One or more fields are empty",
            emptyDates: "One or more dates are empty",
            emptyDatesColombia: "The first 2 dates are needed",
            invalidDate: "Invalid date",
            invalidDateWeekend: "Invalid: It's not possible to schedule on Weekends at plate",
            invalidDateOutSchedule: "Invalid: The service hour cannot be outside working hours at plate",
            invalidDatePass48Hours: "Invalid: Appointments must be made 48 hours in advance at plate",
            invalidDatePreviousDate: "Invalid: Cannot be scheduled prior to today's date at plate",
            invalidDateOutScheduleBrasil: "Invalid: The selected service time is not valid, the service hours are from 7h to 17h at plate",
            invalidDateOutScheduleMexico: "Invalid: The selected service time is not valid, the service hours are from 8h to 20h at plate",
            invalidDateOutSchedulePeru: "Invalid: The selected service time is not valid, the service hours are from 7h to 18h at plate",
            invalidDateOutScheduleChile: "Invalid: The selected service time is not valid, the service hours are from 8h to 17h at plate",
            invalidDateOutScheduleColombia: "Invalid: The selected service time is not valid, the service hours are from 8h to 18h at plate",
            invalidDateWeekendColombia: "Invalid: It's not possible to schedule on Sundays at plate",
            invalidEmail: "Responsal email has an invalid format at plate",
            repeatedPlate: "The following plate is repeated",
            rowError: "Error sending data in plate",
            failConnection: "A connection error occurred, please try again later",
            successConnection: "All data has been sent successfully",
            successRowSend: "Data send successfully on plate",
            repeatedService: "The following plate(s) has a similar service in progress",
            emptyServiceType: "The field Service Type is empty at plate",
            emptyPlate: "A row without Plate assigned has been found",
            emptyPhone: "The field Phone is empty at plate",
            emptyKilometer: "The field Kilometer is empty at plate",
            emptyCountryestate: "The field Estate is empty at plate",
            emptyCity: "The field City is empty at plate",
            emptyRegion: "The field Region is empty at plate",
            emptyDate1: "The field Date1 is empty at plate",
            emptyDate2: "The field Date2 is empty at plate",
            emptyDate3: "The field Date3 is empty at plate",
            emptyDates: "It is necessary to select at least one date for the Plate",
            emptyArmored: "Indicate the field Armored at plate",
            emptyRoundTrip: "Indicate the field Door to Door at plate",
            emptyPickupDir: "The field Door to Door Address is empty at plate",
            emptyResponsalName: "The field Responsal Name is empty at plate",
            emptyResponsalPhone: "The field Responsal Phone is empty at plate",
            emptyResponsalEmail: "The field Responsal Email is empty at plate",
            limitPhoneChile: "The phone provided is not valid, it must have a minimum of 9 or a maximum of 11 digits in the plate",
            limitPhoneRSChile: "The driver's phone provided is not valid, it must have a minimum of 9 or a maximum of 11 digits in the patent",
            limitPhonePeru: "The phone provided is not valid, it must have a minimum of 9 digits on the plate",
            limitPhoneRSPeru: "The driver's phone provided is not valid, it must have a minimum of 9 digits on the plate",
            limitPhoneColombia: "The telephone number provided is not valid, it must have a minimum of 10 characters on the plate",
            limitPhoneRSColombia: "The responsible telephone number provided is not valid, it must have a minimum of 10 characters on the plate",
            errorBI: "Sorry, an error occurred on our server while loading the BI, please try again later."
        }
    },
    modalTicketBr: {
        alertMessage: {
            title: "Attention",
            description: "The dates of your preference for scheduling Maintenance, Periodic Review or Tires Change must not exceed 45 days in advance."
        },
        inputs: [
            /*00*/"DDD + Driver's phone",
            /*01*/"Mileage",
            /*02*/"Estate",
            /*03*/"City",
            /*04*/"Region of your preference for the service",
            /*05*/"Possible date 1",
            /*06*/"Possible date 2",
            /*07*/"Possible date 3",
            /*08*/"Armored vehicle?",
            /*09*/"Door to Door?",
            /*10*/"Insert the address for pick your car in case of option by Door to Door service",
            /*11*/"Insert the address for delivery your car in case of option by Door to Door service",
            /*12*/"Insert the address for pick up and delivery your car in case of option by Door to Door service",
            /*13*/"Aditional information",
            /*14*/"Name",
            /*15*/"Email",
            /*16*/"Find out here if your vehicle has a noise or additional problems like air conditioning and other ...",
            /*17*/"Plate",
            /*18*/"DDD + Responsible phone number"
        ],
        levaETraz: [
            /*00*/"Door to Door?",
            /*01*/"No",
            /*02*/"Pick up",
            /*03*/"Delivery",
            /*04*/"Pick up and delivery"
        ],
        errorMessages: [
            /*00*/"Enter a phone or cell phone number",
            /*01*/"Select a vehicle before continuing",
            /*02*/"Enter the name of the person who will be in charge of the service",
            /*03*/"Enter the Email of the person who will be in charge of the service",
            /*04*/"Enter a valid email for the person responsible for the service",
            /*05*/"Enter a phone or cell phone number for the person who will be in charge of the service",
            /*06*/"Enter the vehicle mileage",
            /*07*/"Select a state to schedule the service",
            /*08*/"Select a city to schedule the service",
            /*09*/"Fill the aditional information field",
            /*10*/"Indicate if the car has armor",
            /*11*/"Select if you require the Door to Door service",
            /*12*/"Service dates can't be the same",
            /*13*/"Insert the adrress to perform the service Door to Door",
            /*14*/"Please select the region where you want to perform your service",
            /*15*/"You can not schedule a service on weekends",
            /*16*/"The selected service hour is not valid, the business hours are from 7h to 17h",
            /*17*/"Por favor seleccione una fecha para agendar el servicio",
            /*18*/"Sorry for the moment we can not register the requested service",
            /*19*/"An error occurred on our server when registering the requested service, try again later",
            /*20*/"The appointment must be requested 48 hours in advance",
            /*21*/"The scheduling date cannot be less than today's date",
        ],
        successMessages: [
            /*00*/"Your request was sent successfully. Soon one of our administrators will contact you with the details of your appointment"
        ],
        infoMessages: [
            /*00*/"Our team will check if your company contract have eligibility for door to door service",
            /*01*/"The selected contract is assigned to a driver, add the missing information of the person responsible for the service",
            /*02*/"The contract is assigned to more than one driver, manually add the information of the person responsible for the service",
            /*03*/"The contract is not assigned to any driver, manually enter the information of the person responsible for the service"
        ]
    },
    modalTicketGnr: {
        inputs: [
            /*00*/"Plate",
            /*01*/"Telephone",
            /*02*/"Mileage",
            /*03*/"Estate",
            /*04*/"City",
            /*05*/"Possible date 1",
            /*06*/"Possible date 2",
            /*07*/"Aditional information",
            /*08*/"Name and Last Name",
            /*09*/"Email",
            /*10*/"Find out here if your vehicle has a noise or additional problems like air conditioning and other ...",
            /*11*/"Estate", //Nota: Esta traducción no se debe eliminar es la traducción al PT de Departamento que usan en Colombia y Perú
            /*12*/"Estate", //Nota: Esta traducción no se debe eliminar es la traducción al PT de Regíon que usan en Chile
            /*13*/"service type",
            /*14*/ "Corporate phone",
            /*15*/ "Corporate email"
        ],
        serviceType: [
            /*00*/"Preventive Maintenance",
            /*01*/"Corrective Maintenance"
        ],
        errorMessages: [
            /*00*/"Enter a phone or cell phone number",
            /*01*/"Select a vehicle before continuing",
            /*02*/"Enter the name of the person who will be in charge of the service",
            /*03*/"Enter the Email of the person who will be in charge of the service",
            /*04*/"Enter a valid email for the person responsible for the service",
            /*05*/"Enter a phone or cell phone number for the person who will be in charge of the service",
            /*06*/"Enter the vehicle mileage",
            /*07*/"Select a state to schedule the service",
            /*08*/"Select a city to schedule the service",
            /*09*/"Fill the aditional information field",
            /*10*/"Service dates can't be the same",
            /*11*/"ou can not schedule a service on weekends",
            /*12*/"The selected service hour is not valid, the business hours are from 8:00 am to 20:00 pm",
            /*13*/"This unit has a similar service in progress",
            /*14*/"Please select a date to schedule the service",
            /*15*/"It is not possible to schedule services on Sundays",
            /*16*/"The selected service time is not valid, the business hours on Saturdays are from 8:00 am to 14:00 pm",
            /*17*/"he selected service hour is not valid, the business hours are from 8:00 am to 14:00 pm",
            /*18*/"Please select a service type",
            /*19*/"The appointment must be requested 48 hours in advance",
            /*20*/"The scheduling date cannot be less than today's date",
            /*21*/"Select a state to schedule the service",
            /*22*/"Select a state to schedule the service",
            /*23*/"The selected service time is not valid, the opening hours are from 8:30 a.m. to 1:00 p.m. and from 2:00 p.m. to 5:30 p.m.",
            /*24*/"The selected service time is not valid, the opening hours on Saturdays are from 8:30 a.m. to 1:00 p.m."
        ],
        successMessages: [
            /*00*/"Su solicitud se recibió correctamente. En breve, uno de nuestros administradores lo contactará con los detalles de su cita"
        ],
        infoMessages: [
            /*00*/"The selected contract is assigned to a driver, add the missing information of the person responsible for the service",
            /*01*/"The contract is assigned to more than one driver, manually add the information of the person responsible for the service",
            /*02*/"The contract is not assigned to any driver, manually enter the information of the person responsible for the service"
        ],
        noContractMessage: "No contracts available"
    },
    checkPointsTimeline: {
        titles: [
            /*00*/ "Open ticket",
            /*01*/ "Scheduled Maintenance",
            /*02*/ "Did you get to the workshop?",
            /*03*/ "Budget in preparation!",
            /*04*/ "Approval Budget",
            /*05*/ "Delivery of your vehicle",
            /*06*/ "Did you pick up your vehicle?",

            /*07*/ "Check In done",
            /*08*/ "Budget sent to ALD",
            /*09*/ "Approved budget",
            /*10*/ "Check out done!",
            /*11*/ "budget failed"
        ],
        generalMessages: [
            /*00*/ "We're working on it! \n You will soon receive \n your scheduling information",
            /*01*/ "Check in and ensure more agility!",
            /*02*/ "The workshop is analyzing your vehicle \n and will send a quote for approval",
            /*03*/ "We're working on it! \n We will soon approve the maintenance of your vehicle!",
            /*04*/ "Expected delivery",
            /*05*/ "Check out here!",
            /*06*/ "You will soon receive the delivery time of your vehicle",
            /*07*/ "Delivery of your vehicle ",
            /*08*/ "In case of doubts call our Driver Support Center",
            /*09*/ "Ticket canceled",
            /*10*/ "Clique aqui, faça check in e garanta mais agilidade em todo o processo",
            /*11*/ "\n \n * Before removing your vehicle, confirm with the workshop the schedule, as during service on the vehicle unforeseen events may occur.",
            /*12*/ "Click here and make a checkout!"
        ],
        ticketType: [
            /*00*/ "Maintenance | Periodic revision",
            /*01*/ "Preventive Maintenance",
            /*02*/ "Maintenance",

            /*03*/ "Corrective maintenance",
            /*04*/ "Technical review",

            /*05*/ "Tires",
            /*06*/ "Tires",

            /*07*/"Technical mechanical review",

            /*08*/"Repairs",

            /*09*/"Battery",
        ]
    },
    bireport: {
        indicators: "Indicators",
        canbus: "Canbus",
        messageSoon: "Very soon in your Fleet Manager portal, ALD will make this new functionality available to you where you can view reports on your fleet. All this from the comfort of your Fleet Manager portal"
    },
    aldnet: {
        title: "ALD NET",
        message: "Open ALDNET in a new tab",
        instrucctions: "The Login and password to access ALD NET is the same as the one you used to access the old portal. If you don't remember, click on “I forgot my password”",
        button: "Take me to ALDNET"
    },
    alertMessage: {
        message: "The session has ended to continue using the platform it is necessary to log in again"
    },
    PrivacyPolicy: {
        terms: 'Terms and Conditions',
        privacy: 'Privacy Policy',
        cookies: 'Cookies Policy',
        advice: 'Before start using this site, please read and agree to our current Policies and Terms:',
        accept: 'I have read and accept'
    },
    documents: {
        messageSoon: "Very soon here on your MyALD portal for fleet managers, we will put at your disposal this new function with which you can manage and distribute documents among your drivers quickly and comfortably",
        headers: [
            /*00*/ "Type",
            /*01*/ "Title",
            /*02*/ "Category",
            /*03*/ "Shipment data",
            /*04*/ "Notes",
            /*05*/ "Document type"
        ],
        title: "My Documents",
        subtitle: "Contract history",
        modalTitle: "Upload documents",
        buttonTitle: "Upload document",
        deleteMessage: "Are you sure you want to delete the document?",
        modal: [
            /*00*/ "Document name",
            /*01*/ "Category",
            /*02*/ "Notes"
        ],
        options: [
            /*00*/ "Fleet Manager",
            /*01*/ "Driver",
            /*02*/ "General"
        ],
        alertMessages: [

            /*00*/ "No documents found",
            /*01*/ "Document successfully deleted",
            /*02*/ "You need to select a file to upload",
            /*03*/ "You need to select at least one customer to upload the document",
            /*04*/ "The extension of the file is invalid, must be .pdf",
            /*05*/ "The extension of the file is invalid, must be .xlsx",
            /*06*/ "The name of the file is invalid, it can not have special character",
            /*07*/ "The size of the file is too big (3MB maximum)",
            /*08*/ "Invalid file name",
        ]
    },
    gloveBox: {
        documentType: "Type of document",
        gloveBoxApp: "Glove Box App (MY CAR)",
        documentApp: "Documents App",
        descriptionDocuments: "Here you can publish official documents for the drivers of your fleet, such as: fleet policies, process infographics etc. Documents can be seen in the Driver app under Policies and Documents",
        descriptionGloveBox: "Here are the car documents ",
        upload: "Upload File",
        saving: "Uploading",
        save: "Uploaded",
        saveFile: "SAVE",
        alertMessages: [
            /*01*/ "There was an error. Please try again",
            /*02*/ "File upload successfully completed!",
            /*03*/ "No contracts found",
            /*04*/ "The extension of the file is invalid, must be .pdf",
        ]
    },
    Notification: {
        description: "Here you can post general announcements, quick news and events for the drivers of your fleet. Notifications can be seen under \"Notifications\" option in the side menu of the driver app",
        deleteNotificationTitle: "Are you sure you want to delete the notification?",
        deleteNotificationText: "Once the notification has been deleted, you will need to contact your administrator to retrieve it again.",
        noNotifications: "No notifications for the moment",
        deleteNotificationSuccess: "Notification deleted successfully",
        appendix: "Preview",
        category: "Category",
        title: "Title",
        date: "Publication date",
        createDate: "Generation date",
        link: "Additional Link",
        newNotification: "New notification",
        addNotification: {
            addNotification: "Create new notification",
            document: "Document PDF",
            title: "Title *",
            type: "Type *",
            description: "Description",
            date: "Date of publication",
            hourDate: "Time of publication",
            link: "Additional link",
            selectCompany: "Select companies",
            userType: "User type",
            userFM: "FM",
            userDriver: "Drivers",
            selectAll: "Select all",
            accept: "Save",
            labelMaxLenght: "Max. 90 characters",
            labelUsers: "Select all or drag here the users that should receive this communication",
            customerCare: "Customer Care"
        },
        AlertsMassage: [
            "An error occurred while creating your notification.", // 0
            "The notification was successfully removed", // 1
            "Notification upload successful!", // 2
            "No notifications created", // 3
            "Add the missing information for uploading the notification",
            "No notifications for the moment" // 5
        ],
        FilterLabels: {
            category: "Category",
            title: "Title",
            createDate: "Creation date",
            publishDate: "Publication date",
        }
    },
    AldSacTimeLine: {
        titles: [
            /*00*/ "Open ticket",
            /*01*/ "Service appointment",
            /*02*/ "Did you get to the workshop?",
            /*03*/ "Budget in preparation!",
            /*04*/ "Approval Budget",
            /*05*/ "Delivery of your vehicle",
            /*06*/ "Did you pick up your vehicle?",

            /*07*/ "Check in realizado",
            /*08*/ "Budget sent to ALD",
            /*09*/ "Approved budget",
            /*10*/ "Check out done!",
            /*11*/ "budget failed"
        ],
        generalMessages: [
            /*00*/ "We're working on it! \n You will soon receive \n your scheduling information",
            /*01*/ "Check in and ensure more agility!",
            /*02*/ "The workshop is analyzing your vehicle \n and will send a quote for approval",
            /*03*/ "We're working on it! \n We will soon approve the maintenance of your vehicle!",
            /*04*/ "Expected delivery",
            /*05*/ "Check out here!",
            /*06*/ "You will soon receive the delivery time of your vehicle",
            /*07*/ "Delivery of your vehicle ",
            /*08*/ "In case of doubts call our Driver Support Center",
            /*09*/ "Ticket canceled",
            /*10*/ "Clique aqui, faça check in e garanta mais agilidade em todo o processo",
            /*11*/ "* Before removing your vehicle, confirm with the workshop the schedule, as during service on the vehicle unforeseen events may occur.",
            /*12*/ "Click here and make a checkout!"

        ],
        alerts: [
            // Alerta para cancelación de servicio
            /*00*/ 'Cancel',
            /*01*/ 'Do you really want to cancel your service?',

            // Alerta para reagendar servicio
            /*02*/ 'Re-enlarge',
            /*03*/ 'Do you want to re-enlarge your service?',

            // Alerta para CheckIn
            /*04*/ 'Check in',
            /*05*/ 'Do you want to inform ALD that you meet your vehicle in the workshop or agency?',
            /*06*/ 'Yes',
            /*07*/ 'Reagendar Servicio',
            /*08*/ 'No',

            // Alerta al cancelar servicio correctamente
            /*09*/ 'Your cancellation request was sent successfully!',

            // Alerta para error al cancelar servicio
            /*10*/ 'An error occurred while trying to cancel the requested service. \nTry again and if the problem persists contact the Driver Support Center to request cancellation!',

            // Alerta al reagendar servicio correctamente
            /*11*/ 'Your rescheduling request was sent correctly. \nAs soon as our team schedules the service you will receive confirmation on your ticket' + "'" + 's Timeline. Stay tuned!',

            // Alert para error al reagendar servicio
            /*12*/ 'An error occurred while trying to reschedule the service. \nTry again and if the problem persists contact the Driver Support Center.',

            //Check-in/out
            /*13*/ 'Successful confirmation',

            //Error en la obtención de tickets
            /*14*/ 'An error occurred trying to get the service information. \nTry again and if the problem persists contact the Driver Support Center.',

            //Mensajes para checkIn y CheckOut
            /*15*/ "Check In",
            /*16*/ "Do you want to confirm to ALD Automotive the entry of your unit to the assigned service point?",
            /*17*/ "Check Out",
            /*18*/ "Do you want to confirm to ALD Automotive that your unit was delivered to you?",
            /*19*/ "An error occurred in the confirmation"
        ],
        Button: [
            /*00*/ 'Cancel appointment?',
            /*01*/ 'Requesting cancellation of service',
            /*02*/ 'Requesting a new appointment',
            /*03*/ 'Check In',
            /*04*/ 'Check Out'
        ]
    },
    home: {
        products: [
            /*00*/ "Intelligence",
            /*01*/ "Mobility",
            /*02*/ "Performance",
            /*03*/ "Sustainability",
            /*04*/ "Products ALD",
            /*05*/ "Learn more about our products!",
            /*06*/ "More products coming soon !!!"
        ],
        blog: [
            /*00*/ "ALD News",
            /*01*/ "ALD is here to help you come and check out our extensive Blog where you will find ideas, solutions in mobility.",
            /*02*/ "Click and learn more..."
        ],
        news: [
            /*00*/ "Get the latest news on Mobility, to learn more about how ALD can help you and your fleet grow."
        ]
    },
    Months: {
        completos: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ],
        abreviatura: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ]
    },
    ModuleBI: {
        title: 'NOTICE',
        Paragraph_1: 'Your MyALD user does not yet have the necessary permissions to access the Fleet Reports.',
        Paragraph_2: 'After the release of your access to the Portal, the viewing permissions of Reports take 24 hours to be enabled. If this period of time expired, we ask you to contact us by:',
        Paragraph_3: 'Informing that you cannot view the Fleet Reports in MyALD Manager.'
    },
    ModuleAgreements: {
        BudgetApproval: 'Budget approval',
        ChooseCustomer: 'Choose a customer to view the budget',
        DoesntHaveBudget: 'The selected company doesn\'t have budgets to be approved.',
        Filters: {
            clean: 'Clean filters',
            clients: 'Company',
            plates: 'Plate',
            filters: 'Filters',
            pedding: 'Pending',
            approved: 'Approved',
            rejected: 'Rejected',
            cancel: 'Cancel',
            apply: 'Apply',
        },
        table: {
            idSat: 'Agreement ID',
            plate: 'Plate',
            model: 'Model',
            enterprise: 'Company',
            dateService: 'Service date',
            description: 'Description',
            price: 'Cost with IVA',
            provider: 'Supplier',
            status: 'Status',
            actions: 'Actions',
            sfTicket: 'Ticket (SF Case)',
        },
        actions: {
            view: 'Budget details',
            comments: 'Comments',
            history: 'Case History',
            attachment: 'See document',
        },
        budget: {
            id: 'Agreement ID',
            vehicle: 'Vehicle',
            plate: 'Plate',
            km: 'Mileage',
            vin: 'VIN',
            driver: 'Driver',
            customer: 'Customer',
            supplier: 'Supplier',
            workshop: 'Workshop',
            quantity: 'Quantity',
            description: 'Description',
            price: 'Cost with IVA',
            priority: 'Priority',
            rejected: 'Reject',
            partialAproval: 'Partial Approval',
            fullAproval: 'Full Approval ',
            salesForceCaseNumber: 'SF Case number',
            low: 'Low',
            medium: 'Medium',
            high: 'High',
            parts: 'Parts',
            manoObra: 'Labour',
        },
        comments: {
            comments: 'Comments',
            noComments: 'No comments',
            hasNoComments: 'The budget has no comments',
            insertComments: 'Insert comments',
            writeComments: 'Write comments here',
        },
        alerts: {
            selectItems: 'Select at least one item to continue',
            procesingRequest: 'We are processing your request. Please do not try perform the action again.',
            workingNewBudget: 'We are working to send you a new budget. When it\'s available, you could see it here on the portal!',
            selectOneItem: 'Select at least one item to continue',
            rejectedBudget: [
                'You are rejecting the budget. ', // 0
                'W\'d  you like continue?', //1
            ],
            fullApprovalBudget: [
                'You are fully approving the budget .', // 0
                '', //1
                'W\'d  you like continue?' //2
            ],
            partialBudget: [
                'You are trying to send a PARTIAL approval, We will be sending your partial request to ALD.', // 0
                'W\'d  you like continue?' // 1
            ],
            itemsRejected: '* Items Rejected by the Fleet Manager:',
            itemsApproved: '* Items Approved by the Fleet Manager:',
            errorFile: "The file could not be loaded, the attachment structure is invalid.",
            emptyAttachment: "No Attachment available yet",
            errorAttachment: 'Ocurrió un error al obtener el Attachment',
        }
    }
}