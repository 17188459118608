/**
 * @fileoverview Configuration Slice by SnackBar
 * @version 1.0
 * @author Carlos Emilio Blanco Lopez
 * @date 15/0682023
 * @copyright 2023 Industrias RESSER S.A de C.V
 **/

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    notifications: [],
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {

        enqueueSnackbar: (state, action) => {
            const notification = action.payload;
            const key = notification.options && notification.options.key;
            state.notifications.push(
                {
                    ...notification,
                    key: key || new Date().getTime() + Math.random(),
                });
        },

        closeSnackbar: (state, action) => {
            const key = action.payload;

            state.notifications = state.notifications.map(notification => (
                (key && notification.key === key)
                    ? { ...notification, dismissed: true }
                    : { ...notification }
            ));
        },

        removeSnackbar: (state, action) => {
            const key = action.payload;
            state.notifications = state.notifications.filter(
                notification => notification.key !== key,
            );
        },

    }
});

export const {
    enqueueSnackbar,
    closeSnackbar,
    removeSnackbar
} = notificationSlice.actions;

export default notificationSlice.reducer;

