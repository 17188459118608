/**
 * @fileoverview Filter Styles
 * @version 1.0.0
 * @author Carlos Emilio Blanco
 * @date 30/05/2023
 * @copyright Industrias RESSER S.A de C.v
 */

const FilterStyle = theme => ({
    titles: {
        color: "#050B7F",
        fontWeight: 'bold',
        fontSize: '1.5rem'
    },
    subTitles: {
        color: "#050B7F",
        fontWeight: '300',
        fontSize: '1.5rem'
    },
    accordion: {
        display: "flex",
        justifyContent: 'center'
    },
    newServiceButtonStyle: {
        backgroundColor: "#0BCBFB",
        color: "#050B7F",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 15,
        minWidth: 200
    },
    containerFormSections: {
        width: '100%'
    },
    downloadButtonStyle: {
        backgroundColor: "#050B7F",
        color: "white",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 15,
        minWidth: 200
    },
    uploadButtonStyle: {
        backgroundColor: "#0BCBFB",
        color: "#050B7F",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 15,
        minWidth: 200
    },
    viewCRLVStyle: {
        backgroundColor: "#E0E0E0",
        color: "#050B7F",
    },
    downloadCRLVStyle: {
        backgroundColor: "#0BCBFB",
        color: "#050B7F",
    },
    contractTable: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },
    filterIcon: {
        justifyContent: 'flex-end',
        alignSelf: 'flex-end',
    },
    containerTable: {
        flexDirection: 'column',
    },
    containerButton: {
        flexDirection: 'column',
        margin: '0px !important',
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: '10px',
    },
    containerModalFilter: {
        flexDirection: 'column',
        justifyContent: 'center',
        width: '95%',
        maxHeight: 350,
        overflow: 'auto',
        marginBottom: 10,
    },
    containerRow: {
        flexDirection: 'row',
        width: '100%'
    },
    box: {
        width: '100%',
    }
});

export default FilterStyle;