/**
 * @fileoverview GlobeBoxModal
 * @version 1.1
 * @author Carlos Emilio Blanco Lopez
 * @date 27/10/2022
 * @update 02/02/2023
 * @copyright 2023 Industrias RESSER S.A de C.V
 */
// core components
import React, { useState, useEffect, useRef } from 'react';
//@material-ui components
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Item from "components/Grid/GridItem.js";
import Container from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from 'components/CustomModal/CustomModal';
import LinearProgress from '@material-ui/core/LinearProgress';
//translate
import { useTranslation } from 'react-i18next';
//axios
import axios from 'libs/AxiosHelper';
import Rssr from 'libs/Rssr';
//redux actions
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from 'libs/Snackbar';
import { showCircularProgress, closeCircularProgress } from 'redux/slice';
import { useDispatch } from 'react-redux';
//styles
import styles from '../Documents.styles';
import { Grid } from '@material-ui/core';
import { MdCancel,  MdOutlinePublish  } from 'react-icons/md';
import { IconPdf_GloveBox } from 'assets/img/Icons/Icons';

var CryptoJS = require("crypto-js");

const cleanObj = {
    typeDocument: '',
    typeDocumentObj: {},
};

const useStyles = makeStyles(styles);

const GlobeBoxModal = ({ info, OpenModal, setOpenModal, rows, id, FilterDocumentsObj, getDocuments, isUpdate, setIsUpdate }) => {

    const dispatch = useDispatch();

    const fileInputRef = useRef(null);
    const [fileName, setfileName] = useState();
    const [Error, setError] = useState('');
    const [FilterObj, setFilterObj] = useState(cleanObj);
    const [LinearProgress, setLinearProgress] = useState(60);
    const [ShowProgress, setShowProgress] = useState(false);
    const [DocError, setDocError] = useState(false);
    const [DocInBase64, setDocInBase64] = useState('');
    const [ShowDocument, setShowDocument] = useState();
    const [t, i18n] = useTranslation('common');
    const [namefileUpdate, setnamefileUpdate] = useState("");
    const [DocumentIdDelete, setDocumentIdDelete] = useState();
    const classes = useStyles();

    const flatProps = {
        options: rows,
        getOptionLabel: (option) => option.Description,
    };

    useEffect(() => {
        setFilterObj({
            ...FilterObj,
            typeDocumentObj: flatProps.options[id],
        });
    }, [OpenModal]);

    /**
     * useEffect para obtener los datos de un documentos ya guardado.
     */

    useEffect(() => {
        let data = {};
        switch (FilterObj.typeDocumentObj?.DocumentType) {
            case 1:
            case 4:
            case 8:
            case 11:
                data = info.CirculationCard;
                break;
            case 2:
            case 5:
            case 7:
            case 12:
                data = info.InsurancePolicy;
                break;
            case 3:
            case 6:
            case 9:
            case 13:
                data = info.VerificationCard;
                break;
            case 10:
                data = info.FuelCard;
                break;
            default:
                break;
        }
        setShowDocument(data.Value);
        if(data.Value){
            setDocumentIdDelete(data.DocumentNumber);
            const urlSections = CryptoJS.AES.decrypt(data.Data, 'MyALD@Document').toString(CryptoJS.enc.Utf8).split('/');
            setnamefileUpdate(urlSections[urlSections.length - 1]);
        }
    }, [FilterObj])
    

    /**
     * Funcion para la captura y validacion de documento
     * @param {object} e 
     */

    const onFileChange = async (e) => {
        setDocError(false);
        setLinearProgress(60);
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const extend = file.name.split('.'); 
            const lengthExtend = extend.length;
            if (extend[lengthExtend - 1].toLowerCase() !== 'pdf') {
                enqueueErrorSnackbar(dispatch, (dispatch, t('gloveBox.alertMessages.3')));
            }
            else {
                const validation = Rssr.validateDocumentName(false, file.name, file.size);
                setfileName(file.name);
                if (validation.defaultValid) {
                    var aux = await toBase64(e.target.files[0]);
                    aux = aux.split(",")[1];
                    setDocInBase64(aux);
                    setShowProgress(true);
                    setTimeout(() => {
                        setLinearProgress(100);
                    }, 1500);
                }
                else {
                    validateFile(validation);
                    setShowProgress(true);
                    setTimeout(() => {
                        setDocError(true);
                    }, 1500);
                }
            }
        }
    }

    /**
     * Function to validate the name of a file and its extension
     * @param {string} filename the name of the file to validate
     * @memberof DocumentsTable
     * @returns true if the filename it's in the security parameters
     */

    const validateFile = (validate) => {
        if (!validate.documentValid)
            setError(t('documents.alertMessages.2'));
        else if (!validate.expresionValid)
            setError(t('documents.alertMessages.6'));
        else if (!validate.extensionValid)
            setError(t('documents.alertMessages.4'));
        else if (!validate.sizeValid)
            setError(t('documents.alertMessages.7'));
    }

    /**
     * Function to delete the document
     * @param {array} file
     * @return base64 Base64 extracted from the file
     * @memberof Documents
     */

    const DeleteDocument = e => {
        axios
            .del(`GloveBoxDocuments?data=${DocumentIdDelete}`)
            .then(response => {
                if (response.data.success) {
                    enqueueSuccessSnackbar(dispatch, t('documents.alertMessages.1'));
                    getDocuments();
                    onHandleClose();
                }
            })
            .catch(error => { })
    }

    /**
     * Function to get the base64 from a file input
     * @param {array} file
     * @return base64 Base64 extracted from the file
     * @memberof Documents
     */

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    /**
     * @description: funcion para el apoyo de cerrardo del modal
     */

    const onHandleClose = () => {
        onChange('typeDocument', null);
        setDocError(false);
        setShowProgress(false);
        setOpenModal(false);
        setShowDocument(false);
        setIsUpdate(false);
        setLinearProgress(60);
    }

    /**
     * @description: Funcion para cancelar el documento capturado
     */

    const onHandleCancel = () => {
        fileInputRef.current.value = '';
        setDocError(false);
        setShowProgress(false);
        setLinearProgress(60);
    }

    /**
     * @description Funcion de apoyo para el cambio de tipo de documento
     * @param {string} name 
     * @param {object} value 
     */

    const onChange = (name, value) => {
        setFilterObj({
            ...FilterObj,
            typeDocumentObj: value,
            [name]: value !== null ? value.customerName : ''
        });
    }

    /**
     * @description Funcion para el guardo por medio de POST/PUT del documento
     */

    const onSave = async () => {
        const gloveboxType = FilterObj.typeDocumentObj === undefined
            ? flatProps.options[id]
            : FilterObj.typeDocumentObj;
        let gloveBox = {};
        switch (gloveboxType.DocumentType) {
            case 1:
            case 4:
            case 8:
            case 11:
                gloveBox = info.CirculationCard;
                break;
            case 2:
            case 5:
            case 7:
            case 12:
                gloveBox = info.InsurancePolicy;
                break;
            case 3:
            case 6:
            case 9:
            case 13:
                gloveBox = info.VerificationCard;
                break;
            case 10:
                gloveBox = info.FuelCard;
                break;
            default:
                gloveBox = {}
                break;
        }
        dispatch(showCircularProgress());
        if (gloveBox.Value) {
            await axios.put('GloveBoxDocuments', {
                gloveboxDocument: gloveBox.DocumentNumber,
                file: DocInBase64
            }).then(response => {
                if (response.data.success) {
                    enqueueSuccessSnackbar(dispatch, t('gloveBox.alertMessages.1'));
                }
                else {
                    enqueueErrorSnackbar(dispatch, t('gloveBox.alertMessages.0'));
                }
            }).catch(error => { });
        }
        else {
            await axios.post('GloveBoxDocuments', {
                documentType: "pdf",
                gloveboxType: gloveboxType.DocumentType,
                file: DocInBase64,
                plate: info.PlateNumber
            }).then(response => {
                if (response.data.success) {
                    enqueueSuccessSnackbar(dispatch, t('gloveBox.alertMessages.1'));
                }
                else {
                    enqueueErrorSnackbar(dispatch, t('gloveBox.alertMessages.0'));
                }

            }).catch(error => { });
        }
        getDocuments(true, FilterDocumentsObj);
        onHandleClose();
        dispatch(closeCircularProgress());
    }

    /** Fragment */

    return (
        <CustomModal
            open={OpenModal}
            onClose={onHandleClose}>
            <div style={{ width: '90%' }} >
                <Container alignItems="flex-start" justify="center">
                    <Item xs={12} md={10} alignSelf="flex-start" >
                        <p style={{ color: "#050B7F", fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'center' }}> {t('gloveBox.gloveBoxApp')} </p>
                    </Item>
                </Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            {...flatProps}
                            id="combo-box-plate"
                            freeSolo={true}
                            options={rows}
                            defaultValue={flatProps.options[id]}
                            value={FilterObj.typeDocumentObj}
                            onChange={(event, newValue) => { onChange('typeDocument', newValue) }}
                            renderInput={(params) => <TextField {...params} label={t('gloveBox.documentType')} margin="normal" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField label={t('contracts.filters.plates')} margin="normal" disabled={true} style={{ width: "100%" }} value={info.PlateNumber} />
                    </Grid>
                    {
                        ShowDocument && (
                            <Grid item xs={12}>
                                <div className={classes.DocumentDelete}>
                                    <div className={classes.DocumentDeleteIcon}>
                                        <IconPdf_GloveBox style={{ paddingRight: 10 }} />
                                    </div>
                                    <div className={classes.DocumentData}>
                                        <p>{namefileUpdate}</p>
                                        <p>1.5 MB</p>
                                    </div>
                                    <div className={classes.DocumentCancel}>
                                        <MdCancel style={{ color: "#E0E0E0", cursor: "pointer" }} fontSize="large" onClick={() => { DeleteDocument(); }} />
                                    </div>
                                </div>
                            </Grid>
                        )
                    }
                </Grid>
                <Container alignItems="flex-start" justify="center">
                    <Button
                        onClick={() => { fileInputRef.current.click(); }}
                        className={classes.uploadGBButtonStyleModal}
                        aria-label="edit">
                        <MdOutlinePublish  fontSize='large' />{" "}{t('gloveBox.upload')}
                    </Button>
                </Container>

                {
                    ShowProgress && (
                        <>
                            <Item xs={12} alignSelf="flex-start" >
                                <p style={{ color: "#828282", fontSize: '1.3rem', fontFamily: "Source Sans Pro" }}> {LinearProgress < 70 ? t('gloveBox.saving') : t('gloveBox.save')} </p>
                            </Item>
                            <Item xs={12} alignSelf="flex-start" >
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', marginTop: 10 }}>
                                    <p style={{ color: "#828282", fontSize: '1rem', fontFamily: "Source Sans Pro" }}> {fileName} </p>
                                    <MdCancel style={{ color: "#828282", cursor: "pointer" }} onClick={onHandleCancel} />
                                </div>
                                <div style={{ height: 75, marginTop: 15 }}>
                                    <BarProgress Error={DocError} titleError={Error} Upload={LinearProgress} onSave={onSave} titleSave={t('gloveBox.saveFile')} />
                                </div>
                            </Item>
                        </>
                    )
                }
            </div>
            <input
                style={{ overflow: 'hidden', width: 0, height: 0 }}
                ref={fileInputRef}
                onChange={onFileChange}
                type="file"
                accept=".pdf"
            />
        </CustomModal>

    )
}

/**
 * @title BarProgress
 * @description Componente para la barra de carga
 * @param {boolean} Error
 * @param {number} Upload
 * @param {string} titleError
 * @param {function} onSave
 * @param {string} titleSave
 * @returns 
 */

const BarProgress = ({ Error, Upload, titleError, onSave, titleSave }) => {
    const classes = useStyles();
    if (Error) {
        return (
            <>
                <LinearProgress variant="determinate" value={100} style={{ marginTop: 10 }} classes={{ barColorPrimary: classes.LPBarColorError }} />
                <p style={{ color: "#FF303E", fontSize: '1rem', fontFamily: "Source Sans Pro", marginTop: 10 }}> {titleError} </p>
            </>
        )
    }
    else {
        if (Upload === 100) {
            return (
                <>
                    <LinearProgress variant="determinate" value={100} style={{ marginTop: 10 }} classes={{ barColorPrimary: classes.LPBarColorUpload }} />
                    <Container alignItems="flex-start" justify="center">
                        <Button
                            onClick={onSave}
                            className={classes.SaveGBButtonStyleModal}
                            aria-label="edit">
                            {titleSave}
                        </Button>
                    </Container>
                </>)
        }
        else {
            return (<LinearProgress variant="determinate" value={Upload} style={{ marginTop: 10 }} />)
        }
    }

}
export default GlobeBoxModal