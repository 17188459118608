/**
 * @fileoverview Input para ingresar PIN usado actualmente en el login
 * @version 1.0.0
 * @author Leslie Mosqueda
 * @date 21/05/2024
 * @copyright 2024 Industrias RESSER S.A de C.V
 */
import React from 'react';
import VerificationInput from 'react-verification-input';
import './inputToken.css';

const InputToken = ({ setToken }) => {
 
  return (
    <>
      <VerificationInput
        validChars="0-9"
        inputProps={{ inputMode: "numeric" }}
        length={6}
        passwordMode={true}
        onChange={(e) => {  setToken(e)}}
        classNames={{
          container: "container",
          character: "character",
          characterInactive: "character--inactive",
          characterSelected: "character--selected",
          characterFilled: "character--filled"
        }} />
    </>
  )
}

export default InputToken