/**
 * @fileoverview Login Styles
 * estilos de la vista Login
 * @version 1.0
 * @author César Paul Hernández Camacho
 * @date 30/10/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 * 
 */
import loginBg from 'assets/img/login_backgorund.png';

const LoginStyles = theme => ({
    loginContainer: {
        minHeight: '100vh',
        backgroundColor: 'white',
        [theme.breakpoints.down("md")]: {
            width: '100vw'
        },
        [theme.breakpoints.up("md")]: {
            width: '29vw'
        },
    },
    welcomeContainer: {
        minHeight: '25vh', 
        alignItems: 'center'
    },
    welcomeText: {
        // color: 'rgb(6,11,122)', 
        marginLeft: '15%',
        fontSize: '12px', 
        fontWeight: '800'
    },
    myText: {
        color: 'rgb(6,11,122)',
        fontWeight: 'bold',
        fontSize: '3rem'
    },
    aldText: {
        color: 'rgb(93,200,246)',
        fontWeight: 'bold',
        fontSize: '3rem'
    },
    centralMenu: {
        minHeight: '50vh', 
        width:'73%',
        [theme.breakpoints.down("xl")]: {
            width:'100%',
            paddingRight: '20%',
        },
        [theme.breakpoints.down("sm")]: {
            width:'100%',
            paddingRight: '5%'
        },
        [theme.breakpoints.down("xs")]: {
            width:'100%',
            paddingRight: '1%'
        },
    },
    userPassContainer: {
        width: '100%',
        marginTop: '1em',
        minHeight: '30vh', 
        alignItems: 'flex-end', 
        marginLeft:'5%',
        [theme.breakpoints.down("xl")]: {
            marginLeft: '0%',
        },
        [theme.breakpoints.down("sm","xs")]: {
            justifyContent: 'center',
        },
    },
    loginFormContainer: {
        borderRadius: '0px 0px 0vh 10vh',
        width: '70%',
        borderLeft: '8px solid rgb(93,200,246)',
        borderBottom: '8px solid rgb(93,200,246)',
        height: '25vh',
        margin: '0px 0px 0px 15px',
    },
    checkBoxContainer: {
        minHeight: '5vh', 
        alignItems:'flex-end', 
        justifyContent:'space-between',
        [theme.breakpoints.down("sm","xs")]: {
            marginLeft:'0%',
            justifyContent: 'space-around'
        }

    },
    loginButtonContainer: {
        minHeight: '5vh', 
        alignItems: 'center', 
        marginLeft:'25%',
        [theme.breakpoints.down("sm")]: {
            marginLeft:'0%',
            justifyContent: 'center',
        },
    },
    loginButton: {
        textTransform: "uppercase",
        backgroundColor: '#0092FF',
        border: 'none',
        borderRadius: 8,
        height: 40,
        width: 150,
        outline: 0,
        marginTop: 10,
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '0 0 2px 2px grey'
        },
        [theme.breakpoints.down("sm")]: {
            width: 300,
            height: 40,
        }
    },
    helpButtonContainer: {
        minHeight: '10vh', 
        width:'73%', 
        alignContent:'center', 
        justifyContent:'flex-end' ,
        [theme.breakpoints.down("sm")]: {
            justifyContent: 'center',
            alignContent: 'center',
            width: '100%'
        }
    },
    blueBottomLine: {
        minHeight: '15vh',
        borderRadius: '0vh 7vh 0vh 0vh',
        width: '60%',
        height: '10vh',
        borderRight: '8px solid rgb(6,11,122)',
        borderTop: '8px solid rgb(6,11,122)',
        padding: '5%',
        marginRight: '20%',
        marginLeft: 'auto',
        justifyContent: 'flex-end',
        [theme.breakpoints.down("sm")]: {
            marginRight: '5%',
        }
    },
    imageBackground: {
        minHeight: '100vh',
        backgroundImage: `url(${loginBg})`,
        backgroundPosition: 'right center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    languageButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '90%',
        marginLeft: '20%',
        [theme.breakpoints.down("sm")]: {
            marginLeft: '5%',
        }
    }
})

export default LoginStyles;