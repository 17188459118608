/**
 * @fileoverview Styles for Admin
 * @version 1.1.0
 * @author Carlos Emilio Blanco Lopez
 * @dateUpdate 12/03/2024
 * @copyright 2024 Industrias RESSER S.A de C.V
 *
 */

import {
  drawerWidth,
  drawerWidthCollapse,
  transition,
  container
} from "assets/jss/material-dashboard-react.js";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    width: `100%`,
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
  },
  content: {
    marginTop: "25px",
    [theme.breakpoints.down("md")]: {
      marginTop: "80px"
    },
    padding: "30px 15px 0px 15px",
    minHeight: "calc(50vh - 123px)"
  },
  container,
  map: {
    marginTop: "80px",
    [theme.breakpoints.down("md")]: {
      marginTop: "100px"
    },
  },
  panelToggleDrawerCollapse: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidthCollapse}px)`
    },
    // overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  panelToggleDrawer: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    // overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
});

export default appStyle;
