/**
 * @file Protected Route.
 * @author Dworak Esteban <esteban.cervantes@ressser.com>
 * @description Verifies if user is logged to let him in a webpage.
 */

// NPM Packages Imports.
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default function PrivateRoute({ children, auth, ...rest }) {
  // Component.
  return (
    <Route
      {...rest}
      render={({ location }) => (auth ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      ))}
    />
  );
}
