/**
 * @fileoverview Principal Componente Notifications
 * @version 1.0.0
 * @author Carlos Emilio Blanco Lopez
 * @date 13/12/2022
 * @copyright 2022 Industrias RESSER S.A de C.V
 */
import React, { useState, useEffect } from 'react';
import Container from 'components/Grid/GridContainer';
import { makeStyles } from '@material-ui/core/styles';
import Item from "components/Grid/GridItem.js";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {MdExpandMore}  from 'react-icons/md';

import { useTranslation } from "react-i18next";

import styles from './Notifications.styles';
import NotificationsTable from './NotificationsTable';
import CRLVView from 'views/Contracts/CRLVView';

const useStyles = makeStyles(styles);

const Notification = () => {
  //styles
  const classes = useStyles();

  //translate
  const [t, i18n] = useTranslation('common');
  const [Document, setDocument] = useState(null);
  const [ShowDocument, setShowDocument] = useState(false);

  /**
     * Function to clear CRLV
     * @memberof Contract
     */
  const clearDocument = () => {
    setDocument(null);
  }


  return (
    <Container>
      <Item xs={12} md={12} >
        {
          Document ? (
            <CRLVView
              CRLV={Document}
              clearCRLV={clearDocument}
              isNotification={true}
            />
          ) : (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<MdExpandMore  />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p className={classes.title}> {t('dashboardNames.10')} </p>
                
              </AccordionSummary>
              <AccordionDetails className={classes.accordion} >
                <div className={classes.contentNotification}>
                <p className={classes.description}> {t('Notification.description')} </p>
                  <Container item xs={12}
                    direction="column"
                    alignItems="center"
                    justify="flex-start">

                    <Item style={{ width: '100%' }} >
                      <NotificationsTable setDocument={setDocument} Document={Document} />
                    </Item>

                  </Container>
                </div>
              </AccordionDetails>
            </Accordion>
          )
        }
      </Item>
    </Container>
  )
}

export default Notification