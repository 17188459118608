/**
 * @fileoverview Modal para mostrar el Historial a la tabla de SAT
 * @version 1.0
 * @author Carlos Emilio Blanco Lopez
 * @date 23/01/2024
 * @copyright 2024 RESSER S.A de C.V
 */

import React from 'react';
import Modal from './index';
import { Context } from '../Table';
import { Stack } from '@mui/material';

import { ContentInfo } from './index';
import Rssr from 'libs/Rssr';
import PerfectScrollbar from 'react-perfect-scrollbar';

/**
 * @Const TitleInfo
 * @description Componente para el titulo de la sección
 */

const TitleInfo = {
  "STATE_NEW": "Solicitud:",
  "STATE_APPOINTMENT": "Propuesta:",
  "STATE_GARAGE_ARRIVAL_DATE": "Cita:",
  "STATE_BUDGET": "Exitoso:",
  "STATE_AWAITINGFROMFM": "Esperando:",
  "STATE_VEHICLE_NOT_ARRIVED": "Veh'iculo no llego:",
  "STATE_WORKFINISHED": "Finalizado:",
  "STATE_AUTHORIZEDBYALD": "Autorizado:",
  "STATE_AUTHORIZEDBYFM": "Autorizado:",
  "STATE_DELIVERED": "Entregado:",
  "STATE_CLOSED": "Cerrado:",

}

const DescInfo = {
  "STATE_NEW": "Solicitud de mantenimiento",
  "STATE_APPOINTMENT": "Propuesta de cotizacion enviada",
  "STATE_GARAGE_ARRIVAL_DATE": "Fecha de cita 22/09/2023 13:00 Hrs",
  "STATE_BUDGET": "Mantenimiento realizado con exito",
  "STATE_AWAITINGFROMFM": "Esperando aprobacion de FM",
  "STATE_VEHICLE_NOT_ARRIVED": "Vehiculo no llego a la cita",
  "STATE_WORKFINISHED": "Trabajo finalizado",
  "STATE_AUTHORIZEDBYALD": "Autorizado por ALD",
  "STATE_AUTHORIZEDBYFM": "Autorizado por FM",
  "STATE_DELIVERED": "Entregado",
  "STATE_CLOSED": "Mantenimiento cerrado",
}



const ModalHistorial = ({
  OnEdit,
  OnComments,
  OnHistory,
  OnAttachment,
  DataAgreements
}) => {
  const { state } = React.useContext(Context);

  /**
     * @Function formatTime
     * @description Función para dar formato a la fecha
     */

  const formatTime = (date, digits = 8) => {
    var aux = '';
    aux = date.split('T')[1];
    return aux.slice(0, digits);
  };

  return (
    <Modal open={state.ModalHistory} OnComments={() => OnComments(DataAgreements)} OnEdit={() => OnEdit(DataAgreements)} OnHistory={() => OnHistory(DataAgreements)}  OnAttachment={()=> OnAttachment(DataAgreements)}>
      <Stack direction="column" spacing={1} width={'90%'}>
        <PerfectScrollbar style={{ position: "relative", height: "90%", marginTop: 20 }}>
          <div style={{
          }}>
            {
              state.History.map((el, index) => {
                return (
                  <ContentInfo
                    key={index}
                    title={`${TitleInfo[el.Status]}  ${Rssr.formatDate(el.Date)} ${formatTime(el.Date)}`}
                    desc={el.Status === "STATE_GARAGE_ARRIVAL_DATE" ? `Fecha de cita ${Rssr.formatDate(el.Date)} ${formatTime(el.Date, 5)}` : DescInfo[el.Status]}
                  />
                )
              })
            }
          </div>
        </PerfectScrollbar>
          </Stack>

        </Modal>
        )
}

        export default ModalHistorial;