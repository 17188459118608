/**
 * Styles for SAT view
 * @fileoverview SAT view styles
 */


const styles = theme => ({
    Main: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    Buttons: {
        display: 'flex',
        paddingBottom: 10,
        width: 70,
        justifyContent: 'space-between',
    },
    ButtonsFilter: {
        borderRadius: '5px',
        width: '30px',
        height: '30px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    Chip: {
        color: '#828282',
        fontFamily: 'Source Sans Pro',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '150%',
        width: '120px',
        marginRight: '10px',
    },
    RefreshFilter: {
        color: 'var(--gris, #828282)',
        fontFamily: 'Source Sans Pro',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '150%',
    },
    sectionTitleFilter: {
        paddingInline: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 70,
        borderBottom: '2px solid var(--modal-header-border-color-split, rgba(0, 0, 0, 0.06))'
    },
    TitleFilter: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '20px',
        color: '#050B7F'
    },
    sectionCheckBoxFilter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 100,
        borderBottom: '2px solid var(--modal-header-border-color-split, rgba(0, 0, 0, 0.06))'
    },
    sectionButtonFilter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        height: 60,
        borderBottom: '2px solid var(--modal-header-border-color-split, rgba(0, 0, 0, 0.06))'
    }
});

export default styles;
