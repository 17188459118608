/**
 * @fileoverview Contracts view
 * @version 1.0
 * @author César Paul Hernández Camacho
 * @date 14/11/2020
 * @copyright 2020 RESSER S.A de C.V
 * @update 30/11/2020
 */
import React, { useState, useRef, useEffect } from 'react';
import Cookies from 'js-cookie';
import swal from 'sweetalert';
//@material-ui components
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { onShowAlertMessage } from 'redux/slice';
// @material-ui/icons
import {MdExpandMore}  from 'react-icons/md';
//Translations
import { useTranslation } from "react-i18next";
// core components
import Item from "components/Grid/GridItem.js";
import Container from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Table from './DocumentsTable';

//axios
import axios from 'libs/AxiosHelper';
//utils
import Rssr from 'libs/Rssr';
//messages
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from 'libs/Snackbar';
//styles
import styles from './Documents.styles';
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/working-man.json';
import { Grid } from '@material-ui/core';
import GlobeBoxTable from './GloveBox/GlobeBoxTable';

const axiosB = require('axios');

const useStyles = makeStyles(styles);

const filtersObj = {
    contract: '',
    date: '',
    client: '',
    plates: '',
    noSerie: '',
    status: 0
}

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const Documents = (props) => {
    //styles
    const classes = useStyles();
    //Reference
    const fileInputRef = useRef(null);
    const TableRef = useRef(null);
    //redux
    const dispatch = useDispatch();
    const regionId = useSelector(state => state.auth.loginData.regionId)
    //translate
    const [t, i18n] = useTranslation('common');
    //state
    const [reRender, setReRender] = useState(false);
    const [file, setFile] = useState('');
    const [totalDocs, setTotalDocs] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [fileName, setFileName] = useState('');
    const [fileType, setFileType] = useState('');
    const [fileSize, setFileSize] = useState(0);
    const [positionDocuments, setPositionDocuments] = useState(0);
    const [ListDescriptions, setListDescriptions] = useState([]);
    const [ListDocumentos, setListDocumentos] = useState('');

    useEffect(() => {
    }, [reRender]);

    useEffect(() => {
        if (ListDescriptions.length) 
            setListDocumentos(`(${ListDescriptions.join(", ")})`);
    }, [ListDescriptions]);

    /**
     * Function that handle when a file is added to the input
     * @param {e} event
     * @memberof Documents
     */
    const onFileChange = async (e) => {
        if (e.target.files.length > 0) {
            setFileName(e.target.files[0].name);
            setFileType((e.target.files[0].type.includes('pdf')) ? 'pdf' : 'xlsx');
            setFileSize(e.target.files[0].size);
            var aux = await toBase64(e.target.files[0]);
            aux = aux.split(",")[1];
            setFile(aux);
        }
        else {
            setFileName('');
            setFile('');
        }
        fileInputRef.current.value = '';
    }

    const uploadFile = (documentData, postData, getDocuments, onCloseModal, onSetDocuments) => {
        if (postData) {
            axios.post('DocumentsFMV2', {
                name: `${documentData.documentName}.${documentData.documentType}`,
                type: documentData.documentType,
                category: documentData.documentCategory,
                description: documentData.notes,
                CustomersId: documentData.customersId,
                file: file,

            })
                .then(response => {
                    TableRef.current.getDocs();
                    onSetDocuments();
                    getDocuments();
                    onCloseModal();
                    enqueueSuccessSnackbar(dispatch, t('contracts.success.0'));

                })
                .catch(error => {
                    dispatch(onShowAlertMessage())
                });
        }
        else {
            axios.put('DocumentsFMV2', {
                documentId: documentData.documentId,
                name: documentData.documentName,
                type: documentData.documentType,
                category: documentData.documentCategory,
                description: documentData.notes,
                CustomersId: documentData.customersId,
                file: file,

            })
                .then(response => {
                    TableRef.current.getDocs();
                    onSetDocuments();
                    getDocuments();
                    onCloseModal();
                    enqueueSuccessSnackbar(dispatch, t('contracts.success.0'));
                })
                .catch(error => {
                    dispatch(onShowAlertMessage())
                });
        }
        fileInputRef.current.value = '';
    }

    /**
     * Function to get the base64 from a file input
     * @param {array} file
     * @return base64 Base64 extracted from the file
     * @memberof Documents
     */
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    /**
     * Function to show filter modal
     * @memberof Documents
     */
    const onShowModal = () => {
        setShowModal(true);
    }

    /**
     * Function to hide filter modal
     * @memberof Documents
     */
    const onHideModal = () => {
        setShowModal(false);
        setFile('');
        setFileName('');
    }

    return (
        <>
            <Container>
                <Item xs={12} md={12} >
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<MdExpandMore  />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >

                            <p style={{ color: "#050B7F", fontWeight: 'bold', fontSize: '2rem', marginLeft: 7 }}> {t('dashboardNames.8')} </p>


                        </AccordionSummary>
                        <AccordionDetails className={classes.accordion} >
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', position: 'relative', bottom: 15 }}>
                                <Grid container spacing={2}>
                                    {
                                        regionId !== 0 && (
                                            <Grid item sx={6}>
                                                <Button
                                                    onClick={() => setPositionDocuments(1)}
                                                    className={
                                                        positionDocuments === 1 ?
                                                            classes.ButtonGo2GlobeBoxStyleOnSelect :
                                                            classes.ButtonGo2GlobeBoxStyle
                                                    }
                                                    aria-label="edit"
                                                    size="xs"
                                                >
                                                    {t('gloveBox.gloveBoxApp')}
                                                </Button>
                                            </Grid>
                                        )
                                    }
                                    <Grid item sx={6}>
                                        <Button
                                            onClick={() => setPositionDocuments(0)}
                                            className={
                                                positionDocuments === 0 ?
                                                    classes.ButtonGo2DocumentsStyleOnSelect :
                                                    classes.ButtonGo2DocumentsStyle
                                            }
                                            aria-label="edit"
                                            size="xs"
                                        >
                                            {t('gloveBox.documentApp')}
                                        </Button>
                                    </Grid>
                                </Grid>

                                {
                                    positionDocuments === 0
                                        ? <p style={{ color: "#828282", fontWeight: '300', fontSize: '1.0rem', marginTop: 10, marginLeft: 5 }}> {t('gloveBox.descriptionDocuments')} </p>
                                        : <p style={{ color: "#828282", fontWeight: '300', fontSize: '1.0rem', marginTop: 10, marginLeft: 5 }}> {t('gloveBox.descriptionGloveBox')} {ListDocumentos} </p>
                                }



                                <Container item xs={12} direction="column" alignItems="center" justify="flex-start">
                                    <Item xs={12} style={{ width: '100%' }} >
                                        {
                                            positionDocuments === 0 ?
                                                (
                                                    <Table
                                                        ref={TableRef}
                                                        onHideModal={onHideModal}
                                                        showModal={showModal}
                                                        onShowModal={onShowModal}
                                                        fileName={fileName}
                                                        fileSize={fileSize}
                                                        onUploadFile={uploadFile}
                                                        onRerender={() => { setReRender(!reRender) }}
                                                        onDocUpload={() => { fileInputRef.current.click(); }}
                                                    />
                                                ) : (
                                                    <GlobeBoxTable setListDocs={setListDescriptions} />
                                                )
                                        }
                                    </Item>
                                </Container>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </Item>
            </Container>
            <input
                style={{ overflow: 'hidden', width: 0, height: 0 }}
                ref={fileInputRef}
                onChange={onFileChange}
                type="file"
                accept="application/pdf"
            />
        </>
    );
}

export default Documents;