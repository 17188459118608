/**
 * @fileoverview SideBar Styles
 * @version 1.0.0
 * @author Carlos Emilio Blanco Lopez <emilio.blanco@resser.com>
 * @date 11/09/2023
 * @copyright 2023 Industrias RESSER S.A de C.V
 */

import { createStyles, makeStyles } from "@mui/styles";


const useStyles = makeStyles((_theme) =>
    createStyles({

        LoadingMask: {
            width: '100%',
            height: '100%',
            color: '#050B7F',
            fontFamily: 'Montserrat',
        },
        Principal: {
            fontWeight: 'bold',
            fontSize: 16,
            paddingTop: 25,
            paddingBottom: 25,
            paddingLeft: 15,
        },
        Section: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        ModuleName: {
            width: '90%',
            height: 40,
        },
        ImgStyle: {
            width: 20,
            height: 20,
            marginLeft: 15
        },
        LabelStyle: {
            fontWeight: 600
        },
        SectionLoading: {
            position: 'absolute',
            bottom: 20,
            width: '100%',
            height: '100%',
            backgroundColor: 'RGB(242, 242, 242, 0.6)',
            backdropFilter: 'blur(4px)',
            color: '#050B7F',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        Loadinginfo: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '70%',
        }

    })
);

export default useStyles;