/**
 * @fileoverview Configuration Slice by Modules
 * @version 1.0
 * @author Carlos Emilio Blanco Lopez
 * @date 15/08/2023
 * @copyright 2023 Industrias RESSER S.A de C.V
 **/

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    modules: [],
};

const modulesSlice = createSlice({
    name: 'modules',
    initialState,
    reducers: {
        addModules: (state, action) => {
            state.modules = action.payload;
        },
        deleteModules: (state, action) => {
            state.modules = [];
        }
    },
});

export const {
    addModules,
    deleteModules
} = modulesSlice.actions;

export default modulesSlice.reducer;