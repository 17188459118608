/**
 * @fileoverview Custom modal to request password reset
 * @version 1.0.0
 * @author Leslie Mosqueda
 * @date 12/04/2024
 * @copyright 2024 Industrias RESSER S.A de C.V
 */
import React, { useState } from "react";
//Messages
import { 
    enqueueSuccessSnackbar, 
    enqueueErrorSnackbar 
} from 'libs/Snackbar';
// Local Imports.
import CustomModal from 'components/CustomModal/CustomModal';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
//axios
const Axios = require('axios');


const ModalLogin = ({showModal, setShowModal}) => {
    const [recoveryEmail, setRecoveryEmail] = useState("");
    const [t] = useTranslation('common');
    const dispatch = useDispatch();

    /**
   * Function to recover the password via email
   * @memberof UserProfile
   */
  const onForgetPassClick = () => {
    if (recoveryEmail === "") {
      enqueueErrorSnackbar(dispatch, t('profileConfiguration.account.errors.2'));
    }
    else {
      Axios.get(`${process.env.REACT_APP_API_SERVER}UserWithoutRegister?query=${recoveryEmail}`)
        .then(async (response) => {
          if (response.data.success) {
            Axios.get(`${process.env.REACT_APP_API_SERVER}RestorePassword/RestoreMail?_dc=1608580837541&email=&usrName=${recoveryEmail}`)
              .then(response => {
                if (response.data.success) {
                  enqueueSuccessSnackbar(dispatch, t('profileConfiguration.account.success.2'));
                }
                else {
                  enqueueErrorSnackbar(dispatch, t('login.errors.auth.3'));
                }
                onHideModal();
              });
          } else {
            enqueueErrorSnackbar(dispatch, t("login.errors.auth.5"));
          }
        }) 
        .catch(error => { });

    }
  }

  /**
   * Function to hide filter modal
   * @memberof Contracts
   */
   const onHideModal = () => {
    setRecoveryEmail('');
    setShowModal(false);
  }

    return (
        <CustomModal
            open={showModal}
            onClose={onHideModal}>
            <GridContainer alignItems="center" justify="center" direction="column" style={{ width: '100%' }}>
                <GridItem xs={12} md={10} alignSelf="flex-start" >
                    <p style={{ color: "#050B7F", fontWeight: 'bold', fontSize: '1.5rem' }}> {t('login.rememberPassword')} </p>
                </GridItem>
            </GridContainer>
            <GridItem xs={12} style={{ width: '70%' }}>
              <CustomInput
                labelText={t('profileConfiguration.account.forgotPassMessage')}
                id="email"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: recoveryEmail,
                  name: "recoveryEmail",
                  onChange: ({target}) => { setRecoveryEmail(target.value); }
                }}
              />
            </GridItem>
            <GridContainer alingContent={"center"} justify="center">
              <Button onClick={onForgetPassClick} style={{ backgroundColor: "rgb(93,200,246)" }} aria-label="edit">
                {t('generalText.send')}
              </Button>
            </GridContainer>
        </CustomModal>
    )
}

export default ModalLogin;