/**
 * @fileoverview LoginForm
 * @description Formulario de Login
 * @version 1.0.0
 * @author Carlos Emilio Blanco Lopez
 * @date 25/10/2022
 * @copyright 2022 Industrias RESSER S.A de C.V
 * @update 25/10/2022
 */

import React, { useEffect, useState } from "react";
import { TextField, Grid } from "@material-ui/core";
//axios
import axios from "libs/AxiosHelper";
import { useHistory } from "react-router-dom";
//traductions
import { useTranslation } from "react-i18next";
//Messages
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from "libs/Snackbar";
import logo from "assets/img/logo_ALD_black.png";
import Cookies from "js-cookie";
//redux actions
import {
  showCircularProgress,
  closeCircularProgress,
  loginUser,
  setLoginData,
  addModules,
  addCategories,
  setRememeberUserData,
  setCurrentUrl,
  setShowCookies,
  showSidebarMask,
  hideSidebarMask,
  onHideAlertMessage,
  showNotificationMask
} from 'redux/slice';
import { hideNotificationMask } from 'redux/slice/loadingMask';
import { useDispatch, useSelector } from 'react-redux';
import HCaptcha from '@hcaptcha/react-hcaptcha';
//Estilos
import { makeStyles } from "@material-ui/core/styles";
import styles from "../Login.styles";
import swal from "sweetalert2";

const Axios = require("axios");

var CryptoJS = require("crypto-js");

// Component Styles.
const useStyles = makeStyles(styles);

const labelProps = {
  style: { fontFamily: "Source Sans Pro !important" },
};

/**
 * Componente para el formulario del login
 * @param {string}   user
 * @param {function} setUser
 * @param {string}   pass
 * @param {function} setPass
 * @param {boolean}  checked
 * @param {function} setChecked
 * @param {function} onShowModal
 * @param {function} ereaseCaches
 * @param {function} setLastPass
 * @param {function} setPositionForm
 * @returns Fragment
 */

const LoginForm = ({
  user,
  setUser,
  pass,
  setPass,
  checked,
  setChecked,
  onShowModal,
  ereaseCaches,
  setLastPass,
  setPositionForm,
  setData
}) => {

  let history = useHistory();
  const classes = useStyles();
  const [t] = useTranslation("common");

  //Redux
  const dispatch = useDispatch();
  const language = useSelector((state) => state.configurations.language);
  const seenCookies = useSelector((state) => state.configurations.seenCookies);
  const showAlertMessage = useSelector((state) => state.loadingMask.showAlertMessage);

  // Refs
  const userNameInput = React.useRef(null);
  const userPasswordInput = React.useRef(null);
  const captchaRef = React.useRef(null);

  //onChanges
  const handleChangeUser = ({ target }) => setUser(target.value);
  const handleChangePassword = ({ target }) => setPass(target.value);

  useEffect(() => {
    userNameInput.current.focus();
    if (showAlertMessage) {
      dispatch(onHideAlertMessage());
    }
  }, []);

  /**
   * @title handleUserKeyDown
   * @description Función para detectar interaccion de eventos en el formulario, apoyo para la captura de eventos en el formulario.
   */

  const handleUserKeyDown = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      if (user != "" && pass != "") {
        onLoginClickHandler();
      }
      else if (user != "" && pass === "") {
        userPasswordInput.current.focus();
      }
      else if (user === "" && pass != "") {
        userNameInput.current.focus();
      }
    }
  };

  /**
   * @title getCategoryes (Async / Await)
   * @description Función Asincrona para el GET de categorias, peticion para la obtencion de las categorias relacionadas co el usuario.
   */

  const getCategoryes = async () => {
    axios.get("modulecategoriesfmv2")
      .then(async (res) => {
        if (res.data.success == true) {
          if (res.data.items) {

            const data = res.data.items.sort(function (a, b) {
              if (a.order > b.order) {
                return 1;
              } else {
                return -1;
              }
            });
            dispatch(addCategories(data));
            dispatch(hideSidebarMask());
            return true;
          }
        } else {
          enqueueErrorSnackbar(dispatch, t("login.errors.auth.3"));
        }
      })
      .catch((error) => {
        enqueueErrorSnackbar(dispatch, t("login.errors.auth.3"));
      });
  };

  /**
   * @title getModules (Async / Await)
   * @description Función Asincrona para el GET de modulos, peticion para la obtencion de modulos relacionados con los usuario.
   */

  const getModules = async () => {
    dispatch(showSidebarMask());
    axios.get("ModulesFMV2")
      .then(async (res) => {
        if (res.data.success) {
          dispatch(addModules(res.data.items));
          return true;
        } else {
          enqueueErrorSnackbar(dispatch, t("login.errors.auth.3"));
        }
      })
      .catch((error) => {
        enqueueErrorSnackbar(dispatch, t("login.errors.auth.3"));
      });
  };

  /**
   * @title getNotification (Async / Await)
   * @description Función Asincrona para el GET de Notificaciones, peticion para la obtencion de Notificaciones relacionados con los usuario.
   */

  const getNotification = async () => {
    dispatch(showNotificationMask());
    axios
      .get("NotificationsCatalog/All?start=0&limit=100")
      .then((response) => {
        if (response.data.success) {
          dispatch(hideNotificationMask());
        }
      })
      .catch((error) => { });
  };

  /**
   * @title onLoginClickHandler
   * @description Función Principal del LoginForm, flujo para el login con las credenciales ya capturadas del usuario
   */

  /**
   * Función que realiza el inicio de sesión en el portal FM
   */
  const onLoginClickHandler = () => {
    dispatch(addModules([]));
    dispatch(addCategories([]));
    dispatch(showCircularProgress());
    let password = CryptoJS.AES.encrypt(pass, "mizutsune").toString();
    const userData = {
      username: user,
      password,
    };

    axios
      .getToken(userData, 0)
      .then((response) => {
        axios
          .get("CheckSystemStatus?platform=2")
          .then(async (response) => {
            if (await response.data.status) {
              axios
                .get("loginFMV2?code=2")
                .then(async (response) => {
                  if (await response.data.Success) {
                    // if (false) { //TO-DO : Se cambiara cuando se lanze a produccion doble factor
                    if (response.data.TokenLogin === true && response.data.TokenStatus === 0) {

                      dispatch(closeCircularProgress());
                      let data = {
                        data: response.data,
                        userData: userData
                      }

                      setData(data);
                      setPositionForm(2);
                    } else {
                      if (response.data.UserRole !== 15) {
                        Cookies.set('userData', JSON.stringify(userData), { domain: 'www.myald-latam.com', secure: true, sameSite: 'strict' });
                        localStorage.setItem("userData", JSON.stringify(userData));
                        let scope;
                        switch (response.data.ApiId) {
                          case 3:
                            scope = "MyAldFMAPI openid";
                            dispatch(setCurrentUrl(process.env.REACT_APP_API_FM));
                            break;
                          case 4:
                            scope = "MyAldSupplierAPI openid";
                            dispatch(
                              setCurrentUrl(process.env.REACT_APP_API_SUPPLIER)
                            );
                            break;
                          default:
                            scope = "MyAldAPI openid";
                            dispatch(
                              setCurrentUrl(process.env.REACT_APP_API_SERVER)
                            );
                            break;
                        }
                        await axios.getToken(userData, response.data.ApiId);
                        await getCategoryes();
                        await getModules();
                        await getNotification();
                        if (checked) {
                          dispatch(setRememeberUserData(true));
                        }
                        dispatch(
                          setLoginData({
                            regionId: response.data.RegionId,
                            userId: response.data.UserId,
                            userRole: response.data.UserRole,
                            SignedPolicies: response.data.SignedPolicies,
                          })
                        );
                        localStorage.setItem(
                          "NewUserAccount",
                          response.data.Email
                        );
                        if (response.data.TokenStatus === 0) {
                          dispatch(loginUser());
                          enqueueSuccessSnackbar(dispatch, t("login.correct"));
                          dispatch(closeCircularProgress());
                          if (!seenCookies) {
                            dispatch(setShowCookies(true));
                          }

                          if (response.data.UserRole === 27) {
                            history.push("/admin/aldsat");
                          } else {
                            history.push("/admin/home");
                          }

                        } else {
                          dispatch(closeCircularProgress());
                          if (response.data.TokenStatus === 1) {
                            swal.fire({
                              icon: "info",
                              title: `${t("resetSenha.title")}`,
                              html: `${t(
                                "resetSenha.expiredToken_1"
                              )} <br><br> ${t("resetSenha.expiredToken_2")}`,
                            });
                          } else {
                            setLastPass(password);
                            setPositionForm(1);
                          }
                        }
                      } else {
                        swal.fire({
                          icon: "info",
                          title: `${t("resetSenha.title")}`,
                          html: `${t("login.errors.auth.6")}`,
                        });
                        dispatch(closeCircularProgress());
                      }
                    }
                  } else {
                    ereaseCaches();
                    enqueueErrorSnackbar(dispatch, t("login.errors.auth.4"));
                  }
                })
                .catch((error) => {
                  ereaseCaches();
                  enqueueErrorSnackbar(dispatch, t("login.errors.auth.0"));
                });
            } else {
              const info =
                language === "en"
                  ? response.data.systemMsg.msgEn
                  : language === "es"
                    ? response.data.systemMsg.msgEsMX
                    : language === "fr"
                      ? response.data.systemMsg.msgFr
                      : response.data.systemMsg.msgPt;
              swal
                .fire({
                  icon: "info",
                  title: `${t("ModuleBI.title")}`,
                  html: `${info}`,
                })
                .catch((error) => {
                  ereaseCaches();
                  enqueueErrorSnackbar(dispatch, t("login.errors.auth.0"));
                });
            }
          })
          .catch((error) => {
            ereaseCaches();
            enqueueErrorSnackbar(dispatch, t("login.errors.auth.0"));
          });
      })
      .catch(() => {
        ereaseCaches();
        Axios.get(
          `${process.env.REACT_APP_API_SERVER}UserWithoutRegister?query=${user}`
        )
          .then(async (response) => {
            if (response.data.success) {
              enqueueErrorSnackbar(dispatch, t("login.errors.auth.0"));
            } else {
              enqueueErrorSnackbar(dispatch, t("login.errors.auth.5"));
            }
          })
          .catch((error) => { });
      });
  };

  //Fragmento de componente
  return (
    <>
      <Grid
        container="column"
        className={classes.userPassContainer}>
        <label className='labelLoginStyle'>
          {t('login.userPlaceholder')}
          <TextField
            className='Login_textInput'
            InputLabelProps={labelProps}
            inputRef={userNameInput}
            value={user}
            id="user"
            label={t('login.userInput')}
            variant="outlined"
            onKeyDown={handleUserKeyDown}
            style={{ marginTop: '1rem' }}
            onChange={handleChangeUser} />
        </label>
        <label className='labelLoginStyle'>
          {t('login.passwordPlaceholder')}
          <TextField
            className='Login_textInput'
            InputLabelProps={labelProps}
            inputRef={userPasswordInput}
            value={pass}
            type="password"
            id="paassword"
            label={t('login.passwordInput')}
            variant="outlined"
            onKeyDown={handleUserKeyDown}
            style={{ marginTop: '1rem' }}
            onChange={handleChangePassword} />
        </label>
        {/* CheckBox */}
        <div className='contRememberSession'>
          <label style={{ color: 'rgb(130,130,130)', display: 'flex' }}>
            <input
              type="checkbox"
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
            <p className='rememberSession'>{t('login.rememeberSession')}</p>
          </label>
          <a onClick={onShowModal} className='rememberPassword'> {t('login.rememberPassword')} </a>
        </div>
      </Grid>
      {/* Login Button */}
      <div className='buttonLogin'>
        <button
          type={'button'}
          onClick={() => {
            onLoginClickHandler();
          }}
          className="loginButton">
          {t('login.signIn')}
        </button>
        <div className='buttonLogin'>
          <img src={logo} alt="logo" />
        </div>
      </div>
    </>
  )
}

export default LoginForm;
