/**
 * @fileoverview Modal para el modulo de presupuesto
 * @version 1.0.0
 * @author Carlos Emilio Blanco Lopez
 * @date 23/01/2024
 * @copyright 2024 RESSER S.A de C.V
 */

import React, { useContext } from 'react';
import { Context } from '../Table';
import { Modal, Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
//Icons

import { IconContext } from 'react-icons';
import { MdClose } from 'react-icons/md';

import {
    KanbanEditIcon,
    KanbanComentIcon,
    KanbanHistoryIcon,
    KanbanEditIconActive,
    KanbanCommentsIconActive,
    KanbanHistoryIconActive,
    KanbanAttachmentIcon,
    KanbanAttachmentIconActive
} from '../../../assets/img/Icons/Icons';

// styles
import styles from './styles';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@mui/material';

const useStyles = makeStyles(styles);

const style = {
    position: 'absolute',
    left: '48%',
    width: '50vw',
    height: '100vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};

const ModalPresupuesto = ({
    children,
    open,
    OnEdit,
    OnComments,
    OnHistory,
    OnAttachment,
}) => {

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Header
                    OnEdit={OnEdit}
                    OnComments={OnComments}
                    OnHistory={OnHistory}
                    OnAttachment={OnAttachment} />
                <Divider component="div" role="presentation" style={{
                    marginTop: 13, marginBottom: 15, marginLeft: '-16px', marginRight: '-16px'
                }} />
                <section style={{
                    height: '90vh',
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    {children}
                </section>
            </Box>
        </Modal>
    )
}

export default ModalPresupuesto;

/**
 * @Component Header
 * @description Componente Header para el modal de presupuesto
 */

const Header = ({
    OnEdit,
    OnComments,
    OnHistory,
    OnAttachment,
}) => {
    const { t } = useTranslation('common');
    const { state, dispatch } = useContext(Context);

    const title = state.ModalInfo ? t('ModuleAgreements.BudgetApproval') :
        state.ModalComments ? t('ModuleAgreements.actions.comments') :
            state.ModalHistory ? t('ModuleAgreements.actions.history') :
                t('ModuleAgreements.actions.attachment');
    return (
        <section
            aria-label='HeaderModalQuote'
            style={{
                display: 'flex',
            }}>
            <p style={{
                color: '#050B7F',
                fontFamily: 'Montserrat, sans-serif, Arial',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 700,
                letterSpacing: '0.03px',
                width: '80%',
            }}>{title}</p>
            <div aria-label='Buttons' style={{
                width: 150,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginRight: 20
            }}>
                <Tooltip title={t('ModuleAgreements.actions.view')} arrow>
                    <section style={{ marginTop: 7 }}>
                        {
                            state.ModalInfo ?
                                <KanbanEditIconActive
                                    width='20'
                                    height='22'
                                    style={{ cursor: 'pointer' }} /> :
                                <KanbanEditIcon
                                    width='20'
                                    height='22'
                                    onClick={() => { OnEdit({ AgreementId: state.AgreementId }) }}
                                    style={{ cursor: 'pointer' }} />
                        }
                    </section>
                </Tooltip>
                <Tooltip title={t('ModuleAgreements.actions.comments')} arrow>
                    <section style={{ marginTop: 7 }}>
                        {
                            state.ModalComments ?
                                <KanbanCommentsIconActive
                                    width='20'
                                    height='22'
                                    style={{ cursor: 'pointer' }} /> :
                                <KanbanComentIcon
                                    width='20'
                                    height='22'
                                    onClick={() => { OnComments({ AgreementId: state.AgreementId }) }}
                                    style={{ cursor: 'pointer' }} />
                        }
                    </section>
                </Tooltip>
                <Tooltip title={t('ModuleAgreements.actions.history')} arrow>
                    <section style={{ marginTop: 7 }}>
                        {
                            state.ModalHistory ?
                                <Tooltip title="Historial" arrow>
                                    <KanbanHistoryIconActive
                                        width='18'
                                        height='20'
                                        style={{ cursor: 'pointer' }} />
                                </Tooltip> :
                                <Tooltip title="Historial" arrow>
                                    <KanbanHistoryIcon
                                        width='18'
                                        height='20'
                                        onClick={() => { OnHistory({ AgreementId: state.AgreementId }) }}
                                        style={{ cursor: 'pointer' }} />
                                </Tooltip>
                        }
                    </section>
                </Tooltip>
                <Tooltip title={t('ModuleAgreements.actions.attachment')} arrow>
                    <section style={{ marginTop: 7 }}>
                        {
                            state.ModalAttachment ?
                                <Tooltip title="Attachment" arrow>
                                    <KanbanAttachmentIconActive
                                        width='18'
                                        height='20'
                                        style={{ cursor: 'pointer' }} />
                                </Tooltip> :
                                <Tooltip title="Attachment" arrow>
                                    <KanbanAttachmentIcon
                                        width='18'
                                        height='20'
                                        onClick={() => { OnAttachment({ AgreementId: state.AgreementId }) }}
                                        style={{ cursor: 'pointer' }} />
                                </Tooltip>
                        }
                    </section>
                </Tooltip>
                <IconContext.Provider value={{ size: '24px', color: '#828282' }}>
                    <MdClose onClick={() => { dispatch({ type: 'closeAll' }) }} style={{ cursor: 'pointer' }} />
                </IconContext.Provider>

            </div>

        </section>
    );
}

/**
 * @Component ContentInfo
 * @description Componente para el contenido del modal de presupuesto
 */

export const ContentInfo = (props) => {
    const { title, desc } = props;
    const classes = useStyles();
    let content = desc.split('\n');
    return (
        <div className={classes.sectionHistory} {...props} >
            <p className={classes.HistoryTitle}>{title}</p>
            {
                content.map((item, index) => {
                    return (
                        <p key={index} className={classes.HistoryContent}>{item}</p>
                    )
                })
            }
        </div>
    )
}
