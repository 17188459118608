/*eslint-disable*/
import React from "react";
import './Footer.css';
import logo from 'assets/img/logo_ALD_black.png';
//Translations
import { useTranslation } from "react-i18next";


function Footer({ links }) {
  /**
     * Función encargada de setear y abrir la url de las poiliticas de privacidad en relación a la región del usuario.
     * 
     */
  const onlinckClick = (value) => {
    window.open(links[value]);
  }

  const [t] = useTranslation('common');

  return (
    <footer className="footer">
      <div className="footer_container">
        <div className="footer_container_menu">
          <a href="#home" className="menu_item" onClick={() => onlinckClick(0)}>
            {t('PrivacyPolicy.privacy')}
          </a>
          <p style={{color: '#BDBDBD'}}>|</p>
          <a href="#company" className="menu_item" onClick={() => onlinckClick(1)}>
            {t('PrivacyPolicy.cookies')}
          </a>
          <p style={{color: '#BDBDBD'}}>|</p>
          <a href="#portfolio" className="menu_item" onClick={() => onlinckClick(2)}>
            {t('PrivacyPolicy.terms')}  
          </a>
        </div>
      </div>
      <div className="footer_img">
        <img src={logo} alt="logo" className="footer_logo" />
      </div>
    </footer>
  );
}


export default Footer