/**
 * @fileoverview Category
 * @version 1.0.0
 * @author Martin Duran Anguiano
 * @date 26 de Mayo 2021
 * @copyright 2020 Industrias RESSER S.A de C.V
 */
import React from "react";
import { NavLink } from "react-router-dom";

//@material-ui components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { Skeleton } from "@material-ui/lab";

//Core components
import Container from "components/Grid/GridContainer.js";

//Icons
import IconDocuments from "../../../assets/img/Icons/documentos.svg";
import IconFlota from "../../../assets/img/Icons/Flota.svg";
import IconManutencion from "../../../assets/img/Icons/manutencion.svg";
import IconNet from "../../../assets/img/Icons/ALDNET.svg";
import IconIFRS16 from "../../../assets/img/Icons/ifrs16.svg";
import IconRelatorios from "../../../assets/img/Icons/relatorios.svg";
import IconNotificaciones from "../../../assets/img/Icons/notificacionesSideBar.svg";

//Translations
import { useTranslation } from "react-i18next";

export default function Category(props) {
  const [t] = useTranslation("common");
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ModuleName, setModuleName] = React.useState("");
  const screenWidth = window.screen.width;
  const { routes, modules, SidebarCollapse, isLoading } = props;
  const handleClickOpen = (module) => (event) => {
    // Obtención de nombre del módulo y su ruta
    switch (module.id) {
      // Documentos
      case 32:
        setModuleName(t("dashboardNames.8"));
        break;
      // Administración de flota
      case 33:
        setModuleName(t("dashboardNames.3"));
        break;
      // ALD BI
      case 35:
        setModuleName(t("dashboardNames.7"));
        break;
      // IFRS16
      case 34:
        setModuleName(t("dashboardNames.1"));
        break;
      // ALD Net
      case 30:
        setModuleName(t("dashboardNames.0"));
        break;
      // Mantenimientos
      case 31:
        setModuleName(t("dashboardNames.2"));
        break;
      //ALD SAT
      case 29:
        setModuleName(t("dashboardNames.4"));
        break;
      default:
        break;
    }
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClickClose = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(false);
  };

  /**
   * Función encargada de colocar el nombre de la categoria
   * @param {*} categoryId
   */
  const renderCategoryName = (categoryId) => {
    switch (categoryId) {
      case 5:
        return t(`categoriesNames.2`);
      case 6:
        return t(`categoriesNames.3`);
      case 7:
        return t(`categoriesNames.1`);
      case 8:
        return t(`categoriesNames.0`);
      case 9:
        return t(`categoriesNames.4`);
      default:
        break;
    }
  };

  /**
   * Función que renderea los modulos relacionados a cada categoria.
   */
  const renderModule = () => {
    let moduleName = "",
      moduleRoute = "";
    let moduleIcon;
    let moduleActive = true;

    return modules.map((module, key) => {
      moduleActive = true;

      let rt = routes.find(function (post, index) {
        if (post.id == module.id) return true;
      });

      if (rt != undefined) moduleRoute = rt.layout + rt.path;

      // Obtención de nombre del módulo y su ruta
      switch (module.id) {
        // Documentos
        case 32:
          moduleIcon = IconDocuments;
          moduleName = t("dashboardNames.8");
          break;

        // Administración de flota
        case 33:
          moduleIcon = IconFlota;
          moduleName = t("dashboardNames.3");
          break;

        // ALD BI
        case 35:
          moduleIcon = IconRelatorios;
          moduleName = t("dashboardNames.7");
          break;

        // IFRS16
        case 34:
          moduleIcon = IconIFRS16;
          moduleName = t("dashboardNames.1");
          break;

        // ALD Net
        case 30:
          moduleIcon = IconNet;
          moduleName = t("dashboardNames.0");
          break;

        // Mantenimientos
        case 31:
          moduleIcon = IconManutencion;
          moduleName = t("dashboardNames.2");
          break;

        //ALD SAT
        case 29:
          moduleIcon = IconRelatorios;
          moduleName = t("dashboardNames.4");
          break;

        //ALD SAT
        case 36:
          moduleIcon = IconNotificaciones;
          moduleName = t("dashboardNames.10");
          break;

        default:
          moduleIcon = IconRelatorios;
          moduleActive = false;
          break;
      }
      if (moduleActive) {
        return (
          <NavLink
            key={key}
            to={moduleRoute}
            activeClassName="active"
            onClick={props.onClick}
          >
            {SidebarCollapse && screenWidth >= 960 && (
              <Popper
                open={open}
                anchorEl={anchorEl}
                placement="right"
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper style={{ marginLeft: 30 }} elevation={0}>
                      <div className="Sidebar_Popper">{ModuleName}</div>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            )}
            <div
              className={
                SidebarCollapse && screenWidth >= 960
                  ? "sidebarCollapse_listItem"
                  : "sidebar_listItem"
              }
            >
              <div style={{ display: "flex" }}>
                <div
                  className="sidebar_listItem_Icon"
                  onMouseEnter={handleClickOpen(module)}
                  onMouseLeave={handleClickClose(module)}
                >
                  <img src={moduleIcon} style={{ height: "18px" }} />
                </div>
                <div>
                  {(!SidebarCollapse || screenWidth <= 960) && (
                    <div className="sidebar_listItem_text">
                      <p>{moduleName}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </NavLink>
        );
      } else {
        return null;
      }
    });
  };

  return screenWidth <= 960 ? (
    <Container>
      <List className="Sidebar_categoryList" component="nav">
        <hr />
        {/* Category Section */}
        {(!SidebarCollapse || screenWidth <= 960) && (
          <div>
            {isLoading ? (
              <div>
                <Skeleton animation="wave" width={80} />
                <Skeleton animation="wave" width={200} />
                <Skeleton animation="wave" width={200} />
              </div>
            ) : (
              <ListItem>
                <p className="sidebar_category">
                  {renderCategoryName(props.categoryId)}
                </p>
              </ListItem>
            )}
          </div>
        )}
        {/* Module section */}
        <div
          className={
            SidebarCollapse && screenWidth >= 960
              ? "SidebarCollapse_Modules"
              : "Sidebar_Modules"
          }
        >
          {renderModule()}
        </div>
      </List>
    </Container>
  ) : (
    <Container>
      <List className={"Sidebar_categoryList"} component="nav">
        {/* Module section */}
        <div
          className={
            SidebarCollapse && screenWidth >= 960
              ? "SidebarCollapse_Modules"
              : "Sidebar_Modules"
          }
        >
          <div
            className={
              SidebarCollapse
                ? "SidebarCollapse_Separador"
                : "Sidebar_Separador"
            }
          />
          {/* Category Section */}
          {isLoading ? (
            <div>
              <Skeleton animation="wave" width={80} />
              <Skeleton animation="wave" width={200} />
              <Skeleton animation="wave" width={200} />
            </div>
          ) : (
            <ListItem className="Sidebar_Title_Module">
              {(!SidebarCollapse || screenWidth <= 960) && (
                <p className="sidebar_category">
                  {renderCategoryName(props.categoryId)}
                </p>
              )}
            </ListItem>
          )}
          {renderModule()}
        </div>
      </List>
    </Container>
  );
}
