/**
 * @fileoverview ChangesPassForm
 * @description Formulario de Login
 * @version 2.0
 * @author Carlos Emilio Blanco Lopez
 * @date 25/10/2022
 * @copyright 2022 Industrias RESSER S.A de C.V
 * @update 25/10/2022
 */

// NPM Packages Imports.
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { Grid, TextField } from "@material-ui/core";
import logo from "assets/img/logo_ALD_black.png";
//axios
import axios from 'libs/AxiosHelper';
//traductions
import { useTranslation } from "react-i18next";
//Estilos
import styles from '../Login.styles';
//Messages
import { enqueueSuccessSnackbar } from 'libs/Snackbar';
//redux actions
import {
  showCircularProgress,
  closeCircularProgress,
  loginUser,
} from 'redux/slice'
//image
import swal from 'sweetalert2';

import { useFormik } from 'formik';
import * as Yup from 'yup';

var CryptoJS = require("crypto-js");

// Component Styles.
const useStyles = makeStyles(styles);

const ChangePassForm = ({ LastPass }) => {
  const classes = useStyles();
  const [t] = useTranslation('common');
  const dispatch = useDispatch();

  /**
   * Validaciones de Formik
   */

  const formSchema = Yup.object().shape({
    newPass: Yup.string()
      .min(8, t("resetSenha.formFieldsMessages.shortPass"))
      .max(15, t("resetSenha.formFieldsMessages.longPass"))
      .required(t("resetSenha.formFieldsMessages.fieldRequired"))
      .matches(/^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])^(?!.*['[\]\{\}\?¿\=\+\-_"\;\)(\|\/\\'])(?=.{8,15})/,
        t("resetSenha.formFieldsMessages.passwordCriteria")),
    confirmPass: Yup.string()
      .min(8, t("resetSenha.formFieldsMessages.shortPass"))
      .max(15, t("resetSenha.formFieldsMessages.longPass"))
      .required(t("resetSenha.formFieldsMessages.fieldRequired"))
      .matches(/^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])^(?!.*['[\]\{\}\?¿\=\+\-_"\;\)(\|\/\\'])(?=.{8,15})/,
        t("resetSenha.formFieldsMessages.passwordCriteria")),
  });


  /**
   * Objeto Formik
   */

  const formik = useFormik({
    initialValues: {
      newPass: "",
      confirmPass: ""
    },
    validationSchema: formSchema,
    onSubmit: async (values) => { await onSubmitFormik(values) },
  });

  /**
    * @title onSubmitFormik
    * @description Función para actualizar contraseña por medio del Token, flujo para actualizacion de contraseña por medio de token.
   */

  const onSubmitFormik = async (values) => {
    if (values.newPass !== values.confirmPass) {
      swal.fire({
        icon: 'error',
        title: `Error`,
        text: `${t("resetSenha.formFieldsMessages.samePass")}`,
      })
    }
    else {
      let password = CryptoJS.AES.encrypt(values.newPass, 'mizutsune').toString();
      dispatch(showCircularProgress());
      await axios.put('UpdatePasswordFMV2', {
        oldPassword: LastPass,
        newPassword: password,
        tokenPassword: true
      })
        .then(async response => {
          switch (await response.data.CodeNumber) {
            case 4: //El usuario tardo más de 30 minutos
              dispatch(closeCircularProgress());
              swal.fire({
                icon: 'warning',
                title: `${t("resetSenha.titleWarning")}`,
                text: `${t('profileConfiguration.alerts.errors.1')}`,
              })
              break;
            case 1: //El usuario coloco otro correo
              dispatch(closeCircularProgress());
              swal.fire({
                icon: 'error',
                title: `${t("resetSenha.titleWarning")}`,
                text: `${t("resetSenha.formFieldsMessages.samePass")}`,
              })
              break;
            case 3:// Se puede restablecer la contraseña
              swal.fire({
                icon: 'success',
                title: `${t("resetSenha.titleSuccess")}`,
                text: `${t('profileConfiguration.alerts.success.1')}`,
              })
              dispatch(loginUser());
              enqueueSuccessSnackbar(dispatch, t('login.correct'));
              dispatch(closeCircularProgress());
              break;
            case 5: //El usuario coloco una contraseña similar a las 5 anteriores
              dispatch(closeCircularProgress());
              swal.fire({
                icon: 'warning',
                title: `${t("resetSenha.titleWarning")}`,
                text: `${t('profileConfiguration.alerts.errors.3')}`,
              })
              break;
            default:
              dispatch(closeCircularProgress());
              swal({ title: t("resetSenha.titleWarning"), text: t('profileConfiguration.alerts.errors.2'), icon: 'error' })
              break
          }
        })
    }
  }

  return (
    <>
      <Grid
        container="column"
        className={classes.userPassContainer}>
        <label className='labelLoginStyle'>
          <p className='textNewPass_login'>
            {t('login.infoMessages')}
          </p>
          {t('login.passwordLabel_2')}
          <TextField
            InputLabelProps={{ style: { fontFamily: 'Source Sans Pro !important' } }}
            value={formik.values.newPass}
            type="password"
            id="newPass"
            label={t('login.passwordInput_2')}
            variant="outlined"
            className='Login_textInput'
            inputProps={{
              maxLength: 15,
              minLenght: 8,
            }}
            style={{ marginTop: '1rem' }}
            onChange={formik.handleChange}
            error={formik.errors.newPass}
            helperText={formik.errors.newPass}
          />
        </label>
        <label className='labelLoginStyle'>
          {t('login.passwordLabel_3')}
          <TextField
            InputLabelProps={{ style: { fontFamily: 'Source Sans Pro !important' } }}
            value={formik.values.confirmPass}
            inputProps={{
              maxLength: 15,
              minLenght: 8
            }}
            type="password"
            id="confirmPass"
            style={{ marginTop: '1rem' }}
            label={t('login.passwordInput_2')}
            variant="outlined"
            className='Login_textInput'
            onChange={formik.handleChange}
            error={formik.errors.confirmPass}
            helperText={formik.errors.confirmPass}
          />
        </label>
      </Grid>
      {/* Login Button */}
      <div className='buttonLogin'>
        <button
          type={'submit'}
          onClick={formik.handleSubmit}
          className="loginButton">
          {t('login.continue')}
        </button>
        <div className='buttonLogin'>
          <img src={logo} alt="logo" />
        </div>
      </div>
    </>
  )
}

export default ChangePassForm