/**
 * @fileoverview routes
 * rutas del dashboard
 * @version 1.0
 * @author César Paul Hernández Camacho
 * @date 30/10/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 * @upadte 4/12/2020
 */
// @material-ui/icons
import {
  MdAssignment,
  MdBusinessCenter,
  MdLocalShipping,
  MdHome,
  MdOutlineDescription,
  MdLanguage,
} from "react-icons/md";

import Notifications from "views/NotificationView/notification";
// core components/views for Admin layout
// import DashboardPage from "views/Dashboard/Dashboard.js";
import ALDNET from "views/ALDNET/ALDNET";
import IFRS16 from "views/IFRS16/IFRS16";
import Contracts from "views/Contracts/Contracts";
import ALDSAT from "views/ALDSAT/ALDSAT";
import BiReport from "views/BiReport/BiReport";
import Maintenance from "views/Maintenance/Maintenance";
import Documents from "views/Documents/Documents";
import Home from "views/Home/Home";

const dashboardRoutes = [
  {
    path: "/home",
    componentNumber: 9,
    icon: MdHome,
    component: Home,
    layout: "/admin",
    id: 1,
  },
  {
    path: "/report",
    componentNumber: 0,
    icon: MdAssignment,
    component: ALDNET,
    layout: "/admin",
    id: 30,
  },
  {
    path: "/bi-report",
    componentNumber: 7,
    icon: MdAssignment,
    component: BiReport,
    layout: "/admin",
    id: 35,
  },
  {
    path: "/ifrs16",
    componentNumber: 1,
    icon: MdAssignment,
    component: IFRS16,
    layout: "/admin",
    id: 34,
  },
  {
    path: "/maintenance",
    componentNumber: 2,
    icon: MdBusinessCenter,
    component: Maintenance,
    layout: "/admin",
    id: 31,
  },
  {
    path: "/FleetAdministrator",
    componentNumber: 3,
    icon: MdLocalShipping,
    component: Contracts,
    layout: "/admin",
    id: 33,
  },
  {
    path: "/aldsat",
    componentNumber: 4,
    icon: MdLanguage,
    component: ALDSAT,
    layout: "/admin",
    id: 29,
  },
  {
    path: "/documents",
    componentNumber: 8,
    icon: MdOutlineDescription,
    component: Documents,
    layout: "/admin",
    id: 32,
  },
  {
    path: "/notifications",
    componentNumber: 10,
    icon: MdOutlineDescription,
    component: Notifications,
    layout: "/admin",
    id: 36,
  },
];

export default dashboardRoutes;
