/**
 * @fileoverview Card Notification
 * @version 1.0.0
 * @author Carlos Emilio Blanco Lopez
 * @date 09/04/2023
 * @copyright 2023 Industrias RESSER S.A de C.V
 */

import React, { useEffect, useState } from 'react'
import {
    AvisosNotifications,
    ProcedimientosNotifications,
    EventosNotifications,
    PoliticasNotifications,
    ProductosNotifications,
} from 'assets/img/Icons/IconsNotifications';
import cardStyle from 'assets/jss/material-dashboard-react/components/cardNotifications.styles';
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import otroNotification from 'assets/img/Icons/otrosNotifications.png'
import { useHistory } from 'react-router-dom';
import axios from 'libs/AxiosHelper';
//redux actions
import {
    addInfoNotification,
    showNotificationMask,
    addNotification,
    hideNotificationMask
} from 'redux/slice';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const useStyles = makeStyles(cardStyle);
const CardNotification = ({ data, onViewNotification }) => {
    //Redux
    const dispatch = useDispatch();

    const history = useHistory();

    const {
        idCategory,
        readStatus,
        url,
        title,
        description,
        releaseDate,
        id
    } = data;

    // styles
    const classes = useStyles();
    const [color, setcolor] = useState("red");
    useEffect(() => {
        switch (idCategory) {
            case 1:
                setcolor("#42A6FF");
                break;
            case 2:
                setcolor("#4391FF");
                break;
            case 3:
                setcolor("#050B7F");
                break;
            case 4:
                setcolor("#80B4FF");
                break;
            case 5:
                setcolor("#0BCBFB");
                break;
            case 6:
                setcolor("#644AF3");
                break;
            default:
                break;
        }
    }, []);

    /**
     * @title PutNotification
     * @description Funcion que actualiza el estado de la notificacion
     * @param {Number} id
     * @returns void
     */

    const PutNotification = () => {
        dispatch(showNotificationMask());
        axios.put('NotificationsFMV2', { notification: id })
            .then(res => {
                if (res.data.success) {
                    dispatch(addInfoNotification({
                        title,
                        description,
                        url,
                        id
                    }));
                    dispatch(hideNotificationMask());
                    onViewNotification();
                    getNotification();
                }
            })
            .catch(err => {
            });
    }

    /**
    * @title getNotification (Async / Await)
    * @description Función Asincrona para el GET de Notificaciones, peticion para la obtencion de Notificaciones relacionados con los usuario.
   */

    const getNotification = async () => {
        axios.get("NotificationsCatalog/All?start=0&limit=100")
            .then(response => {
                if (response.data.success) {
                    dispatch(addNotification(response.data.data));
                    history.push("/admin/Document-Notification");
                }
            })
    }

    return (

        <div className={classes.Body} onClick={() => PutNotification()}>

            <div>
                <div style={
                    {
                        backgroundColor: !readStatus ? "red" : "inherit",
                        width: "0.7vw",
                        height: "0.7vw",
                        borderRadius: 25,
                        marginTop: 10
                    }
                } />
            </div>
            <div className={classes.containerIcon}>
                {idCategory === 1 && <PoliticasNotifications style={{ marginTop: 10 }} />} {/* 1 : Politicas */}
                {idCategory === 2 && <ProcedimientosNotifications style={{ marginTop: 10 }} />} {/* 2: Precedimientos */}
                {idCategory === 3 && <EventosNotifications style={{ marginTop: 10 }} />} {/* 3: Eventos */}
                {idCategory === 4 && <AvisosNotifications style={{ marginTop: 10 }} />} {/* 4: Avisos Generales */}
                {idCategory === 5 && <ProductosNotifications style={{ marginTop: 10 }} />} {/* 5: Productos */}
                {idCategory === 6 &&
                    <img
                        src={otroNotification}
                        alt='OtroNotificacion'
                        style={{ width: "23px", height: "24px", marginTop: 10 }} />} {/* 6: Otros */}
            </div>
            <div style={{ width: "80%" }}>
                <div>
                    <h3 className={classes.headerCard} style={{ color: color }}>{title}</h3>
                    <Tooltip
                        title={description}
                        placement="bottom-start"
                        classes={{ tooltip: classes.tooltipDnD }}>
                        <p className={classes.subtitleCard}>{description}</p>
                    </Tooltip>
                </div>
                <div style={
                    {
                        textAlign: "end",
                        color: "#BDBDBD",
                        fontFamily: 'Source Sans Pro'
                    }
                }>{moment(releaseDate).format("DD/MM/YYYY")}</div>
            </div>
        </div>
    )
}

export default CardNotification