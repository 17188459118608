/**
 * @fileoverview ALDNET
 * @version 1.0
 * @author César Paul Hernández Camacho 
 * @date 1/12/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 * @update 4/12/2020
 */
import React, {useState, useEffect} from 'react';
//redux actions
import { useSelector } from 'react-redux'
//Translations
import { useTranslation } from "react-i18next";
//@material-ui components
import { makeStyles } from '@material-ui/core/styles';
// core components
import Item from "components/Grid/GridItem.js";
import Container from "components/Grid/GridContainer.js";
import Button from 'components/CustomButtons/Button';
import Card from 'components/card/Card';
//styles
import styles from '../IFRS16/IFRS16.styles';

import { getAnalytics, logEvent } from "@firebase/analytics";

const useStyles = makeStyles(styles);

const ALDNET = (props) => {
    const analytics = getAnalytics();
    //redux
    const loginData = useSelector(state => state.auth.loginData)
    const classes = useStyles();
    //state
    const [url, setUrl] = useState("")

    //translationos
    const [t, i18n] = useTranslation('common');

    useEffect(() => {
        renderIframe();
    }, [])

    /**
     * Function to load the iframe on ALDFMV2
     * @memberof ALDNET
     */
    function renderIframe() {
        var aux = "";
        switch (loginData.regionId) {
            case 0:
                aux = "https://www.aldnet.com.br";
                break;
            case 1:
                aux = "https://www.aldnet.mx";
                break;
            case 2:
                aux = "https://www.pe.aldnet.com";
                break;
            case 3:
                aux = "https://www.aldnet.cl";
                break;
            case 4:
                aux = "https://www.co.aldnet.com";
                break;
            default:
                aux = "https://www.aldnet.mx";
                break;
        }
        setUrl(aux);
        // return (
        //     <iframe src={url} height="100%" width="100%"/>
        // )
    }

    /**
     * Function to open ALDNET in a new browser tab
     * @memberof ALDNET
     */
    const openInNewTab = () => {
        logEvent(analytics, "Fleet_Access_ALDNET");
        var win = window.open(url, '_blank');
        win.focus();
    }

    return (
        <>
        <Card>
            <Container>
                
                    <Item xs={12} md={12}>
                        <p style={{color: "#050B7F", fontWeight: 'bold', fontSize: '1.7rem', paddingLeft: '1.5rem'}}> {t('aldnet.title')} </p>
                    </Item>
                    <Item xs={12} md={12} style={{ minHeight: '10vh' }}>
                        <Container xs={12} md={12} direction="column" alignItems="center" justify="center"  style={{ padding: 10}}>
                            <Item Container  xs={12} alignItems="center" justify="center" >
                                <p style={{color: "#050B7F", fontWeight:'bold', fontSize: '1.5rem' }}> {t('aldnet.message')} </p>
                            </Item>
                            <Item Container  xs={12} alignItems="center" justify="center" >
                                <p style={{color: "#828282", fontWeight:'300', fontSize: '1.2rem'}}> {t('aldnet.instrucctions')} </p>
                            </Item>
                            <Item Container  xs={12} alignItems="center" justify="center" >
                                <Button
                                    onClick={openInNewTab}
                                    className={classes.ifrs16Button}
                                    aria-label="takeMeTo">
                                    {t('aldnet.button')}
                                </Button>
                            </Item>
                        </Container>
                        {/* {renderIframe()} */}
                    </Item>
                
            </Container>
            </Card>
        </>
    );
};

export default ALDNET;