import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
//Styles
import IconNavbar from "../../assets/img/Icons/Navbar.svg";
import "./designNavbar.css";

export default function Header(props) {
  const onDrawerClick = (props) => props.handleDrawerToggle();

  return (
    <>
      <AppBar className="navbar_container" color="#050b7f" elevation={0}>
        <Toolbar className="navbar_toolbar">
          <div className="navbar_logo_content">
            <img src={IconNavbar} className="navbar_menuMobil_SideBar" onClick={() => {onDrawerClick(props)}}/>
            <p className="navbar_logo_txt"><h2>My ALD</h2><h4>Manager</h4></p>
          </div>
          <AdminNavbarLinks />
        </Toolbar>
      </AppBar>
    </>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};
