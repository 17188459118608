/**
 * @fileoverview MaintenanceTable componente para el manejo de la tabla y sus filtros
 * @version 1.0.0
 * @author Mártin Duran Anguiano
 * @date 29/01/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 *
 */

import React, { useState, useEffect } from 'react';
//@material-ui components
import IconButton from '@material-ui/core/IconButton';
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

//@material-ui icons
import { MdFilterList } from 'react-icons/md';
// core components
import Item from "components/Grid/GridItem.js";
import Container from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from 'components/CustomModal/CustomModal';
import { enqueueErrorSnackbar, enqueueInfoSnackbar } from 'libs/Snackbar';
import MaintenanceTimelineBR from './Timeline/MaintenanceTimelineBR';
import MaintenanceGroupTable from './MaintenanceGroupTable';

//translate
import { useTranslation } from 'react-i18next';
//axios
import axios from 'libs/AxiosHelper';
//React Table
import CustomTable from 'components/Table/CustomTable';
//redux actions
import { showCircularProgress, closeCircularProgress, onShowAlertMessage } from 'redux/slice';
import { useDispatch, useSelector } from 'react-redux';
//Styles
import styles from './Maintenance.styles';
import { MaintenanceFilter } from 'components/Filter/Filter';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import ButtonPresupuestoIcon from '../../assets/img/Icons/ButtonPresupuesto.svg';

const useStyles = makeStyles(styles);

//Objeto para realizar filtrado en la tabla.
const filtersObj = {
    contractNumber: '',
    plateNumber: "",
    contractNumberObj: '',//TODO: Revisar para que se utilizan o porque estan.... 🤦🏻‍♂️
    status: '',
    ticketType: '',
    requestDateStart: '',
    requestDateEnd: '',
    endDate: '',
    responsible: '',
    customerId: '',
    customerIdObj: '', //TODO: Revisar para que se utilizan o porque estan.... 🤦🏻‍♂️
    start: 0,
    limit: 100
}

//Objeto para manejo de errores en los filtros
const errorFiltersObj = {
    requestDateStart: false,
    requestDateEnd: false,
}

const MaintenanceTable = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [t, i18n] = useTranslation('common');
    const history = useHistory();

    const [filters, setfilters] = useState(filtersObj);
    const [totalTickets, setTotalTickets] = useState(0);
    const [tickets, setTickets] = useState([]);
    const [currentTicket, setCurrentTicket] = useState({});
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [filtersActive, setFiltersActive] = useState(false);
    const [filtersError, setFiltersError] = useState(errorFiltersObj);

    const [showTimeline, setShowTimeline] = useState(false);
    const [timelineData, setTimelineData] = useState({});

    const [setGetTicketsFirstTimeFlag, setSetGetTicketsFirstTimeFlag] = useState(true);
    const [getTicketsWithFiltersFlag, setGetTicketsWithFiltersFlag] = useState(false);
    const [getTicketsOnChangePageFlag, setgetTicketsOnChangePageFlag] = useState(false);

    const regionId = useSelector(state => state.auth.loginData.regionId);
    const language = useSelector((state) => state.configurations.language);
    const userRole = useSelector(state => state.auth.loginData.userRole);
    /**
     * Función que se manda llamar una vez que el componente se renderea y cuando el estado cambia
     *
     */
    useEffect(() => {
        //Carga de tickets al iniciar el módulo
        if (setGetTicketsFirstTimeFlag === true) {
            getTickets();
            return;
        }

        // Carga de tikets al realizar un filtrado
        else if (getTicketsWithFiltersFlag === true) {
            getTickets();
            return;
        }

        //Carga de tickets al realizar la alta de un ticket
        else if (props.reloadTicket === true) {
            getTickets();
            return;
        }

        // Carga de tickets al cambiar de pagina
        else if (getTicketsOnChangePageFlag === true) {
            getTickets();
            return;
        }
        else if (language) {
            getTickets();
        }
    }, [rowsPerPage, props.reloadTicket, getTicketsWithFiltersFlag, getTicketsOnChangePageFlag, language])

    /**
     * Función encargada de generar los headers de la tabla mantenimientos
     * 
     */
    const renderColumsTitles = () => {
        let columns = [
            { accessor: "contractNumber", header: t('maintenance.table.0'), isDate: false },
            { accessor: "ticketType", header: t('maintenance.table.2'), isDate: false, defaultValue: "sin tipo" },
            { accessor: "requestDate", header: t('maintenance.table.4'), isDate: true, defaultValue: "" },
            { accessor: "cityName", header: t('maintenance.table.6'), isDate: false, defaultValue: "" },
            { accessor: "responsibleName", header: t('maintenance.table.7'), isDate: false, defaultValue: "" },
            { accessor: "responsiblePhone", header: t('maintenance.table.8'), isDate: false, defaultValue: "N/A" },
            { accessor: "customerName", header: t('maintenance.table.9'), isDate: false, defaultValue: "" }
        ];

        //Switch encargado de colocar traducciones para campos especificos por país
        switch (regionId) {
            // México y Brasil
            case 0:
            case 1:
                columns.splice(2, 0, { accessor: "plateNumber", header: t('maintenance.table.1'), isDate: false, defaultValue: "" });
                columns.splice(4, 0, { accessor: "status", header: t('maintenance.table.3'), isDate: false, defaultValue: "" });
                columns.splice(5, 0, { accessor: "stateName", header: t('maintenance.table.5'), isDate: false, defaultValue: "" });
                break;
            // Perú y Colombia
            case 2:
            case 4:
                columns.splice(2, 0, { accessor: "plateNumber", header: t('maintenance.table.1'), isDate: false, defaultValue: "" });
                columns.splice(5, 0, { accessor: "stateName", header: t('maintenance.table.10'), isDate: false, defaultValue: "" });
                break;
            // Chile
            case 3:
                columns.splice(2, 0, { accessor: "plateNumber", header: t('maintenance.table.12'), isDate: false, defaultValue: "" });
                columns.splice(5, 0, { accessor: "stateName", header: t('maintenance.table.11'), isDate: false, defaultValue: "" });
                break;
            default:
                columns.splice(5, 0, { accessor: "stateName", header: t('maintenance.table.5'), isDate: false, defaultValue: "" });
                break;
        }

        return columns
    }

    /**
     * Función encargada de renderear el nombre para el botón de MAG
     * 
     */
    const renderButtonMAG = () => {

        if (language !== 'es') return t('maintenance.groupTable.groupService');

        switch (regionId) {
            case 0:
                return t('maintenance.groupTable.groupService');
            case 1:
                return t('maintenance.groupTable.groupServiceMexico');
            case 2:
            case 3:
            case 4:
                return t('maintenance.groupTable.groupServicePeru');
            default:
                break;
        }
    }

    /**
     * Función que obtiene todos los tickets de la flota de un FM
     *
     */
    const getTickets = () => {
        dispatch(showCircularProgress());

        const url = `TicketsFleetManager/All?contractNumber=${filters.contractNumber}&plateNumber=${filters.plateNumber}&status=${filters.status}&ticketType=${filters.ticketType}&requestDateStart=${filters.requestDateStart}&requestDateEnd=${filters.requestDateEnd}&endDate=${filters.endDate}&responsible=${filters.responsible}&customerId=${filters.customerId}&start=${filters.start}&limit=${filters.limit}`
        axios.get(url)
            .then(async response => {

                if (response.data.success) {
                    let data = await translateCheckpoint(response.data.result);
                    await setTotalTickets(response.data.total);
                    await setTickets(data);
                }
                else if (response.data.success === false && filtersActive === true) {
                    enqueueInfoSnackbar(dispatch, t("maintenance.infoMessages.2"))
                }
                else if (response.data.success === false && filtersActive === false) {
                    enqueueInfoSnackbar(dispatch, t("maintenance.infoMessages.3"))
                }
                dispatch(closeCircularProgress());
            })
            .catch(error => {
                dispatch(closeCircularProgress());
                if (error.response.status === 401) {
                    dispatch(onShowAlertMessage());
                }
            });
        props.onReloadTickets();

        if (getTicketsWithFiltersFlag === true || getTicketsOnChangePageFlag === true || setGetTicketsFirstTimeFlag === true) {
            setGetTicketsWithFiltersFlag(false);
            setgetTicketsOnChangePageFlag(false);
            setSetGetTicketsFirstTimeFlag(false);
        }
    }

    /**
     * Función encargada de traducir los puntos de control de un servicio
     * @param {*} data
     */
    const translateCheckpoint = (data) => {
        data.forEach(ticket => {
            // Ticket checkpoint
            if (ticket.cancelled) {
                ticket.status = t('AldSacTimeLine.generalMessages.9');
            }
            else {
                switch (ticket.checkPointId) {
                    case 1:
                        ticket.status = t('checkPointsTimeline.titles.0');
                        break;
                    case 2:
                        ticket.status = t('checkPointsTimeline.titles.1');
                        break;
                    case 3:
                        ticket.status = t('checkPointsTimeline.generalMessages.9');
                        break;
                    case 4:
                        ticket.status = t('checkPointsTimeline.titles.7');
                        break;
                    case 6:
                        ticket.status = t('checkPointsTimeline.titles.8');
                        break;
                    case 7:
                        ticket.status = t('checkPointsTimeline.titles.9');
                        break;
                    case 8:
                        ticket.status = t('checkPointsTimeline.titles.5');
                        break;
                    case 9:
                        ticket.status = t('checkPointsTimeline.titles.10');
                        break;
                    case 13:
                        ticket.status = t('checkPointsTimeline.titles.11');
                        break
                    default:
                        ticket.status = "S/N";
                        break;
                }
            }

            // Para incluir los modismos de Ticket Type
            switch (ticket.ticketType) {
                case "Mantenimiento Preventivo":
                    // Brasil
                    if (regionId === 0) {
                        ticket.ticketType = t(`checkPointsTimeline.ticketType.0`)
                    }

                    // México y Colombia
                    else if (regionId === 1 || regionId === 4) {
                        ticket.ticketType = t(`checkPointsTimeline.ticketType.1`)
                    }

                    // Chile y Perú
                    else if (regionId === 2 || regionId === 3) {
                        ticket.ticketType = t(`checkPointsTimeline.ticketType.2`)
                    }
                    break;

                case "Mantenimiento Correctivo":
                    // Brasil
                    if (regionId === 0) {
                        ticket.ticketType = t(`checkPointsTimeline.ticketType.0`)
                    }

                    // México y Colombia
                    else if (regionId === 1 || regionId === 4) {
                        ticket.ticketType = t(`checkPointsTimeline.ticketType.3`)
                    }

                    // Chile y Perú
                    else if (regionId === 2 || regionId === 3) {
                        ticket.ticketType = t(`checkPointsTimeline.ticketType.4`)
                    }
                    break;

                case "Neumáticos":
                    //Perú y Chile
                    if (regionId === 2 || regionId === 3) {
                        ticket.ticketType = t(`checkPointsTimeline.ticketType.5`)
                    }

                    //Colombia
                    else if (regionId === 4) {
                        ticket.ticketType = t('checkPointsTimeline.ticketType.6')
                    }
                    break;

                //Servicio exclusivo de Colombia
                case "Revisión Tecnicomecánica":
                    ticket.ticketType = t(`checkPointsTimeline.ticketType.7`)
                    break;

                //Servicio exclusivo de Chile
                case "Reparaciones":
                    ticket.ticketType = t(`checkPointsTimeline.ticketType.8`)
                    break;

                //Servicio exclusivo de Perú
                case "Batería":
                    ticket.ticketType = t(`checkPointsTimeline.ticketType.9`)
                    break;

            }

        });

        return data;
    }

    /**
     * Function to handle limit change
     * @param {int} limit limit of contracts to get
     * @memberof ContractTable
     */
    const onHandleLimitChange = async (limit) => {
        await setPage(0);
        await setRowsPerPage(limit);
        await setfilters({
            ...filters,
            start: 1,
            limit: limit
        });
    }

    /**
     * Function to handle when page is changed
     * @param {object} params object to handle when page is changed
     * @memberof ContractTable
     */
    const changePage = async (newPage, start) => {
        window.scrollTo(0, 0);
        let filterSstart = filters.start;
        let filterSLimit = filters.limit;

        if (newPage >= page) {
            filterSstart = filterSstart + rowsPerPage;
            filterSLimit = rowsPerPage;
        }
        else if (newPage < page) {
            filterSstart = filterSstart - rowsPerPage;
            filterSLimit = rowsPerPage;
        }

        setfilters({
            ...filters,
            start: filterSstart,
            limit: filterSLimit
        });

        await setPage(newPage);
        setgetTicketsOnChangePageFlag(true);
    }

    /**
     * Function to handle close modal
     * @memberof ContractTable
     */
    const onCloseModal = () => {
        props.onHideModal(false);
    }

    /**
     * Function to handle close group maintenance modal
     * @memberof ContractTable
     */
    const onCloseGroupModal = () => {
        getTickets();
        props.onHideGroupModal();
    }

    /**
     * Función que detecta el cambio de customer y  contrato
     *
     */
    const onChangeAutocomplete = (input, newValue) => {

        if (newValue === null) return false;

        if (input === 'client') {
            setfilters({
                ...filters,
                contractNumberObj: newValue,
                customerIdObj: newValue,
                customerId: newValue.id
            });
        }
        else {
            setfilters({
                ...filters,
                contractNumberObj: newValue,
                customerIdObj: newValue,
                contractNumber: newValue.contractNumber
            });
        }
    }

    /**
     * Función que detecta el cambio de fechas
     * @param {*} e
     */
    const onChangeFormData = (e) => {
        const inputName = e.target.name;
        let inputValue = e.target.value;
        setfilters({
            ...filters,
            [inputName]: inputValue
        });

        setFiltersError({
            ...filtersError,
            [inputName]: false
        });
    }

    /**
     * Función que ejecuta la búsqueda de tickets por filtros
     */
    const getTicketsWithFilters = async () => {
        setFiltersActive(true);

        if (filters.requestDateStart !== "" && filters.requestDateEnd === "") {
            enqueueErrorSnackbar(dispatch, t("maintenance.infoMessages.1"))
            setFiltersError({
                ...filtersError,
                requestDateEnd: true
            });
            return false;
        }
        else if (filters.requestDateEnd !== "" && filters.requestDateStart === "") {
            enqueueErrorSnackbar(dispatch, t("maintenance.infoMessages.0"))
            setFiltersError({
                ...filtersError,
                requestDateStart: true
            });
            return false;
        }

        await setfilters({
            ...filters,
            start: 0,
            limit: 100
        });
        await setPage(0);
        await setRowsPerPage(100);



        await setGetTicketsWithFiltersFlag(true);
        props.onHideModal(false);
    }

    /**
     * Función encargada de limpiar los filtros
     */
    const onCleanFilters = () => {
        props.onHideModal(false);
        setPage(0);
        setfilters(filtersObj);
        setFiltersActive(false);
        setGetTicketsWithFiltersFlag(true);
        // getTickets();
    }

    /**
     * Function to handle when a ticket is clicked
     * @param {type} description
     * @return type description
     * @memberof component
     */
    const onTicketClickHandler = async (ticket) => {
        setCurrentTicket(ticket);
        if (regionId === 0) {
            await axios.get(`ALDSACUpdateCheckPoints?Service=${ticket.ticketId}`)
                .then(async response => {
                    if (await response.data.Success) {
                        //** Objeto de timeline producción **//
                        await setTimelineData(await response.data.Items);

                        //** Objeto de timeline pruebas **//
                        //await setTimelineData(await exampleJson);

                        await setShowTimeline(true);
                    }
                    else {
                        enqueueInfoSnackbar(dispatch, t("maintenance.infoMessages.4"))
                    }
                })
        }
    }

    /**
     * Function to reschedule a ticket
     * @memberof MaintenanceTable
     */
    const onShowReschedulingForm = () => {
        props.onShowReschedulingForm(currentTicket);
        setShowTimeline(false);
    }

    return (
        <React.Fragment>
            <Container className={classes.maintenanceTable}>

                <Item xs={12} md={12} className={classes.filterIcon}>
                    {
                        regionId !== 0 && (
                            <Button
                                className={classes.newProjectManagement}
                                aria-label="edit"
                                onClick={() => { history.push('/admin/SAT') }}
                            >
                                <img
                                    src={ButtonPresupuestoIcon}
                                    style={{ marginRight: 15 }} />
                                {t('ModuleAgreements.BudgetApproval')}
                            </Button>
                        )
                    }
                    {regionId === 0 || regionId === 1 ?
                        <Button
                            onClick={props.onRequestNewService}
                            className={classes.newServiceButtonStyle}
                            aria-label="edit"
                        >
                            {(regionId === 4) ? t('maintenance.buttons.2') : t('maintenance.buttons.0')}
                        </Button>
                        : null}
                    <Hidden smDown >
                        <Button
                            onClick={props.onShowGroupModal}
                            className={classes.newServiceButtonStyle}
                            aria-label="edit"
                        >
                            {renderButtonMAG()}
                        </Button>
                    </Hidden>
                    <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={props.onShowModal}
                    >
                        <MdFilterList />
                    </IconButton>
                </Item>
                <Item xs={12} md={12}>
                    <CustomTable
                        page={page}
                        cols={renderColumsTitles()}
                        rows={tickets}
                        totalRows={totalTickets}
                        handleLimitChange={(limit) => { onHandleLimitChange(limit) }}
                        onRowClick={ticket => { }}
                        showAction={regionId === 0 ? true : false} //TODO: modificar if
                        onShow={(ticket) => { onTicketClickHandler(ticket) }}
                        handlePageChange={(page, start) => { changePage(page, start) }} />
                </Item>
            </Container>
            {/* Filtros */}
            <CustomModal
                open={props.showModal}
                onClose={onCloseModal}
            >
                <Paper elevation={0} className={classes.containerModalFilter}>
                    <MaintenanceFilter
                        contracts={props.contracts}
                        clients={props.clients}
                        onChangeAutocomplete={onChangeAutocomplete}
                        filters={filters}
                        filtersError={filtersError}
                        onChangeFormData={onChangeFormData}
                        onCleanFilters={onCleanFilters}
                        onFilter={getTicketsWithFilters}
                    />
                </Paper>
            </CustomModal>
            {/* Mantenimientos Agrupados */}
            <MaintenanceGroupTable
                contracts={props.contracts}
                showGroupModal={props.showGroupModal}
                onCloseGroupModal={onCloseGroupModal}
            />
            {/* Timeline */}
            <MaintenanceTimelineBR
                ticket={timelineData}
                open={showTimeline}
                currentTicket={currentTicket}
                onShowReschedulingForm={onShowReschedulingForm}
                onTicketClickHandler={onTicketClickHandler}
                onClose={() => {
                    setShowTimeline(false);
                }}
            />
        </React.Fragment>
    );
}

export default MaintenanceTable;

