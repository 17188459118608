/**
 * @fileoverview Modal para agregar comentarios a la tabla de SAT
 * @version 1.0
 * @author Carlos Emilio Blanco Lopez
 * @date 23/01/2024
 * @copyright 2024 RESSER S.A de C.V
 */

import React, { useEffect, useState } from 'react';
import { Context } from '../Table';
import Modal, { ContentInfo } from './index';
import { TextField, Button } from '@mui/material';
import Rssr from 'libs/Rssr';

import { useDispatch } from 'react-redux';
import { enqueueErrorSnackbar } from 'libs/Snackbar';
import { useTranslation } from 'react-i18next';

import PerfectScrollbar from 'react-perfect-scrollbar';

const ModalComentarios = ({
    OnEdit,
    OnComments,
    OnHistory,
    OnAttachment,
    DataAgreements,
    ...props
}) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();
    const [Text, setText] = useState('');
    const [Comments, setComments] = useState([]);
    const { state, dispatch: dispatchView } = React.useContext(Context);

    useEffect(() => {
        if (state.Comments) setComments(state.Comments);
    }, [state.Comments]);

    /**
     * @Function onChangeComment
     * @description Función para guardar el valor del comentario
     */

    const handleInputChange = (event) => {
        const newText = event.target.value;

        /// Filtrar solo letras
        const sanitizedText = newText.replace(/[^a-zA-Z0-9.,; ]+/g, '');

        // Aplicar el límite de 200 caracteres
        if (sanitizedText.length <= 200) {
            setText(sanitizedText);
        }
    };

    /**
     * @Function formatTime
     * @description Función para dar formato a la fecha
     */

    const formatTime = (date) => {
        var aux = '';
        aux = date.split('T')[1];
        return aux.slice(0, 8);
    };

    /**
     * @Function OnSendComment
     * @description Función para enviar el comentario
     */

    const OnSendComment = () => {
        if (Text === '') {
            enqueueErrorSnackbar(dispatch, 'El comentario no puede estar vacío');
            return;
        }
        props.PostComment({
            AgreementId: state.AgreementId,
            CommentText: Text
        }, dispatchView);
    };

    return (
        <Modal open={state.ModalComments} OnComments={() => OnComments(DataAgreements)} OnEdit={() => OnEdit(DataAgreements)} OnHistory={() => OnHistory(DataAgreements)}  OnAttachment={()=> OnAttachment(DataAgreements)}>
            <section style={{
                alignContent: 'center',
                width: '90%',
            }}>
                <div style={{
                    backgroundColor: '#F2F2F2',
                    height: '50px',
                    marginTop: '25px',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <p style={{
                        color: 'var(--gris, #828282)',
                        fontFamily: 'Montserrat, sans-serif, ',
                        fontSize: '14px',
                        fontWeight: 600,
                        marginLeft: '10px',
                    }}>{t('ModuleAgreements.comments.comments')}</p>
                </div>
                <PerfectScrollbar style={{ position: "relative", height: "80%" }}>
                    <div style={{
                        width: '90%',
                        margin: 'auto',
                        minHeight: '300px',
                    }}>
                        {
                            Comments.length === 0 &&
                            <ContentInfo
                                title={t('ModuleAgreements.comments.noComments')}
                                desc={t('ModuleAgreements.comments.hasNoComments')}
                                style={{ borderBottom: 'none' }}
                            />
                        }
                        {
                            Comments.map((el, index) => {
                                return (
                                    <ContentInfo
                                        key={index}
                                        style={{ minHeight: '120px', borderBottom: index === Comments.length - 1 ? 'none' : '1px solid #828282'}}
                                        title={`${el.UserName} ${Rssr.formatDate(el.CreatedDate)} ${formatTime(el.CreatedDate)}`}
                                        desc={el.CommentText}
                                    />
                                )
                            })
                        }
                        <TextField
                            placeholder={`${t('ModuleAgreements.comments.writeComments')}...`}
                            fullWidth
                            multiline
                            value={Text}
                            onChange={handleInputChange}
                            minRows={3}
                            maxRows={3}
                            style={{ marginTop: Comments.length === 0 ? 20 : 0 }} />
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '20px',
                        }}>
                            <Button
                                variant="contained"
                                sx={ButtonStyle}
                                onClick={OnSendComment}
                            >
                                {t('ModuleAgreements.comments.insertComments')}
                            </Button>
                        </div>
                    </div>
                </PerfectScrollbar>

            </section>
        </Modal>
    )
}

export default ModalComentarios;

const ButtonStyle = {
    color: 'var(--btn-primary-color, #FFF)',
    textShadow: '0px -1px 0px rgba(0, 0, 0, 0.12)',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '157.15%',
    backgroundColor: '#4391FF',
    borderRadius: '20px',
    boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.04)',
    textTransform: 'none',
};