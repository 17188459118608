/**
* @fileoverview DnD_Notification Component
* @version 1.0.0
* @author Carlos Emilio Blanco Lopez
* @date 13/12/2022
* @copyright 2022 Industrias RESSER S.A de C.V
*/

import React, { useState, useEffect, memo, useContext } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {MdSearch, MdArrowBackIos,MdArrowForwardIos,MdMoreVert  } from "react-icons/md";
import PerfectScrollbar from 'react-perfect-scrollbar';
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/load.json';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, OutlinedInput } from "@material-ui/core";
import { DataModulo } from '../../views/NotificationView/NotificationsTable';

//translate
import { useTranslation } from 'react-i18next';
import { reorder, move } from './FunctionsDragAndDrop';
import styles from "assets/jss/material-dashboard-react/tooltipStyle";

const useStyles = makeStyles(styles);

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  fontSize: "15px",
  height: "40px",
  background: isDragging ? "#E5E5E5" : "#FFF",
  display: "flex",
  alignItems: "center",
  ...draggableStyle
});

const getListStyle = (isDraggingOver, color) => ({
  paddingBottom: 7,
  width: "45%",
  border: `1px solid ${color}`,
});

const DnD_Notification = ({
  cantItems,
  start,
  setStart,
  StartSend,
  setStartSend,
  SendCantItems,
  setSendCantItems,
  setItemsID,
  ItemsID,
  isFM,
  isDrivers,
  GetUsers,
  selectAll,
  setselectAll,
  customerID,
  setFilterName,
  FilterName,
  FilterNameD,
  setFilterNameD,
  ErrorUsers,
  setErrorUsers,
  ...props
}) => {

  const { ReducerState, customDispatch } = useContext(DataModulo);

  const [FirstUpdate, setFirstUpdate] = useState(true);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  //translations
  const [t, i18n] = useTranslation('common');

  const classes = useStyles();

  const [state, setState] = useState([[], []]);
  const [SupportState, setSupportState] = useState([]);
  const [StartD, setStartD] = useState(0);
  const [LimitD, setLimitD] = useState(0);
  const [StartL, setStartL] = useState(0);
  const [LimitL, setLimitL] = useState(0);

  /**
* useEffect Para vaciar el listado cuando no este seleccionado FM ni Drivers en los checkbox
* @param {bool} isFM - valor del checkbox isFM cuando este sea true o false.
* @param {bool} isDrivers - valor del checkbox isDrivers cuando este sea true o false.
*/
  useEffect(() => {
    if (!isFM && !isDrivers) {
      setItemsID([]);
      setState([[], []]);
    }
  }, [isFM, isDrivers]);

  useEffect(() => {
    if(ReducerState.isUpdate && FirstUpdate){
      setFirstUpdate(false);
      return;
    }
    if (!selectAll && FilterNameD === "") {
      const elementsID = [];
      SupportState.forEach(element => elementsID.push(element.id));
      setItemsID(elementsID);
      setState([state[0], SupportState]);
      setSupportState([]);
    }
  }, [FilterNameD])

  /**
   * useEffect Para validar cuando se seleccionen todos los usuarios
   * @param {selectAll} selectAll - valor del checkbox selectAll cuando este sea true o false.
  */

  useEffect(() => {
    setFilterName("");
    setFilterNameD("");
    if (ReducerState.isUpdate && FirstUpdate) {
      return;
    }
    else if (selectAll === false) {
      setStart(1);
      setSendCantItems(0);
      setItemsID([]);
      setState([[...state[0], ...state[1]], []]);
      GetUsers(isFM, isDrivers, 0, customerID);
    }
    else {
      setSendCantItems(ReducerState.CantItems);
      setStartSend(1);
      setState([[], [...state[0], ...state[1]]]);
      GetUsers(isFM, isDrivers, 0, customerID);
    }
  }, [selectAll]);


  useEffect(() => {
    setErrorUsers(false);
    if (ReducerState.isUpdate && FirstUpdate) {
      setSendCantItems(ReducerState.dataUpdate.users.length);
    }
    else {
      setSendCantItems(ItemsID.length);
    }
  }, [ItemsID, ReducerState.isUpdate]);

  /**
 * useEffect que validara los datos de paginacion del Drag&Drop
 * @param {int} cantItems - cantidad de Items del Customer y/o Filtro
 * @param {int} SendCantItems - cantidad de Items seleccionados para la notiticación
 * @param {int} start - Valor inicial del segmento de paginacion para Items del customer y/o filtro
 * @param {int} StartSend - Valor inicial del segmento de paginacion para Items de que senviaran
 */

  useEffect(() => {
    const cantItems = ReducerState.CantItems;
    // StartD
    if (SendCantItems === 0) { setStartD(0); }
    else { setStartD(StartSend); }
    //StartL
    if (cantItems === 0) { setStartL(0); }
    else { setStartL(start); }
    //LimitD
    if (selectAll) {
      if ((start + 99) > cantItems) { setLimitD(cantItems); }
      else if (ItemsID.length > SendCantItems) { setLimitD(ItemsID.length) }
      else { setLimitD(StartSend + 99); }
    }
    else {
      if (ItemsID.length > 0) { setLimitD(ItemsID.length) }
      else if ((start + 99) > SendCantItems) { setLimitD(0); }
      else { setLimitD(StartSend + 99); }
    }
    //LimitL
    if ((start + 99) > cantItems) { setLimitL(cantItems); }
    else { setLimitL(start + 99); }
  }, [ReducerState.CantItems, SendCantItems, start, StartSend, ItemsID]);

  /**
 * useEffect que facilita la separacion de items cuando el WS te regresa items ya guardados
 * @param {array} rows - Array de items del Drag%Drop
 */

  useEffect(() => {
    const rows = ReducerState.itemsDnD;
    if (!selectAll) {
      let rowResult = [], rowResultD = [], ItemRelation = false;
      for (let j = 0; j < rows.length; j++) {
        ItemRelation = false;
        for (let i = 0; i < ItemsID.length; i++) {
          if (rows[j].id === ItemsID[i]) {
            ItemRelation = true;
          }
        }
        if (ItemRelation && ReducerState.isUpdate) {
          rowResultD.push(rows[j]);
        }
        if (!ItemRelation)
          rowResult.push(rows[j]);
      }
      if (rowResultD.length > 0) {
        const result = [];
        ReducerState.dataUpdate.ListItems.forEach(item => result.push({ ...item, id: item.id.toString() }));
        setState([rowResult, result]);
      }
      else {
        setState([rowResult, state[1]]);
      }
    }
    else {
      setState([[], rows]);
    }
  }, [ReducerState.itemsDnD]);

  /**
   * @title onDoubleClick
   * @description Funcion auxiliar para la utilizacion del Doble Click.
   * @param {object} item 
   * @param {boolean} isRigth 
   */

  const onDoubleClick = (item, isRigth) => {
    let elementsID = [], result = [], resultD = [];
    if(isRigth === 0){
      resultD = state[1];
      result = state[0].filter(base => item.id !== base.id);
      resultD.push(item);
    }
    else{
      resultD = state[1].filter(base => base.id !== item.id);
      result = state[0];
      result.push(item);
    }
    resultD.forEach(element => elementsID.push(element.id));
    setItemsID(elementsID);
    setState([result, resultD]);
  }

  /**
 * Function para la paginacion siguiente de Items Seleccionados.
 * @title onDragEnd
 */

  const onNextSend = () => {
    if (selectAll && (StartSend + 100) < ReducerState.CantItems) {
      setStartSend(StartSend + 100);
    }
  }

  /**
 * Function para la paginacion siguiente de Items del usuario.
 * @title onDragEnd
 */

  const onNext = () => {
    if (!selectAll && (start + 100) < ReducerState.CantItems) {
      setStart(start + 100);
    }

  };

  /**
 * Function para la paginacion anterior de Items Seleccionados.
 * @title onDragEnd
 */

  const onReturnSend = () => {
    if (selectAll && (StartSend - 100) > 0) {
      setStartSend(StartSend - 100);
    }
  }

  /**
 * Function para la paginacion anterior de Items del usuario.
 * @title onDragEnd
 */

  const onReturn = () => {
    if (!selectAll && (start - 100) > 0) {
      setStart(start - 100);
    }
  };

  /**
 * Function para filtrado por Nombre
 * @title onDragEnd
 */

  const onFilterbyName = (id) => {
    if (id === 1 && !selectAll) {
      if (FilterNameD !== "") {
        setSupportState(state[1]);
        const elementsID = [];
        const elements = state[1].filter((el) => el.email.toLowerCase().indexOf(FilterNameD) > -1);
        elements.forEach(element => elementsID.push(element.id));
        setItemsID(elementsID);
        setState([state[0], elements]);
      }
      else {
        const elementsID = [];
        SupportState.forEach(element => elementsID.push(element.id));
        setItemsID(elementsID);
        setState([state[0], SupportState]);
        setSupportState([]);
      }
    }
    else {
      props.FilterForName();
    }
  };

  /**
  * Function para el desplazamiento de Items
  * @title onDragEnd
  * @param {Array} result
  * @memberof DnD_Notification
  */

  function onDragEnd(result) {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const elementsID = [];
      result[1].forEach(element => elementsID.push(element.id));
      setItemsID(elementsID);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];
      setState(newState);
    }
  }

  return (
    <>
      <label style={{ color: 'rgb(130,130,130)', display: 'flex', marginTop: 10, width: "fit-content" }}>
        <input
          type="checkbox"
          checked={selectAll}
          onChange={() => setselectAll(!selectAll)}
        />
        <p className='userTypeCheckBox'>{t("Notification.addNotification.selectAll")}</p>
      </label>
      <div style={{ height: "35vh", minHeight: 250, display: "flex", justifyContent: "space-around", marginTop: 15 }}>
        <DragDropContext onDragEnd={onDragEnd}>
          {state.map((el, ind) => (
            <Droppable key={ind} droppableId={`${ind}`}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver, ErrorUsers && ind === 1 ? "red" : "#828282")}
                  {...provided.droppableProps}
                >
                  <div style={{ width: "97%", margin: "auto", marginTop: 8, marginBottom: 8 }}>
                    <OutlinedInput
                      placeholder={t('profileConfiguration.profile.inputs.email')}
                      value={ind === 0 ? FilterName : FilterNameD}
                      onKeyDown={(event) => event.key === "Enter" && onFilterbyName(ind)}
                      onChange={({ target }) => ind === 0 ? setFilterName(target.value) : setFilterNameD(target.value)}
                      fullWidth
                      style={{ backgroundColor: "#F2F2F2", borderRadius: 18, height: 40, outline: "none" }}
                      endAdornment={
                        <MdSearch style={{ color: "#050B7F", fontSize: 20, cursor: "pointer" }} onClick={() => onFilterbyName(ind)} />
                      } 
                      />
                  </div>
                  {
                    ind === 1 && el.length === 0 && <div style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: 50
                    }}>
                      <p style={{ width: "85%", color: "#050b7f", height: "50%" }}>
                        {t("Notification.addNotification.labelUsers")}
                      </p>
                    </div>
                  }
                  {
                    ReducerState.isLoad ? (
                      <div >
                        <Lottie options={defaultOptions} height={120} width={120} />
                      </div>
                    ) : (
                      <PerfectScrollbar style={{ position: "relative", height: "80%" }}>
                        {el.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <Tooltip
                                title={item.fullData}
                                placement="bottom-start"
                                classes={{ tooltip: classes.tooltipDnD }}
                              >
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                  onDoubleClick={() => {onDoubleClick(item, ind)}}
                                >

                                  <MdMoreVert />
                                  <p style={{
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                  }}>
                                    {item.email}
                                  </p>
                                </div>
                              </Tooltip>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </PerfectScrollbar>
                    )
                  }
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>

      </div>
      <div style={{ display: "flex" }}>
        <div style={{ color: "black", fontSize: "0.875rem", fontWeight: "normal", marginTop: 10, marginLeft: 15, width: "48%" }}>
          {selectAll ? 0 : StartL} - {selectAll ? 0 : LimitL} de {selectAll ? 0 : ReducerState.CantItems}
          <button style={{ backgroundColor: "white", height: "fit-content", marginLeft: 10 }} onClick={() => { onReturn(); }}>
            <MdArrowBackIos style={{ color: "#3c4858", fontSize: "0.875rem" }} />
          </button>
          <button style={{ backgroundColor: "white", height: "fit-content", position: "relative", right: 5 }} onClick={() => { onNext(); }}>
            <MdArrowForwardIos style={{ color: "#3c4858", fontSize: "0.875rem" }} />
          </button>
        </div>
        <div style={{ color: "black", fontSize: "0.875rem", fontWeight: "normal", marginTop: 10, marginLeft: 15 }}>
          {
            ErrorUsers
              ? <p style={{color: "red"}}>{t("Notification.addNotification.customerCare")}</p>
              :
              <>
                {StartD} - {LimitD} de {selectAll ? ReducerState.CantItems : SendCantItems}
                <button style={{ backgroundColor: "white", height: "fit-content", marginLeft: 10 }}>
                  <MdArrowBackIos style={{ color: "#3c4858", fontSize: "0.875rem" }} onClick={() => { onReturnSend(); }} />
                </button>
                <button style={{ backgroundColor: "white", height: "fit-content", position: "relative", right: 5 }}>
                  <MdArrowForwardIos style={{ color: "#3c4858", fontSize: "0.875rem" }} onClick={() => { onNextSend(); }} />
                </button>
              </>
          }
        </div>
      </div>
    </>
  )
}

export default DnD_Notification