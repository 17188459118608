/**
 * @fileoverview Contracts view styles
 * @version 2.0
 * @author César Paul Hernández Camacho
 * @date 18/11/2020
 * @copyright Industrias RESSER S.A de C.v
 * @update 02/02/2022
 */

const headerLinksStyle = theme => ({
    cancelButtonStyle: {
        backgroundColor: "#828282",
        paddingLeft: 10,
        paddingRight: 10,
        color: "white",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 15,
        minWidth: '15vw',
        [theme.breakpoints.down('md')]: {
            width: '100vw',
            fontSize: '0.7rem'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem'
        },
        height: 40,
        marginBottom: 20
    },
    acceptButtonStyle: {
        backgroundColor: "#0BCBFB",
        paddingLeft: 10,
        paddingRight: 10,
        color: "#050B7F",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 15,
        minWidth: '15vw',
        [theme.breakpoints.down('md')]: {
            width: '100vw',
            fontSize: '0.7rem'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem'
        },
        height: 40,
        marginBottom: 20
    },

    ButtonGo2DocumentsStyle: {
        paddingLeft: 10,
        paddingRight: 10,
        color: "white",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 10,
        height: 31,
        width: '18.5rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.5rem'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '0.918rem'
        },
    },

    ButtonGo2GlobeBoxStyle: {
        paddingLeft: 10,
        paddingRight: 10,
        color: "white",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 10,
        height: 31,
        width: '18.5rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.5rem'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '0.8rem'
        },
    },

    // Buttons on Select

    ButtonGo2DocumentsStyleOnSelect: {
        backgroundColor: "#0BCBFB",
        paddingLeft: 10,
        paddingRight: 10,
        color: "white",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 10,
        height: 31,
        width: '18.5rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.5rem'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '0.918rem'
        },
    },

    ButtonGo2GlobeBoxStyleOnSelect: {
        backgroundColor: "#050B7F",
        paddingLeft: 10,
        paddingRight: 10,
        color: "white",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 10,
        height: 31,
        width: '18.5rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.5rem'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '0.8rem'
        },
    },
    accordion: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center"
    },
    contractTable: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },
    containerModalUploadDocuments: {
        flexDirection: 'column',
        justifyContent: 'center',
        width: '95%',
        maxHeight: 500,
        overflow: 'auto',
    },
    downloadButtonStyle: {
        backgroundColor: "#050B7F",
        color: "white",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 15,
        minWidth: 200,
        [theme.breakpoints.up("xl")]: {
            minWidth: 300
        },
    },
    uploadButtonStyle: {
        backgroundColor: "#56BC23",
        color: "white",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 15,
        minWidth: 200,
        [theme.breakpoints.up("xl")]: {
            minWidth: 300
        },
    },
    uploadGBButtonStyle: {
        backgroundColor: "#0BCBFB",
        color: "white",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 15,
        minWidth: 250, 
        height: 35,
        [theme.breakpoints.up("xl")]: {
            minWidth: 300
        },
    },
    uploadGBButtonStyleModal: {
        backgroundColor: "#44E1C3",
        color: "white",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 15,
        marginBottom: 20,
        minWidth: 250, 
        height: 35,
        [theme.breakpoints.up("xl")]: {
            minWidth: 300
        },
    },
    SaveGBButtonStyle: {
        backgroundColor: "#0BCBFB",
        color: "#050B7F",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        minWidth: 200, 
        height: 35,
        marginTop: 35,
        [theme.breakpoints.up("xl")]: {
            minWidth: 250
        },
    },
    SaveGBButtonStyleModal: {
        backgroundColor: "#0BCBFB",
        color: "#050B7F",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        minWidth: 200, 
        height: 35,
        marginTop: 20,
        [theme.breakpoints.up("xl")]: {
            minWidth: 250
        },
    },
    LPBarColorPrimary: {
        backgroundColor: '#80B4FF',
    },
    LPBarColorError: {
        backgroundColor: '#FF303E',
    },
    LPBarColorUpload: {
        backgroundColor: '#56BC23',
    },
    DocumentDelete: {
        border: "1px solid #BDBDBD", 
        borderRadius: 3, 
        display: "flex" ,
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column"
        },
    },
    DocumentDeleteIcon: {
        width: "10%",
        height: 80, 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "end", 
        paddingRight: 10,
        [theme.breakpoints.down("xs")]: {
            width: "100%", 
            justifyContent: "center",
            alignItems: "center" 
        },
    },
    DocumentData: {
        width: "80%", 
        height: 80, 
        display: "flex", 
        flexDirection: "column", 
        justifyContent: "center",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            justifyContent: "center", 
            alignItems: "center"
        },
    },
    DocumentCancel: { 
        width: "10%", 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        height: 80,
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            justifyContent: "center", 
            alignItems: "center"
        },
    }
});

export default headerLinksStyle;