/**
 * @fileoverview AuthenticationToken Styles
 * estilos de la vista AuthenticationToken
 * @version 1.0.0
 * @author Leslie Mosqueda
 * @date 27/05/2024
 * @copyright 2024 Industrias RESSER S.A de C.V
 * 
 */

const TokenStyles = theme => ({
    tokenContainer: {
        width: '100%',
        marginTop: '1.5em',
        minHeight: '30vh',
        alignItems: 'flex-end',
        marginLeft: '5%',
        [theme.breakpoints.down("xl")]: {
            marginLeft: '0%',
        },
        [theme.breakpoints.down("sm", "xs")]: {
            justifyContent: 'center',
        },
    },
    tokenButton: {
        textTransform: "uppercase",
        backgroundColor: '#0092FF',
        border: 'none',
        borderRadius: 8,
        height: 40,
        width: 150,
        outline: 0,
        marginTop: 10,
        margin: 0,
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '0 0 2px 2px grey'
        },
        [theme.breakpoints.down("sm")]: {
            width: 300,
            height: 40,
        }
    },
    labelTokenStyle: {
        marginTop: '18px',
        marginBottom: '5px',
        maxWidth: '25rem',
        fontSize: '11pt',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        color: 'rgb(130, 130, 130)',
    },
    resendToken: {
        textDecoration: 'underline',
        color: '#0092FF',
        fontSize: '12pt',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        cursor: 'pointer',
        margin: '10px 2px',
    },
    helpStyle: {
        textDecoration: 'underline',
        color: 'rgb(130,130,130)',
        fontSize: '12pt',
        fontFamily: 'Source Sans Pro, sans-serif',
        cursor: 'pointer'
    },
    helpStyleDisabled: {
        textDecoration: 'underline',
        color: 'rgb(130,130,130)',
        fontSize: '12pt',
        fontFamily: 'Source Sans Pro, sans-serif',
        pointerEvents: 'none',
        cursor: 'pointer',
    },
    logo: {
        marginTop: '5%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logoImg: {
        width: '50%',
        // marginRight: '1em',
        marginBottom: '1.0em',
    },
    returnInicio: {
        textDecoration: 'underline',
        color: 'rgb(130,130,130)',
        fontSize: '12pt',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        cursor: 'pointer',
        margin: '15px 2px',
    },
    tooltip: {
        padding: "10px 15px",
        minWidth: "130px",
        lineHeight: "1.7em",
        border: "none",
        maxWidth: "350px",
        textAlign: "center",
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "400",
        textShadow: "none",
        textTransform: "none",
        letterSpacing: "normal",
        wordBreak: "normal",
        wordSpacing: "normal",
        wordWrap: "normal",
        whiteSpace: "normal",
        lineBreak: "auto",
        marginTop: '2px',

    }
})

export default TokenStyles;