/**
 * @fileoverview Maintenance view styles
 * @version 1.0.0
 * @author Martin Duran Anguiano
 * @date 22/12/2020
 * @copyright Industrias RESSER S.A de C.v
 */

const headerLinksStyle = theme => ({
  titles: {
    color: "#050B7F",
    fontWeight: 'bold',
    fontSize: '2rem',
    marginLeft: 7
  },
  subTitles: {
    color: "#050B7F",
    fontWeight: '300',
    fontSize: '1.5rem'
  },
  description: {
    color: "#828282",
    fontWeight: '300',
    fontSize: '1.0rem',
    marginLeft: 5,
    width: "90%"
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    bottom: 15
  },
  accordion: {
    display: "flex",
    justifyContent: 'center'
  },
  newServiceButtonStyle: {
    backgroundColor: "#0BCBFB",
    color: "#050B7F",
    fontWeight: 'bold',
    borderRadius: '1.5rem',
    marginTop: 15,
    marginLeft: 10,
    minWidth: 200,
  },
  newProjectManagement: {
    backgroundColor: "#050B7F",
    color: "#FFF",
    fontWeight: 'bold',
    borderRadius: '1.5rem',
    marginTop: 15,
    minWidth: 200
  },
  containerFormSections: {
    width: '100%'
  },
  downloadButtonStyle: {
    backgroundColor: "#050B7F",
    color: "white",
    fontWeight: 'bold',
    borderRadius: '1.5rem',
    marginTop: 15,
    minWidth: 200
  },
  uploadButtonStyle: {
    backgroundColor: "#0BCBFB",
    color: "#050B7F",
    fontWeight: 'bold',
    borderRadius: '1.5rem',
    marginTop: 15,
    minWidth: 200
  },
  maintenanceTable: {
    display: 'flex',
    flexDirection: 'column'
  },
  filterIcon: {
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
  containerTable: {
    flexDirection: 'column',
  },
  containerButton: {
    flexDirection: 'column',
    margin: '0px !important',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '10px',

  },
  containerModalFilter: {
    flexDirection: 'column',
    justifyContent: 'center',
    width: '95%',
    maxHeight: 350,
    overflow: 'auto',
    marginBottom: 10,
  },
  containerRow: {
    flexDirection: 'row',
    width: '100%'
  },
  box: {
    width: '100%',
  }
});

export default headerLinksStyle;