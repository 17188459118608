/**
 * Styles for SAT view
 * @fileoverview SAT view styles
 */


const styles = theme => ({
    title: {
        color: "#050B7F",
        fontWeight: 'bold',
        fontSize: '2rem',
        marginLeft: 7,
    },
    ButtonsFilter: {
        borderRadius: '5px',
        width: '30px',
        height: '30px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
});

export default styles;
