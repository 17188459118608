/**
 * @fileoverview Poppers
 * @version 1.0.0
 * @author Carlos Emilio Blanco Lopez
 * @date 27/03/2023
 * @copyright 2023 Industrias RESSER S.A de C.V
 */
import React from 'react';
import CustomPoppers from 'components/CustomPoppers/CustomPoppers';
import { IdiomaIcon, AjustesIcon, LogOutIcon, PerfilIcon } from "../../assets/img/Icons/Icons";
import { Paper, MenuItem, MenuList, Select, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';

//Styles
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { makeStyles } from '@material-ui/core/styles';
import CardNotification from 'components/card/CardNotification';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(styles);

/**
 * @title PopperMenu
 * @param {boolean} open 
 * @param {Ref} anchorEl 
 * @param {Function} handleClickLenguage 
 * @param {Function} goToProfileUser
 * @param {Function} goToProfileSettings
 * @param {Function} onLogOut  
 * @returns Fragment
 */

export const PopperMenu = ({
    open,
    anchorEl,
    handleClickLenguage,
    goToProfileUser,
    goToProfileSettings,
    onLogOut
}) => {
    return (
        <CustomPoppers
            open={open}
            anchorEl={anchorEl}>
            <Paper className="navbar_menuMobil_Paper">
                <div style={{ display: "flex" }} className="navbar_links_mobil">
                    <IdiomaIcon width={23} height={23} onClick={handleClickLenguage} />
                    <PerfilIcon width={23} height={23} onClick={goToProfileUser} />
                    <AjustesIcon width={23} height={23} onClick={goToProfileSettings} />
                    <LogOutIcon width={23} height={23} onClick={onLogOut} />
                </div>
            </Paper>
        </CustomPoppers>
    )
}

/**
 * @title PopperLenguage
 * @param {boolean} open 
 * @param {Ref} anchorEl 
 * @param {Function} setOpenProfile
 * @param {String} language
 * @param {Function} onChangeLanguage
 * @returns Fragment
 */

export const PopperLenguage = ({
    open,
    anchorEl,
    setOpenProfile,
    language,
    onChangeLanguage
}) => {
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');
    return (
        <CustomPoppers
            open={open}
            anchorEl={anchorEl}>
            <Paper style={{ marginTop: "10pt" }}>
                <MenuList role="menu">
                    <MenuItem
                        onClick={() => setOpenProfile(null)}
                        className={classes.dropdownItem}
                    >
                        <p style={{ color: "#828282" }}>
                            {t('profileConfiguration.account.selectLanguage')}
                        </p>
                        <Select
                            labelId="Idioma"
                            id="laguage"
                            value={language}
                            fullWidth
                            onChange={({ target }) => onChangeLanguage(target.value)}
                        >
                            <MenuItem value={t('profileConfiguration.account.languages.0.value')}> {t('profileConfiguration.account.languages.0.text')} </MenuItem>
                            <MenuItem value={t('profileConfiguration.account.languages.1.value')}> {t('profileConfiguration.account.languages.1.text')} </MenuItem>
                            <MenuItem value={t('profileConfiguration.account.languages.2.value')}> {t('profileConfiguration.account.languages.2.text')} </MenuItem>
                            <MenuItem value={t('profileConfiguration.account.languages.3.value')}> {t('profileConfiguration.account.languages.3.text')} </MenuItem>
                        </Select>
                    </MenuItem>
                </MenuList>
            </Paper>
        </CustomPoppers>
    )
}

/**
 * @title PopperOptions
 * @param {boolean} open 
 * @param {Ref} anchorEl 
 * @param {string} option
 * @returns Fragment
 */

export const PopperOptions = ({
    open,
    anchorEl,
    option
}) => {
    return (
        <CustomPoppers
            open={open}
            anchorEl={anchorEl}>
            <Paper elevation={0}>
                <div className="navbar_options">
                    {option}
                </div>
            </Paper>
        </CustomPoppers>
    )
}


/**
 * @title PopperOptions
 * @param {boolean} open 
 * @param {Ref} anchorEl 
 * @param {string} option
 * @returns Fragment
 */

export const PopperNotifications = ({
    open,
    anchorEl,
    option,
    onOpenNotification
}) => {
    const [t, i18n] = useTranslation('common');
    const isLoading = useSelector(state => state.loadingMask.showNotificationMask);
    const NotificationData = useSelector(state => state.Notifications.notifications);
    return (
        <CustomPoppers
            open={open}
            anchorEl={anchorEl}>
            <Paper elevation={10} style={{ width: "25vw", height: "75vh", marginTop: 10 }}>
                <div className="navbar_Notifications">
                    <p>{t("NavBar.notifications")}</p>
                </div>
                {
                    NotificationData === null &&
                    <div style={{ position: "relative", maxHeight: "85%", display: "flex", justifyContent: "center", marginTop: 5 }}>
                        <p style={{textAlign: "center"}}>{t('Notification.noNotifications')}</p>
                    </div>
                }
                <PerfectScrollbar style={{ position: "relative", maxHeight: "85%", display: "flex", justifyContent: "center", marginTop: 5 }}>
                    {
                        isLoading ?
                            <Skeleton animation='wave' width={"95%"} /> :
                            <div style={{ width: "95%" }}>
                                {
                                    NotificationData !== null && NotificationData.map(data => <CardNotification data={data} onViewNotification={onOpenNotification} />)
                                }
                            </div>
                    }
                </PerfectScrollbar>
            </Paper>
        </CustomPoppers>
    )
}
