/**
 * @fileoverview Table component to use in the project
 * @version 1.0
 * @author René Francisco Coss y León Monterde
 * @date 30/06/2021
 * @copyright 2021 Industrias RESSER S.A de C.V
 */

//React
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import axios from 'libs/AxiosHelper';
import {enqueueSuccessSnackbar, enqueueErrorSnackbar} from 'libs/Snackbar';
import {showCircularProgress, closeCircularProgress} from 'redux/slice';
import moment from 'moment';
//@material-ui components
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  Table,
  Typography,
} from '@material-ui/core';
import { MdDelete, MdAddCircleOutline, MdClose } from "react-icons/md";
//Components
import Button from "components/CustomButtons/Button.js";
import TableBrasil from "./RegionTables/TableBrasil";
import TableMexico from "./RegionTables/TableMexico";
import TablePeru from "./RegionTables/TablePeru";
import TableChile from "./RegionTables/TableChile";
import TableColombia from "./RegionTables/TableColombia";
//Styles
import styles from './MaintenanceGroupTable.styles';
import Rssr from 'libs/Rssr';

const useStyles = makeStyles(styles);

const MaintenanceGroupTable = (props) => {
  //Styles
  const classes = useStyles();
  //Translations
  const [t, i18n] = useTranslation('common');
  //Constants
  const dispatch = useDispatch();

  //State form maintenance object
  const formDataObject = {
    serviceType: -1,
    contract: "",
    plate: '',
    phone: '',
    kilometer: '',
    countryEstate: -1,
    city: -1,
    region: '',
    date1: '',
    date2: '',
    date3: '',
    armored: '',
    pickup: '',
    pickupDir: '',
    details: '',
    rName: '',
    rPhone: '',
    rEmail: '',
  }

  //States
  const [countryEstates, setCountryEstates] = useState([]);
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [rowQuantity, setRowQuantity] = useState([
    {
      id: 0,
      checked: false,
      formData: formDataObject,
    },
    {
      id: 1,
      checked: false,
      formData: formDataObject,
    }
  ]);

  const regionId = useSelector(state => state.auth.loginData.regionId);
  const language = useSelector((state) => state.configurations.language);
  useEffect(() => {
    getCountryEstates();
  }, []);

  /**
  * Function that gets the Countrystates of a region
  * @memberof MaintenanceGroupTable
  */
   const getCountryEstates = () =>{
    axios.get(`StateCatalogFMV2?regionId=${regionId}`)
      .then(async response => {
        setCountryEstates(response.data.items);
      })
      .catch((error) => {
        enqueueErrorSnackbar(dispatch, t('maintenance.errors.failConnection'));
      });
  }

  /**
  * Function called when any checkbox change its state, checks if it was header to select all rows.
  * @param {*} idRow
  * @memberof MaintenanceGroupTable
  */
  const handleCheckBox = (idRow) =>{
    let auxList = rowQuantity;
    if(idRow == 0){
      let flgCheckAll = false;
      auxList = rowQuantity.map((item) => {
        if(item.id == 0){
          item.checked = !item.checked;
          flgCheckAll = item.checked;
        }
        item.checked = flgCheckAll;
        if(flgCheckAll){
          setSelectedRowCount(rowQuantity.length - 1);
        }
        else{
          setSelectedRowCount(0);
        }
        return item;
      });
    }
    else{
      let auxCount=0;
      auxList = rowQuantity.map((item) => {
        if(item.id == idRow){
          item.checked = !item.checked;
          if(item.checked){
            setSelectedRowCount(selectedRowCount + 1);
          }
          else{
            setSelectedRowCount(selectedRowCount - 1);
          }
        }
        if(item.checked && item.id != 0){
          auxCount++;
        }
        return item;
      });
      if(auxCount == rowQuantity.length - 1){
        auxList[0].checked = true;
      }
      else{
        auxList[0].checked = false;
      }
    }
    setRowQuantity(auxList);
  }

  /**
  * Function that handles adding a row by adding an element to the array.
  * @memberof MaintenanceGroupTable
  */
   const handleAddRow = () =>{
    let auxList = rowQuantity;
    auxList[0].checked = false;
    setRowQuantity(auxList);
    setRowQuantity(rowQuantity.concat([{
      id: rowQuantity[rowQuantity.length-1].id + 1,
      checked: false,
    }]));
  }

  /**
  * Function that handles erasing the selected rows by creating a new array without those elements.
  * @memberof MaintenanceGroupTable
  */
   const handleEraseRow = () =>{
    let auxList = [rowQuantity[0]];
    const travelList = rowQuantity.map((item) => {
      if(item.id != 0 && item.checked == false){
        auxList.push(item);
      }
    });
    if(auxList.length <= 1){
      auxList = [
      {
        id: 0,
        checked: false,
        formData: formDataObject,
      },
      {
        id: 1,
        checked: false,
        formData: formDataObject,
      },
      ]
    }
    setRowQuantity(auxList);
    setSelectedRowCount(0);
  }

  /**
  * Function called when any input change its state.
  * @param {*} idRow
  * @param {*} dataObject
  * @memberof MaintenanceGroupTable
  */
  const handleChangeInput = (idRow, dataObject) => {
    if(idRow != 0){
      const auxList = rowQuantity.map((item) => {
        if(item.id == idRow){
          item.formData = dataObject;
        }
        return item;
      })
      setRowQuantity(auxList);
    }
  }

  /**
  * Function that handles the interaction with the send button, using the different validations.
  * @memberof MaintenanceGroupTable
  */
  const handleSendGroup = () => {
    dispatch(showCircularProgress());
    let flgValidations = true;
    if(hasEmptyFields()){
      flgValidations = false;
    }
    else if(hasInvalidConditions()){
      flgValidations = false;
    }
    if(flgValidations){
      sendData();
    }
    else{
      dispatch(closeCircularProgress());
    }
  }

  /**
  * Function that sends the object of rowQuantity through Axios.
  * @memberof MaintenanceGroupTable
  */
  const sendData = async () => {
    const data ={
      ticketData:[]
    }
    rowQuantity.map((item) => {
      if(item.id !== 0){
        const fecha1 = item.formData.date1;
        const fecha2 = item.formData.date2;
        const fecha3 = item.formData.date3;
        data.ticketData.push({
          ticketType: item.formData.serviceType,
          requestorPhone: item.formData.rPhone,
          requestorEmail: item.formData.rEmail,
          requestorName: item.formData.rName,
          currentKm: item.formData.kilometer,
          vehiclePlate: item.formData.plate,
          blindado: item.formData.armored,
          levaETraz: item.formData.pickup,
          levaETrazDir: item.formData.pickupDir,
          date1: moment(fecha1).format("YYYY-MM-DD HH:mm"),
          date2: moment(fecha2).format("YYYY-MM-DD HH:mm"),
          date3: moment(fecha3).format("YYYY-MM-DD HH:mm"),
          infoAdicional: item.formData.details,
          state: item.formData.countryEstate,
          city: item.formData.city,
          region: item.formData.region
        })
      }
    });

    let flgSendCorrectly = true;
    await axios.post("TicketsGroup", data)
      .then((response) => {
        let rowError1 = t('maintenance.errors.rowError');
        let rowError2 = t('maintenance.errors.repeatedService');
        for(let i=0; i<response.data.length; i++){
          if(!response.data[i].success){
            flgSendCorrectly = false;
            if(response.data[i].Message = 3){
              rowError2 = rowError2+" "+rowQuantity[i+1].formData.plate+",";
            }
            else{
              rowError1 = rowError1+" "+rowQuantity[i+1].formData.plate+",";
            }
          }
        }
        dispatch(closeCircularProgress());
        if(flgSendCorrectly){
          enqueueSuccessSnackbar(dispatch, t('maintenance.errors.successConnection'));
        }
        else{
          if(rowError1 !== t('maintenance.errors.rowError')){
            enqueueErrorSnackbar(dispatch, rowError1);
          }
          if(rowError2 !== t('maintenance.errors.repeatedService')){
            enqueueErrorSnackbar(dispatch, rowError2);
          }
        }
      })
      .catch((error) =>{
        dispatch(closeCircularProgress());
        enqueueErrorSnackbar(dispatch, t('maintenance.errors.failConnection'));
      })
    props.onCloseGroupModal();
  }

  /**
  * Function called to check if any input field is empty.
  * @memberof MaintenanceGroupTable
  */
  const hasEmptyFields = () => {
    for(let i = 1; i < rowQuantity.length; i++){
      let auxPlate = "";
      let flgPlate = false;
      let auxError = "";
      if(rowQuantity[i].formData.plate) auxPlate = rowQuantity[i].formData.plate;
      if(rowQuantity[i].formData.plate.length <= 0){
        flgPlate = true;
        auxError = t('maintenance.errors.emptyPlate');
        if(language === "es" && regionId === 3) auxError = t('maintenance.errors.emptyPlateChile');
      }
      else if(rowQuantity[i].formData.phone.length <= 0 && regionId !== 4){
        auxError = t('maintenance.errors.emptyPhone');
        if(language === "es" && regionId === 3) auxError = t('maintenance.errors.emptyPhoneChile');
      }
      else if(rowQuantity[i].formData.kilometer.length <= 0){
        auxError = t('maintenance.errors.emptyKilometer');
        if(language === "es" && regionId === 3) auxError = t('maintenance.errors.emptyKilometerChile');
      }
      else if(rowQuantity[i].formData.countryEstate < 0){
        auxError = t('maintenance.errors.emptyCountryestate');
        if(language === "es"){
          switch(regionId){
            case 0:
              auxError = t('maintenance.errors.emptyCountryestate');
            break;
            case 1:
              auxError = t('maintenance.errors.emptyCountryestateMexico');
            break;
            case 2:
              auxError = t('maintenance.errors.emptyCountryestatePeru');
            break;
            case 3:
              auxError = t('maintenance.errors.emptyCountryestateChile');
            break;
            case 4:
              auxError = t('maintenance.errors.emptyCountryestateColombia');
            break;
            default:
              auxError = t('maintenance.errors.emptyCountryestate');
            break;
          }
        }
      }
      else if(rowQuantity[i].formData.city < 0){
        auxError = t('maintenance.errors.emptyCity');
        if(language === "es"){
          switch(regionId){
            case 0:
              auxError = t('maintenance.errors.emptyCity');
            break;
            case 1:
              auxError = t('maintenance.errors.emptyCityMexico');
            break;
            case 2:
              auxError = t('maintenance.errors.emptyCityPeru');
            break;
            case 3:
              auxError = t('maintenance.errors.emptyCityChile');
            break;
            case 4:
              auxError = t('maintenance.errors.emptyCityColombia');
            break;
            default:
              auxError = t('maintenance.errors.emptyCity');
            break;
          }
        }
      }
      else if(rowQuantity[i].formData.region.length <= 0 && (regionId !== 2 && regionId !== 3) ){
        auxError = t('maintenance.errors.emptyRegion');
        if(language === "es"){
          switch(regionId){
            case 0:
              auxError = t('maintenance.errors.emptyRegion');
            break;
            case 1:
              auxError = t('maintenance.errors.emptyRegionMexico');
            break;
            case 2:
              auxError = t('maintenance.errors.emptyRegionPeru');
            break;
            case 3:
              auxError = t('maintenance.errors.emptyRegionChile');
            break;
            case 4:
              auxError = t('maintenance.errors.emptyRegionColombia');
            break;
            default:
              auxError = t('maintenance.errors.emptyRegion');
            break;
          }
        }
      }
      else if(rowQuantity[i].formData.rName.length <= 0){
        auxError = t('maintenance.errors.emptyResponsalName');
        if(language === "es" && regionId === 3) auxError = t('maintenance.errors.emptyResponsalNameChile');
      }
      else if(rowQuantity[i].formData.rPhone.length <= 0 && regionId !== 4){
        auxError = t('maintenance.errors.emptyResponsalPhone');
        if(language === "es" && regionId === 3) auxError = t('maintenance.errors.emptyResponsalPhoneChile');
        else if (language === "es" && regionId === 2) auxError = t('maintenance.errors.emptyResponsalPhonePeru');
      }
      else if(rowQuantity[i].formData.rEmail.length <= 0){
        auxError = t('maintenance.errors.emptyResponsalEmail');
        if(language === "es" && regionId === 3) auxError = t('maintenance.errors.emptyResponsalEmailChile');
      }
      else if(regionId === 0){ //Brasil Validar las 3 fechas no vacías y la dirección de Door to Door si se eligió algo diferente a si
        if(rowQuantity[i].formData.date1.length <= 0) auxError = t('maintenance.errors.emptyDate1');
        else if(rowQuantity[i].formData.date2.length <= 0) auxError = t('maintenance.errors.emptyDate2');
        else if(rowQuantity[i].formData.date3.length <= 0) auxError = t('maintenance.errors.emptyDate3');
        else if(rowQuantity[i].formData.armored.length <= 0) auxError = t('maintenance.errors.emptyArmored');
        else if(rowQuantity[i].formData.pickup.length <= 0) auxError = t('maintenance.errors.emptyRoundTrip');
        else if(rowQuantity[i].formData.pickup !== t('modalTicketBr.levaETraz.1')){
          if(rowQuantity[i].formData.pickupDir.length <= 0) auxError = t('maintenance.errors.emptyPickupDir');
        }
      }
      else if(regionId === 3){ //Chile validar 1 de dos fechas cualquiera ingresada
        if(rowQuantity[i].formData.date1.length <= 0 && rowQuantity[i].formData.date2.length <= 0){
          auxError = t('maintenance.errors.emptyDates');
        }
      }
      else if(regionId === 4){ //Colombia Validar 2 fechas cualesquiera ingresadas
        if(rowQuantity[i].formData.date1.length <= 0) auxError = t('maintenance.errors.emptyDate1');
        else if(rowQuantity[i].formData.date2.length <= 0) auxError = t('maintenance.errors.emptyDate2');
      }
      else{
        if(regionId === 3){ //Chile Validar Blindado y pickup
          if(rowQuantity[i].formData.serviceType < 0) auxError = t('maintenance.errors.emptyServiceTypeChile');
          else if(rowQuantity[i].formData.date1.length <= 0) auxError = t('maintenance.errors.emptyDate1Chile');
          else if(rowQuantity[i].formData.date2.length <= 0) auxError = t('maintenance.errors.emptyDate2Chile');
          else if(rowQuantity[i].formData.armored.length <= 0) auxError = t('maintenance.errors.emptyArmoredChile');
          else if(rowQuantity[i].formData.pickup.length <= 0) auxError = t('maintenance.errors.emptyRoundTripChile');
          else if(rowQuantity[i].formData.pickup !== t('generalText.no') && rowQuantity[i].formData.pickup !== t('generalText.na')){
            if(rowQuantity[i].formData.pickupDir.length <= 0) auxError = t('maintenance.errors.emptyPickupDirChile');
          }
        }
        else if(rowQuantity[i].formData.serviceType < 0) auxError = t('maintenance.errors.emptyServiceType');
        else if(rowQuantity[i].formData.date1.length <= 0) auxError = t('maintenance.errors.emptyDate1');
        else if(rowQuantity[i].formData.date2.length <= 0) auxError = t('maintenance.errors.emptyDate2');
      }

      if(auxError !== ""){
        if(flgPlate) enqueueErrorSnackbar(dispatch, auxError);
        else enqueueErrorSnackbar(dispatch, auxError + " " + auxPlate);
        return true;
      }
    }
    return false;
  }

  /**
  * Function called to check conditions: email format is right, plates aren't repeated and dates are in the range. If any is not valid an Alert will pop up.
  * @memberof MaintenanceGroupTable
  */
  const hasInvalidConditions = () =>{
    // Validación de placa repetida
    let flgRepeatedPlate = false;
    for(let i = 1; i < rowQuantity.length; i++){
      let auxPlate = "";
      if(rowQuantity[i].formData.plate) auxPlate = rowQuantity[i].formData.plate;
      flgRepeatedPlate = rowQuantity.some(function (item) {
        if(item.id !== rowQuantity[i].id) return item.formData.plate == auxPlate;
      });
      if(flgRepeatedPlate){
        if(language === "es" && regionId === 3) enqueueErrorSnackbar(dispatch, t('maintenance.errors.repeatedPlateChile') + " - " + auxPlate);
        else enqueueErrorSnackbar(dispatch, t('maintenance.errors.repeatedPlate') + " - " + auxPlate);
        return true;
      }
    }

    // Validación de fechas
    let auxDate = "";
    for(let i = 1; i < rowQuantity.length; i++){
      const date1 = moment(rowQuantity[i].formData.date1).format("YYYY-MM-DDTHH:mm");
      const date2 = moment(rowQuantity[i].formData.date2).format("YYYY-MM-DDTHH:mm");
      const date3 = moment(rowQuantity[i].formData.date3).format("YYYY-MM-DDTHH:mm");

      let auxPlate = "";
      if(rowQuantity[i].formData.plate) auxPlate = rowQuantity[i].formData.plate;

      if(regionId === 3){ //Comprobación de fechas para Chile
        if(date1.length > 0 && date2.length <= 0){
          const flgValidDate1 = Rssr.validateServiceDayRegion(date1, regionId);
          if(flgValidDate1 !== true){
            errorMessageDate(flgValidDate1, auxPlate, t("maintenance.groupTable.headerDateOne"));
            return true;
          }
        }

        else if(date2.length > 0 && date1.length <= 0){
          const flgValidDate2 = Rssr.validateServiceDayRegion(date2, regionId);
          if(flgValidDate2 !== true){
            errorMessageDate(flgValidDate2, auxPlate, t("maintenance.groupTable.headerDateTwo"));
            return true;
          }
        }
        else{
          if(date1.length > 0){
            const flgValidDate1 = Rssr.validateServiceDayRegion(date1, regionId);
            if(flgValidDate1 !== true){
              errorMessageDate(flgValidDate1, auxPlate, t("maintenance.groupTable.headerDateOne"));
              return true;
            }
          }
          if(date2.length > 0){
            auxDate = rowQuantity[i].formData.date2;
            const flgValidDate2 = Rssr.validateServiceDayRegion(date2, regionId);
            if(flgValidDate2 !== true){
              errorMessageDate(flgValidDate2, auxPlate, t("maintenance.groupTable.headerDateTwo"));
              return true;
            }
          }
        }
      }
      else{
        if(date1.length > 0){
          const flgValidDate1 = Rssr.validateServiceDayRegion(date1, regionId);
          if(flgValidDate1 !== true){
            errorMessageDate(flgValidDate1, auxPlate, t("maintenance.groupTable.headerDateOne"));
            return true;
          }
        }
        if(date2.length > 0){
          const flgValidDate2 = Rssr.validateServiceDayRegion(date2, regionId);
          if(flgValidDate2 !== true){
            errorMessageDate(flgValidDate2, auxPlate, t("maintenance.groupTable.headerDateTwo"));
            return true;
          }
        }
      }
      if(regionId === 0 || regionId === 4){
        if(date3.length > 0){
          const flgValidDate3 = Rssr.validateServiceDayRegion(date3, regionId);
          if(flgValidDate3 !== true){
            errorMessageDate(flgValidDate3, auxPlate, t("maintenance.groupTable.headerDateThree"));
            return true;
          }
        };
      }
      if(!Rssr.validateEmailFormat(rowQuantity[i].formData.rEmail)){
        enqueueErrorSnackbar(dispatch, t('maintenance.errors.invalidEmail') + " " + auxPlate);
        return true;
      }
    }

    //Validación de campo teléfono para Perú
    if(regionId === 2) {
      for (let i = 1; i < rowQuantity.length; i++) {
        let auxPlate = "";
        if(rowQuantity[i].formData.plate) auxPlate = rowQuantity[i].formData.plate;
        // Revisión de Teléfono 1 (FM)
        if(rowQuantity[i].formData.phone.length < 9){
          enqueueErrorSnackbar(dispatch, t('maintenance.errors.limitPhonePeru') + " " + auxPlate);
          return true;
        }
        // Revisión de Telefóno 2 (Responsable)
        else if(rowQuantity[i].formData.rPhone.length < 9) {
          enqueueErrorSnackbar(dispatch, t('maintenance.errors.limitPhoneRSPeru') + " " + auxPlate);
          return true;
        }
      }
    }

    //Validación de campo teléfono para Chile
    else if(regionId === 3){
      for (let i = 1; i < rowQuantity.length; i++) {
        let auxPlate = "";
        if(rowQuantity[i].formData.plate) auxPlate = rowQuantity[i].formData.plate;
        // Revisión de Teléfono 1 (FM)
        if(rowQuantity[i].formData.phone.length < 9 || rowQuantity[i].formData.phone.length > 11){
          enqueueErrorSnackbar(dispatch, t('maintenance.errors.limitPhoneChile') + " " + auxPlate);
          return true;
        }
        // Revisión de Telefóno 2 (Responsable)
        else if(rowQuantity[i].formData.rPhone.length < 9 || rowQuantity[i].formData.rPhone.length > 11) {
          enqueueErrorSnackbar(dispatch, t('maintenance.errors.limitPhoneRSChile') + " " + auxPlate);
          return true;
        }
      }
    }

    //Validación de campo teléfono para Colombia
    else if (regionId === 4) {
      for (let i = 1; i < rowQuantity.length; i++) {
        let auxPlate = "";
        if (rowQuantity[i].formData.plate) auxPlate = rowQuantity[i].formData.plate;
        /*// Revisión de Teléfono 1 (FM)
        if (rowQuantity[i].formData.phone.length < 10) {
          enqueueErrorSnackbar(dispatch, t('maintenance.errors.limitPhoneColombia') + " " + auxPlate);
          return true;
        }*/
        // Revisión de Telefóno 2 (Responsable)
       if (rowQuantity[i].formData.rPhone.length < 10) {
          enqueueErrorSnackbar(dispatch, t('maintenance.errors.limitPhoneRSColombia') + " " + auxPlate);
          return true;
        }
      }
    }

    return false;
  }

  /**
  * Function that sends the proper Alert depending on date invalidation.
  * @param {*} errCode
  * @param {*} errPlate
  * @param {*} errDate
  * @memberof MaintenanceGroupTable
  */
  const errorMessageDate = (errCode, errPlate, errDate) =>{
    let auxError = errDate + " ";
    switch(errCode){
      case 2:
        if(language === "es" && regionId === 3) auxError = auxError + " " + t("maintenance.errors.invalidDateWeekendChile");
        else if(regionId === 4) auxError = auxError + " " + t("maintenance.errors.invalidDateWeekendColombia");
        else auxError = auxError + " " + t("maintenance.errors.invalidDateWeekend");
      break;
      case 3:
        switch(regionId){
          case 0:
            auxError = auxError + " " + t("maintenance.errors.invalidDateOutScheduleBrasil");
          break;
          case 1:
            auxError = auxError + " " + t("maintenance.errors.invalidDateOutScheduleMexico");
          break;
          case 2:
            auxError = auxError + " " + t("maintenance.errors.invalidDateOutSchedulePeru");
          break;
          case 3:
            auxError = auxError + " " + t("maintenance.errors.invalidDateOutScheduleChile");
          break;
          case 4:
            auxError = auxError + " " + t("maintenance.errors.invalidDateOutScheduleColombia");
          break;
          default:
            auxError = auxError + " " + t("maintenance.errors.invalidDateOutSchedule");
          break;
        }
      break;
      case 4:
        auxError = auxError + " " + t("maintenance.errors.invalidDatePass48Hours");
      break;
      case 5:
        auxError = auxError + " " + t("maintenance.errors.invalidDatePreviousDate");
      break;
    }
    enqueueErrorSnackbar(dispatch, auxError + " " + errPlate);
  }

  /**
  * Function that renders the table in maintenance group.
  * @memberof MaintenanceGroupTable
  */
  const renderTableRows = () =>{
    let flgHeader=true;
    switch(regionId){
      case 0: //Brasil
        return rowQuantity.map((item) => {
          if(item.id > 0){
            flgHeader = false;
          }
          return(
            <TableBrasil
              id={item.id}
              checked={item.checked}
              flgHeader={flgHeader}
              contracts={props.contracts}
              handleCheckBox={(param) => handleCheckBox(param)}
              countryEstates={countryEstates}
              formData={item.formData}
              handleChangeInput={(idRow, dataObject) => handleChangeInput(idRow, dataObject)}
            />
          );
        });
      case 1: //México
        return rowQuantity.map((item, key) => {
          if(key > 0){
            flgHeader = false;
          }
          return(
            <TableMexico
              id={item.id}
              checked={item.checked}
              flgHeader={flgHeader}
              contracts={props.contracts}
              handleCheckBox={(param) => handleCheckBox(param)}
              countryEstates={countryEstates}
              formData={item.formData}
              handleChangeInput={(idRow, dataObject) => handleChangeInput(idRow, dataObject)}
            />
          );
        });
      case 2: //Perú
        return rowQuantity.map((item, key) => {
          if(key > 0){
            flgHeader = false;
          }
          return(
            <TablePeru
              id={item.id}
              checked={item.checked}
              flgHeader={flgHeader}
              contracts={props.contracts}
              handleCheckBox={(param) => handleCheckBox(param)}
              countryEstates={countryEstates}
              formData={item.formData}
              handleChangeInput={(idRow, dataObject) => handleChangeInput(idRow, dataObject)}
            />
          );
        });
      case 3: //Chile
        return rowQuantity.map((item, key) => {
          if(key > 0){
            flgHeader = false;
          }
          return(
            <TableChile
              id={item.id}
              checked={item.checked}
              flgHeader={flgHeader}
              contracts={props.contracts}
              handleCheckBox={(param) => handleCheckBox(param)}
              countryEstates={countryEstates}
              formData={item.formData}
              handleChangeInput={(idRow, dataObject) => handleChangeInput(idRow, dataObject)}
            />
          );
        });
      case 4: //Colombia
      return rowQuantity.map((item, key) => {
        if(key > 0){
          flgHeader = false;
        }
        return(
          <TableColombia
              id={item.id}
              checked={item.checked}
              flgHeader={flgHeader}
              contracts={props.contracts}
              handleCheckBox={(param) => handleCheckBox(param)}
              countryEstates={countryEstates}
              formData={item.formData}
              handleChangeInput={(idRow, dataObject) => handleChangeInput(idRow, dataObject)}
          />
        );
      });
    }
  }

  return(

    <Dialog xs={12} md={12}
      open={props.showGroupModal}
      onClose={props.onCloseGroupModal}
      fullWidth
      maxWidth="lg"
      classes={{paper: classes.dialog}}
    >
      <DialogTitle lg={12} md={12} disableTypography className={classes.dialogHeaderFooter}>
        <Typography lg={10} md={10} className={classes.titles}>
          {t('maintenance.groupTable.groupMaintenance')}
        </Typography>
        <IconButton lg={2} md={2}
          className={classes.buttonClose}
          onClick={() => {props.onCloseGroupModal()}}
          >
            <MdClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Table stickyHeader>
          {(rowQuantity.length > 0) && renderTableRows()}
        </Table>
      </DialogContent>
      <Divider className={classes.divider} />
      {/*Sección de borrar, añadir fila y enviar*/}
      <DialogActions lg={12} md={12} disableTypography className={classes.dialogHeaderFooter}>
        <IconButton lg={4} md={4}
          className={classes.buttonDeleteRow}
          onClick={() => {handleEraseRow()}}
          >
            <MdDelete />
            {selectedRowCount + "  " + t('maintenance.groupTable.selected')}
        </IconButton>
        <IconButton lg={4} md={4}
          alignItems={'center'}
          justify={'center'}
          className={classes.buttonAddRow}
          onClick={() => {handleAddRow()}}
          >
            <MdAddCircleOutline/>
            <Typography variant='body1' style={{marginLeft: 5}}>
              {t('maintenance.groupTable.addRow')}
            </Typography>
        </IconButton>
        <Button lg={4} md={4}
          className={classes.button}
          onClick={() => {handleSendGroup()}}
          >
          {t('maintenance.groupTable.requestService')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MaintenanceGroupTable;