/**
 * @fileoverview Configuration Slice by Categories
 * @version 1.0
 * @author Carlos Emilio Blanco Lopez
 * @date 15/08/2023
 * @copyright 2023 Industrias RESSER S.A de C.V
 **/

import { createSlice } from '@reduxjs/toolkit';

const configState = {
    language: 'es',
    showCookies: false,
    showFooter: true,
    seenCookies: false,
    getProfileInfo: false,
    showFixedDrawer: true,
    showDrawer: false,
    currentURL: process.env.REACT_APP_API_SERVER
};

const configuration = createSlice({
    name: 'configuration',
    initialState: configState,
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
        setCookies: (state, action) => {
            state.seenCookies = true;
        },
        setShowCookies: (state, action) => {
            state.showCookies = action.payload;
        },
        setProfileInfo: (state, action) => {
            state.getProfileInfo = action.payload;
        },
        setFixedDrawer: (state, action) => {
            state.showFixedDrawer = true;
        },
        showDrawer: (state, action) => {
            state.showDrawer = true;
        },
        setCurrentUrl: (state, action) => {
            state.currentURL = action.payload;
        },
        setShowFooter: (state, action) => {
            state.showFooter = action.payload;
        }
    }
});

export const {
    setLanguage,
    setCookies,
    setShowCookies,
    setProfileInfo,
    setFixedDrawer,
    showDrawer,
    setCurrentUrl,
    setShowFooter,
} = configuration.actions;

export default configuration.reducer;


