/**
 * @fileoverview Table component for the region of Colombia
 * @version 1.0
 * @author René Francisco Coss y León Monterde./
 * @date 30/06/2021
 * @copyright 2021 Industrias RESSER S.A de C.V
 */

//React
import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import axios from 'libs/AxiosHelper';
import { useDispatch, useSelector } from 'react-redux';
import {onShowAlertMessage, showCircularProgress, closeCircularProgress} from 'redux/slice'
import { enqueueSuccessSnackbar, enqueueErrorSnackbar, enqueueInfoSnackbar } from 'libs/Snackbar';
//@material-ui components
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Checkbox,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
//Components
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomDatePicker from 'components/CustomInput/CustomDateTimePicker';
import Rssr from '../../../libs/Rssr';
import moment from 'moment';
//Styles
import styles from '../MaintenanceGroupTable.styles';
const useStyles = makeStyles(styles);

//State form maintenance object
const formDataObject = {
  serviceType: -1,
  contract: "",
  plate: '',
  phone: '',
  kilometer: '',
  countryEstate: -1,
  city: -1,
  region: '',
  date1: '',
  date2: '',
  date3: '',
  details: '',
  rName: '',
  rPhone: '',
  rEmail: '',
}

//State form error object
const formMaintenanceErrorsObject = {
  contract: false,
  phone: false,
  kilometer: false,
  estate: false,
  city: false,
  region: false,
  date1: false,
  date2: false,
  date3: false,
  details: false,
  rName: false,
  rPhone: false,
  rEmail: false,
}

const TableColombia = (props) => {
  //Styles
  const classes = useStyles();
  //Translations
  const [t, i18n] = useTranslation('common');
  //States
  const [formData, setFormData] = useState(formDataObject);
  const [formMaintenanceErrors, setformMaintenanceErrors] = useState(formMaintenanceErrorsObject);
  const [cities, setCities] = useState([]);
  //Constants
  const dispatch = useDispatch();
  const regionId = 4;
  const language = useSelector((state) => state.configurations.language);
  const idRow = props.id;
  const columnHeaders = [
    {id: 'serviceType', label: t('maintenance.groupTable.headerServiceType')},
    {id: 'plate', label: t('maintenance.groupTable.headerPlate')},
    {id: 'kilometer', label: t('maintenance.groupTable.headerKilometer')},
    {id: 'countrystate', label: (language == 'es') ? t('maintenance.groupTable.countryestateColombia'):t('maintenance.groupTable.headerCountrystate')},
    {id: 'city', label: (language == 'es') ? t('maintenance.groupTable.cityColombia'):t('maintenance.groupTable.headerCity')},
    {id: 'region', label: (language == 'es') ? t('maintenance.groupTable.regionColombia'):t('maintenance.groupTable.headerRegion')},
    {id: 'date1', label: t('maintenance.groupTable.headerDateOne')},
    {id: 'date2', label: t('maintenance.groupTable.headerDateTwo')},
    {id: 'date3', label: t('maintenance.groupTable.headerDateThree')},
    {id: 'details', label: t('maintenance.groupTable.headerDetails')},
    {id: 'responsalName', label: t('maintenance.groupTable.headerResponsalName')},
    {id: 'responsalPhone', label: t('maintenance.groupTable.headerResponsalPhone')},
    {id: 'responsalEmail', label: t('maintenance.groupTable.headerResponsalEmail')},
  ]

  //Effect used to pass the object update to handleChangeInput
  useEffect( () => {
    props.handleChangeInput(idRow, formData);
  }, [formData]);

  /**
  * Function that searches the informaton of the contract's driver
  * @param {*} newValue
  * @param {*} plateNumber
  * @param {*} contractId
  * @memberof TableColombia
  */
  const getDriver = async (contractId, contractNumber, plateNumber) => {
    //TODO: Checar si se puede obtener rEmail y rPhone en el setFormData de abajo
    dispatch(showCircularProgress());
    axios.get(`UsersDriver/Relation?contractId=${contractId}`)
    .then(async response => {
      dispatch(closeCircularProgress());
      if(response.data.success) {
        if(response.data.total === 1) {
          enqueueInfoSnackbar(dispatch, t('modalTicketBr.infoMessages.1'));
          setFormData({
            ...formData,
            contract: contractNumber,
            plate: plateNumber,
            rName: response.data.users[0].userName,
            rEmail: "", //TODO: Agregar valor de Email en el GET
            rPhone: "" //TODO: Agregar valor de Phone en el GET
          });
        }
        else if(response.data.total > 1) {
          enqueueInfoSnackbar(dispatch, t('modalTicketBr.infoMessages.2'));
          setFormData({
            ...formData,
            contract: contractNumber,
            plate: plateNumber,
          });
        }
      }
      else{
        enqueueInfoSnackbar(dispatch, t('modalTicketBr.infoMessages.3'));
        setFormData({
          ...formData,
          contract: contractNumber,
          plate: plateNumber,
        });
      }
    })
    .catch(error => {
      dispatch(closeCircularProgress());
      if(error.response.status === 401) {
        dispatch(onShowAlertMessage());
      }
    });
  }

  /**
     * Función que detecta cambios en los campos de fecha del formulario de mantenimiento
     * @param {*} date
     * 
     */
  const onChangeFormDate = (date, o) => {

    if (date === null)
      return

    const inputName = date.name;
    const value = date.value;

    if (value !== null) {
      var minDate = moment(Rssr.getMinRequestServiceDate(regionId));
      var diffInHours = value.diff(minDate, 'hours');
      if ((diffInHours <= 12) && (minDate.hours() > value.hours())) {
        setFormData({
          ...formData,
          [inputName]: value.hours(minDate.hours())
        });
      }
      else {
        setFormData({
          ...formData,
          [inputName]: value
        });
      }
    }
    else {
      setFormData({
        ...formData,
        [inputName]: value
      });
    }

  }

  /**
  * Function to change object that gets inputs
  * @param {event} e Event of the input
  * @memberof TableColombia
  */
  const onChangeInputData = (e) => {
    setFormData({
        ...formData,
        [e.target.name]: e.target.value
    })
  }

  /**
   * Function that detects the contract selected and fills respective fields
   * @param {*} e
   * @param {*} newValue
   * @memberof TableColombia
  */
  const onChangeContract = (e, newValue) => {

    if(newValue === null) {
      setFormData({
        ...formData,
        contract: "",
        plate: "",
        rName: "",
        rEmail: "",
        rPhone: ""
      });
      return false
    }

    const contractNumber = newValue.contractNumber;
    const contractId = newValue.contractId;
    const plateNumber = newValue.plateNumber;
    getDriver(contractId, contractNumber, plateNumber);
  }

  /**
  * Function that detects the change on inputs that must accept only numbers.
  * @param {*} e
  * @memberof TableColombia
  */
   const onChangeInputNumber = (e) => {
    const inputName = e.target.name;
    let text = e.target.value;
    let newText = '';
    let numbers = '0123456789';

    for (var i=0; i < text.length; i++) {
      if(numbers.indexOf(text[i]) > -1 ) {
        newText = newText + text[i];
      }
    }

    setFormData({
      ...formData,
      [inputName]: newText
    });
  }

  /**
   * Function that detects the estate selected and fills respective fields
   * @param {*} e
   * @param {*} newValue
   * @memberof TableColombia
  */
  const onChangeEstate = (e, newValue) =>{
    if (newValue === null){
      setFormData({
        ...formData,
        countryEstate: -1,
      });
      return false;
    }

    axios.get(`CityCatalogFMV2?stateId=${newValue.id}&regionId=${regionId}`)
      .then(async response => {
        setCities(response.data.items);
        setFormData({
          ...formData,
          countryEstate: newValue.id,
        });
      })
      .catch((error) => {
        enqueueErrorSnackbar(dispatch, t('maintenance.errors.failConnection'));
      });
  }

  /**
  * Function that detects the city selected and fills respective fields
  * @param {*} e
  * @param {*} newValue
  * @memberof TableColombia
  */
  const onChangeCity = (e, newValue) =>{
    if (newValue === null){
      setFormData({
        ...formData,
        city: -1,
      });
      return false;
    }

    setFormData({
      ...formData,
      city: newValue.id,
    });
  }

  if(props.flgHeader){
    return(
      <TableHead>
        <TableRow>
          <TableCell key={'checkbox'} align='center'>
            <Checkbox
              defaultChecked={false}
            checked={props.checked}
            onChange={() => props.handleCheckBox(0)}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </TableCell>
          {columnHeaders.map((column) => (
            <TableCell key={column.id} align='center' className={classes.tableHeader}>
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }
  else{
    return (
      <TableBody>
        <TableCell key={'checkbox'} align='center'>
          <Checkbox
            defaultChecked={false}
            checked={props.checked}
            onChange={() => props.handleCheckBox(idRow)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </TableCell>
        <TableCell key={'serviceType'} align='center' className={classes.tableCell}>
          <FormControl style={{width: '100%', marginTop: 10}}>
            <InputLabel id="roundTripLabel">{t('maintenance.groupTable.headerServiceType')}</InputLabel>
            <Select
              labelId="serviceSelector"
              id="serviceSelector"
              name="serviceType"
              value={formData.serviceType}
              onChange={(e) => {onChangeInputData(e)}}
              style={{width: '100%'}}
            >
              <MenuItem value={6}> {t('modalTicketGnr.serviceType.0')} </MenuItem>
              <MenuItem value={8}> {(language == 'es') ? t('maintenance.groupTable.tiresColombia'):t('maintenance.groupTable.tires')} </MenuItem>
              <MenuItem value={7}> {t('modalTicketGnr.serviceType.1')} </MenuItem>
              <MenuItem value={24}> {t('maintenance.groupTable.technicomechanicalRevision')} </MenuItem>
            </Select>
          </FormControl>
        </TableCell>
        <TableCell key={'plate'} align='center' className={classes.tableCell}>
          <Autocomplete
            id="combo-box-vehicles"
            size="small"
            freeSolo={true}
            options={props.contracts}
            inputValue={(props.contracts.length > 0)? formData.plate.value : t('modalTicketGnr.noContractMessage')}
            getOptionLabel={(option) => option.plateNumber}
            onChange={(e, newValue) => onChangeContract(e, newValue)}
            renderInput={(params) => <TextField {...params} label={t('modalTicketBr.inputs.17')} margin="normal" />}
          />
        </TableCell>
        <TableCell key={'kilometer'} align='center' className={classes.tableCell}>
          <CustomInput
            id="kilometer"
            labelText={t('modalTicketGnr.inputs.2')}
            error={formMaintenanceErrors.km}
            formControlProps={{
              fullWidth: true
              }}
            inputProps={{
              value: formData.kilometer,
              name: "kilometer",
              onChange: (e) => {onChangeInputNumber(e)}
              }}
          />
        </TableCell>
        <TableCell key={'countryEstates'} align='center' className={classes.tableCell}>
          <Autocomplete
            id="countryEstate"
            size="small"
            disableListWrap={true}
            freeSolo={true}
            options={props.countryEstates}
            inputValue={props.countryEstates.value}
            getOptionLabel={(option) => option.value}
            onChange={(e, newValue) => onChangeEstate(e, newValue)}
            renderInput={(params) => <TextField {...params} label={(language == 'es') ? t('maintenance.groupTable.countryestateColombia'):t('maintenance.groupTable.headerCountrystate')} margin="normal" />}
          />
        </TableCell>
        <TableCell key={'city'} align='center' className={classes.tableCell}>
          <Autocomplete
            id="comboCiudad"
            size='small'
            disableListWrap={true}
            freeSolo={true}
            options={cities}
            inputValue={formData.countryEstate.value}
            getOptionLabel={(option) => option.value}
            onChange={(e, newValue) => onChangeCity(e, newValue)}
            renderInput={(params) => <TextField {...params} label={(language == 'es') ? t('maintenance.groupTable.cityColombia'):t('maintenance.groupTable.headerCity')} className={classes.root} margin="normal" />}
          />
        </TableCell>
        <TableCell key={'region'} align='center' className={classes.tableCell}>
          <CustomInput
            id="region"
            labelText={(language == 'es') ? t('maintenance.groupTable.regionColombia'):t('modalTicketBr.inputs.4')}
            error={formMaintenanceErrors.region}
            formControlProps={{
                fullWidth:true
            }}
            inputProps={{
                value: formData.region,
                name: "region",
                onChange: (e) => {onChangeInputData(e)}
            }}
          />
        </TableCell>
        <TableCell key={'date1'} align='center' className={classes.tableCell}>
          <FormControl fullWidth>
              <CustomDatePicker
                data={formData}
                id="date1"
                labelText={t('modalTicketBr.inputs.5')}
                value={formData.date1}
                onChange={(date) => { onChangeFormDate({ name: "date1", value: date }); }}
                validateMin={Rssr.getMinRequestServiceDate(regionId)} />
          </FormControl>
        </TableCell>
        <TableCell key={'date2'} align='center' className={classes.tableCell}>
          <FormControl fullWidth>
              <CustomDatePicker
                data={formData}
                id="date2"
                labelText={t('modalTicketBr.inputs.6')}
                value={formData.date2}
                onChange={(date) => { onChangeFormDate({ name: "date2", value: date }); }}
                validateMin={Rssr.getMinRequestServiceDate(regionId)} />
          </FormControl>
        </TableCell>
        <TableCell key={'date3'} align='center' className={classes.tableCell}>
          <FormControl fullWidth>
            <CustomDatePicker
              data={formData}
              id="date3"
              labelText={t('modalTicketBr.inputs.7')}
              value={formData.date3}
              onChange={(date) => { onChangeInputData({target: { name: "date3", value: date }}); }}
              validateMin={Rssr.getMinRequestServiceDate(regionId)} />
          </FormControl>
        </TableCell>
        <TableCell key={'details'} align='center' className={classes.tableCell}>
          <TextField
            id="details"
            label={t("modalTicketGnr.inputs.7")}
            placeholder={t("modalTicketGnr.inputs.10")}
            name="details"
            multiline
            value={formData.details}
            onChange={(e) => {onChangeInputData(e)}}
            rows={3}
            className={classes.tableCell}
          />
        </TableCell>
        <TableCell key={'responsalName'} align='center' className={classes.tableCell}>
          <CustomInput
            id="responsalName"
            labelText={t("modalTicketGnr.inputs.8")}
            error={formMaintenanceErrors.rName}
            formControlProps={{
                fullWidth:true
            }}
            inputProps={{
                value: formData.rName,
                name: "rName",
                onChange: (e) => onChangeInputData(e)
            }}
          />
        </TableCell>
        <TableCell key={'responsalPhone'} align='center' className={classes.tableCell}>
          <CustomInput
            id="responsalPhone"
            labelText={t("maintenance.groupTable.placeholderPhoneColombia")}
            error={formMaintenanceErrors.rPhone}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: formData.rPhone,
              name: "rPhone",
              onChange: (e) => onChangeInputNumber(e)
            }}
          />
        </TableCell>
        <TableCell key={'responsalEmail'} align='center' className={classes.tableCell}>
          <CustomInput
            id="responsalEmail"
            labelText={t("modalTicketGnr.inputs.9")}
            error={formMaintenanceErrors.rEmail}
            formControlProps={{
              fullWidth:true
            }}
            inputProps={{
              value: formData.rEmail,
              name: "rEmail",
              onChange: (e) => onChangeInputData(e)
            }}
          />
        </TableCell>
      </TableBody>
    )
  }
}
export default TableColombia;