/**
 * @fileoverview IFRS16 Styles
 * estilos de la vista IFRS16
 * @version 1.0.0
 * @author César Paul Hernández Camacho
 * @date 30/10/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 * 
 */

 const IFRS16Styles = theme => ({
     titleModule: {
        color: "#050B7F", 
        fontWeight: 'bold', 
        fontSize: '1.5rem'
     },
     ifrs16Form: {
        paddingBottom: "10px",
        margin: "27px 0 0 0",
     },
     ifrs16Selector: {
        alignSelf: "center", 
        width: '100%'
     },
     ifrs16Button: {
        backgroundColor: "#0BCBFB",
        color: "#050B7F",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginLeft: 15,
        minWidth: 240
     }
 });

 export default IFRS16Styles;