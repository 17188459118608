/**
 * @fileoverview Table Component for Notifications
 * @version 1.0.0
 * @author Carlos Emilio Blanco Lopez
 * @date 13/12/2022
 * @copyright 2022 Industrias RESSER S.A de C.V
 */
import React, { useState, useEffect, createContext, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
//@material-ui icons
import { MdFilterList, MdPublish } from "react-icons/md";

import IconButton from "@material-ui/core/IconButton";
// core components
import Container from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import CustomTable from "components/Table/CustomTable";
// Styles
import styles from "./Notifications.styles";
//translate
import { useTranslation } from "react-i18next";
import NotificationModal from "./NotificationModal";
import {
  onShowAlertMessage,
  showCircularProgress,
  closeCircularProgress,
} from "redux/slice";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSuccessSnackbar } from "libs/Snackbar";
import { initialState, reducer } from "./notification.reducer";
import axios from "libs/AxiosHelper";
import NotificationFilter from "./Filter/NotificationFilter";
var CryptoJS = require("crypto-js");

const useStyles = makeStyles(styles);
export const DataModulo = createContext();

const NotificationsTable = ({ setDocument, Document }) => {
  const [OpenFilter, setOpenFilter] = useState(false);

  //translations
  const [t, i18n] = useTranslation("common");

  // Columns
  const cols = [
    {
      accessor: "type",
      header: t("Notification.appendix"),
      isNotification: true,
    },
    { accessor: "notificationType", header: t("Notification.category") },
    { accessor: "title", header: t("Notification.title") },
    {
      accessor: "createdDate",
      header: t("Notification.createDate"),
      isDate: true,
    },
    { accessor: "releaseDate", header: t("Notification.date"), isDate: true },
    { accessor: "additionalLink", header: t("Notification.link"), isURL: true },
  ];
  //Disparador de eventos
  const userRole = useSelector((state) => state.auth.loginData.userRole);
  const language = useSelector((state) => state.configurations.language);
  const dispatch = useDispatch();
  // Styles
  const classes = useStyles();

  // states
  const [rows, setrows] = useState([]);
  const [RowTable, setRowTable] = useState([]);
  const [cantItems, setcantItems] = useState(0);
  const [step, setStep] = useState(100);
  const [count, setCount] = useState(0);

  // state Modal
  const [ReducerState, customDispatch] = useReducer(reducer, initialState);
  const [ListNotifications, setListNotifications] = useState([]);
  const [ListCategorias, setListCategorias] = useState([]);
  const [itemsID, setitemsID] = useState([]);

  //Effects
  useEffect(() => GetNotifications(), [step, count]);
  useEffect(() => {
    GetNotificationsCategory();
    GetListNotifications();
  }, []);

  /**
   * @description {useEffect}, Funcion para las traducciones de la columna de Categorias
   */

  useEffect(() => {
    let translateRow = [];
    if (rows.length > 0) {
      rows.map((element, index) => {
        const data = ReducerState.Categories.filter(
          (i) => i.idCategory === element.notificationType
        )[0];
        switch (language) {
          case "es":
            translateRow.push({
              ...rows[index],
              notificationType: data?.descriptionEs,
            });
            break;
          case "en":
            translateRow.push({
              ...rows[index],
              notificationType: data?.descriptionEn,
            });
            break;
          case "pt":
            translateRow.push({
              ...rows[index],
              notificationType: data?.descriptionPt,
            });
            break;
          case "fr":
            translateRow.push({
              ...rows[index],
              notificationType: data?.descriptionFr,
            });
            break;
          default:
            break;
        }
      });
    }
    setRowTable(translateRow);
  }, [ReducerState.Categories, language, rows]);

  const onDelete = (doc) => DeleteNotifications(doc.notificationId);
  const onHandleLimitChange = async (limit) => await setStep(limit);
  const onModal = () => customDispatch({ type: "onModal" });
  const onCloseModal = () => {
    GetNotifications();
    customDispatch({ type: "ChangeStatusUpdate", payload: false });
    onModal();
  };
  const onEdit = (doc) => GetSpecificNotifications(doc.notificationId);

  const changePage = async (page, start) => {
    window.scrollTo(0, 0);
    await setCount(start + 1);
  };

  /**
   * @title getusers
   * @description Función para obtener el catalogo de Usuarios
   */

  const getUsers = (
    FM = userRole === 7 ? true : false,
    Drivers = true,
    Start = 0,
    customerID = "",
    limit = 100
  ) => {
    if (!FM && !Drivers) {
      customDispatch({
        type: "ChangeItemsDnD",
        payload: { items: [], CantItems: 0 },
      });
      dispatch(closeCircularProgress());
      return;
    }
    customDispatch({ type: "ChangeLoad" });
    axios
      .get(
        `UsersCatalog?query=&customerId=${customerID}&isFM=${FM}&isDriver=${Drivers}&start=${Start}&limit=${limit}`
      )
      .then((response) => {
        if (response.data.success) {
          const items = [];
          response.data.items.forEach((element) => {
            if (limit === 100) {
              items.push({ ...element, id: element.id.toString() });
            } else {
              items.push(element.id.toString());
            }
          });
          if (limit === 100) {
            customDispatch({
              type: "ChangeItemsDnD",
              payload: { items: items, CantItems: response.data.total },
            });
          } else {
            customDispatch({ type: "GetItemsID", payload: items });
            setitemsID(items);
          }
          customDispatch({ type: "ChangeLoad" });
        }
      })
      .catch((error) => console.error(error));
  };

  /**
   * @title getUsersUpdate
   * @description Función para obtener el catalogo de Usuarios
   */

  const getUsersUpdate = (data, id) => {
    const FM = userRole === 7 ? true : false;
    axios
      .get(
        `UsersCatalog?query=&customerId=&isFM=${FM}&isDriver=true&start=0&limit=20000`
      )
      .then((response) => {
        if (response.data.success) {
          let Items = [],
            ItemsID = [];
          data.users.forEach((items) => {
            const result = response.data.items.filter(
              (item) => item.id === items
            );
            if (result[0] !== undefined) {
              ItemsID.push(result[0].id.toString());
              Items.push(result[0]);
            }
          });
          customDispatch({
            type: "ChangeDataUpdate",
            payload: {
              ...data,
              notificationId: id,
              ListItems: Items,
              users: ItemsID,
            },
          });
          customDispatch({ type: "ChangeStatusUpdate" });
          dispatch(closeCircularProgress());
          onModal();
        }
      })
      .catch((error) => console.error(error));
  };

  /**
   * @title getUsersFilter
   * @description Función para obtener el catalogo de Usuarios
   */

  const getUsersFilter = (
    query = "",
    isFM = userRole === 7 ? true : false,
    isDrivers = true
  ) => {
    if (!isFM && !isDrivers) {
      customDispatch({
        type: "ChangeItemsDnD",
        payload: { items: [], CantItems: 0 },
      });
      dispatch(closeCircularProgress());
      return;
    }
    customDispatch({ type: "ChangeLoad" });
    const FM = userRole === 7 ? true : false;
    axios
      .get(
        `/UsersCatalog?query=${query}&customerId=&isFM=${FM}&isDriver=true&start=0&limit=20000`
      )
      .then((response) => {
        if (response.data.success) {
          const items = [];
          response.data.items.forEach((element) =>
            items.push({ ...element, id: element.id.toString() })
          );
          customDispatch({
            type: "ChangeItemsDnD",
            payload: { items: items, CantItems: response.data.total },
          });
          customDispatch({ type: "ChangeLoad" });
        }
      })
      .catch((error) => console.error(error));
  };

  /**
   * @title getSpecificNotification
   * @description Función para obtener la data de una notificacion especifica.
   */

  const GetSpecificNotifications = (id) => {
    const url = `/Notifications/Info?query=${id}`;
    dispatch(showCircularProgress());
    axios
      .get(url)
      .then(async (response) => {
        if (response.data.success) {
          const data = response.data.data;
          const fileName = CryptoJS.AES.decrypt(
            data.documentFile,
            "MyALD@Document"
          ).toString(CryptoJS.enc.Utf8);
          convertFileToBase64(fileName)
            .then((base64) => {
              customDispatch({
                type: "GetUpdateFile",
                payload: base64.split(",")[1],
              });
              getUsersUpdate(data, id);
            })
            .catch((error) => console.error(error));
        }
      })
      .catch((error) => dispatch(onShowAlertMessage()));
  };

  /**
   * @title getNotifications
   * @description Función para obtener todas las notificaciones relacionadas a el perfil
   */

  const GetNotifications = (modal = false) => {
    const url = `Notifications?start=${count}&limit=${step}`;
    dispatch(showCircularProgress());
    axios
      .get(url)
      .then(async (response) => {
        if (response.data.success) {
          setrows(response.data.data === null ? [] : response.data.data);
          setcantItems(response.data.total);
          if (ReducerState.FirstGet) {
            customDispatch({ type: "ChangeFirstGet" });
            if (modal) getUsers();
          }
          dispatch(closeCircularProgress());
          return;
        }
        dispatch(closeCircularProgress());
        dispatch(onShowAlertMessage());
      })
      .catch((error) => dispatch(onShowAlertMessage()));
  };

  /**
   * @title getNotificationsFilter
   * @description Función para obtener todas las notificaciones relacionadas a el perfil
   */

  const GetNotificationsFilter = (
    categoryId,
    reference,
    createDate,
    releaseDate
  ) => {
    const url2 = `notifications?start=0&limit=100&categoryId=${
      categoryId === undefined ? "" : categoryId
    }&reference=${
      reference === undefined ? "" : reference
    }&createDate=${createDate}&releaseDate=${releaseDate}`;
    
    const url = `Notifications?start=${count}&limit=${step}`;

    dispatch(showCircularProgress());
    axios
      .get(url2)
      .then(async (response) => {
        if (response.data.success) {
          setrows(response.data.data === null ? [] : response.data.data);
          setcantItems(response.data.total);
          if (ReducerState.FirstGet) {
            customDispatch({ type: "ChangeFirstGet" });
          }
          dispatch(closeCircularProgress());
        }
      })
      .catch((error) => dispatch(onShowAlertMessage()));
  };

  /**
   * @title getNotificationsCategory
   * @description Función para obtener todas las categorias relacionadas por region
   */

  const GetNotificationsCategory = () => {
    const url = `/Notifications/Category`;
    dispatch(showCircularProgress());
    axios
      .get(url)
      .then(async (response) => {
        if (response.data.success) {
          setListCategorias(response.data.data);
          customDispatch({
            type: "GetCategories",
            payload: response.data.data,
          });
        }
      })
      .catch((error) => dispatch(onShowAlertMessage()));
  };

  /**
   * @title GetListNotifications
   * @description Función para la obtencion del listado de notificaciones ya creada
   */

  const GetListNotifications = () => {
    const url = "/NotificationsCatalog";
    axios.get(url).then(async (response) => {
      if (response.data.success) {
        setListNotifications(response.data.data);
      }
    });
  };

  /**
   * @title PutNotifications
   * @description Función para la eliminacion de notificaciónes
   * @param { int } id
   */

  const DeleteNotifications = async (id) => {
    dispatch(showCircularProgress());
    axios
      .put(`Notifications/Delete?idNotification=${id}`)
      .then((response) => {
        if (response.data.success) {
          dispatch(closeCircularProgress());
          enqueueSuccessSnackbar(dispatch, t("Notification.AlertsMassage.1"));
          GetNotifications();
        }
      })
      .catch((error) => dispatch(onShowAlertMessage()));
  };

  /**
   * @title onView
   * @description Funcion para visualizar el PDF
   * @param {string} item
   */

  const onView = (item) => {
    const fileName = CryptoJS.AES.decrypt(
      item.documentFile,
      "MyALD@Document"
    ).toString(CryptoJS.enc.Utf8);
    convertFileToBase64(fileName)
      .then((base64) => {
        setDocument({
          plate: item.title,
          base64: base64.split(",")[1],
        });
      })
      .catch((error) => console.error(error));
  };

  const convertFileToBase64 = async (url) => {
    const headers = new Headers({
      "Cache-Control": "no-cache",
    });

    const response = await fetch(url, { headers });
    const fileBlob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = reject;
    });
  };

  return (
    <div className={classes.contractTable}>
      <Container direction="row" item xs={12}>
        <Button
          onClick={onModal}
          className={classes.uploadButtonStyle}
          aria-label="edit"
          size="xs"
          startIcon={<MdPublish />}
        >
          {t("Notification.newNotification")}
        </Button>
        <IconButton
          style={{ alignSelf: "flex-end" }}
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={() => setOpenFilter(true)}
        >
          <MdFilterList />
        </IconButton>
      </Container>
      <CustomTable
        cols={cols}
        rows={RowTable}
        totalRows={cantItems}
        actions
        delAction
        updateAction
        onView={(doc) => onView(doc)}
        onEdit={(doc) => {
          onEdit(doc);
        }}
        onDelete={(item) => {
          onDelete(item);
        }}
        handleLimitChange={(limit) => {
          onHandleLimitChange(limit);
        }}
        handlePageChange={(page, start) => {
          changePage(page, start);
        }}
      />

      <DataModulo.Provider value={{ ReducerState, customDispatch }}>
        <NotificationModal
          onClose={onCloseModal}
          GetUsers={getUsers}
          GetUsersFilter={getUsersFilter}
        />
        <NotificationFilter
          open={OpenFilter}
          GetNotifications={GetNotificationsFilter}
          onClose={() => setOpenFilter(!OpenFilter)}
          ListNotifications={ListNotifications}
        />
      </DataModulo.Provider>
    </div>
  );
};

export default NotificationsTable;
