/**
 * @fileoverview Modal para mostrar los archivos/attachment de a la tabla de SAT
 * @version 1.0.0
 * @author Leslie Mosqueda
 * @date 03/07/2024
 * @copyright 2024 RESSER S.A de C.V
 */
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { Box, IconButton, CardMedia, Grid, Stack, Typography, Skeleton, Divider } from '@mui/material';
import { MdArrowBackIos, MdArrowForwardIos, MdRefresh, MdSaveAlt } from 'react-icons/md';
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import { MSGReader } from 'wl-msg-reader';

import { Context } from '../Table';
import Modal from './index';
import { makeStyles } from '@material-ui/core/styles';

//Hooks
import useSAT from 'views/SAT/useSAT';
//Styles
import styles from './styles';
import PDFError from "../../../assets/img/Icons/pdfError.svg";
//Components
const useStyles = makeStyles(styles);

const ModalAttachment = ({ OnEdit, OnComments, OnHistory, OnAttachment, DataAgreements }) => {
    const { t } = useTranslation('common');
    const classes = useStyles();
    const { state } = React.useContext(Context);
    const [base64Attachment, setBase64Attachment] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [MsgData, setMsgData] = useState({
        body: '',
        subject: '',
        from: '',
        to: '',
    })
    const { GetAttachmentId, loading, setLoading } = useSAT();
    const limit = state?.Attachment?.length;

    //Keep track of current page number
    const pageChange = (num) => {
        setLoading(true);
        setPageNumber(num);
    }

    const handleGetDocumentBase64 = () => {
        GetAttachmentId(state?.client?.clientNumber, state?.Attachment?.[pageNumber - 1]?.AttachmentId, setBase64Attachment)
    };

    useEffect(() => {
        if (state.ModalAttachment === true) {
            setBase64Attachment([]);

            handleGetDocumentBase64();
        } else {
            setBase64Attachment([]);
            setPageNumber(1);
        }
    }, [state.ModalAttachment, pageNumber]);

    useEffect(() => {
        if (state?.Attachment[pageNumber - 1]?.Extension === "msg" && base64Attachment.length > 0) {
            ViewMSG(base64Attachment);
        }
    }, [base64Attachment]);

    /**
     * @function ViewMSG
     * @description Función que muestra un mensaje en caso de que el archivo sea de tipo .msg
     */

    const ViewMSG = (date64) => {

        const byteCharacters = atob(date64);
        const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
        const byteArray = new Uint8Array(byteNumbers);

        const msgReader = new MSGReader(byteArray);

        const msgFile = msgReader.getFileData();

        setMsgData({
            body: msgFile.body,
            subject: msgFile.subject,
        })
    }


    const downloadAttachment = (data64) => {
        const name = state?.Attachment[pageNumber - 1]?.FileName.split(".");
        const fullName = name[0] + "." + state?.Attachment[pageNumber - 1]?.Extension;

        var binaryString = atob(data64);
        var bytes = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        var arrBuffer = bytes.buffer;;
        var newBlob = new Blob([arrBuffer]);

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        // For other browsers: Create a link pointing to the ObjectURL containing the blob.
        var data = window.URL.createObjectURL(newBlob);

        var link = document.createElement('a');
        document.body.appendChild(link); //required in FF, optional for Chrome
        link.href = data;
        link.download = fullName;
        link.click();
        window.URL.revokeObjectURL(data);
        link.remove();
    };

    // Función para determinar el estilo basado en los caracteres especiales
    const getStyleForCharacter = (char) => {
        // Ejemplo básico: aplicar diferentes estilos para diferentes caracteres especiales
        switch (char) {
            case '!':
                return { color: 'red' };
            case '?':
                return { fontStyle: 'italic' };
            case '\t': // Tabulación
                return { marginLeft: '20px' };
            case ' ': // Espacio en blanco
                return { }; // Ejemplo de estilo para espacio en blanco
            default:
                return { fontSize: 14, color: '#828282', fontFamily: 'Source Sans Pro', }; // Estilo por defecto
        }
    };

    // Función para renderizar cada carácter con su estilo correspondiente
    const renderFormattedText = (text) => {
        return Array.from(text).map((char, index) => {
            if (char === '\n') {
                return <br key={index} />; // Renderiza un <br> para saltos de línea
            }
            return <span key={index} style={{ ...getStyleForCharacter(char) }}>{char}</span>;
        });
    };

    return (
        <Modal open={state.ModalAttachment} OnComments={() => OnComments(DataAgreements)} OnEdit={() => OnEdit(DataAgreements)}
            OnHistory={() => OnHistory(DataAgreements)} OnAttachment={() => OnAttachment(DataAgreements)}>

            <section style={{ width: '90%', }}>
                {state.Attachment != null && <>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item xs={7} md={7}>
                            <Typography nowrap
                                style={{
                                    fontSize: 16, color: '#828282',
                                    fontFamily: 'Source Sans Pro',
                                    whiteSpace: 'nowrap',
                                    overflow: ' hidden',
                                    textOverflow: 'ellipsis',
                                    width: '80%'
                                }}>
                                {state?.Attachment[pageNumber - 1]?.FileName}
                            </Typography>
                        </Grid>
                        <Grid item container xs={5} md={5} direction="row" flexWrap={"nowrap"} justifyContent={'flex-end'}>
                            {state.Attachment.length > 1 &&
                                <Stack direction="row" spacing={1} alignItems={'center'} mr='5px'>
                                    <Typography style={{ flexWrap: 'nowrap', fontSize: 16, color: '#828282', fontFamily: 'Source Sans Pro', }}>{pageNumber} de {limit}</Typography>
                                    <IconButton
                                        aria-label="Back"
                                        component="span"
                                        size="small"
                                        disabled={pageNumber === 1 || loading}
                                        onClick={() => pageChange(pageNumber > 1 && pageNumber - 1)} >
                                        <MdArrowBackIos style={{ color: "#828282", fontSize: "0.875rem" }} />
                                    </IconButton>
                                    <IconButton
                                        aria-label="Forward"
                                        component="span"
                                        size="small"
                                        disabled={pageNumber === limit || loading}
                                        onClick={() => pageChange(pageNumber < limit && pageNumber + 1)}   >
                                        <MdArrowForwardIos style={{ color: "#828282", fontSize: "0.875rem" }} />
                                    </IconButton>
                                </Stack>
                            }

                            <IconButton
                                color="primary"
                                aria-label=""
                                component="span"
                                className={classes.saveButton}
                                disabled={base64Attachment.length === 0 || base64Attachment.Message}
                                onClick={() => downloadAttachment(base64Attachment)}>
                                <MdSaveAlt />
                            </IconButton>

                            {base64Attachment.Message &&
                                <IconButton
                                    color={"primary"}
                                    aria-label=""
                                    component="span"
                                    style={{
                                        backgroundColor: "#e0e0e0",
                                        color: "rgba(0, 0, 0, 0.87)",
                                        marginLeft: '5px'
                                    }}
                                    onClick={handleGetDocumentBase64}>
                                    <MdRefresh />
                                </IconButton>
                            }
                        </Grid>
                    </Grid>

                    <PerfectScrollbar style={{ position: "relative", height: state?.Attachment[pageNumber - 1]?.Extension === "msg" ? "85%" : "90%", marginTop: state?.Attachment[pageNumber - 1]?.Extension === "msg" ? 20 : 10 }}>
                        <Grid container direction="column" alignContent={'center'} mt={2} mb={1} >
                            {loading ? <Skeleton animation='wave' width={'100%'} height={'500px'} sx={{ transformOrigin: '0 20%' }} />
                                : base64Attachment.Message ?
                                    <Typography my={2} style={{ flexWrap: 'wrap', fontSize: 16, color: '#828282', fontFamily: 'Source Sans Pro', }}>
                                        {t('ModuleAgreements.alerts.errorFile')}
                                    </Typography>
                                    :
                                    state?.Attachment[pageNumber - 1]?.Extension == "jpeg" ||
                                        state?.Attachment[pageNumber - 1]?.Extension == "png" ||
                                        state?.Attachment[pageNumber - 1]?.Extension == "jpg" ?
                                        <CardMedia
                                            component="img"
                                            sx={{ maxHeight: 700, mt: 3, mb: 4 }}
                                            src={`data:image/${state?.Attachment[pageNumber - 1]?.Extension};base64,${base64Attachment}`}
                                            title="Imagen"
                                            alt='attachment'
                                        />
                                        : state?.Attachment[pageNumber - 1]?.Extension === "pdf" ?
                                            <Document file={`data:application/pdf;base64,${base64Attachment}`}
                                                error={
                                                    <Box
                                                        sx={{
                                                            padding: '15px',
                                                            paddingX: '50px',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                        }}>
                                                        <img src={PDFError}
                                                            width={"65px"}
                                                            height={"65px"}
                                                            style={{
                                                                margin: '15px',
                                                                marginTop: "88px"
                                                            }} />
                                                        <Typography
                                                            variant='h6'
                                                            mt={1} mb={11}
                                                            color={"#050b7f"}
                                                            fontFamily={'Source Sans Pro'}>
                                                            {t('ModuleAgreements.alerts.errorFile')}
                                                        </Typography>
                                                    </Box>
                                                }>
                                                <Page pageNumber={1} />
                                            </Document>
                                            : state?.Attachment[pageNumber - 1]?.Extension === "mp4" ?
                                                <ReactPlayer controls={true} muted playing width='97%' height='75vh'
                                                    url={`data:video/mp4;base64,${base64Attachment}`} />
                                                : state?.Attachment[pageNumber - 1]?.Extension === "msg" ?
                                                    <section style={{ width: '100%' }}>
                                                        <Typography style={{ flexWrap: 'wrap', fontSize: 16, color: '#828282', fontFamily: 'Source Sans Pro', fontWeight: 'bold' }}>
                                                            {MsgData.subject}
                                                        </Typography>
                                                        <Divider />
                                                        <Typography style={{ marginTop: 20 }}>
                                                            {renderFormattedText(MsgData.body)}
                                                        </Typography>
                                                    </section>
                                                    :
                                                    <Typography style={{ flexWrap: 'wrap', fontSize: 16, color: '#828282', fontFamily: 'Source Sans Pro', }}>
                                                        Sin previa descarga el archivo ...
                                                    </Typography>
                            }
                        </Grid>
                    </PerfectScrollbar>
                </>}
            </section>
        </Modal>
    )
}

export default ModalAttachment