/**
 * @fileoverview GlobeBoxTable
 * @version 1.1
 * @author Carlos Emilio Blanco Lopez
 * @date 27/10/2022
 * @update 02/02/2023
 * @copyright 2023 Industrias RESSER S.A de C.V
 */
import React, { useState, useEffect } from 'react';
//@material-ui components
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
//@material-ui icons
import {MdFilterList} from 'react-icons/md';
// core components
import Container from "components/Grid/GridContainer.js";
//translate
import { useTranslation } from 'react-i18next';
//axios
import axios from 'libs/AxiosHelper';
//React Table
import CustomTable from "components/Table/CustomTable";
//redux actions
import { showCircularProgress, closeCircularProgress, onShowAlertMessage } from 'redux/slice';
import { enqueueInfoSnackbar } from 'libs/Snackbar';
import { useSelector, useDispatch } from 'react-redux';
//styles
import styles from '../Documents.styles';
import GlobeBoxModal from './GlobeBoxModal';
import GlobeBoxFilter from './GlobeBoxFilter';

const useStyles = makeStyles(styles);

const cleanObj = {
    contract: '',
    contractObj: {},
    plate: '',
    plateObj: {},
    customer: '',
    customerObj: {}
};

const GlobeBoxTable = ({setListDocs}) => {

    const dispatch = useDispatch();

    const userRole = useSelector(state => state.auth.loginData.userRole);
    const [FilterObj, setFilterObj] = useState(cleanObj);
    const [Documents, setDocuments] = useState([]);
    const [TotalDocs, setTotalDocs] = useState(0);
    const [Contracts, setContracts] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [step, setStep] = useState(100);
    const [ModalUpload, setModalUpload] = useState(false);
    const [InfoUpload, setInfoUpload] = useState('');
    const [FilterModal, setFilterModal] = useState(false);
    const [ListDocsType, setListDocsType] = useState([]);
    const [DocID, setDocID] = useState(0);
    const [Clients, setClients] = useState([]);
    const [Docs, setDocs] = useState([]);
    const [IsUpdate, setIsUpdate] = useState(false);
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');

    const cols = [
        { accessor: "type", header: t('documents.headers.0'), isDate: false, isGloveBox: true },
        { accessor: "PlateNumber", header: t('contracts.tableColumns.LicensePlate'), isDate: false },
        { accessor: "ContractNumber", header: t('contracts.tableColumns.ContractNumber'), isDate: false },
        { accessor: "CustomerName", header: t('contracts.tableColumns.Client'), isDate: false },
        { accessor: "CustomerNumber", header: t('contracts.tableColumns.ClientNumber'), isDate: false },
    ];

    /**
     * Efecto para obtencion de todos los datos para el filtro
     */

    useEffect(() => {
        getClient();
        getContracts();
    }, []);

    useEffect(() => {
        getDocuments();
    }, [count]);
    

    /**
     * Efecto para el apoyo de filtro al momento de vaciar un campo
     */
    
    useEffect(() => {
        if (FilterObj.contractObj === null || FilterObj.plateObj === null || FilterObj.customerObj === null) {
            getDocuments(true, FilterObj);
        }
    }, [FilterObj]);

    useEffect(() => {
        axios.get('GloveBoxDocuments').then(response => {
            const items = response.data.items;
            let ListDocs = [], ListDescription = [];
            items.forEach(res => {
                ListDocs.push(res.DocumentType);
                ListDescription.push(res.Description);
            });
            setListDocs(ListDescription);
            setListDocsType(ListDocs);
            setDocs(items);
        }).catch(error => { });
        getDocuments();
    }, [step]);

    /**
     * Function to handle when page is changed
     * @param {object} params object to handle when page is changed
     * @memberof ContractTable
     */

    const changePage = async (page, start) => {
        window.scrollTo(0, 0);
        await setPage(page);
        await setCount(start === -1 ? 0 : start);
    }

    /**
     * Function to handle limit change
     * @param {int} limit limit of contracts to get
     * @memberof ContractTable
     */

    const onHandleLimitChange = async (limit) => {
        await setPage(0);
        await setStep(limit);
    }

    /**
    * @title getClients
    * @description Función para obtener todos los clientes relacionados al filtro.
   */

    const getClient = () => {
        if (userRole === 7) {
            axios.get(`clientsFMV2/Catalog?customerName=&start=0&limit=12000`)
                .then(response => {
                    if (response.data.success) {
                        setClients(response.data.items);
                    }
                })
                .catch(error => {
                    dispatch(onShowAlertMessage());
                });
        }
        else {
            axios.get(`clientsFMV2`)
                .then(response => {
                    if (response.data.success) {
                        setClients(response.data.items);
                    }
                })
                .catch(error => {
                    dispatch(onShowAlertMessage());
                });
        }
    }

    /**
    * @title getContracts
    * @description Función para obtener todos los contratos relacionados al filtro.
   */

    const getContracts = () => {
        dispatch(showCircularProgress());
        axios.get('ContractsFleetManager')
            .then(async response => {
                if (response.data.success) {
                    const contracts = [];
                    setTotalDocs(response.data.total);
                    response.data.contracts.forEach(element => {
                        let elementAux = {};
                        elementAux = {
                            ...element,
                            plateNumber: element.plateNumber.replaceAll(' ', '')
                        }
                        contracts.push(elementAux);
                    })
                    await setContracts(response.data.contracts);
                }
            })
            .catch(error => {
                if (error.response.status === 401) {
                    dispatch(onShowAlertMessage());
                }
            });
    }

    /**
     * @title getDocuments
     * @description Función para obtener todos los contratos relacionados a gloveBox
     * @param { boolean } filters parametro para detectar si la solicitud se realizara con un filtro
     * @param { object } data parametro para obtener todos los datos a enviar en el filtro
    */

    const getDocuments = (filters = false, data) => {
        var url = `GloveBoxDocuments/All?contract=&plate=&customer=&start=${count}&limit=${step}`;
        if (filters) {
            dispatch(showCircularProgress());
            url = `GloveBoxDocuments/All?contract=${data.contract}&plate=${data.plate}&customer=${data.customer}&start=${count}&limit=${step}`
        }
        axios.get(url).then(response => {
            if (response.data.success) {
                if (response.data.total > 0) {
                    setDocuments(response.data.items);
                }
                else {
                    enqueueInfoSnackbar(dispatch, t('gloveBox.alertMessages.2'))
                    setDocuments([]);
                    setTotalDocs(0);
                }
                dispatch(closeCircularProgress());
            }
            else {
                dispatch(onShowAlertMessage());
            }
            if (filters)
                dispatch(closeCircularProgress());
        }).catch(error => {
            dispatch(closeCircularProgress());
            dispatch(onShowAlertMessage());
        });
    }

    /**Fragment */

    return (
        <>
            <div className={classes.contractTable} >
                <Container direction="row" item xs={12} >
                    <IconButton
                        style={{ alignSelf: 'flex-end' }}
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={() => setFilterModal(true)}
                    >
                        <MdFilterList />
                    </IconButton>
                </Container>
                <CustomTable
                    cols={cols}
                    rows={Documents}
                    handleLimitChange={(limit) => { onHandleLimitChange(limit) }}
                    handlePageChange={(page, start) => { changePage(page, start) }}
                    handleOpenModal={(table, id, isUpdate) => {
                        setDocID(id);
                        setInfoUpload(table);
                        setModalUpload(true);
                        setIsUpdate(isUpdate);
                    }}
                    isGloveBox
                    ListDocsType={ListDocsType}
                    ListDocs={Docs}
                    totalRows={TotalDocs}
                />
            </div>
            <GlobeBoxFilter
                setFilterModal={setFilterModal}
                getDocuments={getDocuments}
                setFilterObj={setFilterObj}
                rows={Contracts}
                FilterModal={FilterModal}
                FilterObj={FilterObj}
                clients={Clients}
            />
            <GlobeBoxModal
                setOpenModal={setModalUpload}
                getDocuments={getDocuments}
                FilterDocumentsObj={FilterObj}
                info={InfoUpload}
                rows={Docs}
                OpenModal={ModalUpload}
                id={DocID}
                isUpdate={IsUpdate}
                setIsUpdate={setIsUpdate}
                GloveBoxTypeID={ListDocsType} />
        </>
    )
}

export default GlobeBoxTable