/**
 * @fileoverview Sidebar
 * @version 2.0.0
 * @author César Paul Hernández Camacho
 * @date 30/08/2021
 * @copyright 2020 Industrias RESSER S.A de C.V
 */
/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Category from '../UI/Category/Category';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { Skeleton } from "@material-ui/lab";
//redux
import { useSelector } from 'react-redux';
//Icons
import IconHome from '../../assets/img/Icons/home.svg';
import {MdMenu} from 'react-icons/md';
import IconDocuments from '../../assets/img/Icons/documentos.svg';
import IconFlota from '../../assets/img/Icons/Flota.svg';
//Translations
import { useTranslation } from "react-i18next";

import PerfectScrollbar from 'react-perfect-scrollbar';

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import useStylesLoading from "./SideBar.styles";
import './sidebar.css';
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {

  const { routes, SidebarCollapse } = props;
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [t] = useTranslation('common');
  const classes = useStyles();
  let history = useHistory();

  const categories = useSelector(state => state.categories.categories);
  const modules = useSelector(state => state.modules.modules);
  const showFixedDrawer = useSelector(state => state.configurations.showFixedDrawer);
  const isLoading = useSelector(state => state.loadingMask.showSidebarMask);

  //const variables
  const screenWidth = window.screen.width;

  let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;

  const handleClick = (Open) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(Open);
  };

  /**
   * Función encargada de realizar la creación del menú lateral con sus categorias y módulos
   */
  const renderSideMenu = () => {

    //Ordenamiento de categorias
    const cat = categories;

    return cat.map((category, key) => {
      //Filtro de módulos relacionados a la categoria a crear
      let modulesCategory = modules.filter(module => {
        if (module.categoryId === category.id) {
          return true;
        }
      });

      return (
        <>
          {SidebarCollapse && (
            <Popper open={open} anchorEl={anchorEl} placement="right" transition >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350} >
                  <Paper style={{ marginLeft: 30 }} elevation={0} >
                    <div className='Sidebar_Popper'>
                      {t('dashboardNames.9')}
                    </div>
                  </Paper>
                </Fade>
              )}
            </Popper>
          )}
          <Category
            SidebarCollapse={zoom < 200 && props.SidebarCollapse}
            categoryId={category.id}
            modules={modulesCategory}
            routes={routes}
            isLoading={isLoading}
            onClick={() => {
              if ((!showFixedDrawer) || (screenWidth < 960)) {
                props.handleDrawerToggle()
              };
            }} />
        </>
      )
    });
  }

  const Classes = SidebarCollapse ? classes.drawerPaperCollapse : classes.drawerPaper;

  var drawer = (
    <Drawer
      variant="temporary"
      anchor="left"
      open={props.open}
      style={{ display: "flex" }}
      classes={{
        paper: classNames(classes.drawerPaper, {
          [classes.drawerPaperRTL]: true
        })
      }}
      onClose={props.handleDrawerToggle}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
    >
      <PerfectScrollbar>
        <div className={"Sidebar_content"}>
          <div className="Sidebar_content_home">
            {
              isLoading ?
                <Skeleton animation='wave' width={80} style={{ marginLeft: '-16px' }} /> :
                <p onClick={() => history.push("/admin/home")}><img src={IconHome} /> {t('dashboardNames.9')}</p>
            }
          </div>
          {renderSideMenu()}
        </div>
      </PerfectScrollbar>
    </Drawer>
  );

  return (
    <div>
      <Hidden mdUp implementation="css">
        {drawer}
      </Hidden>
      <Hidden smDown implementation="css">
        {(showFixedDrawer) ?
          <Drawer
            anchor="left"
            variant="permanent"
            open
            classes={{
              paper: classNames(Classes, {
                [classes.drawerPaperRTL]: props.rtlActive
              })
            }}
          >
            {
              isLoading ?
                <LoadingSideBar /> :
                (
                  <>
                    <div className={SidebarCollapse ? "SidebarCollapse_button" : "Sidebar_button"}> <MdMenu color="#050b7f" onClick={() => { props.handleDrawerCollapseToggle() }} className="Icon" /> </div>

                    <div className={SidebarCollapse ? "SidebarCollapse_content" : "Sidebar_content"}>

                      <div className={SidebarCollapse ? "SidebarCollapse_content_home" : "Sidebar_content_home"}>
                        {
                          isLoading ?
                            <Skeleton animation='wave' width={80} style={{ marginLeft: '-16px' }} /> :
                            <p onClick={() => history.push("/admin/home")}>
                              <img src={IconHome}
                                onMouseEnter={handleClick(true)}
                                onMouseLeave={handleClick(false)} />
                              {!SidebarCollapse && t('dashboardNames.9')}</p>
                        }
                      </div>
                      {renderSideMenu()}
                    </div>
                  </>
                )

            }



          </Drawer>
          :
          drawer
        }
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};


/**
 * @Componente LoadingSideBar
 * @description Componente encargado de mostrar el esqueleto de la barra lateral
 */

const LoadingSideBar = () => {

  const classes = useStylesLoading();
  const [t] = useTranslation('common');

  return (
    <div className={classes.LoadingMask}>

      <div className={false ? "SidebarCollapse_button" : "Sidebar_button"}> <MdMenu color="#050b7f" className="Icon" /> </div>
      <p className={classes.Principal}>Minha Frota</p>

      <div className={classes.Section}>
        <div className={classes.ModuleName} style={{borderBottom: '2px solid #050B7F'}}>
          <p className={classes.LabelStyle}><img src={IconHome} className={classes.ImgStyle} /> {t('dashboardNames.9')}</p>
        </div>
      </div>

      <div className={classes.Section} style={{marginTop: 25}}>
        <div className={classes.ModuleName}>
          <p className={classes.LabelStyle}><img src={IconDocuments} className={classes.ImgStyle} /> {t('dashboardNames.8')}</p>
        </div>
      </div>

      <div className={classes.Section}>
        <div className={classes.ModuleName} style={{borderBottom: '2px solid #050B7F'}}>
          <p className={classes.LabelStyle}><img src={IconFlota} className={classes.ImgStyle} /> {t('dashboardNames.8')}</p>
        </div>
      </div>

      <div className={classes.Section} style={{marginTop: 25}}>
        <div className={classes.ModuleName}>
          <p className={classes.LabelStyle}><img src={IconDocuments} className={classes.ImgStyle} /> {t('dashboardNames.8')}</p>
        </div>
      </div>

      <div className={classes.Section}>
        <div className={classes.ModuleName} style={{borderBottom: '2px solid #050B7F'}}>
          <p className={classes.LabelStyle}><img src={IconFlota} className={classes.ImgStyle} /> {t('dashboardNames.8')}</p>
        </div>
      </div>

      <div className={classes.SectionLoading}>
        <div className={classes.Loadinginfo}>
          <CircularProgress />
          <p className="labelLoading">{t('generalText.loadingSidebar')}</p>
        </div>
      </div>

    </div>
  )
}