/**
 * @fileoverview Configuration Slice by Authorization
 * @version 1.0
 * @author Carlos Emilio Blanco Lopez
 * @date 15/08/2023
 * @copyright 2023 Industrias RESSER S.A de C.V
 **/

import { createSlice } from '@reduxjs/toolkit';
import axios from 'libs/AxiosHelper';
import Cookies from 'js-cookie';

const initialState = {
    isLoggingIn: false,
    isLoggingOut: false,
    isVerifying: false,
    loginError: false,
    logoutError: false,
    isAuthenticated: false,
    userData: {},
    loginData: {},
    rememberPassword: false
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginUser: (state, action) => {
            state.isAuthenticated = true;
            state.isLoggingIn = false;
            state.loginError = false;
            state.userData = action.payload;
        },
        logoutUser: (state) => {
            Cookies.remove('token');
            Cookies.remove('userData');
            state.isAuthenticated = false;
            state.isLoggingOut = false;
            state.logoutError = false;
            state.userData = {};
            state.loginData = {};
        },
        verifyAuth: (state) => {
            const userData = Cookies.get('userData');

            state.isVerifying = true;

            setTimeout(() => {
                axios.get('Test')
                    .then(async response => {
                        if (await response.status !== 200) {
                            axios.getToken(userData)
                                .then(async response => {
                                    if (await response.status !== 200) {

                                        Cookies.remove('token');
                                        Cookies.remove('userData');

                                        state.isAuthenticated = false;
                                        state.isLoggingOut = false;
                                        state.logoutError = false;
                                        state.userData = {};
                                        state.loginData = {};
                                    }
                                });

                            axios.get('login?code=2')
                                .then(async response => {
                                    if (await response.data.Success) {
                                        await axios.getToken(userData, response.data.UserRole);
                                        state.isAuthenticated = true;
                                        state.isLoggingIn = false;
                                        state.loginError = false;
                                        state.userData = response.data;
                                    }
                                });
                        }
                    });
            }, (1500));
            state.isVerifying = false;
        },
        setUserDataR: (state, action) => {
            state.userData = action.payload;
        },
        setLoginData: (state, action) => {
            const data = action.payload;
            state.loginData = {
                ...state.loginData,
                regionId: data.regionId,
                userId: data.userId,
                userRole: data.userRole,
                SignedPolicies: data.SignedPolicies,
            };
        },
        setSignedPolicies: (state, action) => {
            const data = action.payload;
            state.loginData = {
                ...state.loginData,
                SignedPolicies: data,
            };
        },
        setRememeberUserData: (state, action) => {
            state.rememberPassword = action.payload;
        }
    }
});

export const {
    loginUser,
    logoutUser,
    verifyAuth,
    setUserDataR,
    setLoginData,
    setRememeberUserData,
    setSignedPolicies
} = authSlice.actions;

export default authSlice.reducer;
