/**
 * @fileoverview Filter
 * @version 1.0
 * @author Carlos Emilio Blanco Lopex
 * @date 01/06/2023
 * @copyright 2023 Industrias RESSER S.A de C.V
 */
import React from 'react';

import Container from 'components/Grid/GridContainer.js';
import Item from "components/Grid/GridItem.js";
import CustomAutocomplete from 'components/CustomInput/CustomAutocomplete.jsx';
import Button from "components/CustomButtons/Button.js";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Grid, TextField } from '@mui/material';
import CustomInput from "components/CustomInput/CustomInput.js";

// Styles
import { makeStyles } from '@material-ui/core/styles';
import styles from './Filter.styles.js'

//translate
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(styles);

/**
 * @title FilterContract
 * @description Componente para filtrar los contratos
 * @param {Object} props - component props
 * @param {Array} props.contractsFilter - contracts list
 * @param {Object} props.filters - filters data
 * @param {Function} props.onChangeAutocomplete - function to handle autocomplete changes
 * @param {Array} props.clients - clients list
 * @param {Function} props.onChangeFiltersData - function to handle form data changes
 * @param {Array} props.contracts - contracts list
 * @param {Function} props.onCleanFilters - function to clean filters
 * @param {Function} props.onSetFilters - function to set filters
 * @returns component view
 */

export const FilterContract = ({
    contractsFilter,
    filters,
    onChangeAutocomplete,
    clients, // TO-DO: Se obtentra desde el API al inicio de la aplicacion
    onChangeFiltersData,
    contracts, // TO-DO: Se obtentra desde el API al inicio de la aplicacion
    onCleanFilters,
    onSetFilters
}) => {
    //styles
    const classes = useStyles();
    //translations
    const [t, i18n] = useTranslation('common');

    return (
        <>
            <Container xs={12} md={12} className={classes.containerRow}>
                <Item xs={12} md={12} alignSelf="flex-start" >
                    <p style={{ color: "#050B7F", fontWeight: 'bold', fontSize: '1.5rem' }}> {t('contracts.filters.title')} </p>
                </Item>
            </Container>
            <Container xs={12} md={12} className={classes.containerRow}>
                <Item xs={12} md={6} className={classes.box}>
                    <FormControl fullWidth>
                        <CustomAutocomplete
                            options={contractsFilter}
                            value={filters.contract}
                            onChange={onChangeAutocomplete}
                            id="contract"
                        />
                    </FormControl>
                </Item>
            </Container>
            <Container xs={12} md={12} className={classes.containerRow}>
                <Item xs={12} md={6} className={classes.box}>
                    <FormControl fullWidth>
                        <CustomAutocomplete
                            options={clients}
                            value={filters.client}
                            onChange={onChangeAutocomplete}
                            id="client"
                        />
                    </FormControl>
                </Item>
                <Item xs={12} md={6} className={classes.box}>
                    <FormControl fullWidth>
                        <CustomAutocomplete
                            options={contractsFilter}
                            value={filters.plates}
                            onChange={onChangeAutocomplete}
                            id="plates"
                        />
                    </FormControl>
                </Item>
            </Container>
            <Container xs={12} md={12} className={classes.containerRow}>
                <Item xs={12} md={6} className={classes.box}>
                    <FormControl fullWidth>
                        <CustomAutocomplete
                            options={contracts}
                            value={filters.noSerie}
                            onChange={onChangeAutocomplete}
                            id="noSerie"
                        />
                    </FormControl>
                </Item>
                <Item xs={12} md={6} className={classes.box}>
                    <FormControl fullWidth>
                        <InputLabel id="StatusLabel">{t('contracts.filters.status')}</InputLabel>
                        <Select
                            labelId='StatusLabel'
                            fullWidth
                            label={t('contracts.filters.status')}
                            name="status"
                            value={filters.status}
                            onChange={onChangeFiltersData}
                        >
                            <MenuItem value={''}>{t('contracts.filters.select')}</MenuItem>
                            <MenuItem value={0}>{t('generalText.finalized')}</MenuItem>
                            <MenuItem value={1}>{t('generalText.active')}</MenuItem>
                        </Select>
                    </FormControl>
                </Item>
            </Container>
            <Container xs={12} md={12} className={classes.containerRow}>
                <Item xs={12} md={6} container alignItems="center" justify="center">
                    <Button
                        onClick={onCleanFilters}
                        className={classes.downloadButtonStyle}
                        aria-label="edit"
                    >
                        {t('contracts.filters.clean')}
                    </Button>
                </Item>
                <Item xs={12} md={6} container alignItems="center" justify="center">
                    <Button
                        onClick={onSetFilters}
                        className={classes.uploadButtonStyle}
                        aria-label="edit"
                    >
                        {t('contracts.filters.button')}
                    </Button>
                </Item>
            </Container>
        </>
    )
};

/**
 * @title MaintenanceFilter
 * @description Componente para filtrar las solicitudes de mantenimiento
 * @param {Object} props - component props
 * @param {Object} props.filters - filters data
 * @param {Function} props.onChangeAutocomplete - function to handle autocomplete changes
 * @param {Array} props.clients - clients list
 * @param {Function} props.onChangeFormData - function to handle form data changes
 * @param {Array} props.contracts - contracts list
 * @param {Function} props.onCleanFilters - function to clean filters
 * @param {Function} props.onFilter - function to filter data
 * @param {Object} props.filtersError - filters errors
 * @returns component view
 */

export const MaintenanceFilter = ({
    filters,
    onChangeAutocomplete,
    clients, // TO-DO: Se obtentra desde el API al inicio de la aplicacion
    onChangeFormData,
    contracts, // TO-DO: Se obtentra desde el API al inicio de la aplicacion
    onCleanFilters,
    onFilter,
    filtersError
}) => {

    //styles
    const classes = useStyles();
    //translations
    const [t, i18n] = useTranslation('common');

    return (
        <>
            <Container xs={12} md={12} className={classes.containerRow}>
                <Item xs={12} md={12}>
                    <p className={classes.titles}> {t('contracts.filters.title')} </p>
                </Item>
            </Container>
            <Container xs={12} md={12} className={classes.containerRow}>
                <Item xs={12} md={6} container alignItems="center" justify="center" className={classes.box}>
                    <FormControl fullWidth>
                        <CustomAutocomplete
                            id="contractNumber"
                            options={contracts}
                            value={filters.contractNumberObj}
                            onChange={onChangeAutocomplete}
                        />
                    </FormControl>
                </Item>
                <Item xs={12} md={6} container alignItems="center" justify="center" className={classes.box}>
                    <FormControl fullWidth>
                        <CustomAutocomplete
                            id="client"
                            options={clients}
                            value={filters.customerIdObj}
                            onChange={onChangeAutocomplete}
                        />
                    </FormControl>
                </Item>
            </Container>
            <Container xs={12} md={12} className={classes.containerRow}>
                <Item xs={12} md={6} className={classes.box}>
                    <FormControl fullWidth>
                        <CustomInput
                            id="startDate"
                            labelText={t('maintenance.filters.1')}
                            error={filtersError.requestDateStart}
                            labelProps={{ shrink: true }}
                            inputProps={{
                                value: filters.requestDateStart,
                                type: 'date',
                                name: 'requestDateStart',
                                onChange: (e) => { onChangeFormData(e); }
                            }}
                            formControlProps={{ fullWidth: true }}
                        />
                    </FormControl>
                </Item>
                <Item xs={12} md={6} className={classes.box}>
                    <FormControl fullWidth>
                        <CustomInput
                            id="endDate"
                            labelText={t('maintenance.filters.2')}
                            error={filtersError.requestDateEnd}
                            labelProps={{ shrink: true }}
                            inputProps={{
                                value: filters.requestDateEnd,
                                type: 'date',
                                name: 'requestDateEnd',
                                onChange: (e) => { onChangeFormData(e); }
                            }}
                            formControlProps={{ fullWidth: true }}
                        />
                    </FormControl>
                </Item>
            </Container>
            <Container xs={12} md={12} className={classes.containerRow}>
                <Item xs={12} md={6} container alignItems="center" justify="center">
                    <Button
                        onClick={onCleanFilters}
                        className={classes.downloadButtonStyle}
                        aria-label="edit">
                        {t('maintenance.filters.4')}
                    </Button>
                </Item>
                <Item xs={12} md={6} container alignItems="center" justify="center">
                    <Button
                        onClick={onFilter}
                        className={classes.uploadButtonStyle}
                        aria-label="edit">
                        {t('maintenance.filters.3')}
                    </Button>
                </Item>
            </Container>
        </>
    )
}

/**
 * @title NotificationFilter
 * @description Componente para filtrar las notificaciones
 * @param {Object} props - component props
 * @param {Object} props.filters - filters data
 * @param {Function} props.onChangeAutocomplete - function to handle autocomplete changes
 * @param {Array} props.clients - clients list
 * @param {Function} props.onChangeFormData - function to handle form data changes
 * @param {Array} props.contracts - contracts list
 * @param {Function} props.onCleanFilters - function to clean filters
 * @param {Function} props.onFilter - function to filter data
 * @param {Object} props.filtersError - filters errors
 * @returns component view
 */


export const NotificationFilter = ({
    Filter,
    onChangeTitle,
    onChangeCategory,
    onChangeFormData,
    ListNotifications,
    ReducerState,
    onReset,
    onSend,
}) => {

    //styles
    const classes = useStyles();
    //translations
    const [t, i18n] = useTranslation('common');

    return (
        <>
            <Container xs={12} md={12} className={classes.containerRow}>
                <Item xs={12} md={12}>
                    <p className={classes.titles}> {t('contracts.filters.title')} </p>
                </Item>
            </Container>
            <Grid container spacing={2} style={{ width: "100%" }}>
                <Grid item xs={12} md={6}>
                    <CustomAutocomplete
                        id='notificationContract'
                        options={ListNotifications === null ? [] : ListNotifications}
                        value={Filter.title}
                        onChange={onChangeTitle}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomAutocomplete
                        id='notificationCategory'
                        options={ReducerState.Categories}
                        value={Filter.categorie}
                        onChange={onChangeCategory}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomInput
                        id="startDate"
                        labelText={t('Notification.FilterLabels.createDate')}
                        labelProps={{ shrink: true }}
                        inputProps={{
                            value: Filter.createDate,
                            type: 'date',
                            name: 'createDate',
                            onChange: (e) => { onChangeFormData(e); }
                        }}
                        formControlProps={{ fullWidth: true }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomInput
                        id="startDate"
                        labelText={t('Notification.FilterLabels.publishDate')}
                        labelProps={{ shrink: true }}
                        inputProps={{
                            value: Filter.publishDate,
                            type: 'date',
                            name: 'publishDate',
                            onChange: (e) => { onChangeFormData(e); }
                        }}
                        formControlProps={{ fullWidth: true }}
                    />
                </Grid>
            </Grid>
            <Container xs={12} md={12} className={classes.containerRow}>
                <Item xs={12} md={6} container alignItems="center" justify="center">
                    <Button
                        onClick={onReset}
                        className={classes.downloadButtonStyle}
                        aria-label="edit">
                        {t('maintenance.filters.4')}
                    </Button>
                </Item>
                <Item xs={12} md={6} container alignItems="center" justify="center">
                    <Button
                        onClick={onSend}
                        className={classes.uploadButtonStyle}
                        aria-label="edit">
                        {t('maintenance.filters.3')}
                    </Button>
                </Item>
            </Container>
        </>
    )
}

/**
 * @title DocumentsFilter
 * @description Componente para filtrar los documentos
 * @param {Object} props - component props
 * @param {Object} props.filters - filters data
 * @param {Function} props.onChangeAutocomplete - function to handle autocomplete changes
 * @param {Array} props.clients - clients list
 * @param {Function} props.onChangeFormData - function to handle form data changes
 */

export const DocumentsFilter = ({
    filters,
    onChangeFiltersData,
    onCleanFilters,
    onSetFilters }) => {

    //styles
    const classes = useStyles();
    //translations
    const [t, i18n] = useTranslation('common');

    return (
        <Container alignItems="flex-start" justify="center">
            <Item xs={12} md={10} alignSelf="flex-start" >
                <p style={{ color: "#050B7F", fontWeight: 'bold', fontSize: '1.5rem' }}> {t('contracts.filters.title')} </p>
            </Item>
            <Item container xs={12} alignItems="center" justify="center" >
                <Container item xs={12} alignItems="center" justify="center" >
                    <Item xs={12} md={6}>
                        <CustomInput
                            labelText={t('documents.modal.0')}
                            id="documentName"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                value: filters.documentName,
                                name: "documentName",
                                onChange: (e) => { onChangeFiltersData(e); }
                            }}
                        />
                    </Item>

                    <Item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="StatusLabel">{t('documents.headers.0')}</InputLabel>
                            <Select
                                labelId='StatusLabel'
                                fullWidth
                                label={t('documents.headers.0')}
                                name="documentType"
                                value={filters.documentType}
                                onChange={onChangeFiltersData}
                            >
                                <MenuItem value={1}>PDF</MenuItem>
                            </Select>
                        </FormControl>
                    </Item>
                </Container>
                <Container item xs={12} direction="row" alignItems="center" justify="center" >
                    <Container item xs={12} sm={6} alignItems="center" justify="center" >
                        <FormControl fullWidth>
                            <InputLabel id="StatusLabel">{t('documents.modal.1')}</InputLabel>
                            <Select
                                labelId='StatusLabel'
                                fullWidth
                                label={t('documents.modal.1')}
                                name="documentCategory"
                                value={filters.documentCategory}
                                onChange={onChangeFiltersData}
                            >
                                <MenuItem value={8}>{t('documents.options.0')}</MenuItem>
                                <MenuItem value={15}>{t('documents.options.1')}</MenuItem>
                                <MenuItem value={2}>{t('documents.options.2')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Container>
                </Container>
                <Container item xs={12} direction="row" alignItems="center" justify="center">
                    <Item xs={12} md={6} container alignItems="center" justify="center">
                        <Button
                            onClick={onCleanFilters}
                            className={classes.downloadButtonStyle}
                            size="lg"
                        >
                            {t('generalText.cancel')}
                        </Button>
                    </Item>
                    <Item xs={12} md={6} container alignItems="center" justify="center">
                        <Button
                            onClick={onSetFilters}
                            className={classes.uploadButtonStyle}
                            size="lg"
                        >
                            {t('generalText.accept')}
                        </Button>
                    </Item>
                </Container>
            </Item>
        </Container>
    )
}

/**
 * @title GLoveBoxFilter
 * @description Componente para filtrar los documentos
 */

export const GloveBoxFilter = ({
    filters,
    onChange,
    options,
    clients,
    onClean,
    onSubmit }) => {

    //styles
    const classes = useStyles();
    //translations
    const [t, i18n] = useTranslation('common');

    return (
        <>
            <Container alignItems="flex-start" justify="center">
                <Item xs={12} md={10} alignSelf="flex-start" >
                    <p style={{ color: "#050B7F", fontWeight: 'bold', fontSize: '1.5rem' }}> {t('contracts.filters.title')} </p>
                </Item>
            </Container>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <CustomAutocomplete
                        id='plates'
                        options={options}
                        value={filters.plateObj}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomAutocomplete
                        id='contract'
                        options={options}
                        value={filters.contractObj}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomAutocomplete
                        id='customer'
                        options={clients}
                        value={filters.customerObj}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>
            <Container item xs={12} direction="row" alignItems="center" justify="center">
                <Item xs={12} md={6} container alignItems="center" justify="center">
                    <Button
                        onClick={onClean}
                        className={classes.downloadButtonStyle}
                        size="lg"
                    >
                        {t('maintenance.filters.4')}
                    </Button>
                </Item>
                <Item xs={12} md={6} container alignItems="center" justify="center">
                    <Button
                        onClick={onSubmit}
                        className={classes.uploadButtonStyle}
                        size="lg"
                    >
                        {t('maintenance.filters.3')}
                    </Button>
                </Item>
            </Container>
        </>
    )
}   