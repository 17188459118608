/**
 * @fileoverview Maintenance ModalTicket
 * @version 1.0.0
 * @author Martin Duran Anguiano
 * @date 22/12/2020
 * @copyright 2020 RESSER S.A de C.V
 */

import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { Select } from '@material-ui/core';

import Cookies from 'js-cookie';
import styles from './ModalTicketBr.styles';
import Paper from '@material-ui/core/Paper';
import Item from "components/Grid/GridItem.js";
import Container from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import CustomInput from "components/CustomInput/CustomInput.js";
import { enqueueSuccessSnackbar, enqueueErrorSnackbar, enqueueInfoSnackbar } from 'libs/Snackbar';
import { onShowAlertMessage, showCircularProgress, closeCircularProgress } from 'redux/slice'
import axios from 'libs/AxiosHelper';
import CustomDatePicker from 'components/CustomInput/CustomDateTimePicker';
import { Alert, AlertTitle } from '@material-ui/lab';
import Rssr from '../../../../libs/Rssr';


import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';


const axiosB = require('axios');
const useStyles = makeStyles(styles);

//State form error object
const formMaintenanceErrorsObject = {
    contract: false,
    phone: false,
    km: false,
    estate: false,
    city: false,
    reference: false,
    date1: false,
    date2: false,
    date3: false,
    armored: false,
    pickup: false,
    details: false,
    rName: false,
    rPhone: false,
    rEmail: false,
    directionPick: false
}

//State form maintenance object
const formDataObject = {
    contract: "",
    phone: '',
    km: '',
    estate: 0,
    city: 0,
    reference: '',
    date1: '',
    date2: '',
    date3: '',
    armored: '',
    pickup: '',
    details: '',
    rName: '',
    rPhone: '',
    rEmail: '',
    levaETrazDir: ''
}

export default function ModalTicketBr(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');
    const [formData, setformData] = useState(formDataObject);
    const [formMaintenanceErrors, setformMaintenanceErrors] = useState(formMaintenanceErrorsObject);
    const [states, setstates] = useState([]);
    const [cities, setcities] = useState([]);
    const [aditionalSize, setaditionalSize] = useState(6)
    const [directionPick, setdirectionPick] = useState("")
    const [contractId, setContractId] = useState(0);
    const [plateNumer, setPlateNumer] = useState('');

    const regionId = useSelector(state => state.auth.loginData.regionId);
    const language = useSelector((state) => state.configurations.language);
    useEffect(() => {
        getStates();
        checkForrescheduleTicket();
    }, []);

    const checkForrescheduleTicket = () => {
        if (props.rescheduleTicket !== null) {
            setPlateNumer(props.rescheduleTicket.plateNumber);
        }
    }

    /**
     * Función encargada de obtener el catalogo de estados disponibles para el usuario
     * 
     */
    const getStates = async () => {
        axios.get(`StateCatalogFMV2?regionId=${regionId}`)
            .then(async response => {
                setstates(response.data.items)
            })
            .catch((error) => {
            });
    }

    /**
     * Función encargada de realizar la búsqueda de información del conductor de un contrato
     * @param {*} contractId
     */
    const getDriver = async (contractId, contractNumber) => {
        dispatch(showCircularProgress());
        axios.get(`UsersDriver/Relation?contractId=${contractId}`)
            .then(async response => {
                dispatch(closeCircularProgress());
                if (response.data.success) {
                    if (response.data.total === 1) {
                        //Alerta de aviso de que el contrato esta asignado a un conductor.
                        enqueueInfoSnackbar(dispatch, t('modalTicketBr.infoMessages.1'));
                        setformData({
                            ...formData,
                            'contract': contractNumber,
                            'rName': response.data.users[0].userName,
                            'rEmail': "", //TODO: Agregar valor de Email en el GET
                            'rPhone': "" //TODO: Agregar valor de Phone en el GET
                        });
                    }
                    else if (response.data.total > 1) {
                        //Alerta de aviso de que el contrato esta asignado a mas de un conductor.
                        enqueueInfoSnackbar(dispatch, t('modalTicketBr.infoMessages.2'));
                        setformData({
                            ...formData,
                            'contract': contractNumber
                        });
                    }
                } else {
                    //Alerta de aviso que el contrato no esta asignado a un conductor.
                    enqueueInfoSnackbar(dispatch, t('modalTicketBr.infoMessages.3'));
                    setformData({
                        ...formData,
                        'contract': contractNumber
                    });
                }
            })
            .catch(error => {
                dispatch(closeCircularProgress());
                if (error.response.status === 401) {
                    dispatch(onShowAlertMessage());
                }
            });
    }

    /**
     * Función encargada de obtener el estado y buscar las ciudades de ese estado
     * @param {Evento ejecutado en la función} event
     * @param {Valor seleccionado del selector} newValue
     * 
     */
    const onChangeState = async(event, newValue) => {
        if (newValue === null) return false;

        setformData({
            ...formData,
            estate: newValue.id
        });

        axios.get(`CityCatalogFMV2?stateId=${newValue.id}&regionId=${regionId}`)
            .then(async response => {
                setcities(response.data.items);
            })
            .catch((error) => {
            });
    }

    /**
     * Función que obtiene el valor en el campo ciudad
     * @param {Evento ejecutado en la función} e 
     * @param {Valor seleccionado del selector} newValue
     * 
     */
    const onChangeCity = (e, newValue) => {
        if (newValue === null) return false;
        setformData({
            ...formData,
            city: newValue.id
        });
    }

    /**
     * Función que detecta cambios en los campos de fecha del formulario de mantenimiento
     * @param {*} date
     * 
     */
    const onChangeFormDate = (date, o) => {

        if (date === null)
            return

        const inputName = date.name;
        const value = date.value;

        if (value !== null) {
            var minDate = moment(Rssr.getMinRequestServiceDate(regionId));
            var diffInHours = value.diff(minDate, 'hours');
            if ((diffInHours <= 12) && (minDate.hours() > value.hours())) {
                setformData({
                    ...formData,
                    [inputName]: value.hours(minDate.hours())
                });
            }
            else {
                setformData({
                    ...formData,
                    [inputName]: value
                });
            }
        }
        else {
            setformData({
                ...formData,
                [inputName]: value
            });
        }

        setformMaintenanceErrors({
            ...formMaintenanceErrors,
            [inputName]: false
        });
    }


    /**
     * Función que detecta cambios en los campos del formulario de mantenimiento
     * @param {*} e
     * 
     */
    const onChangeFormData = (e, o) => {
        const inputName = e.target.name;
        let inputValue = e.target.value;
        setformData({
            ...formData,
            [inputName]: inputValue
        });

        setformMaintenanceErrors({
            ...formMaintenanceErrors,
            [inputName]: false
        });

        //Sección para mensajes de aviso
        switch (inputName) {
            case 'pickup':
                const index = o.key;
                if(index != .0) {
                    enqueueInfoSnackbar(dispatch, t('modalTicketBr.infoMessages.0'));
                    setaditionalSize(3);
                    if(index == .1) {
                        setdirectionPick(t('modalTicketBr.inputs.10'));
                    }
                    else if(index == .2) {
                        setdirectionPick(t('modalTicketBr.inputs.11'));
                    }
                    else if(index == .3) {
                        setdirectionPick(t('modalTicketBr.inputs.12'));
                    }
                }
                break;
            default:
                break;
        }
    }

    /**
     * Función que detecta el cambio en campos que solo admiten numeros
     * @param {*} e 
     * 
     */
    const onChangeInputNumber = (e) => {
        const inputName = e.target.name;
        let text = e.target.value;
        let newText = '';
        let numbers = '0123456789';

        for (var i=0; i < text.length; i++) {
            if(numbers.indexOf(text[i]) > -1 ) {
                newText = newText + text[i];
            }
        }

        setformData({
            ...formData,
            [inputName]: newText
        });

        setformMaintenanceErrors({
            ...formMaintenanceErrors,
            [inputName]: false
        });
    }

    /**
     * Función que detecta la selección del contrato
     * @param {*} e 
     * @param {*} newValue
     */
    const onChangeContract = (e, newValue) => {
        if(newValue === null) {
            setformData({
                ...formData,
                contract: "",
                rName: "",
                rEmail: "",
                rPhone: ""
            });    
            setPlateNumer('');

            return false
        }

        setPlateNumer(newValue.plateNumber);
        const contractNumber = newValue.contractNumber;
        const contractId = newValue.contractId;

        getDriver(contractId, contractNumber);
    }

    const rescheduleTicket = () => {
        var data = {
            comentario: "Favor cancelar o agendamento para revisão do meu veículo",
            reagendamento: true,
            ticketid: props.rescheduleTicket.ticketId, 
            ticket: {
                requestorPhone: formData.rPhone,
                requestorEmail: formData.rName,
                requestorName: formData.rName,
                ticketType: 11,
                ticketData: {
                    vehiclePlate: plateNumer,
                    date1: formData.date1.format("YYYY-MM-DD HH:mm"),
                    date2: formData.date2.format("YYYY-MM-DD HH:mm"),
                    date3: formData.date3.format("YYYY-MM-DD HH:mm"),
                    currentKm: formData.km,
                    city: formData.city,
                    state: formData.estate,
                    region: formData.reference,
                    blindado: formData.armored,
                    levaETraz: formData.pickup,
                    levaETrazDir: formData.levaETrazDir,
                    infoAdicional: formData.details
                }
            }
        }
        axios.put(`ALDSACReagendamientoFMV2`, data)
            .then(response => {
                if (response.data.success) {
                    enqueueSuccessSnackbar(dispatch, t("modalTicketBr.successMessages.0"));
                    props.closeModal(false);
                    props.onReloadTickets();

                } else {
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.18"));
                }
                dispatch(closeCircularProgress());
            })
            .catch(error => {
                dispatch(closeCircularProgress());
                if (error.response.status === 401) {
                    dispatch(closeCircularProgress());
                    return false
                }
                enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.19"));
            });
    }

    /**
     * Función que realiza la solicitud de ticket
     * 
     */
    const onSendTicket = async () => {
        var hasTicketToReschedule = (props.rescheduleTicket !== null) ? true : false;
        const tType = 11;
        var base = {
            telefono: formData.phone,
            contrato: "",
            tipoTicket: tType,
            lang: language,
            region: 0,
            ticketData: {
                attachment1:{},
                attachment2:{}
            }
        }

        dispatch(showCircularProgress());
        
        switch(tType){
            case 11:
            case 12:
            case 13:
                base.contrato = formData.contract;
                base.responsibleName = formData.rName;
                base.responsibleEmail = formData.rEmail;
                base.responsiblePhone = formData.rPhone;
                base.ticketData.km = formData.km;
                base.ticketData.region = formData.reference;
                base.ticketData.fecha1 = formData.date1.format("YYYY-MM-DD HH:mm");
                base.ticketData.fecha2 = formData.date2.format("YYYY-MM-DD HH:mm");
                base.ticketData.fecha3 = formData.date3.format("YYYY-MM-DD HH:mm");
                base.ticketData.estado = formData.estate;
                base.ticketData.ciudad = formData.city;
                base.ticketData.infoAdicional = formData.details;
                base.ticketData.blindado = formData.armored;
                base.ticketData.levaETraz = formData.pickup;
                base.ticketData.levaETrazDir = formData.levaETrazDir;
                break;
        }

        if(validateInputs(base)) {
            var obj = {ticket: base};
            if (hasTicketToReschedule) {
                rescheduleTicket();
            }
            else {
                axios.post('TicketsFleetManager', obj)
                    .then(async response => {
                        if (response.data.success) {
                            enqueueSuccessSnackbar(dispatch, t("modalTicketBr.successMessages.0"));
                            props.closeModal(false);
                            props.onReloadTickets();

                        } else {
                            enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.18"));
                        }
                        dispatch(closeCircularProgress());
                    })
                    .catch(error => {
                        dispatch(closeCircularProgress());
                        if (error?.response?.status === 401) {
                            dispatch(closeCircularProgress());
                            return false
                        }
                        enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.19"));
                    });
            }
        } else {
            dispatch(closeCircularProgress());
        }
    }

    /**
     * Función encargada de validar los datos del usuario.
     * @param {datos del ticket} data 
     * 
     */
    const validateInputs = (data) => {
        const { contrato, responsibleName, responsibleEmail, responsiblePhone, tipoTicket, telefono} = data;
        const { levaETrazDir, estado, ciudad, fecha1, fecha2, fecha3, infoAdicional, km, blindado, levaETraz, region } = data.ticketData;
        
        const dateOneValid = Rssr.validateServiceDayRegion(fecha1, 0);
        const dateTwoValid = Rssr.validateServiceDayRegion(fecha2, 0);
        const dateThreeValid = Rssr.validateServiceDayRegion(fecha3, 0);
        
        //Validaciones de días y horarios seleccionados
        if(dateOneValid === 2 || dateOneValid === 3 || dateOneValid === 4 || dateOneValid === 5) {
            switch (dateOneValid) {
                case 2:
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.15"));
                    break;
                case 3:
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.16"));
                    break;
                case 4:
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.20"));
                    break;
                case 5:
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.21"));
                    break;
                default:
                    break;
            }

            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "date1": true
            });
            return false;
        }

        if(dateTwoValid === 2 || dateTwoValid === 3 || dateTwoValid === 4 || dateTwoValid === 5) {
            switch (dateTwoValid) {
                case 2:
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.15"));
                    break;
                case 3:
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.16"));
                    break;
                case 4:
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.20"));
                    break;
                case 5:
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.21"));
                    break;
                default:
                    break;
            }

            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "date2": true
            });
            return false;
        }

        if(dateThreeValid === 2 || dateThreeValid === 3 || dateThreeValid === 4 || dateThreeValid === 5) {
            switch (dateThreeValid) {
                case 2:
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.15"));
                    break;
                case 3:
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.16"));
                    break;
                case 4:
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.20"));
                    break;
                case 5:
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.21"));
                    break;
                default:
                    break;
            }

            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "date3": true
            });
            return false;
        }

        //Validaciones generales
        if(telefono === '') {
            enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.0"));
            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "phone": true
            });
            return false;
        }
        if(contrato === '') {
            enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.1"));
            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "contract": true
            });
            return false;
        }
        if(responsibleName === '') {
            enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.2"));
            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "rName":true
            });
            return false;
        }
        if(responsibleEmail === '') {
            enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.3"));
            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "rEmail":true
            });
            return false;
        }
        if(!Rssr.validateEmailFormat(responsibleEmail)) {
            enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.4"));
            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "rEmail":true
            });
            return false;
        }
        if(responsiblePhone === '') {
            enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.5"));
            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "rPhone": true
            });
            return false;
        }
        if(dateOneValid === false) {
            enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.17"));
            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "date1": true
            });
            return false;
        }
        if(dateTwoValid === false) {
            enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.17"));
            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "date2": true
            });
            return false;
        }
        if(dateThreeValid === false) {
            enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.17"));
            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "date3": true
            });
            return false;
        }

        //Validaciones especificas por ticket
        switch (tipoTicket) {
            case 11:
            case 12:
            case 13:
                if(km === '') {
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.6"));
                    setformMaintenanceErrors({
                        ...formMaintenanceErrors,
                        "km":true
                    });
                    return false;
                }
                if(estado === "" || estado === 0) {
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.7"));
                    return false;
                }
                if(ciudad === "" || ciudad === 0) {
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.8"));
                    return false;
                }
                if(infoAdicional === '') {
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.9"));
                    return false;
                }
                if(blindado === '') {
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.10"));
                    return false;
                }
                if(levaETraz === '') {
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.11"));
                    return false;
                }
                if((fecha1 === fecha2) || (fecha2 === fecha3) || (fecha1 === fecha3)){
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.12"));
                    return false;
                }
                if( (levaETraz !== '') && (levaETrazDir === '') && (levaETraz !== t('modalTicketBr.levaETraz.1')) ) {
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.13"));
                    return false;
                }
                if(region === '') {
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.14"));
                    return false;
                }
                return true;
            default:
                return false;
        }
    }


    return (
        <Paper elevation={0} className={classes.containerModalForm}>
            <Container className={classes.containerFormSections}>
                <Item xs={12} md={12}>
                    <p className={classes.titles}> {t("maintenance.titles.0")} </p>
                </Item>
            </Container>
            {/* Vehiculo, Teleforno, Kilometraje */}
            <Container direction="row" alignItems="flex-end" className={classes.containerFormSections}>
                <Item xs={12} sm={4} md={4}>
                    <FormControl fullWidth>
                        {
                            props.contracts.length > 0 ?
                                <Autocomplete
                                    id="combo-box-vehicles"
                                    size="small"
                                    freeSolo={true}
                                    options={props.contracts}
                                    getOptionLabel={(option) => option.plateNumber}
                                    onChange={(event, newValue) => { onChangeContract(event, newValue); }}
                                    renderInput={(params) => <TextField {...params} label={t('modalTicketBr.inputs.17')} margin="normal" />}
                                /> :
                                <TextField
                                    disabled
                                    label={t('modalTicketBr.inputs.17')}
                                    margin="normal"
                                    value={t('modalTicketGnr.noContractMessage')} />
                        }
                    </FormControl>
                </Item>
                <Item xs={12} sm={4} md={4}>
                    <CustomInput
                        id="phone"
                        labelText={t('modalTicketBr.inputs.0')}
                        error={formMaintenanceErrors.phone}
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            value: formData.phone,
                            name: "phone",
                            onChange: (e) => { onChangeInputNumber(e); }
                        }}
                    />
                </Item>
                <Item xs={12} sm={4} md={4}>
                    <CustomInput
                        id="km"
                        labelText={t('modalTicketBr.inputs.1')}
                        error={formMaintenanceErrors.km}
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            value: formData.km,
                            name: "km",
                            onChange: (e) => { onChangeInputNumber(e); },
                        }}
                    />
                </Item>
                <Item xs={12} sm={6} md={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            id="comboEstado"
                            disableListWrap={true}
                            freeSolo={true}
                            size="small"
                            options={states}
                            getOptionLabel={(option) => option.value}
                            onChange={(event, newValue) => { onChangeState(event, newValue); }}
                            inputValue={states.value}
                            renderInput={(params) => <TextField {...params} label={t('modalTicketBr.inputs.2')} margin="normal" />}
                        />
                    </FormControl>
                </Item>
                <Item xs={12} sm={6} md={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            id="comboCiudad"
                            freeSolo={true}
                            options={cities}
                            getOptionLabel={(option) => option.value}
                            onChange={(event, newValue) => { onChangeCity(event, newValue); }}
                            inputValue={formData.estate.value}
                            renderInput={(params) => <TextField {...params} label={t('modalTicketBr.inputs.3')} className={classes.root} margin="normal" />}
                        />
                    </FormControl>
                </Item>
            </Container>

            <Container direction="row" justify="center" alignItems="flex-end" className={classes.containerFormSectionsPadding}>
                <Item xs={12} sm={4} md={4}>
                    <FormControl fullWidth>
                        <CustomDatePicker
                            id="date"
                            labelText={t('modalTicketBr.inputs.5')}
                            value={formData.date1}
                            onChange={(date) => { onChangeFormDate({ name: "date1", value: date }); }}
                            validateMin={Rssr.getMinRequestServiceDate(regionId)} />
                    </FormControl>
                </Item>
                <Item xs={12} sm={4} md={4}>
                    <FormControl fullWidth>
                        <CustomDatePicker
                            data={formData}
                            id="date"
                            labelText={t('modalTicketBr.inputs.6')}
                            value={formData.date2}
                            onChange={(date) => { onChangeFormDate({ name: "date2", value: date }); }}
                            validateMin={Rssr.getMinRequestServiceDate(regionId)} />
                    </FormControl>
                </Item>
                <Item xs={12} sm={4} md={4}>
                    <FormControl fullWidth>
                        <CustomDatePicker
                            data={formData}
                            id="date"
                            labelText={t('modalTicketBr.inputs.7')}
                            value={formData.date3}
                            onChange={(date) => { onChangeFormDate({ name: "date3", value: date }); }}
                            validateMin={Rssr.getMinRequestServiceDate(regionId)} />
                    </FormControl>
                </Item>
            </Container>

            <Container direction="row" justify="flex-end" alignItems="flex-end" className={classes.containerFormSections}>
                <Item xs={12} sm={12} md={12}>
                    <FormControl fullWidth>
                        <CustomInput
                            id="regionPreference"
                            labelText={t('modalTicketBr.inputs.4')}
                            error={formMaintenanceErrors.reference}
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                value: formData.reference,
                                name: "reference",
                                onChange: (e) => { onChangeFormData(e); }
                            }}
                        />
                    </FormControl>
                </Item>
            </Container>

            {/* Blindado, ida y vuelta, dirección y adicional */}
            <Container direction="row" justify="flex-end" alignItems="flex-end" className={classes.containerFormSections}>
                <Item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel style={{ marginTop: 15 }} id="demo-simple-select-label">{t('modalTicketBr.inputs.8')}</InputLabel>
                        <Select
                            labelId="vehicleArmored"
                            id="armored"
                            name="armored"
                            value={formData.armored}
                            onChange={(e) => { onChangeFormData(e); }}
                            style={{ width: '100%', marginTop: 30 }}
                        >
                            <MenuItem value={t('generalText.yes')}>{t('generalText.yes')}</MenuItem>
                            <MenuItem value={t('generalText.no')}>{t('generalText.no')}</MenuItem>
                        </Select>
                    </FormControl>
                </Item>
                <Item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel style={{ marginTop: 15 }} id="demo-simple-select-label">{t("modalTicketBr.levaETraz.0")}</InputLabel>
                        <Select
                            labelId="vehiclePickup"
                            id="pickup"
                            name="pickup"
                            value={formData.pickup}
                            onChange={(e, o) => {onChangeFormData(e, o);}}
                            style={{width: '100%', marginTop: 30}}
                            >
                                <MenuItem value={t('modalTicketBr.levaETraz.1')}>{t("modalTicketBr.levaETraz.1")}</MenuItem>
                                <MenuItem value={t('modalTicketBr.levaETraz.2')}>{t("modalTicketBr.levaETraz.2")}</MenuItem>
                                <MenuItem value={t('modalTicketBr.levaETraz.3')}>{t("modalTicketBr.levaETraz.3")}</MenuItem>
                                <MenuItem value={t('modalTicketBr.levaETraz.4')}>{t("modalTicketBr.levaETraz.4")}</MenuItem>
                            </Select>
                    </FormControl>
                </Item>
                <Item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                        <TextField
                        id="details"
                        label={t("modalTicketBr.inputs.13")}
                        placeholder={t("modalTicketBr.inputs.16")}
                        name="details"
                        multiline
                        value={formData.details}
                        onChange={(e) => {onChangeFormData(e);}}
                        rows={3}/>
                    </FormControl>
                </Item>
            </Container>

            {/* Dirección de pick */}
            <Container direction="row" justify="flex-end" alignItems="flex-end" className={classes.containerFormSections}>
                <Item xs={12} sm={12} md={12}>
                    <FormControl fullWidth>
                    {(formData.pickup !== t('modalTicketBr.levaETraz.1') && formData.pickup !== "") && [
                        <CustomInput
                        id="idaVueltaDireccion"
                        labelText={directionPick}
                        error={formMaintenanceErrors.directionPick}
                        formControlProps={{
                            fullWidth:true
                        }}
                        inputProps={{
                            value: formData.levaETrazDir,
                            name: "levaETrazDir",
                            onChange: (e) => {onChangeFormData(e);}
                        }}
                        />
                        ]}
                    </FormControl>
                </Item>
            </Container>
            
            {/* Responsable title*/}
            <Container direction="row" justify="flex-end" alignItems="flex-end" className={classes.containerFormSections}>
                <Item xs={12} md={12}>
                    <p className={classes.titles}> {t("maintenance.titles.1")} </p>
                    <p className={classes.infoTitle}>{t("maintenance.titles.2")}</p>
                </Item>
            </Container>
            
            {/* Nombre, telefono y email */}
            <Container direction="row" justify="flex-start" alignItems="flex-start" className={classes.containerFormSections}>
                <Item xs={12} sm={4} md={4}>
                    <CustomInput
                        id="responsalName"
                        labelText={t("modalTicketBr.inputs.14")}
                        error={formMaintenanceErrors.rName}
                        formControlProps={{
                            fullWidth:true
                        }}
                        inputProps={{
                            value: formData.rName,
                            name: "rName",
                            onChange: (e) => {onChangeFormData(e);}
                        }}
                        />
                </Item>
                <Item xs={12} sm={5} md={4}>
                    <CustomInput 
                    id="responsablePhone" 
                    labelText={t("modalTicketBr.inputs.18")}
                    error={formMaintenanceErrors.rPhone} 
                    formControlProps={{ 
                        fullWidth: true
                    }}
                    inputProps={{
                        value: formData.rPhone,
                        name: "rPhone",
                        onChange: (e) => {onChangeInputNumber(e);}
                        }}
                    />
                </Item>
                <Item xs={12} sm={3} md={4}>
                        <CustomInput
                        id="responsalEmail"
                        labelText={t("modalTicketBr.inputs.15")}
                        error={formMaintenanceErrors.rEmail}
                        formControlProps={{
                            fullWidth:true
                        }}
                        inputProps={{
                            value: formData.rEmail,
                            name: "rEmail",
                            onChange: (e) => {onChangeFormData(e);}
                        }}
                        />
                </Item>
            </Container>

            <Container direction="column" justify="center" alignItems="center" className={classes.containerFormSections}>
                <Item xs={12} sm={12} md={12}>
                    <Button 
                    onClick={onSendTicket}
                    disabled={(props.contracts.length > 0)? false : true}
                    className={classes.requestServiceButton}
                    aria-label="edit">
                    {t("maintenance.buttons.1")}
                    </Button>
                </Item>
            </Container>
        </Paper>
    );
}
