/**
 * @fileoverview PopperFilter for SAT page.
 * @version 1.0
 * @author Carlos Emilio Blanco Lopez
 * @date 23/01/2024
 * @copyright 2024 RESSER S.A de C.V
 */

import React, { useState, useEffect } from 'react';
import { Box, Popper, Grid, FormControlLabel, Checkbox, Button } from '@mui/material';

import { useTranslation } from 'react-i18next';

//Icons
import { MdClose } from "react-icons/md";
import { IconContext } from 'react-icons';
import { CheckBoxChecked } from 'assets/img/Icons/Icons';

//Styles
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';

const useStyles = makeStyles(styles);

const initialValues = {
    pending: false,
    approved: false,
    rejected: false
};

const PopperFilter = ({ anchorEl, setAnchorEl, setSelect, Selected }) => {

    const [States, setStates] = useState(initialValues);

    const classes = useStyles();
    const { t } = useTranslation('common');

    const open = Boolean(anchorEl);
    const id = open ? 'Filter-popper' : undefined;

    useEffect(() => {
        setStates(Selected);
    }, [Selected]);

    /**
     * @Function ChangeStatus
     * @description Función que se encarga de cambiar el estado de los filtros
     */

    const ChangeStatus = (e) => {
        setStates({...initialValues, [e.target.value]: e.target.checked })
    };

    /**
     * @Function ApplyFilter
     * @description Función que se encarga de aplicar los filtros
     */

    const ApplyFilter = () => {
        setSelect(States);
        setAnchorEl(null);
    };

    /**
     * @Function ClearFilter
     * @description Función que se encarga de limpiar los filtros
     */

    const ClearFilter = () => {
        setSelect(initialValues);
        setStates(initialValues);
        setAnchorEl(null);
    };

    return (
        <Popper id={id} open={open} anchorEl={anchorEl}>
            <Box sx={BoxStyle}>
                <section className={classes.sectionTitleFilter}>
                    <p className={classes.TitleFilter}>{t('ModuleAgreements.Filters.filters')}</p>
                    <IconContext.Provider value={{ size: '24px', color: '#828282' }}>
                        <MdClose onClick={() => setAnchorEl(null)} style={{cursor: 'pointer'}}/>
                    </IconContext.Provider>
                </section>
                <section className={classes.sectionCheckBoxFilter}>
                    <Grid container width={'90%'} spacing={1}>
                        <Grid item xs={6}>
                            <FormControlLabel
                                value="pending"
                                label={t('ModuleAgreements.Filters.pedding')}
                                control={<Checkbox
                                    checked={States.pending}
                                    checkedIcon={<CheckBoxChecked />}
                                    onChange={ChangeStatus} />}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                value="approved"
                                label={t('ModuleAgreements.Filters.approved')}
                                control={
                                    <Checkbox
                                        checked={States.approved}
                                        checkedIcon={<CheckBoxChecked />}
                                        onChange={ChangeStatus} />
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControlLabel
                                value="rejected"
                                label={t('ModuleAgreements.Filters.rejected')}
                                control={<Checkbox
                                    checked={States.rejected}
                                    checkedIcon={<CheckBoxChecked />}
                                    onChange={ChangeStatus}
                                />}
                            />
                        </Grid>
                    </Grid>
                </section>
                <section className={classes.sectionButtonFilter}>
                    <Button
                        variant="outlined"
                        style={{ width: 120 }}
                        onClick={ClearFilter}
                        sx={ButtonCancelStyle}>
                        {t('ModuleAgreements.Filters.cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        style={{ width: 120 }}
                        onClick={ApplyFilter}
                        sx={ButtonApplyStyle}>
                        {t('ModuleAgreements.Filters.apply')}
                    </Button>
                </section>
            </Box>
        </Popper>
    )
}

export default PopperFilter;

/**
 * @Constant BoxStyle
 * @description Estilos para el contenedor principal
 */

const BoxStyle = {
    bgcolor: 'background.paper',
    boxShadow: '0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);',
    marginRight: 5,
    marginTop: 1,
    width: '300px',
};

/**
 * @Constant ButtonCancelStyle
 * @description Estilos para el boton de cancelar
 */

const ButtonCancelStyle = {
    borderColor: '#828282',
    borderRadius: '20px',
    color: '#828282',
    "&:hover": {
        borderColor: "#828282",
        color: "#828282"
    }
};

/**
 * @Constant ButtonApplyStyle
 * @description Estilos para el boton de aplicar
 */

const ButtonApplyStyle = {
    backgroundColor: '#4391FF',
    borderRadius: '20px',
    color: '#FFFFFF',
    "&:hover": {
        color: "#FFFFFF"
    }
};