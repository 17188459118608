/**
 * @fileoverview Home 
 * @version 1.0.0
 * @author Carlos Emilio Blanco Lopez
 * @date 24/02/2021
 * @dateUpdate 12/03/2024
 * @copyright 2024 Industrias RESSER S.A de C.V
 *
 */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid'
import { Card, CardContent, Typography } from '@material-ui/core';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';

import Footer from "components/Footer/Footer.js";
import axios from 'libs/AxiosHelper';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// icons
import { FaFacebookSquare, FaInstagram,FaLinkedin ,FaYoutube   } from "react-icons/fa";

// image
import cardProducts from 'assets/img/products.jpg';
import cardMovility from 'assets/img/movility.jpg';

//Translations
import { useTranslation } from "react-i18next";

//Style
import './HomeStyle.css';
import { MdFiberManualRecord } from 'react-icons/md';

let baseInfo = {
  facebook: {
    active: true,
    link: ''
  },
  instagram: {
    active: true,
    link: ''
  },
  linkedin: {
    active: true,
    link: ''
  },
  youtube: {
    active: true,
    link: ''
  },
}

let videos = [
  "https://ald.blob.core.windows.net/homefmv2/10801_ALD__CORPORATIVO_MEX.mp4",
  "https://ald.blob.core.windows.net/homefmv2/Brand%20launch%20film_Internal%20version_EN_Final%201920X1080.mp4",
  "https://ald.blob.core.windows.net/homefmv2/Move%202025_1920x1080%20_EN.mp4",
  "https://ald.blob.core.windows.net/homefmv2/ultima%20versao%20correta.mp4",
]

let styles = theme => ({
  networkIcon: {
    cursor: 'pointer',
  },
  socialIcon: {
    cursor: 'pointer',
    height: 25,
    width: 25
  },
  image: {
    width: '100%',
    height: "100%"
  },
  card: {
    [theme.breakpoints.up('md')]: {
      height: '35vh'
    }
  }
})

const useStyles = makeStyles(styles);

function Home(props) {

  const classes = useStyles();

  //Translations
  const [t, i18n] = useTranslation('common');

  const regionId = useSelector(state => state.auth.loginData.regionId);
  const [socialNetworks, setSocialNetworks] = useState(baseInfo);
  const [productsLink, setProductsLink] = useState('');
  const [links, setLinks] = useState('');

  useEffect(() => {
    getPrivacyPolicies();
    renderSocialNetworks();
  }, []);

  const getPrivacyPolicies = () => {
    axios.get('PoliciesAndTerms').then(async response => {
      if (await response.data.success) {
        if (response.data.policies.length > 0) {
          let policiesAux = response.data.policies.find(
            e => e.policyTypeId === 1,
          );
          let termsAux = response.data.policies.find(
            e => e.policyTypeId === 3,
          );
          let cookiesAux = response.data.policies.find(
            e => e.policyTypeId === 2,
          );
          setLinks([
            policiesAux === undefined ? '' : policiesAux.data,
            cookiesAux === undefined ? '' : cookiesAux.data,
            termsAux === undefined ? '' : termsAux.data
          ])
        }
      }
    });
  };

  /**
   * Function to render the social networks of each country
   * @memberof Home
   */
  const renderSocialNetworks = () => {
    switch (regionId) {
      case 0: // Brasil
        setSocialNetworks({
          ...socialNetworks,
          facebook: { active: true, link: 'https://www.facebook.com/ALDAutomotiveBrasil/' },
          instagram: { active: true, link: 'https://www.instagram.com/aldautomotivebr/' },
          linkedin: { active: true, link: 'https://www.linkedin.com/authwall?trk=gf&trkInfo=AQHlTdllMDOffAAAAXmF943ASG7o0LnfRm26o-k7-4v4MATY-j2drsXfQOSj5auRQ0Sn-u0cSi-7JgrdKj-_OMbsHY9tpavWJeGFLj0Ae_xOi-tpmdaCF1oNMqp0RNmlaPVzATU=&originalReferer=https://www.aldautomotive.com.br/blog&sessionRedirect=https://www.linkedin.com/company/ald-automotive-brasil' },
          youtube: { active: true, link: 'https://www.youtube.com/c/ALDAutomotiveBrasilS%C3%A3oPaulo' }
        })
        setProductsLink('https://www.aldautomotive.com.br/mobilidadesustentavel')
        break;
      case 1: // México
        setSocialNetworks({
          ...socialNetworks,
          facebook: { active: true, link: 'https://www.facebook.com/ALDAutomotiveMexico/' },
          instagram: { active: false, link: '' },
          linkedin: { active: true, link: 'https://www.linkedin.com/company/aldautomotivemexico/' },
          youtube: { active: true, link: 'https://www.youtube.com/channel/UC103PCkFtT3T2_stiIYSmlw' },
        });
        setProductsLink('https://www.aldautomotive.com.mx/');
        break;
      case 2: // Peru
        setSocialNetworks({
          ...socialNetworks,
          facebook: { active: false, link: '' },
          instagram: { active: false, link: '' },
          linkedin: { active: true, link: 'https://www.linkedin.com/company/ald-automotive-peru/' },
          youtube: { active: false, link: '' },
        })
        setProductsLink('https://www.aldautomotive.pe/');
        break;
      case 3: // Chile
        setSocialNetworks({
          ...socialNetworks,
          facebook: { active: false, link: '' },
          instagram: { active: false, link: '' },
          linkedin: { active: true, link: 'https://www.linkedin.com/in/ald-automotive-chile-7b2560101/' },
          youtube: { active: false, link: '' },
        })
        setProductsLink('https://www.aldautomotive.cl/');
        break;
      case 4: // Colombia
        setSocialNetworks({
          ...socialNetworks,
          facebook: { active: false, link: '' },
          instagram: { active: false, link: '' },
          linkedin: { active: true, link: 'https://www.linkedin.com/company/ald-automotive-colombia/?elqTrackId=a71058d6db984cd7afcd4a98faed8c4f' },
          youtube: { active: false, link: '' },
        });
        setProductsLink('https://www.aldautomotive.co/');
        break;
      default:
        break;
    }
  }

  /**
   * Function to open a new tab with a specific link
   * @param {string} link link to open in the new browser tab
   * @memberof Home
   */
  const openInNewTab = (link) => {
    window.open(link);
  }

  /**
   * Function to open the blog of each region
   * @memberof Home
   */
  const openBlog = () => {
    switch (regionId) {
      case 0: // Brasil
        openInNewTab('https://www.aldautomotive.com.br/blog-mobilidade');
        break;
      case 1: //México
        openInNewTab('https://www.aldautomotive.com.mx/blog-de-movilidad');
        break;
      case 2: // Peru
        openInNewTab('https://www.aldautomotive.pe/sobre-ald/noticias');
        break;
      case 3: // Chile
        openInNewTab('https://www.aldautomotive.cl/noticias/en-la-prensa');
        break;
      case 4: // Colombia
        openInNewTab('https://www.aldautomotive.co/sobre-ald/grupo-ald-automotive/noticias');
        break;

      default:
        break;
    }
  }

  let date = new Date();

  /**
   * Function that return a random video from the ald blob
   * @return {string} url link of the video to display
   * @memberof Home
   */
  const getUrlvideo = () => {
    let randNumber = 0;
    if (regionId === 0) {
      randNumber = Math.round(Math.random() * 3);
    }
    else {
      randNumber = Math.round(Math.random() * 2);
    }
    let url = videos[randNumber];
    return url
  }

  /**
   * Function that returns the producs to each region
   * @returns
   */
  const renderProducts = () => {
    switch (regionId) {
      case 0:
        return (
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} container direction="row" justify="flex-start" alignItems="center" onClick={() => {openInNewTab("https://www.aldautomotive.com.br/inteligencia")}}>
              <MdFiberManualRecord  style={{color: '#828282', height: 10, width: 10}}/>
              <Typography variant="body1" style={{flexWrap: 'wrap', fontSize: 13, color: '#828282', cursor: 'pointer', textDecoration: 'underline'}} >{t('home.products.0')}</Typography>
            </Grid>
            <Grid item xs={12} container direction="row" justify="flex-start" alignItems="center" onClick={() => {openInNewTab("https://www.aldautomotive.com.br/mobilidade")}}>
              <MdFiberManualRecord style={{color: '#828282', height: 10, width: 10}}/>
              <Typography variant="body1" style={{flexWrap: 'wrap', fontSize: 13, color: '#828282', cursor: 'pointer', textDecoration: 'underline'}} >{t('home.products.1')}</Typography>
            </Grid>
            <Grid item xs={12} container direction="row" justify="flex-start" alignItems="center" onClick={() => {openInNewTab("https://www.aldautomotive.com.br/performance")}}>
              <MdFiberManualRecord style={{color: '#828282', height: 10, width: 10}}/>
              <Typography variant="body1" style={{flexWrap: 'wrap', fontSize: 13, color: '#828282', cursor: 'pointer', textDecoration: 'underline'}} >{t('home.products.2')}</Typography>
            </Grid>
            <Grid item xs={12} container direction="row" justify="flex-start" alignItems="center" onClick={() => {openInNewTab("https://www.aldautomotive.com.br/sustentabilidade")}}>
              <MdFiberManualRecord style={{color: '#828282', height: 10, width: 10}}/>
              <Typography variant="body1" style={{flexWrap: 'wrap', fontSize: 13, color: '#828282', cursor: 'pointer', textDecoration: 'underline'}} >{t('home.products.3')}</Typography>
            </Grid>
          </Grid>
        );
      case 1:
      case 2:
      case 3:
      case 4:
        return (
          <Grid item xs={12} spacing={3}>
            <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start" onClick={() => {openInNewTab(productsLink)}} >
              <MdFiberManualRecord style={{color: '#828282', height: 10, width: 10, marginTop: 7}}/>
              <Typography variant="body1" style={{flexWrap: 'wrap', fontSize: 15, width: '90%', color: '#828282', cursor: 'pointer', textDecoration: 'underline'}} >{t('home.products.5')}</Typography>
            </Grid>
            <Grid item xs={12} container direction="row" justify="flex-start" alignItems="flex-start">
              <MdFiberManualRecord style={{color: '#828282', height: 10, width: 10, marginTop: 7}}/>
              <Typography variant="body1" style={{flexWrap: 'wrap', fontSize: 15, width: '90%', color: '#828282', textDecoration: 'underline'}} >{t('home.products.6')}</Typography>
            </Grid>
          </Grid>
        );
      default:
        break;
    }
  }

  return (
    <>
      <div className='Home_Content'>
        <Grid container item direction="row" style={{ left: 0 }} >
          <Grid container item xs={12} md={9} justify="center" alignItems="center" >
            <ReactPlayer loop muted playing width='97%' height='90%' url={getUrlvideo()} />
          </Grid>
          <Grid container direction="row" justify='center' alignItems="center" spacing={3} item xs={12} md={3} style={{ margin: 0, width: '100%' }}>
            <Grid item xs={12} md={11}>
              <Card className="Home_Card">
                <CardContent style={{ minHeight: 150, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Grid item xs={10}>
                    <img
                      src={cardProducts}
                      alt=''
                      className={classes.image}
                    />
                  </Grid>
                  <Typography onClick={() => { openInNewTab(productsLink) }} variant="h6" style={{ fontWeight: 'bold' }} className={classes.networkIcon} color="primary">{t('home.products.4')}</Typography>
                  <Grid container direction='row' item xs={12} >
                    {renderProducts()}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={11}>
              <Card className="Home_Card">
                <CardContent style={{ minHeight: 150 }}>
                  <Grid container direction='row' justify="center" alignItems="center">
                    <Grid item xs={10}>
                      <img
                        src={cardMovility}
                        alt=''
                        className={classes.image}
                      />
                    </Grid>
                    <Grid container item xs={10} justify="center" alignItems="center" direction="column" style={{ paddingLeft: 5 }}>
                      <Typography onClick={openBlog} className={classes.networkIcon} style={{ fontSize: '0.7rem', flexWrap: 'wrap', fontWeight: 'bold', textAlign: 'justify' }} variant="body1" color="primary">{t('home.blog.1')}</Typography>
                      <Typography style={{ flexWrap: 'wrap', color: '#828282', fontSize: 13, fontWeight: 'bold', textAlign: 'justify' }} variant="body1" color="primary">{t('home.blog.2')}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

          <Grid container item xs={12} md={10} justify="space-around" direction="row" alignItems="center" spacing={6} style={{height: 100}}>
            {socialNetworks.facebook.active && <FaFacebookSquare color="primary" fontSize="large" className={classes.socialIcon} onClick={() => {openInNewTab(socialNetworks.facebook.link)}} />}
            {socialNetworks.instagram.active && <FaInstagram color="primary" fontSize="large" className={classes.socialIcon} onClick={() => {openInNewTab(socialNetworks.instagram.link)}} />}
            {socialNetworks.linkedin.active && <FaLinkedin  color="primary" fontSize="large" className={classes.socialIcon} onClick={() => {openInNewTab(socialNetworks.linkedin.link)}} />}
            {socialNetworks.youtube.active && <FaYoutube color="primary" fontSize="large" className={classes.socialIcon} onClick={() => {openInNewTab(socialNetworks.youtube.link)}} />}
          </Grid>

          </Grid>
        </Grid>
      </div>
      <Footer links={links} />
    </>
  );
}

export default Home;