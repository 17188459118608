/**
 * @fileoverview Login
 * Vista de Login
 * @version 2.1.0
 * @author Carlos Emilio Blanco Lopez
 * @date 30/10/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 * @update 25/10/2022
 */

// NPM Packages Imports.
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
//traductions
import { useTranslation } from "react-i18next";
//Estilos
import './design.css';
//redux actions
import { closeCircularProgress } from 'redux/slice/loadingMask';
//image
import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import LayoutLogin from './LayoutLogin';
import ModalLogin from './ModalLogin';
import LoginForm from './Forms/LoginForm';
import ChangePassForm from './Forms/ChangePassForm';
import AuthenticationToken from './Forms/AuthenticationToken';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Source Sans Pro',
  }
});

export default function Login() {
  const dispatch = useDispatch();
  // State.
  const [user, setUser] = useState('');
  const [LastPass, setLastPass] = useState('');
  const [pass, setPass] = useState('');
  const [checked, setChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [positionForm, setPositionForm] = useState(0);
  const [data, setData] = useState([]);
  const [t] = useTranslation('common');

  useEffect(() => {
    ereaseCaches();
    return () => { }
  }, []);

  /**
   * Function to delete browser caches
   */
  const ereaseCaches = () => {
    if ('caches' in window) {
      caches.keys().then((names) => names.forEach((name) => caches.delete(name)));
    }
    dispatch(closeCircularProgress());
  }

  /**
   * Function to show filter modal
   * @memberof Contracts
   */
  const onShowModal = () => {
    setShowModal(true);
  }

  /**
   * Function to show clean Login
   */
  const returnLogin = () => {
    setUser('');
    setPass('');
    setPositionForm(0);
  }

  // Component.
  return (
    <>
      <LayoutLogin>
        <ThemeProvider theme={theme}>
          <div className="form">

            {/* Texto de bienvenida */}

            <div className='welcomeContainer'>
              <div className='welcomeText'>
                <h2>My ALD</h2>
                <p className={'subtitleLogin'}>{t('login.title')}</p>
              </div>
            </div>

            <div className='centralMenu'>
              {
                positionForm === 0 ? (
                  <LoginForm
                    user={user}
                    setUser={setUser}
                    pass={pass}
                    setPass={setPass}
                    checked={checked}
                    setChecked={setChecked}
                    onShowModal={onShowModal}
                    ereaseCaches={ereaseCaches}
                    setLastPass={setLastPass}
                    setPositionForm={setPositionForm}
                    setData={setData}
                    data={data}
                  />

                ) : positionForm === 2 ? (
                  <AuthenticationToken
                    checked={checked}
                    data={data}
                    returnLogin={returnLogin}  />
                ) : (
                  <ChangePassForm LastPass={LastPass} />
                )
              }
            </div>
          </div>
        </ThemeProvider>
      </LayoutLogin>
      <ModalLogin showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}