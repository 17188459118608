/**
 * @fileoverview Principal Componente Notifications
 * @version 1.0.0
 * @author Carlos Emilio Blanco Lopez
 * @date 13/12/2022
 * @copyright 2022 Industrias RESSER S.A de C.V
 */
import React, { useState, useEffect } from 'react';
import Container from 'components/Grid/GridContainer';
import { makeStyles } from '@material-ui/core/styles';
import Item from "components/Grid/GridItem.js";
import { useHistory } from 'react-router-dom';
import { enqueueSuccessSnackbar } from 'libs/Snackbar';
//redux actions
import { addNotification } from 'redux/slice';

import { useTranslation } from "react-i18next";

import styles from '../NotificationView/Notifications.styles';
import CRLVView from 'views/Contracts/CRLVView';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'libs/AxiosHelper';
import swal from 'sweetalert2';

const useStyles = makeStyles(styles);

const Notification = () => {
    const dispatch = useDispatch();
    const { url, id } = useSelector(state => state.Notifications.infoNotification);
    const history = useHistory();
    //styles
    const classes = useStyles();

    //translate
    const [t, i18n] = useTranslation('common');
    const [Document, setDocument] = useState(null);
    const [ShowDocument, setShowDocument] = useState(false);

    useEffect(() => {
        convertFileToBase64(url)
            .then(result => {
                setDocument({ base64: result.split(",")[1], plate: url.split("/71/")[1] });
                setShowDocument(true);
            })
    }, [url])


    /**
     * @title clearDocument
     * @description Función para limpiar el documento
     * @memberof Contract
     * @returns {void}
     * @param {boolean} del
     */

    const clearDocument = (del) => {
        if(del){
            swal.fire({
                title: t('Notification.deleteNotificationTitle'),
                text: t('Notification.deleteNotificationText'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t('generalText.delete'),
                cancelButtonText: t('generalText.cancel'),
                reverseButtons: true
            }).then((result) => {
                if(result.isConfirmed){
                    deleteNotification();
                }
            })
        }
        else{
            getNotification();
            history.push("/");
        }

    }

    /**
     * @title deleteNotification
     * @description Función para eliminar la notificación
     * @memberof Contract
     * @returns {void}
     */

    const deleteNotification = () => {
        axios.del(`Notifications?notification=${id}`)
            .then(response => {
                if (response.data.success) {
                    enqueueSuccessSnackbar(dispatch, t("Notification.deleteNotificationSuccess"));
                    getNotification();
                    history.push("/");
                }
            })
            .catch(error => {
            })
    }


    /**
    * @title getNotification (Async / Await)
    * @description Función Asincrona para el GET de Notificaciones, peticion para la obtencion de Notificaciones relacionados con los usuario.
   */

    const getNotification = async () => {
        axios.get("NotificationsCatalog/All?start=0&limit=100")
            .then(response => {
                if (response.data.success) {
                    dispatch(addNotification(response.data.data));
                }
            })
            .catch(error => {});
    }

    /**
     * @title convertFileToBase64
     * @description Función para convertir un archivo a base64
     * @param {string} url
     * @returns {string} base64
     * @memberof Contract
     */

    const convertFileToBase64 = async (url) => {

        const headers = new Headers({
            'Cache-Control': 'no-cache'
        });

        const response = await fetch(url, { headers });
        const fileBlob = await response.blob();
        const reader = new FileReader();
        reader.readAsDataURL(fileBlob);
        return new Promise((resolve, reject) => {
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            };
            reader.onerror = reject;
        });
    }

    /**
    * @title return
    * @description Función para retornar el componente
    * @returns {JSX.Element} Componente
    * @memberof Contract
    */

    return (
        <Container>
            <Item xs={12} md={12} >
                <CRLVView
                    CRLV={ShowDocument ? Document : ""}
                    clearCRLV={(del) => clearDocument(del)}
                    isViewDocumentNotification
                />
            </Item>
        </Container>
    )
}

export default Notification