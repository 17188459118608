/**
 * @fileoverview ContractTable
 * @version 1.0
 * @author César Paul Hernández Camacho
 * @date 18/11/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 * @update 30/11/2020
 */
import React, { useState, useEffect } from 'react';
//@material-ui components
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
//@material-ui icons
import {MdFilterList} from 'react-icons/md';
// core components
import Button from "components/CustomButtons/Button.js";
import CustomModal from 'components/CustomModal/CustomModal';
import Container from "components/Grid/GridContainer.js";
import KilometerUpdate from './KilometerUpdate';
//translate
import { useTranslation } from 'react-i18next';
//axios
import axios from 'libs/AxiosHelper';
//React Table
import CustomTable from "components/Table/CustomTable";
//redux actions
import { showCircularProgress, closeCircularProgress, onShowAlertMessage } from 'redux/slice';
import { useDispatch, useSelector } from 'react-redux';
//styles
import styles from './Contracts.styles';
import { makeStyles } from '@material-ui/core/styles';
import { FilterContract } from 'components/Filter/Filter';


const useStyles = makeStyles(styles);

const filtersObj = {
    contract: '',
    date: '',
    client: '',
    plates: '',
    noSerie: '',
    status: ''
}

const ContractTable = (props) => {
    //styles
    const classes = useStyles();
    //redux
    const dispatch = useDispatch();
    const regionId = useSelector(state => state.auth.loginData.regionId);
    const language = useSelector((state) => state.configurations.language);
    const userRole = useSelector(state => state.auth.loginData.userRole);
    //translations
    const [t, i18n] = useTranslation('common');

    //State
    const [filters, setFilters] = useState(filtersObj);
    const [clients, setClients] = useState([]);
    const [totalContracts, setTotalContracts] = useState(0);
    const [contracts, setContracts] = useState([]);
    const [totalContractsFilter, setTotalContractsFilter] = useState(0);
    const [contractsFilter, setContractsFilter] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [step, setStep] = useState(100);
    const [ActiveFilter, setActiveFilter] = useState(false);

    /**
     * Function called once when component render for the first time and when the page state changes
     * @memberof ContractTable 
     */
    useEffect(() => {
        getClients();
        getFleetInventory();
    }, [step, language])

    /*
    * UseEffect para obtener los contratos del usuario desde el inicio
    */
    useEffect(() => {
        getContracts();
    }, []);

    /**
     * Función que renderea las columnas de la tabla en base a la región
     */
    const renderColumsTitles = () => {
        const cols = [
            { accessor: "LicensePlate", header: t('contracts.tableColumns.LicensePlate'), isDate: false },
            { accessor: "Brand", header: t('contracts.tableColumns.Brand'), isDate: false },
            { accessor: "Model", header: t('contracts.tableColumns.Model'), isDate: false },
            { accessor: "SerialNumber", header: t('contracts.tableColumns.SerialNumber'), isDate: false },
            { accessor: "UserName", header: t('contracts.tableColumns.UserName'), isDate: false },
            { accessor: "StartDate", header: t('contracts.tableColumns.StartDate'), isDate: true },
            { accessor: "EndDate", header: t('contracts.tableColumns.EndDate'), isDate: true },
            { accessor: "ContractStatus", header: t('contracts.tableColumns.ContractStatus'), isDate: false, isActive: true },
            { accessor: "ContractNumber", header: t('contracts.tableColumns.ContractNumber'), isDate: false },
            { accessor: "ContractType", header: t('contracts.tableColumns.ContractType'), isDate: false },
            { accessor: "ClientNumber", header: t('contracts.tableColumns.ClientNumber'), isDate: false },
            { accessor: "Client", header: t('contracts.tableColumns.Client'), isDate: false },
            // {accessor: "KMDateUpdate", header: t('contracts.tableColumns.KMDateUpdate'), isDate: true},
            // {accessor: "KMUpdate", header: t('contracts.tableColumns.KMUpdate'), isDate: false},
            // {accessor: "Register", header: t('contracts.tableColumns.Register'), width: 130},
        ];

        switch (regionId) {
            //Brasil, México y Perú
            case 0:
                cols.splice(0, 0, { accessor: "LicensePlate", header: t('contracts.tableColumns.CRLV'), isDate: false, defaultValue: "", isCRLV: true });
                cols.splice(9, 0, { accessor: "KMDateUpdate", header: t('contracts.tableColumns.KMDateUpdate'), isDate: true, defaultValue: "" });
                cols.splice(10, 0, { accessor: "KMUpdate", header: t('contracts.tableColumns.KMStart'), isDate: false, defaultValue: "" });
                break;
            case 1:
            case 2:
                cols.splice(8, 0, { accessor: "KMDateUpdate", header: t('contracts.tableColumns.KMDateUpdate'), isDate: true, defaultValue: "" });
                cols.splice(9, 0, { accessor: "KMUpdate", header: t('contracts.tableColumns.KMStart'), isDate: false, defaultValue: "" });
                break;
            default:
                break;
        }

        return cols;
    }

    /**
     * @title getContracts
     * @description Function that gets all the contracts from the API
     */

    const getContracts = () => {
        var url = `FleetInventoryFMV2?contract=&plates=&clientid=&username=&statuscontract=&vin=&page=${page}&start=0&limit=100000`;
        dispatch(showCircularProgress());
        axios.get(url)
            .then(async response => {
                if (await response.data.success) {
                    await setTotalContractsFilter(response.data.total);
                    await setContractsFilter(response.data.items);
                }
                dispatch(closeCircularProgress());
            })
            .catch(error => {
                dispatch(closeCircularProgress());
                dispatch(onShowAlertMessage());
            })
    }

    /**
     * @title URLFleetInventory
     * @description funcion que regresa la url de la api para obtener los contratos
     * @param {Object} filters
     * @param {Number} pageTable
     * @param {Number} start
     * @returns {String} url
     */

    const URLFleetInventory = (withfilters = false, pageTable, start) => {
        if (withfilters) {
            return `FleetInventoryFMV2?contract=${filters.contract.hasOwnProperty('ContractNumber') ? filters.contract.ContractNumber : ""}&plates=${filters.plates !== "" ? filters.plates.LicensePlate : ""}&clientid=${filters.client !== "" ? filters.client.id : ""}&username=&statuscontract=${filters.status}&vin=${filters.noSerie !== "" ? filters.noSerie.SerialNumber : ""}&page=${page}&start=0&limit=100000`;
        }

        if (ActiveFilter) {
            return `FleetInventoryFMV2?contract=${filters.contract.hasOwnProperty('ContractNumber') ? filters.contract.ContractNumber : ""}&plates=${filters.plates !== "" ? filters.plates.LicensePlate : ""}&clientid=${filters.client !== "" ? filters.client.id : ""}&username=&statuscontract=${filters.status}&vin=${filters.noSerie !== "" ? filters.noSerie.SerialNumber : ""}&page=${start === undefined ? page : pageTable}&start=${start === undefined ? count : start}&limit=${step}`;
        }

        return `FleetInventoryFMV2?contract=&plates=&clientid=&username=&statuscontract=&vin=&page=${start === undefined ? page : pageTable}&start=${start === undefined ? count : start}&limit=${step}`;
    }

    /**
     * Function that gets all the FleetInventory from the API
     * @memberof Contracts
     */
    const getFleetInventory = (filters = false, pageTable, start) => {
        dispatch(showCircularProgress());
        axios.get(URLFleetInventory(filters, pageTable, start))
            .then(async response => {
                if (await response.data.success) {
                    await setTotalContracts(response.data.total);
                    await setContracts(response.data.items);
                }
                dispatch(closeCircularProgress());
            })
            .catch(error => {
                dispatch(closeCircularProgress());
                dispatch(onShowAlertMessage());
            })
        // }
    }

    /**
     * Function that gets all the FleetInventory from the API
     * @memberof Contracts
     */

    const getFleetInventoryClean = () => {
        const url = `FleetInventoryFMV2?contract=&plates=&clientid=&username=&statuscontract=&vin=&page=${0}&start=${0}&limit=${step}`;
        dispatch(showCircularProgress());
        axios.get(url)
            .then(async response => {
                if (await response.data.success) {
                    await setTotalContracts(response.data.total);
                    await setContracts(response.data.items);
                    setPage(0);
                    setCount(0);
                    props.onHideModal(false);
                }
                dispatch(closeCircularProgress());
            })
            .catch(error => {
                dispatch(closeCircularProgress());
                props.onHideModal(false);
                dispatch(onShowAlertMessage());
            })
        // }
    }

    /**
     * Function to get all clients
     * @memberof Contracts
     */
    const getClients = () => {
        if (userRole === 7) {
            axios.get(`clientsFMV2/Catalog?customerName=&start=0&limit=12000`)
                .then(response => {
                    if (response.data.success) {
                        setClients(response.data.items);
                    }
                })
                .catch(error => {
                    dispatch(onShowAlertMessage());
                });
        }
        else {
            axios.get(`clientsFMV2`)
                .then(response => {
                    if (response.data.success) {
                        setClients(response.data.items);
                    }
                })
                .catch(error => {
                    dispatch(onShowAlertMessage());
                });
        }
    }

    /**
     * Function to handle when page is changed
     * @param {object} params object to handle when page is changed
     * @memberof ContractTable
     */
    const changePage = async (page, start) => {
        window.scrollTo(0, 0);
        await setPage(page);
        await setCount(start);
        await getFleetInventory(false, page, start === -1 ? 0 : start);
    }

    /**
     * Function to handle limit change
     * @param {int} limit limit of contracts to get
     * @memberof ContractTable
     */
    const onHandleLimitChange = async (limit) => {
        await setPage(0);
        await setStep(limit);
    }

    /**
     * Function to change Filters data
     * @param {event} e Event of the input
     * @memberof Contracts
     */
    const onChangeFiltersData = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        })
    }

    /**
     * Function to apply filters
     * @memberof Contracts
     */
    const onSetFilters = () => {
        setPage(0);
        getFleetInventory(filters);
        props.onHideModal(false);
        setActiveFilter(true);
    }

    /**
     * Function to handle close modal
     * @memberof ContractTable
     */
    const onCloseModal = () => {
        props.onHideModal(false);
    }

    /**
     * Function to handle close Kilometer Update modal
     * @memberof ContractTable
     */
    const onCloseKilometerModal = () => {
        getFleetInventory();
        props.onHideKilometerModal();
    }

    /**
     * Function to clean filters
     * @memberof ContractTable
     */
    const onCleanFilters = () => {
        setFilters(filtersObj);
        setActiveFilter(false);
        getFleetInventoryClean();
    }

    const onChangeAutocomplete = (name, data) => {
        if (data === null) return false;
        setFilters({
            ...filters,
            [name]: data
        })
    }

    return (
        <div className={classes.contractTable} >
            <Container direction="row" item xs={12} >
                <Button
                    onClick={props.onDownloadClickHandler}
                    className={classes.downloadButtonStyle}
                    aria-label="edit"
                    size="lg"
                >
                    {t('contracts.downloadButton')}
                </Button>
                <Button
                    onClick={props.onUploadFileClick}
                    className={classes.uploadButtonStyle}
                    aria-label="edit"
                    size="lg"
                >
                    {t('contracts.uploadButton')}
                </Button>
                {regionId != 3 && regionId != 4 ?
                    <Button
                        onClick={props.onShowKilometerModal}
                        className={classes.uploadButtonStyle}
                        aria-label="updateKM"
                    >
                        {t('contracts.updateKM.updateKM')}
                    </Button>
                    : null
                }
                <IconButton
                    style={{ alignSelf: 'flex-end' }}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={props.onShowModal}
                >
                    <MdFilterList />
                </IconButton>
            </Container>
            <CustomTable
                key={contracts.length}
                cols={renderColumsTitles()}
                rows={contracts}
                totalRows={totalContracts}
                handleLimitChange={(limit) => { onHandleLimitChange(limit) }}
                handlePageChange={(page, start) => { changePage(page, start) }}
                handleCRLV={props.handleCRLV}
                page={page}
                count={count}
            />
            {/* Modal de filtros para contratos */}
            <CustomModal
                open={props.showModal}
                onClose={onCloseModal}
            >
                <Paper elevation={0} className={classes.containerModalFilter}>

                    <FilterContract
                        contractsFilter={contractsFilter}
                        clients={clients}
                        filters={filters}
                        contracts={contracts}
                        onChangeFiltersData={onChangeFiltersData}
                        onChangeAutocomplete={onChangeAutocomplete}
                        onCleanFilters={onCleanFilters}
                        onSetFilters={onSetFilters}
                    />

                </Paper>
            </CustomModal>

            {/* Actualización de Kilometraje */}
            <KilometerUpdate
                contracts={props.contracts}
                showKilometerModal={props.showKilometerModal}
                onCloseKilometerModal={onCloseKilometerModal}
            />
        </div>
    );
}

export default ContractTable;