/**
 * @fileoverview CustomAutocomplete
 * @version 1.0
 * @author Carlos Emilio Blanco Lopez
 * @date 29/05/2023
 * @copyright 2023 Industrias RESSER S.A de C.V
 */

import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme } from '@material-ui/core/styles';
import { VariableSizeList } from 'react-window';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
//translate
import { useTranslation } from 'react-i18next';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
}

const OuterElementContext = React.createContext({});

/**
 * Enviar ref a componentes DOM
 * @param {Array} data 
 * @returns 
 */

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

/**
 * Resetea el Ref del Listado
 * @param {Array} data 
 * @returns 
 */

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

/**
 * Renderiza de manera virtual el listado del Autocomplete antes del visualizarlo en pantalla.
 * @returns     
 */

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (React.isValidElement(child) && child.type === ListSubheader) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

/**
 * Renderiza el listado del Autocomplete antes del visualizarlo en pantalla.
 * @param {object} params 
 * @returns     
 */

const renderGroup = (params) => [
    <ListSubheader key={params.key} component="div">
        {params.group}
    </ListSubheader>,
    params.children,
];



const CustomAutocomplete = ({ options, value, onChange, id }) => {
    const userRole = useSelector(state => state.auth.loginData.userRole);
    const language = useSelector(state => state.configurations.language);
    //translations
    const [t, i18n] = useTranslation('common');

    /**
    * Funtion RenderInput
    * @param {object} params
    * @returns
    * @description Renderiza el input del Autocomplete
    */

    const renderInput = (params) => {
        switch (id) {
            case 'customer':
                return <TextField {...params} label={t('contracts.filters.client')} />;
            case 'contract':
                return <TextField {...params} label={t('contracts.filters.contract')} />;
            case 'client':
                return <TextField {...params} label={t('contracts.filters.client')} />;
            case 'plates':
                return <TextField {...params} label={t('contracts.filters.plates')} />;
            case 'noSerie':
                return <TextField {...params} label={t('contracts.filters.noSerie')} />;
            case 'contractNumber':
                return <TextField {...params} label={t('modalTicketBr.inputs.17')} />;
            case 'notificationContract':
                return <TextField {...params} label={t('Notification.FilterLabels.title')} />;
            case 'notificationCategory':
                return <TextField {...params} label={t('Notification.FilterLabels.category')} />
            default:
                return <TextField {...params} label={t('contracts.filters.contract')} />;
        }
    };

    /**
     * OptionalLabel
     * @param {object} params
     * @returns
     * @description Renderiza el label del Autocomplete
     */

    const OptionalLabel = (option) => {
        switch (id) {
            case 'customer':
                return option.clientName;
            case 'contract':
                return option.ContractNumber || option.contractNumber || '';
            case 'client':
                return (userRole === 7) ? option.clientName : option.client;
            case 'plates':
                return option.LicensePlate || option.plateNumber || '';
            case 'noSerie':
                return option.SerialNumber;
            case 'contractNumber':
                return option.plateNumber;
            case 'notificationContract':
                return option.title === undefined ? '' : option.title;
            case 'notificationCategory':
                if(language === 'en')
                    return option.descriptionEn;
                if(language === 'es')
                    return option.descriptionEs;
                if(language === 'pt')
                    return option.descriptionPt;
                if(language === 'fr')
                    return option.descriptionFr;
            default:
                return option;
        }
    }

    /**
     * RenderOption
     * @param {object} params
     * @returns
     * @description Renderiza el listado del Autocomplete
     */

    const renderOption = (option) => {
        switch (id) {
            case 'customer':
                return <Typography noWrap>{option.clientName}</Typography>;
            case 'contract':
                return <Typography noWrap>{option.ContractNumber || option.contractNumber}</Typography>;
            case 'client':
                return <Typography noWrap>{(userRole === 7) ? option.clientName : option.client}</Typography>;
            case 'plates':
                return <Typography noWrap>{option.LicensePlate || option.plateNumber}</Typography>;
            case 'noSerie':
                return <Typography noWrap>{option.SerialNumber}</Typography>;
            case 'contractNumber':
                return <Typography noWrap>{option.plateNumber}</Typography>;
            case 'notificationContract':
                return <Typography noWrap>{option.title}</Typography>;
            case 'notificationCategory':
                if(language === 'en')
                    return <Typography noWrap>{option.descriptionEn}</Typography>;
                if(language === 'es')
                    return <Typography noWrap>{option.descriptionEs}</Typography>;
                if(language === 'pt')
                    return <Typography noWrap>{option.descriptionPt}</Typography>;
                if(language === 'fr')
                    return <Typography noWrap>{option.descriptionFr}</Typography>;
            default:
                return <Typography noWrap>{option.ContractNumber}</Typography>;
        }
    }

    return (
        <Autocomplete
            fullWidth
            disableListWrap
            labelId="StatusLabel"
            id={id}
            options={options}
            renderGroup={renderGroup}
            ListboxComponent={ListboxComponent}
            getOptionLabel={OptionalLabel}
            renderInput={renderInput}
            renderOption={renderOption}
            value={value}
            onChange={(e, data) => { onChange(id, data) }}
        />
    )
}

export default CustomAutocomplete;

export { ListboxComponent, renderGroup };

ListboxComponent.propTypes = {
    children: PropTypes.node,
};

