/**
 * @fileoverview Filter Component for Notifications
 * @version 1.0.0
 * @author Carlos Emilio Blanco Lopez
 * @date 13/03/2023
 * @copyright 2023 Industrias RESSER S.A de C.V
 */
import React, { useState, useContext } from 'react'
import CustomModal from 'components/CustomModal/CustomModal';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import NotificationFilterStyle from './NotificationFilter.style';
// core components
import { makeStyles } from '@material-ui/core';

//translate
import { useTranslation } from 'react-i18next';
import { DataModulo } from '../NotificationsTable';

import { useSelector } from 'react-redux';
import { NotificationFilter } from 'components/Filter/Filter';

const useStyles = makeStyles(NotificationFilterStyle);

const FormFilter = {
    title: '',
    categorie: '',
    createDate: '',
    publishDate: ''
}

/**
 * NotificationFilter
 * @param {boolean} open
 * @param {boolean} onClose
 * @param {boolean} ListNotifications
 * @returns Fragment
 */

const Filter = ({ open, onClose, ListNotifications, GetNotifications }) => {
    const language = useSelector(state => state.configurations.language);
    const { ReducerState } = useContext(DataModulo);

    const classes = useStyles();
    const [t, i18n] = useTranslation('common');

    const [Filter, setFilter] = useState(FormFilter);

    /**
     * Función que detecta el cambio de fechas
     * @param {*} e
     */
    const onChangeFormData = (e) => {
        const inputName = e.target.name;
        let inputValue = e.target.value;
        setFilter({
            ...Filter,
            [inputName]: inputValue
        });
    }

    /**
     * @title onSend
     * @description Funcion principal para el filtrado de notificaciones
     */

    const onSend = () => {
        GetNotifications(Filter.categorie.idCategory, Filter.title.title, Filter.createDate, Filter.publishDate);
        onClose();
    }

    /**
     * @title onReset
     * @description Funcion principal para el reseteo del filtro
     */

    const onReset = () => {
        GetNotifications(undefined, '', '', '');
        setFilter(FormFilter);
        onClose();

    }

    // Fragment

    return (
        <CustomModal
            open={open}
            onClose={onClose}>
            <Paper elevation={0} className={classes.containerModalFilter}>
                <NotificationFilter 
                    onReset={onReset}
                    onSend={onSend}
                    Filter={Filter}
                    ListNotifications={ListNotifications}
                    ReducerState={ReducerState}
                    onChangeFormData={onChangeFormData}
                    onChangeTitle={(e, data) => {
                        setFilter({
                            ...Filter,
                            title: data !== null ? data : ''
                        })
                    }}
                    onChangeCategory={(e, data) => {
                        setFilter({
                            ...Filter,
                            categorie: data !== null ? data : ''
                        })
                    }}
                    />
            </Paper>
        </CustomModal>
    )
}

Filter.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
}

export default Filter;
