/**
 * @fileoverview UserProfile
 * Vista de la configuración del perfil
 * @version 1.0.0
 * @author César Paul Hernández Camacho
 * @date 30/10/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 * 
 */
import React, { useEffect, useState, useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
//redux actions
import { useDispatch, useSelector } from 'react-redux'
import { setUserDataR, showCircularProgress, closeCircularProgress, onShowAlertMessage } from 'redux/slice';
// @material-ui/icons
import { MdExpandMore, MdModeEdit, MdHelpOutline } from 'react-icons/md';

//Translations
import { useTranslation } from "react-i18next";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
//axios
import axios from 'libs/AxiosHelper';
//utils
import { getBase64Image } from 'libs/utils';
import Rssr from 'libs/Rssr';
//messages
import { enqueueSuccessSnackbar, enqueueErrorSnackbar, enqueueWarningSnackbar } from 'libs/Snackbar';
//Styles
import styles from './UserProfile.style';
var CryptoJS = require("crypto-js");

const useStyles = makeStyles(styles);

const userDataObject = {
  Name: '',
  Email: '',
  PhoneNumber: '',
  BirthDate: '',
  RFC: '',
  ZipCode: '',
  FleetManegerAddress: '',
  ProfileImageUrl: '',
}

const userDataErrorsObject = {
  name: false,
  email: false,
  cellphone: false,
  birthdate: false,
  rfc: false,
  postalCode: false,
  adress: false
}

export default function UserProfile(p) {
  const classes = useStyles();
  let [t, i18n] = useTranslation('common');

  const fileInputRef = useRef(null);

  //redux
  const dispatch = useDispatch();
  const userDataR = useSelector(state => state.auth.userData);
  const regionId = useSelector(state => state.auth.loginData.regionId);

  //state
  const [userData, setUserData] = useState(userDataObject);
  const [userDataErrors, setUserDataErrors] = useState(userDataErrorsObject);
  const [file, setFile] = useState('');
  const [usePost, setUsePost] = useState(false);
  const [flagProfileUpdate, setFlagProfileUpdate] = useState(true);

  useEffect(() => {
    dispatch(showCircularProgress());
    getProfileData();
    dispatch(closeCircularProgress());
  }, [])

  /**
   * Función encargada de obtener la información del perfil del usuario
   * 
   */
  const getProfileData = () => {
    axios.get('FMProfile')
      .then(async response => {
        if (response.data.success) {
          dispatch(setUserDataR(response.data.profileInfo));
          if ((response.data.profileInfo.Name !== '') && (response.data.profileInfo.BirthDate !== null)) {
            const url = response.data.profileInfo.ProfileImageUrl;
            const fileName = CryptoJS.AES.decrypt(
              url,
              "MyALD@Document"
            ).toString(CryptoJS.enc.Utf8);  
            blobToBase64(fileName);
            setUserData({
              Name: response.data.profileInfo.Name,
              Email: response.data.profileInfo.Email,
              PhoneNumber: response.data.profileInfo.PhoneNumber,
              BirthDate: response.data.profileInfo.BirthDate.split('T')[0],
              RFC: response.data.profileInfo.RFC,
              ZipCode: response.data.profileInfo.ZipCode,
              FleetManegerAddress: response.data.profileInfo.FleetManegerAddress,

            });
          }
          else {
            setUsePost(true);
          }
        }
      })
      .catch(error => {
        dispatch(onShowAlertMessage());
      });
  }

  /**
   * Función que detecta cuando existe un cambio en campos del perfil del usuario
   * @param {Campo que esta siendo editado} e 
   * 
   */
  const onChangeProfileData = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value
    })
    setUserDataErrors({
      ...userDataErrors,
      [e.target.name]: false
    });
  }

  /**
   * Función que realiza la actualización de la información en la sección de perfil de usuario
   * 
   */
  const onSaveProfileData = () => {
    var image = '';
    if (file != '') {
      image = file;
    }
    else {
      getBase64Image(userDataR.ProfileImageUrl, function (base64image) {
        image = base64image;
      });
    }

    const objectToSend = {
      UserName: userData.Name,
      PhoneNumber: userData.PhoneNumber,
      BirthDate: `${userData.BirthDate}T00:00:00`,
      RFC: userData.RFC,
      ZipCode: userData.ZipCode,
      UserAddress: userData.FleetManegerAddress,
      ProfileImageUrl: image
    };
    if (usePost) {
      axios.post('FMProfile', objectToSend).
        then(async response => {
          if (await response.data.success) {

            getProfileData();
            setUsePost(false);
            enqueueSuccessSnackbar(dispatch, t('profileConfiguration.profile.success.1'));
          }
          else {
            enqueueErrorSnackbar(dispatch, t('profileConfiguration.profile.errors.1'));
          }
        });
    }
    else {
      axios.put('FMProfile', objectToSend).
        then(async response => {
          if (response.data.success) {
            enqueueSuccessSnackbar(dispatch, t('profileConfiguration.profile.success.1'));
            setTimeout(() => {
              dispatch(showCircularProgress());
              getProfileData();
              dispatch(closeCircularProgress());
            }, 1000);
          }
          else {
            enqueueErrorSnackbar(dispatch, t('profileConfiguration.profile.errors.1'));
          }
        })
        .catch(error => {
          dispatch(onShowAlertMessage());
        });
    }
  }

  /**
   * 
   * @param {*} input 
   */
  const enableErrors = (input) => {
    setUserDataErrors({
      ...userDataErrors,
      [input]: true
    });
  }

  /**
   * Función encargada de validar los datos colocados en la sección del perfil de usuario
   * 
   */
  const validateInputs = () => {
    if (!Rssr.validateEmailFormat(userData.Email)) {
      enableErrors('email');
      enqueueErrorSnackbar(dispatch, t('profileConfiguration.profile.errors.2'));
      return false;
    }
    switch (regionId) {
      case 0:
        if (!Rssr.validateCPFBrasil(userData.RFC)) {
          enableErrors('rfc');
          enqueueErrorSnackbar(dispatch, t('profileConfiguration.profile.errors.4'));
          return false;
        }
        break;
      case 1:
        if (!Rssr.validateRFCMX(userData.RFC)) {
          enableErrors('rfc');
          enqueueErrorSnackbar(dispatch, t('profileConfiguration.profile.errors.4'));
          return false;
        }
        break;

      default:
        break;
    }
    onSaveProfileData();
  }

  /**
   * Función utilizada para detectar el clic sobre el input de tipo file
   * 
   */
  const onUploadPicture = () => {
    fileInputRef.current.click();
  }

  /**
   * Función que realiza el upload de la imagen seleccionada por el usuario como de perfil
   * @param {*} e 
   * 
   */
  const onFileChange = async (e) => {
    if (e.target.files.length > 0) {
      var aux = await toBase64(e.target.files[0]);
      aux = aux.split(",")[1];
      setFile(aux);
      setFlagProfileUpdate(false);
    }
    else {
      setFile('');
    }
  }

  /**
   * Función encargada de convertir un archivo a base64
   * @param {*} file 
   */
  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const blobToBase64 = async (url) => {
    const headers = new Headers({
      "Cache-Control": "no-cache",
    });

    const response = await fetch(url, { headers });
    const blob = await response.blob();
    const base64image = await toBase64(blob);

    setFile(base64image.split(",")[1]);


  };
  return (
    <div>
      <GridContainer>
        {/* Sección de Perfil */}
        <GridItem xs={12} md={12} lg={5} style={{ marginTop: 50 }}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<MdExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <p className={classes.titleSeccionText}> {t('profileConfiguration.profile.title')} </p>
            </AccordionSummary>
            <AccordionDetails>
              <form className={classes.centerCardContent}
                onSubmit={(e) => {
                  e.preventDefault();
                  validateInputs();
                }}
              >
                <Badge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  badgeContent={
                    <Button onClick={onUploadPicture} className={classes.updateButton} aria-label="edit" justIcon round>
                      <MdModeEdit />
                    </Button>
                  }
                >
                  <Avatar style={{ height: 100, width: 100 }} alt="Logo" src={userDataR.ProfileImageUrl && file !== '' && `data:image/jpeg;base64,${file}` } />
                </Badge>
                <GridContainer alignItems={"center"} justify="center">
                  <GridItem xs={10} >
                    <CustomInput
                      labelText={t('profileConfiguration.profile.inputs.name')}
                      id="username"
                      error={userDataErrors.name}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: userData.Name,
                        required: true,
                        name: "Name",
                        onChange: (e) => { onChangeProfileData(e); setFlagProfileUpdate(false); }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={9}>
                    <CustomInput
                      labelText={t('profileConfiguration.profile.inputs.email')}
                      id="email"
                      error={userDataErrors.email}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: userData.Email,
                        required: true,
                        name: "Email",
                        onChange: (e) => { onChangeProfileData(e); setFlagProfileUpdate(false); }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={1} >
                    <Tooltip title={t('profileConfiguration.profile.message')}>
                      <IconButton aria-label="delete">
                        <MdHelpOutline />
                      </IconButton>
                    </Tooltip>
                  </GridItem>
                  <GridItem xs={10} sm={10} md={10} lg={5}>
                    <CustomInput
                      labelText={t('profileConfiguration.profile.inputs.cellphone')}
                      id="cellphone"
                      error={userDataErrors.cellphone}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: userData.PhoneNumber,
                        type: 'number',
                        name: "PhoneNumber",
                        onChange: (e) => { onChangeProfileData(e); setFlagProfileUpdate(false); }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={10} sm={10} md={10} lg={5}>
                    <FormControl fullWidth>
                      <CustomInput
                        labelText={t('profileConfiguration.profile.inputs.birthdate')}
                        id="birthdate"
                        labelProps={{
                          shrink: true
                        }}
                        inputProps={{
                          value: userData.BirthDate,
                          type: 'date',
                          name: "BirthDate",
                          onChange: (e) => { onChangeProfileData(e); setFlagProfileUpdate(false); }
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={10} sm={10} md={10} lg={6}>
                    <CustomInput
                      labelText={t('profileConfiguration.profile.inputs.rfc')}
                      id="rfc"
                      error={userDataErrors.rfc}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: userData.RFC,
                        required: true,
                        name: "RFC",
                        onChange: (e) => { onChangeProfileData(e); setFlagProfileUpdate(false); }
                      }}
                    />
                    {/* <Tooltip title="Hola" placement="top" >?</Tooltip> */}
                  </GridItem>
                  <GridItem xs={10} sm={10} md={10} lg={4}>
                    <CustomInput
                      labelText={t('profileConfiguration.profile.inputs.postalCode')}
                      id="postalCode"
                      error={userDataErrors.postalCode}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: userData.ZipCode,
                        type: 'number',
                        name: "ZipCode",
                        onChange: (e) => { onChangeProfileData(e); setFlagProfileUpdate(false); }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={10}>
                    <CustomInput
                      labelText={t('profileConfiguration.profile.inputs.adress')}
                      id="adress"
                      error={userDataErrors.adress}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: userData.FleetManegerAddress,
                        multiline: true,
                        rows: 3,
                        name: "FleetManegerAddress",
                        onChange: (e) => { onChangeProfileData(e); setFlagProfileUpdate(false); }
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <input
                  style={{ overflow: 'hidden', width: 0, height: 0 }}
                  ref={fileInputRef}
                  onChange={onFileChange}
                  id="profilePicture"
                  type="file"
                  accept="image/jpeg" />

                <GridContainer alignItems={"center"} justify="center">
                  <Button type="sumbit" className={classes.updateButton} aria-label="edit" disabled={flagProfileUpdate}>
                    {t('generalText.edit')}
                  </Button>
                </GridContainer>
              </form>
            </AccordionDetails>
          </Accordion>
        </GridItem>
      </GridContainer>
    </div>
  );
}
