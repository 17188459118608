/**
 * @fileoverview ModalTicketGnr styles
 * @version 1.0.0
 * @author Martin Duran Anguiano
 * @date 28/01/2021
 * @copyright Industrias RESSER S.A de C.v
 */

const headerLinksStyle = theme => ({
  titles: {
    color: "#050B7F",
    fontWeight: 'bold',
    fontSize: '1.5rem'
  },
  subTitles: {
    color: "#050B7F",
    fontWeight: '300',
    fontSize: '1.5rem'
  },
  infoTitle: {
    color: "#808080",
    fontWeight: '300',
    fontSize: '0.9rem'
  },
  accordion: {
    display: "flex",
    alignItems: 'center',
    justifyContent: "center",
    border: '2px solid red'
  },
  containerFormSections: {
    width: '100%'
  },
  customInput: {
    margin: 0
  },
  containerModalForm:{
    width: '95%',
    maxHeight: "250vw",
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      maxHeight: "100vw",
    }
  },
  requestServiceButton: {
    backgroundColor: "#0BCBFB",
    color: "#050B7F",
    fontWeight: 'bold',
    borderRadius: "1.5rem",
    marginTop: 15,
    marginBottom: 15, 
    minWidth: 200
  }
});

export default headerLinksStyle;