/**
 * @fileoverview Table for SAT page.
 * @version 1.1.0
 * @author Carlos Emilio Blanco Lopez
 * @date 23/01/2024
 * @copyright 2024 RESSER S.A de C.V
 */

import React, { Fragment, useReducer, useState, useEffect } from 'react';
import CustomTable from 'components/Table/CustomTable';
import Filter from './Filter';
import ModalComentarios from './Modal/ModalComentarios';
import ModalHistorial from './Modal/ModalHistorial';
import ModalInfo from './Modal/ModalInformativo';
import { initialValue, reducer } from './context';
import { useTranslation } from 'react-i18next';
import ModalAttachment from './Modal/ModalAttachment';

export const Context = React.createContext({});

const Table = ({ data, GetAgreement, GetComments, GetHistory, GetAttachment, setPlateData, PlateData, Clients, SelectClient,Page, onChangePage, setSelectClient, ...props }) => {

    const [state, dispatch] = useReducer(reducer, initialValue);
    const [DataAgreements, setDataAgreements] = useState([]);
    const [DataTable, setDataTable] = useState([]);
    const [Mode, setMode] = useState(0);
    const { t } = useTranslation('common');

    const cols = [
        { accessor: "AgreementId", header: t('ModuleAgreements.table.idSat') },
        { accessor: "Plate", header: t('ModuleAgreements.table.plate') },
        { accessor: "VhDescription", header: t('ModuleAgreements.table.model') },
        { accessor: "CustomerName", header: t('ModuleAgreements.table.enterprise') },
        { accessor: "AggrementDate", header: t('ModuleAgreements.table.dateService'), isDate: true },
        { accessor: "Description", header: t('ModuleAgreements.table.description') },
        { accessor: "TotalAmount", header: t('ModuleAgreements.table.price'), isPrice: true },
        { accessor: "SupplierName", header: t('ModuleAgreements.table.provider') },
        { accessor: "Status", header: t('ModuleAgreements.table.status') },
        { accessor: "actions", header: t('ModuleAgreements.table.actions'), isSAT: true },
    ];

    useEffect(() => {

        dispatch({ type: 'Agreements', value: data });
        setDataTable([...data].map((el) => {
            return {
                ...el,
                Status: Status[el.Status] || "En proceso por ALD",
                Empresa: 'Sin Dato de cliente',
            }
        }
        ));
    }, [data])

    /**
     * @Function OnEdit
     * @description Función que abre el modal de información
     */

    const onEdit = (e) => {
        setDataAgreements(e);
        GetAgreement(e.AgreementId, dispatch, e.VersionId);
    };

    /**
     * @Function OnComment
     * @description Función que abre el modal de comentarios
     */

    const onComment = (e) => {
        setDataAgreements(e);
        GetComments(e.AgreementId, dispatch);
    }

    /**
     * @Function OnHistory
     * @description Función que abre el modal de historial
     */

    const onHistory = (e) => {
        setDataAgreements(e);
        GetHistory(e.AgreementId, dispatch);
    }

    /**
    * @Function OnAttachment
    * @description Función que abre el modal de Attachment
    */
    const onAttachment = (e) => {
        setDataAgreements(e);
        GetAttachment(e.AgreementId, dispatch, e.VersionId);
    }

    return (
        <Context.Provider value={{ state, dispatch }}>
            <Fragment>
                <Filter setMode={setMode} Mode={Mode} setPlateData={setPlateData} PlateData={PlateData} GetData={props.GetData} Clients={Clients} SelectClient={SelectClient} setSelectClient={setSelectClient} setPage={props.setPage} />
                {SelectClient?.clientNumber ?
                    data.length > 0 ?
                        <CustomTable
                            isSat
                            key={0}
                            cols={cols}
                            rows={DataTable}
                            totalRows={data.length}
                            onEdit={onEdit}
                            onComment={onComment}
                            onHistory={onHistory}
                            onAttachment={onAttachment}
                            handleLimitChange={(() => onChangePage('back', 1))}
                            handlePageChange={() => onChangePage('next', 1)}
                            page={Page}
                            count={0}
                        />
                        :
                        <div style={{ color: "#828282", fontWeight: '300', fontSize: '1.0rem' }}>{t('ModuleAgreements.DoesntHaveBudget')}</div>
                    :
                    <div style={{ color: "#828282", fontWeight: '300', fontSize: '1.0rem' }}>{t('ModuleAgreements.ChooseCustomer')}</div>
                }

                <ModalComentarios OnEdit={onEdit} OnComments={onComment} OnHistory={onHistory} OnAttachment={onAttachment} {...props} DataAgreements={DataAgreements} />
                <ModalHistorial OnEdit={onEdit} OnComments={onComment} OnHistory={onHistory} OnAttachment={onAttachment} DataAgreements={DataAgreements} />
                <ModalInfo OnEdit={onEdit} OnComments={onComment} OnHistory={onHistory} OnAttachment={onAttachment}  {...props} DataAgreements={DataAgreements} />
                <ModalAttachment OnEdit={onEdit} OnComments={onComment} OnHistory={onHistory} OnAttachment={onAttachment} {...props} DataAgreements={DataAgreements} />
            </Fragment>
        </Context.Provider>
    )
}

export default Table;

export const Status = {
    "AGREEMENT_STATUS_APPROVED": "Aprobada",
    "AGREEMENT_STATUS_REJECTED": "Rechazada",
    "AGREEMENT_STATUS_ASSIGNED": "Aprobación asignada",
    "AGREEMENT_STATUS_NEW": "Nueva aprobación",
    "AGREEMENT_STATUS_AWAITING_FM_AUTHORIZATION": "En espera de autorización",
    "AGREEMENT_STATUS_FM_AUTHORIZED": "Presupuesto aprobado",
    "AGREEMENT_STATUS_FM_AUTHORIZATION_REJECTED": "Presupuesto rechazado",
    "AGREEMENT_STATUS_COMPLETED": "Completado por ALD",
    "AGREEMENT_STATUS_REJECTED": "Rechazado por ALD",
    "AGREEMENT_STATUS_EXPORTED": "Exportado por ALD",
    "AGREEMENT_STATUS_ONHOLD": "En espera por ALD",
    "AGREEMENT_STATUS_CANCELLED": "Cancelado por ALD",
    "AGREEMENT_STATUS_INVOICED": "Facturado por ALD",
};