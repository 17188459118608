/**
 * @fileoverview CustomTable.jsx - Componente para la creacion de tablas
 * @version 2.0.0
 * @since 13/10/2021
 * @module components/Table/CustomTable
 * @author Carlos Emilio Blanco Lopez
 */

import React, { useState, useEffect, useId } from "react";
import PropTypes from "prop-types";

// core components
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Popper,
  Fade,
  Paper,
  Tooltip
} from "@material-ui/core";

// styles
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import { makeStyles } from "@material-ui/core/styles";

//@material-ui icons
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { MdVisibility, MdEdit, MdDeleteOutline, MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import Rssr from "../../libs/Rssr";
import { getComparator, stableSort } from "./TableFunctions";
import { TableCellTemplate } from "components/tableCell/TableCell";
import { TableCellCRLV } from "components/tableCell/TableCell";
import { TableCellNotification } from "components/tableCell/TableCell";
import { TableCellLink } from "components/tableCell/TableCell";
import { TableCellGloveBox } from "components/tableCell/TableCell";
import { KanbanEditIcon, KanbanComentIcon, KanbanHistoryIcon, KanbanAttachmentIcon} from "../../assets/img/Icons/Icons";
//translate
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";

const useStyles = makeStyles(styles);

let ps;

const CustomTable = (props) => {
  const [isNotification, setIsNotification] = useState(0);
  const id = useId();
  const classes = useStyles();
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [order, setOrder] = React.useState("asc");
  const [MobileOpen, setMobileOpen] = React.useState(false);
  const [orderBy, setOrderBy] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [titleGB, settitleGB] = useState("");
  const [open, setOpen] = React.useState(false);
  //translations
  const [t, i18n] = useTranslation("common");

  // ref para el apoyo del PerfectScrollbar
  const mainPanel = React.createRef();

  // useEffect para inicializar el PerfectScrollbar
  useEffect(() => {
    setIsNotification(
      props.cols.filter((element) => element.isNotification === true).length
    );
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: false,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  /*
   * @title resizeFunction
   * @description Funcion para el resize del PerfectScrollbar
   * @returns
   * */

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  /**
   * @title handleRequestSort
   * @description Funcion para ordenar la tabla
   * @param {*} event
   * @param {*} property
   * @returns
   */

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  /**
   * @title handleLimitChange
   * @description Funcion para cambiar el limite de la tabla
   * @param {*} event
   * @returns
   */

  const handleLimitChange = async (event) => {
    setLimit(event.target.value);
    setPage(0);
    props.handleLimitChange(event.target.value);
  };

  /**
   * @title handlePageChange
   * @description Funcion para cambiar la pagina de la tabla
   * @param {*} event
   * @param {*} newPage
   * @returns
   */

  const handlePageChange = async (event, newPage) => {
    setPage(newPage);
    props.handlePageChange(newPage, newPage * limit - 1);
  };

  /**
   * @title createSortHandler
   * @description Funcion para ordenar la tabla
   * @param {*} property
   * @returns
   */

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  /**
   * @title handleClick
   * @description Funcion para mostrar el tooltip
   * @param {*} Open
   * @param {*} id
   * @returns
   */

  const handleClick = (Open, id) => (event) => {
    const description = props.ListDocs.filter(
      (element) => element.DocumentType === id
    );
    setAnchorEl(event.currentTarget);
    setOpen(Open);
    settitleGB(description[0] !== undefined ? description[0].Description : "");
  };

  /**
   * @title openURL
   * @description Funcion para abrir una url
   * @param {*} url
   * @returns
   */

  const openURL = (url) => {
    if (!url.startsWith("http")) {
      url = "https://" + url;
    }
    window.open(url);
  };

  return (
    <>
      {/** Popper auxiliar para el GloveBox */}
      <Popper open={open} anchorEl={anchorEl} placement="bottom" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper style={{ marginLeft: 30, maxWidth: 250 }} elevation={0}>
              <div className="Sidebar_Popper">{titleGB}</div>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Card className={classes.tableResponsive} ref={mainPanel}>
        <Box minWidth={isNotification > 0 ? 900 : 150} maxHeight={"47vh"}>
          <Table stickyHeader key={id}>
            {/** Cabecera de la tabla */}
            <CustomTableHeader
              cols={props.cols}
              showAction={props.showAction}
              actions={props.actions}
              createSortHandler={createSortHandler}
              order={order}
              orderBy={orderBy}
            />
            {/** Cuerpo de la tabla */}
            <TableBody>
              {stableSort(props.rows, getComparator(order, orderBy)).map(
                (customer, key) => {
                  return (
                    <TableRow
                      hover
                      onClick={() => {
                        if (props.eneableRowClick) props.onRowClick(customer);
                      }}
                      key={customer.id}
                    >
                      {props.showAction && (
                        <TableCell padding="checkbox">
                          <div className={classes.styleActions}>
                            <IconContext.Provider
                              value={{
                                color: "#050B7F",
                                size: "20px",
                                className: "react-icons",
                                style: { marginLeft: "8px", cursor: "pointer" },
                              }}
                            >
                              <MdVisibility
                                onClick={() => {
                                  props.onShow(customer);
                                }}
                              />
                            </IconContext.Provider>
                          </div>
                        </TableCell>
                      )}
                      {/* Renderizado de Celdas de la tabla */}
                      {props.cols.map((item, key) => {
                        let dataToShow = customer[props.cols[key].accessor];
                        // Si el dato es una fecha, se formatea
                        if (item.isDate)
                          return (
                            <TableCellTemplate key={`${key}`}>
                              {Rssr.formatDate(dataToShow) ||
                                t("contracts.tableColumns.DateNotRegisteredKM")}
                            </TableCellTemplate>
                          );
                        // Si el dato es un precio
                        if (item.isPrice)
                          return (
                            <TableCellTemplate key={`${key}`}>
                              {Rssr.formatPrice(dataToShow)}
                            </TableCellTemplate>
                          );
                        // Si es tipo Active or Inactive
                        if (item.isActive)
                          return (
                            <TableCellTemplate key={`${key}`}>
                              {dataToShow === "Active"
                                ? t("contracts.tableColumns.Active")
                                : t("contracts.tableColumns.Inactive")}
                            </TableCellTemplate>
                          );

                        // Si es CRLV, se renderiza un boton
                        if (item.isCRLV)
                          return (
                            <TableCellCRLV
                              key={key}
                              dataToShow={dataToShow}
                              handleCRLV={props.handleCRLV}
                            />
                          );

                        // Si es una notificaciones, se renderiza un preview
                        if (item.isNotification)
                          return (
                            <TableCellNotification
                              key={key}
                              customer={customer}
                              onView={props.onView}
                              header={item.header}
                            />
                          );

                        // Si es un link, se renderiza un link con un tooltip
                        if (item.isURL)
                          return (
                            <TableCellLink
                              key={key}
                              url={dataToShow}
                              openURL={openURL}
                            />
                          );

                        // Si es un GloveBox, se renderiza un tooltip
                        if (item.isGloveBox)
                          return (
                            <TableCellGloveBox
                              key={key}
                              handleClick={handleClick}
                              handleOpenModal={props.handleOpenModal}
                              docsType={props.ListDocsType}
                              customer={customer}
                            />
                          );

                        // Si es un SAT, se renderiza un opciones Correspondientes
                        if (item.isSAT)
                          return (
                            <TableCellTemplate key={`${key}`}>
                              <div style={{ width: '90%', justifyContent: 'space-between', display: 'flex' }}>
                                <Tooltip title={t('ModuleAgreements.actions.view')} arrow>
                                  <div onClick={() => props.onEdit(customer)} style={{ cursor: 'pointer' }}><KanbanEditIcon /></div>
                                </Tooltip>
                                <Tooltip title={t('ModuleAgreements.actions.comments')} arrow>
                                  <div onClick={() => props.onComment(customer)} style={{ cursor: 'pointer' }}><KanbanComentIcon /></div>
                                </Tooltip>
                                <Tooltip title={t('ModuleAgreements.actions.history')} arrow>
                                  <div onClick={() => props.onHistory(customer)} style={{ cursor: 'pointer' }}><KanbanHistoryIcon /></div>
                                </Tooltip>
                                <Tooltip title={t('ModuleAgreements.actions.attachment')} arrow>
                                  <div onClick={() => props.onAttachment(customer)} style={{ cursor: 'pointer' }}><KanbanAttachmentIcon /></div>
                                </Tooltip>
                              </div>
                            </TableCellTemplate>
                          );
                        // Renderizado de celdas default
                        return (
                          <TableCell
                            key={`${key}`}
                            style={{ color: "#828282" }}
                          >
                            {dataToShow}
                          </TableCell>
                        );
                      })}
                      {props.actions && (
                        <TableCell padding="checkbox">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                            }}
                          >
                            {props.updateAction && (
                              <IconContext.Provider
                                value={{
                                  color: "#3f51b5",
                                  size: "20px",
                                  className: "react-icons",
                                  style: {
                                    cursor: "pointer",
                                    marginRight: "10px",
                                  },
                                }}
                              >
                                <MdEdit
                                  onClick={() => props.onEdit(customer)}
                                />
                              </IconContext.Provider>
                            )}
                            {props.delAction && (
                              <IconContext.Provider
                                value={{
                                  color: "#3f51b5",
                                  size: "20px",
                                  className: "react-icons",
                                  style: {
                                    cursor: "pointer",
                                    marginRight: "10px",
                                  },
                                }}
                              >
                                <MdDeleteOutline
                                  onClick={() => props.onDelete(customer)}
                                />
                              </IconContext.Provider>
                            )}
                          </div>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </Box>
      </Card>
      {
        !props.isSat ? (
          <TablePagination
            count={props.totalRows}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={props.page !== undefined ? props.page : page}
            rowsPerPage={limit}
            rowsPerPageOptions={[50, 100, 200]}
          />) : (
            <section style={{fontSize: "0.875rem", fontWeight: "normal", marginTop: 25, width: "98%", display: 'flex', gap: 10, justifyContent: 'flex-end'}}>
              <IconContext.Provider value={{ color: "#3c4858", style: { cursor: "pointer", position: 'relative' } }}>
                <MdArrowBackIos onClick={handleLimitChange} />
              </IconContext.Provider>
              <p style={{
                position: "relative",
                bottom: 5,
                right: 2.5
              }}>Pag: {props.page}</p>
              <IconContext.Provider value={{ color: "#3c4858", style: { cursor: "pointer" } }}>
                <MdArrowForwardIos onClick={handlePageChange} />
              </IconContext.Provider>
            </section>
        )
      }
    </>
  );
};

// default props for this component
CustomTable.defaultProps = {
  accessor: "header",
  actions: false,
  showAction: false,
  updateAction: false,
  delAction: false,
  eneableRowClick: false,
};

// props types for this component
CustomTable.propTypes = {
  cols: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  totalRows: PropTypes.number,
  accessor: PropTypes.string,
  actions: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onShow: PropTypes.func,
  showAction: PropTypes.bool,
  updateAction: PropTypes.bool,
  delAction: PropTypes.bool,
  onRowClick: PropTypes.func,
  eneableRowClick: PropTypes.bool,
};

export default CustomTable;

/**
 * CustomTableHeader
 * @param {*} cols
 * @param {*} showAction
 * @param {*} actions
 * @param {*} orderBy
 * @param {*} order
 * @param {*} createSortHandler
 * @returns
 * @description Custom table header component
 */

export const CustomTableHeader = ({
  cols,
  showAction,
  orderBy,
  actions,
  order,
  createSortHandler,
}) => {
  const classes = useStyles();
  const id = useId();

  return (
    <TableHead>
      <TableRow>
        {showAction && (
          <TableCell
            padding="checkbox"
            style={{ textAlign: "center" }}
            key={id}
          />
        )}
        {cols.map((col, key) => {
          return (
            <TableCell
              key={`${key}`}
              style={{ color: "#050B7F" }}
              sortDirection={orderBy === col.accessor ? order : false}
            >
              <TableSortLabel
                active={orderBy === col.accessor}
                direction={orderBy === col.accessor ? order : "asc"}
                onClick={createSortHandler(col.accessor)}
                disabled={col.isCRLV}
              >
                {col.header}
                {orderBy === col.accessor ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
        {actions && (
          <TableCell
            padding="checkbox"
            style={{ textAlign: "center" }}
            key={0}
          />
        )}
      </TableRow>
    </TableHead>
  );
};
