/**
 * @fileoverview Custom Poppers
 * @version 2.1.0
 * @author Carlos Emilio Blanco Lopez
 * @date 27/03/2023
 * @copyright 2023 Industrias RESSER S.A de C.V
 */
import React from 'react';
// nodejs library to set properties for components
import PropTypes from "prop-types";
import Poppers from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

const CustomPoppers = ({
  open,
  anchorEl,
  children
}) => {
  const classes = useStyles();
  return (
    <Poppers
      open={open}
      anchorEl={anchorEl}
      transition
      disablePortal
      className={
        classNames({ [classes.popperClose]: !open }) +
        " " +
        classes.popperNav
      }>
      {
        ({ TransitionProps, placement }) =>
        (<Grow
          {...TransitionProps}
          id="profile-menu-list-grow"
          style={{
            backgroundColor: "050b7f",
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom"
          }}>
            {children}
        </Grow>
        )
      }
    </Poppers>
  )
}

CustomPoppers.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
  children: PropTypes.node
};

export default CustomPoppers;