/**
 * @fileoverview Maintenance ModalTicket General para (México, Perú, Chile y Colombia)
 * @version 1.0.0
 * @author Martin Duran Anguiano
 * @date 28/01/2021
 * @copyright 2020 RESSER S.A de C.V
 */

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { Select } from '@material-ui/core';

import styles from './ModalTicketGnr.styles';
import Paper from '@material-ui/core/Paper';
import Item from "components/Grid/GridItem.js";
import Container from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import CustomInput from "components/CustomInput/CustomInput.js";
import { enqueueSuccessSnackbar, enqueueErrorSnackbar, enqueueInfoSnackbar } from 'libs/Snackbar';
import { onShowAlertMessage, showCircularProgress, closeCircularProgress } from 'redux/slice'
import axios from 'libs/AxiosHelper';
import Rssr from '../../../../libs/Rssr';
import CustomDatePicker from 'components/CustomInput/CustomDateTimePicker';


import { useDispatch, useSelector } from 'react-redux';


const axiosB = require('axios');
const useStyles = makeStyles(styles);

//State form error object
const formMaintenanceErrorsObject = {
    contract: false,
    phone: false,
    km: false,
    estate: false,
    city: false,
    date1: false,
    date2: false,
    details: false,
    rName: false,
    rPhone: false,
    rEmail: false,
    directionPick: false
}

//State form maintenance object
const formDataObject = {
    contract: '',
    phone: '',
    km: '',
    estate: 0,
    city: 0,
    date1: '',
    date2: '',
    details: '',
    rName: '',
    rPhone: '',
    rEmail: '',
    levaETrazDir: '',
    serviceType: 0
}

export default function ModalTicketGnr(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [t, i18n] = useTranslation('common');
    const [formData, setformData] = useState(formDataObject);
    const [formMaintenanceErrors, setformMaintenanceErrors] = useState(formMaintenanceErrorsObject);
    const [states, setstates] = useState([]);
    const [cities, setcities] = useState([]);
    const [aditionalSize, setaditionalSize] = useState(6)
    const [directionPick, setdirectionPick] = useState("")
    const [plateNumer, setPlateNumer] = useState('');

    const regionId = useSelector(state => state.auth.loginData.regionId);
    const language = useSelector((state) => state.configurations.language);
    useEffect(() => {
        getStates();
    }, []);

    /**
     * Función encargada de obtener el catalogo de estados disponibles para el usuario
     * 
     */
    const getStates = async () => {
        axios.get(`StateCatalogFMV2?regionId=${regionId}`)
            .then(async response => {
                setstates(response.data.items)
            })
            .catch((error) => {
            });
    }

    /**
     * Función encargada de realizar la búsqueda de información del conductor de un contrato
     * @param {*} contractId
     */
    const getDriver = async (contractId, contractNumber) => {
        dispatch(showCircularProgress());
        axios.get(`UsersDriver/Relation?contractId=${contractId}`)
            .then(async response => {
                dispatch(closeCircularProgress());
                if (response.data.success) {
                    if (response.data.total === 1) {
                        //Alerta de aviso de que el contrato esta asignado a un conductor.
                        enqueueInfoSnackbar(dispatch, t('modalTicketGnr.infoMessages.0'));
                        setformData({
                            ...formData,
                            'contract': contractNumber,
                            'rName': response.data.users[0].userName,
                            'rEmail': "", //TODO: Agregar valor de Email en el GET
                            'rPhone': "" //TODO: Agregar valor de Phone en el GET
                        });
                    }
                    else if (response.data.total > 1) {
                        //Alerta de aviso de que el contrato esta asignado a mas de un conductor.
                        enqueueInfoSnackbar(dispatch, t('modalTicketGnr.infoMessages.1'));
                        setformData({
                            ...formData,
                            'contract': contractNumber
                        });
                    }
                } else {
                    //Alerta de aviso que el contrato no esta asignado a un conductor.
                    enqueueInfoSnackbar(dispatch, t('modalTicketGnr.infoMessages.2'));
                    setformData({
                        ...formData,
                        'contract': contractNumber
                    })
                }
            })
            .catch(error => {
                dispatch(closeCircularProgress());
                if (error.response.status === 401) {
                    dispatch(onShowAlertMessage());
                }
            });
    }

    /**
     * Función encargada de renderear el texto estado de forma dinamica en base a la región del usuario
     * 
     */
    const setEstateTranslate = () => {
        switch (regionId) {
            case 0:
            case 1:
                return t('modalTicketGnr.inputs.3');
            case 2:
            case 4:
                return t('modalTicketGnr.inputs.11');
            case 3:
                return t('modalTicketGnr.inputs.12');
            default:
                return t('modalTicketGnr.inputs.3');
                break;
        }
    }

    /**
     * Función encargada de obtener el estado y buscar las ciudades de ese estado
     * @param {Evento ejecutado en la función} event
     * @param {Valor seleccionado del selector} newValue
     * 
     */
    const onChangeState = async (event, newValue) => {
        if (newValue === null) return false;

        setformData({
            ...formData,
            estate: newValue.id
        });

        axios.get(`CityCatalogFMV2?stateId=${newValue.id}&regionId=${regionId}`)
            .then(async response => {
                setcities(response.data.items);
            })
            .catch((error) => {
            });
    }

    /**
     * Función que obtiene el valor en el campo ciudad
     * @param {Evento ejecutado en la función} e 
     * @param {Valor seleccionado del selector} newValue 
     */
    const onChangeCity = (e, newValue) => {
        if (newValue === null) return false;
        setformData({
            ...formData,
            city: newValue.id
        });
    }

    /**
     * Función que detecta cambios en los campos del formulario de mantenimiento
     * @param {*} e 
     */
    const onChangeFormData = (e, o) => {
        const inputName = e.target.name;
        let inputValue = e.target.value;

        setformData({
            ...formData,
            [inputName]: inputValue
        });

        setformMaintenanceErrors({
            ...formMaintenanceErrors,
            [inputName]: false
        });

        //Sección para mensajes de aviso
        switch (inputName) {
            case 'pickup':
                const index = o.key;
                if (index != .0) {
                    enqueueInfoSnackbar(dispatch, t('modalTicketBr.infoMessages.0'));
                    setaditionalSize(3);
                    if (index == .1) {
                        setdirectionPick(t('modalTicketBr.inputs.10'));
                    }
                    else if (index == .2) {
                        setdirectionPick(t('modalTicketBr.inputs.11'));
                    }
                    else if (index == .3) {
                        setdirectionPick(t('modalTicketBr.inputs.12'));
                    }
                }
                break;
            default:
                break;
        }
    }

    /**
     * Función que detecta el cambio en campos que solo admiten numeros
     * @param {*} e 
     * 
     */
    const onChangeInputNumber = (e) => {
        const inputName = e.target.name;
        let text = e.target.value;
        let newText = '';
        let numbers = '0123456789';

        for (var i = 0; i < text.length; i++) {
            if (numbers.indexOf(text[i]) > -1) {
                newText = newText + text[i];
            }
        }

        setformData({
            ...formData,
            [inputName]: newText
        });

        setformMaintenanceErrors({
            ...formMaintenanceErrors,
            [inputName]: false
        });
    }

    /**
     * Función que detecta la selección del contrato
     * @param {*} e 
     * @param {*} newValue
     */
    const onChangeContract = (e, newValue) => {
        if (newValue === null) {
            setformData({
                ...formData,
                contract: "",
                rName: "",
                rEmail: "",
                rPhone: ""
            });
            setPlateNumer('');

            return false
        }
        setPlateNumer(newValue.plateNumber);

        const contractNumber = newValue.contractNumber;
        const contractId = newValue.contractId;

        getDriver(contractId, contractNumber);
    }

    /**
     * Función que realiza la solicitud de ticket
     * 
     */
    const onSendTicket = async () => {
        const tipoTicket = formData.serviceType;
        if (tipoTicket < 6 || tipoTicket > 7) {
            enqueueErrorSnackbar(dispatch, t("modalTicketGnr.errorMessages.18"));
            return;
        }

        dispatch(showCircularProgress());
        const base = {
            contrato: formData.contract,
            estado: formData.estate,
            responsibleName: formData.rName,
            responsibleEmail: formData.rEmail,
            responsiblePhone: formData.rPhone,
            lang: language,
            region: regionId,
            telefono: formData.phone,
            zona: formData.city,
            ticketData: {
                comentario: formData.details,
                km: formData.km,
                fecha1: formData.date1.format("YYYY-MM-DD HH:mm"),
                fecha2: formData.date2.format("YYYY-MM-DD HH:mm"),
            },
            tipoTicket: formData.serviceType
        }

        if (validateInputs(base)) {
            var obj = { ticket: base };
            let message = "";
            axios.post('TicketsFleetManager', obj)
                .then(async response => {
                    if (response.data.success) {
                        //El servició fue solicitado de forma correcta.
                        if (response.data.Message === 1) {
                            message = t("modalTicketGnr.successMessages.0");
                            props.closeModal(false);
                            props.onReloadTickets();
                        }
                        enqueueSuccessSnackbar(dispatch, message);

                    } else {
                        //Error al solicitar servicio
                        if (response.data.Message === 2) {
                            message = t("modalTicketBr.errorMessages.18")
                            enqueueErrorSnackbar(dispatch, message);
                        }

                        //Error la unidad ya cuenta con un servicio similar en proceso
                        if (response.data.Message === 3) {
                            message = t("modalTicketGnr.errorMessages.13");
                            enqueueInfoSnackbar(dispatch, message);
                        }
                    }
                    dispatch(closeCircularProgress());
                })
                .catch(error => {
                    dispatch(closeCircularProgress());
                    if (error.response.status === 401) {
                        dispatch(closeCircularProgress());
                        return false
                    }
                    enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.19"));
                });
        } else {
            dispatch(closeCircularProgress());
        }
    }

    /**
     * Función encargada de validar los datos del usuario.
     * @param {datos del ticket} data 
     * 
     */
    const validateInputs = (data) => {
        const { contrato, responsibleName, responsibleEmail, responsiblePhone, tipoTicket, telefono, estado, zona, region } = data;
        const { fecha1, fecha2, infoAdicional, km } = data.ticketData;

        const dateOneValid = Rssr.validateServiceDayRegion(fecha1, region);
        const dateTwoValid = Rssr.validateServiceDayRegion(fecha2, region);

        //Validaciones de días y horarios seleccionados
        if (dateOneValid === 2 || dateOneValid === 3 || dateOneValid === 4 || dateOneValid === 5) {
            switch (dateOneValid) {
                //Error en el día seleccionado
                case 2:
                    //Validaciones para Colombia y Peru
                    if (region === 4 || regionId === 2) {
                        enqueueErrorSnackbar(dispatch, t("modalTicketGnr.errorMessages.15"));
                    } else {
                        enqueueErrorSnackbar(dispatch, t("modalTicketGnr.errorMessages.11"));
                    }
                    break;

                //Error en la hora del servicio
                case 3:
                    let messageAlert = "";

                    switch (regionId) {
                        case 1: //Mexico y Chile
                        case 3:
                            messageAlert = t("modalTicketGnr.errorMessages.12");
                            break;

                        case 2: //Peru
                            //Alerta entre semana
                            if (new Date(fecha1).getDay() < 5) {
                                messageAlert = t("modalTicketGnr.errorMessages.23");
                            }

                            //Alerta de los sabados
                            else if (new Date(fecha1).getDay() === 6) {
                                messageAlert = t("modalTicketGnr.errorMessages.24");
                            }
                            break;

                        case 4: //Colombia
                            // Alerta entre semana
                            if (new Date(fecha1).getDate() < 5) {
                                messageAlert = t("modalTicketGnr.errorMessages.17");
                            }

                            //Alerta de los sabados
                            else if (new Date(fecha1).getDate() === 5) {
                                messageAlert = t("modalTicketGnr.errorMessages.16");
                            }
                            break;

                        default:
                            messageAlert = t("modalTicketGnr.errorMessages.12");
                            break;
                    }
                    enqueueErrorSnackbar(dispatch, messageAlert);
                    break;

                //Error por fecha del servicio sin 48 horas de antelación
                case 4:
                    enqueueErrorSnackbar(dispatch, t("modalTicketGnr.errorMessages.19"));
                    break;

                //Error por fecha menor
                case 5:
                    enqueueErrorSnackbar(dispatch, t("modalTicketGnr.errorMessages.20"));
                    break;

                default:
                    break;
            }

            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "date1": true
            });
            return false;
        }

        if (dateTwoValid === 2 || dateTwoValid === 3 || dateTwoValid === 4 || dateTwoValid === 5) {
            switch (dateTwoValid) {
                //Error en el día seleccionado
                case 2:
                    //Validaciones para Colombia y Peru
                    if (region === 4 || region === 2) {
                        enqueueErrorSnackbar(dispatch, t("modalTicketGnr.errorMessages.15"));
                    } else {
                        enqueueErrorSnackbar(dispatch, t("modalTicketGnr.errorMessages.11"));
                    }
                    break;

                //Error en la hora del servicio
                case 3:
                    let messageAlert = "";

                    switch (regionId) {
                        case 1: //Mexico y Chile
                        case 3:
                        case 3:
                        case 3:
                            messageAlert = t("modalTicketGnr.errorMessages.12");
                            break;

                        case 2: //Peru
                            //Alerta entre semana
                            if (new Date(fecha2).getDay() < 5) {
                                messageAlert = t("modalTicketGnr.errorMessages.23");
                            }

                            //Alerta de los sabados
                            else if (new Date(fecha1).getDay() === 5) {
                                messageAlert = t("modalTicketGnr.errorMessages.24");
                            }
                            break;

                        case 4: //Colombia
                            // Alerta entre semana
                            if (new Date(fecha2).getDate() < 5) {
                                messageAlert = t("modalTicketGnr.errorMessages.17");
                            }

                            //Alerta de los sabados
                            else if (new Date(fecha2).getDate() === 5) {
                                messageAlert = t("modalTicketGnr.errorMessages.16");
                            }
                            break;

                        default:
                            messageAlert = t("modalTicketGnr.errorMessages.12");
                            break;
                    }
                    enqueueErrorSnackbar(dispatch, messageAlert);
                    break;

                //Error por fecha del servicio sin 48 horas de antelación
                case 4:
                    enqueueErrorSnackbar(dispatch, t("modalTicketGnr.errorMessages.19"));
                    break;

                //Error por fecha menor
                case 5:
                    enqueueErrorSnackbar(dispatch, t("modalTicketGnr.errorMessages.20"));
                    break;

                default:
                    break;
            }

            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "date2": true
            });
            return false;
        }

        //Validaciones generales
        if (telefono === '') {
            enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.0"));
            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "phone": true
            });
            return false;
        }
        if (contrato === '') {
            enqueueErrorSnackbar(dispatch, t("modalTicketGnr.errorMessages.1"));
            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "contract": true
            });
            return false;
        }
        if (responsibleName === '') {
            enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.2"));
            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "rName": true
            });
            return false;
        }
        if (responsibleEmail === '') {
            enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.3"));
            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "rEmail": true
            });
            return false;
        }
        if (!Rssr.validateEmailFormat(responsibleEmail)) {
            enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.4"));
            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "rEmail": true
            });
            return false;
        }
        if (responsiblePhone === '') {
            enqueueErrorSnackbar(dispatch, t("modalTicketBr.errorMessages.5"));
            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "rPhone": true
            });
            return false;
        }
        if (dateOneValid === false) {
            enqueueErrorSnackbar(dispatch, t("modalTicketGnr.errorMessages.14"));
            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "date1": true
            });
            return false;
        }
        if (dateTwoValid === false) {
            enqueueErrorSnackbar(dispatch, t("modalTicketGnr.errorMessages.14"));
            setformMaintenanceErrors({
                ...formMaintenanceErrors,
                "date2": true
            });
            return false;
        }

        //Validaciones especificas por ticket
        switch (tipoTicket) {
            case 7:
            case 6:
                if (km === '') {
                    enqueueErrorSnackbar(dispatch, t("modalTicketGnr.errorMessages.6"));
                    setformMaintenanceErrors({
                        ...formMaintenanceErrors,
                        "km": true
                    });
                    return false;
                }
                if (estado === "" || estado === 0) {
                    let messageAlert = ""

                    switch (regionId) {
                        case 1: //México
                            messageAlert = t("modalTicketGnr.errorMessages.7");
                            break;
                        case 2: //Peru
                        case 4: //Colombia
                            messageAlert = t("modalTicketGnr.errorMessages.21");
                            break;
                        case 3: //Chile
                            messageAlert = t("modalTicketGnr.errorMessages.22");
                            break;
                        default:
                            messageAlert = t("modalTicketGnr.errorMessages.7");
                            break;
                    }

                    enqueueErrorSnackbar(dispatch, messageAlert);
                    return false;
                }
                if (zona === "" || zona === 0) {
                    enqueueErrorSnackbar(dispatch, t("modalTicketGnr.errorMessages.8"));
                    return false;
                }
                if (infoAdicional === '') {
                    enqueueErrorSnackbar(dispatch, t("modalTicketGnr.errorMessages.9"));
                    return false;
                }
                if ((fecha1 === fecha2)) {
                    enqueueErrorSnackbar(dispatch, t("modalTicketGnr.errorMessages.10"));
                    return false;
                }
                return true;
            default:
                return false;
        }
    }


    return (
        <Paper elevation={0} className={classes.containerModalForm}>
            <Container className={classes.containerFormSections}>
                <Item xs={12} md={12}>
                    <p className={classes.titles}> {t("maintenance.titles.0")} </p>
                </Item>
            </Container>
            {/* Vehiculo, Teleforno, Kilometraje */}
            <Container direction="row" alignItems="flex-end" className={classes.containerFormSections}>
                <Item xs={12} sm={3} md={3}>
                    <FormControl fullWidth>
                        <InputLabel>{t('modalTicketGnr.inputs.13')}</InputLabel>
                        <Select
                            labelId="serviceSelector"
                            id="serviceSelector"
                            name="serviceType"
                            value={formData.serviceType}
                            onChange={(e) => { onChangeFormData(e) }}
                        >
                            <MenuItem value={6}> {t('modalTicketGnr.serviceType.0')} </MenuItem>
                            <MenuItem value={7}> {t('modalTicketGnr.serviceType.1')} </MenuItem>
                        </Select>
                    </FormControl>
                </Item>
                <Item xs={12} sm={3} md={3}>
                    <FormControl fullWidth>
                        <Autocomplete
                            id="combo-box-vehicles"
                            size="small"
                            freeSolo={true}
                            options={props.contracts}
                            inputValue={(props.contracts.length > 0) ? plateNumer.value : t('modalTicketGnr.noContractMessage')}
                            getOptionLabel={(option) => option.plateNumber}
                            onChange={(event, newValue) => { onChangeContract(event, newValue); }}
                            renderInput={(params) => <TextField {...params} label={t('modalTicketGnr.inputs.0')} margin="normal" />}
                        />
                    </FormControl>
                </Item>
                <Item xs={12} sm={3} md={3}>
                    <CustomInput
                        id="phone"
                        labelText={t('modalTicketGnr.inputs.1')}
                        error={formMaintenanceErrors.phone}
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            value: formData.phone,
                            name: "phone",
                            onChange: (e) => { onChangeInputNumber(e); }
                        }}
                    />
                </Item>
                <Item xs={12} sm={3} md={3}>
                    <CustomInput
                        id="km"
                        labelText={t('modalTicketGnr.inputs.2')}
                        error={formMaintenanceErrors.km}
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            value: formData.km,
                            name: "km",
                            onChange: (e) => { onChangeInputNumber(e); },
                        }}
                    />
                </Item>
            </Container>

            <Container direction="row" justify="flex-end" alignItems="flex-end" className={classes.containerFormSections}>
                <Item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <Autocomplete
                            id="comboEstado"
                            disableListWrap={true}
                            freeSolo={true}
                            size="small"
                            options={states}
                            getOptionLabel={(option) => option.value}
                            onChange={(event, newValue) => { onChangeState(event, newValue); }}
                            inputValue={states.value}
                            renderInput={(params) => <TextField {...params} label={setEstateTranslate()} margin="normal" />}
                        />
                    </FormControl>
                </Item>
                <Item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <Autocomplete
                            id="comboCiudad"
                            freeSolo={true}
                            options={cities}
                            getOptionLabel={(option) => option.value}
                            onChange={(event, newValue) => { onChangeCity(event, newValue); }}
                            inputValue={formData.estate.value}
                            renderInput={(params) => <TextField {...params} label={t('modalTicketGnr.inputs.4')} className={classes.root} margin="normal" />}
                        />
                    </FormControl>
                </Item>
                <Item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <CustomDatePicker
                            id="date"
                            labelText={t('modalTicketBr.inputs.5')}
                            value={formData.date1}
                            onChange={(date) => {
                                onChangeFormData({ target: { name: "date1", value: date } });
                            }}
                            validateMin={Rssr.getMinRequestServiceDate(regionId)} />
                    </FormControl>
                </Item>
                <Item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <CustomDatePicker
                            id="date"
                            labelText={t('modalTicketBr.inputs.6')}
                            value={formData.date2}
                            onChange={(date) => {
                                onChangeFormData({ target: { name: "date2", value: date } });
                            }}
                            validateMin={Rssr.getMinRequestServiceDate(regionId)} />
                    </FormControl>
                </Item>
            </Container>

            {/* Blindado, ida y vuelta, dirección y adicional */}
            <Container direction="row" justify="flex-end" alignItems="flex-end" className={classes.containerFormSections}>
                <Item xs={12} sm={12} md={12}>
                    <FormControl fullWidth>
                        <TextField
                            id="details"
                            label={t("modalTicketGnr.inputs.7")}
                            placeholder={t("modalTicketGnr.inputs.10")}
                            name="details"
                            multiline
                            value={formData.details}
                            onChange={(e) => { onChangeFormData(e); }}
                            rows={3} />
                    </FormControl>
                </Item>
            </Container>

            {/* Responsable title*/}
            <Container direction="row" justify="flex-end" alignItems="flex-end" className={classes.containerFormSections}>
                <Item xs={12} md={12}>
                    <p className={classes.titles}> {t("maintenance.titles.1")} </p>
                    {(regionId !== 4) &&
                        <p className={classes.infoTitle}>{t("maintenance.titles.2")}</p>
                    }
                    {(regionId === 4) &&
                        <p className={classes.infoTitle}> {t('maintenance.titles.4')} </p>
                    }
                </Item>
            </Container>

            {/* Nombre, telefono y email */}
            <Container direction="row" justify="flex-start" alignItems="flex-start" className={classes.containerFormSections}>
                <Item xs={12} sm={4} md={4}>
                    <CustomInput
                        id="responsalName"
                        labelText={t("modalTicketGnr.inputs.8")}
                        error={formMaintenanceErrors.rName}
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            value: formData.rName,
                            name: "rName",
                            onChange: (e) => { onChangeFormData(e); }
                        }}
                    />
                </Item>
                <Item xs={12} sm={4} md={4}>
                    <CustomInput
                        id="responsablePhone"
                        labelText={(regionId === 4) ? t("modalTicketGnr.inputs.14") : t("modalTicketGnr.inputs.1")}
                        error={formMaintenanceErrors.rPhone}
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            value: formData.rPhone,
                            name: "rPhone",
                            onChange: (e) => { onChangeInputNumber(e); }
                        }}
                    />
                </Item>
                <Item xs={12} sm={4} md={4}>
                    <CustomInput
                        id="responsalEmail"
                        labelText={(regionId === 4) ? t("modalTicketGnr.inputs.15") : t("modalTicketGnr.inputs.9")}
                        error={formMaintenanceErrors.rEmail}
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            value: formData.rEmail,
                            name: "rEmail",
                            onChange: (e) => { onChangeFormData(e); }
                        }}
                    />
                </Item>
            </Container>

            <Container direction="column" justify="center" alignItems="center" className={classes.containerFormSections}>
                <Item xs={12} sm={12} md={12}>
                    <Button
                        onClick={onSendTicket}
                        className={classes.requestServiceButton}
                        contractsdisabled={(props.contracts.length > 0) ? false : true}
                        aria-label="edit">
                        {t("maintenance.buttons.1")}
                    </Button>
                </Item>
            </Container>
        </Paper>
    );
}