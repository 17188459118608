/**
 * @fileoverview Configuration Slice by Categories
 * @version 1.0
 * @author Carlos Emilio Blanco Lopez
 * @date 15/08/2023
 * @copyright 2023 Industrias RESSER S.A de C.V
 **/

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    categories: [],
};

const modulesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        addCategories: (state, action) => {
            state.categories = action.payload;
        },
        deleteCategories: (state, action) => {
            state.categories = [];
        }
    },
});

export const {
    addCategories,
    deleteCategories
} = modulesSlice.actions;

export default modulesSlice.reducer;