/**
 * @fileoverview Maintenance Group view styles
 * @version 1.0
 * @author René Francisco Coss y León Monterde
 * @date 30/06/2020
 * @copyright 2021 Industrias RESSER S.A de C.V
 */

 const headerLinksStyle = theme => ({
    titles: {
      color: "#050B7F",
      fontWeight: 'bold',
      fontSize: '1.5rem'
    },
    dialog:{
      borderRadius: '1.0rem',
    },
    dialogHeaderFooter:{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    tableHeader:{
      color: '#050B7F',
      fontWeight: 'bold',
      minWidth: 270,
    },
    tableCell:{
      minWidth: 270,
    },
    button: {
      backgroundColor: "#0BCBFB",
      color: "#050B7F",
      fontWeight: 'bold',
      borderRadius: '1.5rem',
      marginRight: 15,
      minWidth: 150,
    },
    buttonAddRow: {
      color: "#050B7F",
      fontWeight: 'bold',
      borderRadius: '1.5rem',
    },
    buttonDeleteRow:{
      textAlign: 'left',
      borderRadius: '1.5rem',
      fontSize: 15,
      textDecorationLine: 'underline',
    },
    buttonClose: {
      color: "#050B7F",
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '1.5rem',
    },
    divider:{
      width: '100%',
      marginTop: 25,
      marginBottom: 5,
    },
    dividerTwo:{
      width: '50%',
      marginTop: 25,
      marginBottom: 5,
    },
    textField:{
      borderBottom: 2,
    },
  });

 export default headerLinksStyle;