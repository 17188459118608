/**
 * @fileoverview TableCell.jsx - Componente para renderizar las celdas de la tabla
 * @version 1.0.0
 * @since 13/10/2021
 * @module components/tableCell/TableCell
 * @author Carlos Emilio Blanco Lopez
 */

import React from 'react';

import { TableCell, Tooltip } from '@mui/material';

// styles
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import stylesTooltip from "assets/jss/material-dashboard-react/tooltipStyle";
import { makeStyles } from '@material-ui/core/styles';

import {
    CRLVIcon,
    PolizaSeguroIcon,
    PolizaSeguroIconDisabled,
    TalonVerificacionIcon,
    TalonVerificacionIconDisabled,
    TarjetaCirculacionIcon,
    CertificadoArrendamientoIcon,
    CertificadoArrendamientoIconDisabled,
    TarjetaCirculacionIconDisabled,
} from 'assets/img/Icons/Icons';
import { useId } from 'react';

const useStylesTooltip = makeStyles(stylesTooltip);
const useStyles = makeStyles(styles);

/**
 * @title TableCellCRLV
 * @description Componente para renderizar las celdas de la tabla en formato CRLV
 * @param {key} key - key del componente
 * @param {handleCRLV} handleCRLV - Función para abrir el modal de CRLV
 * @param {dataToShow} dataToShow - Objeto con la información del cliente
 */

export const TableCellCRLV = ({ key, handleCRLV, dataToShow }) => {
    return (
        <TableCellTemplate key={`${key}`} >
            <CRLVIcon style={{ cursor: 'pointer' }} onClick={() => handleCRLV(dataToShow)} />
        </TableCellTemplate>
    );
}

/**
 * @title TableCellTNotification
 * @description Componente para renderizar las celdas de la tabla en formato NOTIFICACIÓN
 * @param {key} key - key del componente
 * @param {onView} onView - Función para abrir la notificación
 * @param {customer} customer - Objeto con la información del cliente
 * @param {header} header - Texto a mostrar en la celda
 */

export const TableCellNotification = ({ key, onView, customer, header }) => {
    const classes = useStyles();
    return (
        <TableCellTemplate key={`${key}`} >
            <div
                className={classes.isNotification}
                onClick={() => onView(customer)}>
                {header}
            </div>
        </TableCellTemplate>
    );
}

/**
 * @title TableCellTemplate
 * @description Componente para renderizar las celdas de la tabla en formato LINK
 * @param {key} key - key del componente
 * @param {url} url - url del link
 * @param {openURL} openURL - Función para abrir la url
 * @param {placeholder} placeholder - Texto a mostrar en la celda
 */

export const TableCellLink = ({ key, url, openURL }) => {
    const classes = useStyles();
    const classesTooltip = useStylesTooltip();
    return (
        <TableCellTemplate key={`${key}`} >
            <Tooltip
                title={url}
                placement="bottom-start"
                classes={{ tooltip: classesTooltip.tooltipDnD }}>
                <div
                    style={{ width: "10vw", cursor: 'pointer' }}
                    onClick={() => openURL(url)}>
                    <p className={classes.placeholder}>
                        {url}
                    </p>
                </div>
            </Tooltip>
        </TableCellTemplate>
    );
}

/**
 * @title TableCellGloveBox
 * @description Componente para renderizar los iconos de los documentos de la guantera
 * @param {key} key - key del componente
 * @param {handleOpenModal} handleOpenModal - Función para abrir el modal de los documentos
 * @param {docsType} docsType - Array con los tipos de documentos que se pueden mostrar
 * @param {handleClick} handleClick - Función para mostrar el tooltip
 * @param {customer} customer - Objeto con la información del cliente
 */

export const TableCellGloveBox = ({ key, handleOpenModal, docsType, handleClick, customer }) => {
    const DocumentsbyChile = docsType.some(e => e === 7);
    let dataType_1 = (<></>);
    let dataType_2 = (<></>);
    let dataType_3 = (<></>);
    let dataType_4 = (<></>);

    if (docsType.some(element => element === 1 || element === 4 || element === 8 || element === 11)) {
        dataType_1 = customer.CirculationCard.Value ?
            (<TarjetaCirculacionIcon
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenModal(customer, DocumentsbyChile ? 1 : 0, true)}
                onMouseEnter={handleClick(true, DocumentsbyChile ? docsType[1] : docsType[0])}
                onMouseLeave={handleClick(false)}

            />) : (
                <TarjetaCirculacionIconDisabled
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleOpenModal(customer, DocumentsbyChile ? 1 : 0, false)}
                    onMouseEnter={handleClick(true, DocumentsbyChile ? docsType[1] : docsType[0])}
                    onMouseLeave={handleClick(false)} />)
    }


    if (docsType.some(element => element === 2 || element === 5 || element === 7 || element === 12)) {
        dataType_2 = customer.InsurancePolicy.Value ?
            (<PolizaSeguroIcon
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenModal(customer, DocumentsbyChile ? 0 : 1, true)}
                onMouseEnter={handleClick(true, DocumentsbyChile ? docsType[0] : docsType[1])}
                onMouseLeave={handleClick(false)} />) : (
                <PolizaSeguroIconDisabled
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleOpenModal(customer, DocumentsbyChile ? 0 : 1, false)}
                    onMouseEnter={handleClick(true, DocumentsbyChile ? docsType[0] : docsType[1])}
                    onMouseLeave={handleClick(false)} />)
    }

    if (docsType.some(element => element === 3 || element === 6 || element === 9 || element === 13)) {
        dataType_3 = customer.VerificationCard.Value ?
            (<TalonVerificacionIcon
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenModal(customer, 2, true)}
                onMouseEnter={handleClick(true, docsType[2])}
                onMouseLeave={handleClick(false)} />) :
            (<TalonVerificacionIconDisabled
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenModal(customer, 2, false)}
                onMouseEnter={handleClick(true, docsType[2])}
                onMouseLeave={handleClick(false)} />)
    }

    if (docsType.some(element => element === 10)) {
        dataType_4 = customer.FuelCard.Value ?
            (<CertificadoArrendamientoIcon
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenModal(customer, 3, true)}
                onMouseEnter={handleClick(true, docsType[3])}
                onMouseLeave={handleClick(false)} />) :
            (<CertificadoArrendamientoIconDisabled
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenModal(customer, 3, false)}
                onMouseEnter={handleClick(true, docsType[3])}
                onMouseLeave={handleClick(false)} />)
    }

    return (
        <TableCellTemplate key={`${key}`} >
            <div style={{ width: '80%', justifyContent: 'space-between', display: 'flex' }}>
                {dataType_1}
                {dataType_2}
                {dataType_3}
                {dataType_4}
            </div>
        </TableCellTemplate>
    );
}


/** 
 * @title TableCellTemplate
 * @description Componente para renderizar las celdas de la tabla en formato TEXTO
 * @param {key} key - key del componente
 * @param {children} children - Texto a mostrar en la celda
 */

export const TableCellTemplate = ({ children, key }) => {
    const id = useId();
    return (
        <TableCell key={id} style={{ color: '#828282' }} >
            {children}
        </TableCell>
    )
}