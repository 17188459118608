/**
 * @fileoverview Table component to update kilometers
 * @version 1.0
 * @author René Francisco Coss y León Monterde
 * @date 18/08/2021
 * @copyright 2021 Industrias RESSER S.A de C.V
 */

//React
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onShowAlertMessage } from "redux/slice";
import { useTranslation } from "react-i18next";
import axios from "libs/AxiosHelper";
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from "libs/Snackbar";
import { showCircularProgress, closeCircularProgress } from "redux/slice";
//@material-ui components
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { MdDelete, MdAddCircleOutline, MdClose } from "react-icons/md";
//Components
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Rssr from "libs/Rssr";
import swal from "sweetalert";
//Styles
import styles from "../Contracts/KilometerUpdate.styles";
import { getAnalytics, logEvent } from "firebase/analytics";

const useStyles = makeStyles(styles);

const KilometerUpdate = (props) => {
  const analytics = getAnalytics();
  //State form maintenance object
  const formDataObject = {
    contract: "",
    plate: "",
    currentKilometer: "",
    newKilometer: "",
  };
  //State form error object
  const formMaintenanceErrorsObject = {
    plate: false,
    currentKilometer: false,
    newKilometer: false,
  };

  //Styles
  const classes = useStyles();
  //Translations
  const [t, i18n] = useTranslation("common");
  //Constants
  const dispatch = useDispatch();
  const regionId = useSelector((state) => state.auth.loginData.regionId);
  const language = useSelector((state) => state.configurations.language);
  //States
  const [formMaintenanceErrors, setformMaintenanceErrors] = useState(
    formMaintenanceErrorsObject
  );
  const [contracts, setContracts] = useState([]);
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [rowQuantity, setRowQuantity] = useState([
    {
      id: 0,
      checked: false,
      formData: formDataObject,
    },
    {
      id: 1,
      checked: false,
      formData: formDataObject,
    },
  ]);

  useEffect(() => {
    getContracts();
  }, []);

  /**
   * Function that gets the contracts of an FM
   * @memberof KilometerUpdate
   */
  const getContracts = () => {
    axios
      .get("ContractsFleetManager")
      .then(async (response) => {
        if (response.data.success) {
          await setContracts(response.data.contracts);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(onShowAlertMessage());
        }
      });
  };

  /**
   * Function called when any input change its state.
   * @param {*} idRow
   * @param {*} dataObject
   * @memberof KilometerUpdate
   */
  const handleChangeInput = (auxFormData, idRow) => {
    const auxList = rowQuantity.map((item) => {
      if (item.id == idRow) {
        item.formData = auxFormData;
      }
      return item;
    });
    setRowQuantity(auxList);
  };

  /**
   * Function that detects the change on the input plate.
   * @param {event} newValue
   * @param {*} formData
   * @param {*} idRow
   * @memberof KilometerUpdate
   */
  const onChangePlate = async (newValue, formData, idRow) => {
    dispatch(showCircularProgress());
    if (newValue === null) {
      dispatch(closeCircularProgress());
      return false;
    }
    let auxKM = "0";
    await axios
      .get(`KmUpdate?vehicle=${newValue.contractId}`)
      .then(async (response) => {
        dispatch(closeCircularProgress());
        if (await response.data.success) {
          auxKM = response.data.km.toString();
        } else if (await !response.data.success) {
          enqueueErrorSnackbar(dispatch, response.data.message);
        }
      })
      .catch((error) => {
        dispatch(closeCircularProgress());
        enqueueErrorSnackbar(
          dispatch,
          t("contracts.updateKMerrors.failConnectionGetKM")
        );
      });

    const auxFormData = {
      ...formData,
      contract: newValue.contractNumber,
      plate: newValue.plateNumber,
      currentKilometer: auxKM,
    };
    handleChangeInput(auxFormData, idRow);
  };

  /**
   * Function that detects the change on inputs that must accept only numbers.
   * @param {event} e Event of the input
   * @param {*} formData
   * @memberof KilometerUpdate
   */
  const onChangeInputNumber = (e, formData, idRow) => {
    const inputName = e.target.name;
    let text = e.target.value;
    let newText = "";
    let numbers = "0123456789";

    for (var i = 0; i < text.length; i++) {
      if (numbers.indexOf(text[i]) > -1) {
        newText = newText + text[i];
      }
    }

    const auxFormData = {
      ...formData,
      [inputName]: newText,
    };

    handleChangeInput(auxFormData, idRow);
  };

  /**
   * Function called when any checkbox change its state, checks if it was header to select all rows.
   * @param {*} idRow
   * @memberof KilometerUpdate
   */
  const handleCheckBox = (idRow) => {
    let auxList = rowQuantity;
    if (idRow === 0) {
      let flgCheckAll = false;
      auxList = rowQuantity.map((item) => {
        if (item.id == 0) {
          item.checked = !item.checked;
          flgCheckAll = item.checked;
        }
        item.checked = flgCheckAll;
        if (flgCheckAll) {
          setSelectedRowCount(rowQuantity.length - 1);
        } else {
          setSelectedRowCount(0);
        }
        return item;
      });
    } else {
      let auxCount = 0;
      auxList = rowQuantity.map((item) => {
        if (item.id === idRow) {
          item.checked = !item.checked;
          if (item.checked) {
            setSelectedRowCount(selectedRowCount + 1);
          } else {
            setSelectedRowCount(selectedRowCount - 1);
          }
        }
        if (item.checked && item.id != 0) {
          auxCount++;
        }
        return item;
      });
      if (auxCount === rowQuantity.length - 1) {
        auxList[0].checked = true;
      } else {
        auxList[0].checked = false;
      }
    }
    setRowQuantity(auxList);
  };

  /**
   * Function that handles adding a row by adding an element to the array.
   * @memberof KilometerUpdate
   */
  const handleAddRow = () => {
    let auxList = rowQuantity;
    auxList[0].checked = false;
    setRowQuantity(auxList);
    setRowQuantity(
      rowQuantity.concat([
        {
          id: rowQuantity[rowQuantity.length - 1].id + 1,
          checked: false,
          formData: formDataObject,
        },
      ])
    );
  };

  /**
   * Function that handles erasing the selected rows by creating a new array without those elements.
   * @memberof KilometerUpdate
   */
  const handleEraseRow = () => {
    let auxList = [rowQuantity[0]];
    const travelList = rowQuantity.map((item) => {
      if (item.id != 0 && item.checked == false) {
        auxList.push(item);
      }
    });
    if (auxList.length <= 1) {
      auxList = [
        {
          id: 0,
          checked: false,
          formData: formDataObject,
        },
        {
          id: 1,
          checked: false,
          formData: formDataObject,
        },
      ];
    }
    setRowQuantity(auxList);
    setSelectedRowCount(0);
  };

  /**
   * Function that handles validating before sending the data.
   * @memberof KilometerUpdate
   */
  const handleSendGroup = async () => {
    dispatch(showCircularProgress());
    let flgValidations = true;
    if (hasEmptyFields()) {
      flgValidations = false;
    } else if (await hasInvalidConditions()) {
      flgValidations = false;
    }
    if (flgValidations) {
      sendData();
    } else {
      dispatch(closeCircularProgress());
    }
  };

  /**
   * Function that sends the object of rowQuantity through Axios.
   * @memberof KilometerUpdate
   */
  const sendData = async () => {
    const data = {
      updates: [],
    };
    rowQuantity.map((item) => {
      if (item.id !== 0) {
        data.updates.push({
          contract: item.formData.contract,
          kilometers: item.formData.newKilometer,
        });
      }
    });
    let flgSendCorrectly = true;
    await axios
      .put("KmUpdateFMV2", data)
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (!response.data[i].success) {
            flgSendCorrectly = false;
            const aux = i + 1;
            const rowError =
              t("maintenance.errors.rowError") + " " + aux.toString();
            enqueueErrorSnackbar(dispatch, rowError);
          }
        }
        dispatch(closeCircularProgress());
        if (flgSendCorrectly) {
          logEvent(analytics, "Fleet_KM_Update");
          swal(
            `${t("generalText.advice")}`,
            t("contracts.updateKM.alertKMSuccess"),
            {
              buttons: {
                accept: t("generalText.accept"),
              },
            }
          ).then((value) => {
            switch (value) {
              default:
                break;
            }
          });
        }
      })
      .catch((error) => {
        enqueueErrorSnackbar(dispatch, t("maintenance.errors.failConnection"));
        dispatch(closeCircularProgress());
      });

    closeKilometerModal();
  };

  /**
   * Function called to clean de data when the modal is closed.
   * @memberof KilometerUpdate
   */
  const closeKilometerModal = () => {
    const auxList = [
      {
        id: 0,
        checked: false,
        formData: formDataObject,
      },
      {
        id: 1,
        checked: false,
        formData: formDataObject,
      },
    ];
    setRowQuantity(auxList);

    props.onCloseKilometerModal();
  };

  /**
   * Function called to check if any input field is empty.
   * @memberof KilometerUpdate
   */
  const hasEmptyFields = () => {
    for (let i = 1; i < rowQuantity.length; i++) {
      if (rowQuantity[i].formData.plate.length <= 0) {
        enqueueErrorSnackbar(
          dispatch,
          regionId === 3 && language === "es"
            ? t("contracts.updateKMerrors.emptyPlateChile")
            : t("contracts.updateKMerrors.emptyPlate")
        );
        return true;
      } else if (rowQuantity[i].formData.currentKilometer.length <= 0) {
        enqueueErrorSnackbar(
          dispatch,
          (regionId === 3 && language === "es"
            ? t("contracts.updateKMerrors.emptyCurrentKMChile")
            : t("contracts.updateKMerrors.emptyCurrentKM")) +
            " " +
            rowQuantity[i].formData.plate +
            " " +
            t("contracts.updateKMerrors.emptyCurrentKMHint")
        );
        return true;
      } else if (rowQuantity[i].formData.newKilometer.length <= 0) {
        enqueueErrorSnackbar(
          dispatch,
          (regionId === 3 && language === "es"
            ? t("contracts.updateKMerrors.emptyNewKMChile")
            : t("contracts.updateKMerrors.emptyNewKM")) +
            " " +
            rowQuantity[i].formData.plate
        );
        return true;
      }
    }

    return false;
  };

  /**
   * Function called to check conditions: plates aren't repeated and if new kilometer is smaller than current kilometer, an alert to confirm must pop. If any is not valid an Alert will pop up.
   * @memberof KilometerUpdate
   */
  const hasInvalidConditions = async () => {
    let flgRepeatedPlate = false;
    for (let i = 1; i < rowQuantity.length; i++) {
      const auxPlate = rowQuantity[i].formData.plate;
      flgRepeatedPlate = rowQuantity.some(function (item) {
        if (item.id !== rowQuantity[i].id)
          return item.formData.plate == auxPlate;
      });
      if (flgRepeatedPlate) {
        enqueueErrorSnackbar(
          dispatch,
          t("maintenance.errors.repeatedPlate") + " - " + auxPlate
        );
        return true;
      }
    }

    for (let i = 1; i < rowQuantity.length; i++) {
      if (parseInt(rowQuantity[i].formData.newKilometer) === 0) {
        enqueueErrorSnackbar(
          dispatch,
          (regionId === 3 && language === "es"
            ? t("contracts.updateKMerrors.invalidKMZeroChile")
            : t("contracts.updateKMerrors.invalidKMZero")) +
            " " +
            rowQuantity[i].formData.plate
        );
        return true;
      }
      if (
        rowQuantity[i].formData.newKilometer ===
        rowQuantity[i].formData.currentKilometer
      ) {
        enqueueErrorSnackbar(
          dispatch,
          (regionId === 3 && language === "es"
            ? t("contracts.updateKMerrors.invalidKMEqualChile")
            : t("contracts.updateKMerrors.invalidKMEqual")) +
            " " +
            rowQuantity[i].formData.plate
        );
        return true;
      }
    }

    let auxPlatesBelow = "";
    let auxPlatesGreater = "";
    let auxPlatesDigits = "";
    for (let i = 1; i < rowQuantity.length; i++) {
      if (
        parseInt(rowQuantity[i].formData.newKilometer) <
        parseInt(rowQuantity[i].formData.currentKilometer)
      ) {
        auxPlatesBelow = auxPlatesBelow + rowQuantity[i].formData.plate + ", ";
      } else if (parseInt(rowQuantity[i].formData.newKilometer) > 450000) {
        auxPlatesGreater =
          auxPlatesGreater + rowQuantity[i].formData.plate + ", ";
      } else if (rowQuantity[i].formData.newKilometer.length > 6) {
        auxPlatesDigits =
          auxPlatesDigits + rowQuantity[i].formData.plate + ", ";
      }
    }

    let flgStop = false;
    if (auxPlatesBelow.length > 0) {
      const trad =
        (regionId === 3 && language === "es"
          ? t("contracts.updateKM.alertKMBelowChile")
          : t("contracts.updateKM.alertKMBelow")) +
        ":\n" +
        auxPlatesBelow +
        "\n" +
        t("contracts.updateKM.continue");
      await swal(`${t("generalText.advice")}`, `${trad}`, "info", {
        buttons: {
          cancel: t("generalText.no"),
          accept: t("generalText.yes"),
        },
      }).then((value) => {
        switch (value) {
          case "accept":
            break;
          default:
            flgStop = true;
        }
      });
    }

    if (flgStop) return true;

    if (auxPlatesGreater.length > 0) {
      const trad =
        (regionId === 3 && language === "es"
          ? t("contracts.updateKM.alertKMGreaterChile")
          : t("contracts.updateKM.alertKMGreater")) +
        ":\n" +
        auxPlatesGreater +
        "\n" +
        t("contracts.updateKM.continue");
      await swal(`${t("generalText.advice")}`, `${trad}`, "info", {
        buttons: {
          cancel: t("generalText.no"),
          accept: t("generalText.yes"),
        },
      }).then((value) => {
        switch (value) {
          case "accept":
            break;
          default:
            flgStop = true;
        }
      });
    }

    if (flgStop) return true;

    /*if(auxPlatesDigits.length > 0){
      const trad = "Las siguientes placas poseen un kilometraje mayor a 6 digitos:\n" + auxPlatesGreater + "\n¿Desea continuar?"  //TODO: Comprobar con ALD si se ocupa esta validación por que no pasaría nunca debido a los 450000
      await swal(`${t('generalText.advice')}`, `${trad}`, 'info', {
        buttons: {
          cancel: t('generalText.no'),
          accept: t('generalText.yes')
        },
      })
      .then((value) => {
        switch (value) {
          case "accept":
          break;
          default:
          flgStop = true;
        }
      });
    }

    if(flgStop) return true;*/

    return false;
  };

  /**
   * Function that renders the table rows in KilometerUpdate.
   * @memberof KilometerUpdate
   */
  const renderTableRows = () => {
    return rowQuantity.map((item) => {
      if (item.id === 0) {
        return (
          <TableHead>
            <TableRow>
              <TableCell key={"checkbox"} align="center">
                <Checkbox
                  defaultChecked={false}
                  checked={item.checked}
                  onChange={() => handleCheckBox(0)}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </TableCell>
              <TableCell
                key={"plate"}
                align="center"
                className={classes.tableHeader}
              >
                {regionId === 3 && language === "es"
                  ? t("maintenance.groupTable.plateChile")
                  : t("modalTicketBr.inputs.17")}
              </TableCell>
              <TableCell
                key={"currentKilometer"}
                align="center"
                className={classes.tableHeader}
              >
                {t("contracts.updateKM.currentKM")}
              </TableCell>
              <TableCell
                key={"newKilometer"}
                align="center"
                className={classes.tableHeader}
              >
                {t("contracts.updateKM.newKM")}
              </TableCell>
            </TableRow>
          </TableHead>
        );
      }
      return (
        <TableBody>
          <TableCell key={"checkbox"} align="center">
            <Checkbox
              defaultChecked={false}
              checked={item.checked}
              onChange={() => handleCheckBox(item.id)}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </TableCell>
          <TableCell key={"plate"} align="center" className={classes.tableCell}>
            <Autocomplete
              id="combo-box-vehicles"
              size="small"
              freeSolo={true}
              options={contracts}
              inputValue={
                contracts.length > 0
                  ? item.formData.plate.value
                  : t("modalTicketGnr.noContractMessage")
              }
              getOptionLabel={(option) => option.plateNumber}
              onChange={(e, newValue) =>
                onChangePlate(newValue, item.formData, item.id)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    regionId === 3 && language === "es"
                      ? t("maintenance.groupTable.plateChile")
                      : t("modalTicketBr.inputs.17")
                  }
                  margin="normal"
                />
              )}
            />
          </TableCell>
          <TableCell
            key={"currentKilometer"}
            align="center"
            className={classes.tableCell}
          >
            <CustomInput
              id="currentKilometer"
              labelText={t("contracts.updateKM.currentKM")}
              error={formMaintenanceErrors.currentKilometer}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                disabled: true,
                value: item.formData.currentKilometer,
                name: "currentKilometer",
                onChange: (e) => {
                  onChangeInputNumber(e, item.formData, item.id);
                },
              }}
            />
          </TableCell>
          <TableCell
            key={"newKilometer"}
            align="center"
            className={classes.tableCell}
          >
            <CustomInput
              id="newKilometer"
              labelText={
                regionId === 0
                  ? t("contracts.updateKM.placeholderNewKM")
                  : t("contracts.updateKM.newKM")
              }
              error={formMaintenanceErrors.newKilometer}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: item.formData.newKilometer,
                name: "newKilometer",
                onChange: (e) => {
                  onChangeInputNumber(e, item.formData, item.id);
                },
              }}
            />
          </TableCell>
        </TableBody>
      );
    });
  };

  return (
    <Dialog
      xs={12}
      md={12}
      lg={12}
      open={props.showKilometerModal}
      onClose={() => {
        closeKilometerModal();
      }}
      fullWidth
      maxWidth="lg"
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle
        lg={12}
        md={12}
        disableTypography
        className={classes.dialogHeaderFooter}
      >
        <Typography lg={10} md={10} className={classes.titles}>
          {t("contracts.updateKM.updateKM")}
        </Typography>
        <IconButton
          lg={2}
          md={2}
          className={classes.buttonClose}
          onClick={() => {
            closeKilometerModal();
          }}
        >
          <MdClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Table stickyHeader>
          {rowQuantity.length > 0 && renderTableRows()}
        </Table>
      </DialogContent>
      <DialogActions
        lg={12}
        md={12}
        disableTypography
        className={classes.dialogHeaderFooter}
      >
        <IconButton
          lg={4}
          md={4}
          className={classes.buttonDeleteRow}
          onClick={() => {
            handleEraseRow();
          }}
        >
          <MdDelete />
          {selectedRowCount + "  " + t("maintenance.groupTable.selected")}
        </IconButton>
        <IconButton
          lg={4}
          md={4}
          alignItems={"center"}
          justify={"center"}
          className={classes.buttonAddRow}
          onClick={() => {
            handleAddRow();
          }}
        >
          <MdAddCircleOutline />
          <Typography variant="body1" style={{ marginLeft: 5 }}>
            {t("maintenance.groupTable.addRow")}
          </Typography>
        </IconButton>
        <Button
          lg={4}
          md={4}
          className={classes.button}
          onClick={() => {
            handleSendGroup();
          }}
        >
          {regionId === 0
            ? t("contracts.updateKM.buttonUpdateKM")
            : t("contracts.updateKM.updateKM")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default KilometerUpdate;
