/**
 * @fileoverview SAT page.
 * @version 1.0
 * @author Carlos Emilio Blanco Lopez
 * @date 23/01/2024
 * @copyright 2024 RESSER S.A de C.V
 */

import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import {useTranslation} from "react-i18next";
import styles from './styles';

import useSAT from './useSAT';

// Components
import Table from 'components/ComponentsSAT/Table';

const useStyles = makeStyles(styles);

const SAT = () => {

  const { sat, handleSat, Agreements, ...useSATProps } = useSAT();
  const classes = useStyles();
  const [t] = useTranslation('common');

  return (
    <Fragment>
      <Accordion
        className={classes.accordion}
        defaultExpanded
      >
        <AccordionSummary>
          <div className={classes.title}>{t('ModuleAgreements.BudgetApproval')}</div>
        </AccordionSummary>

        <AccordionDetails style={{display: 'flex', flexDirection: "column"}}>
          
          <Table data={Agreements} {...useSATProps} />
        </AccordionDetails>
      </Accordion>
    </Fragment>
  )
}

export default SAT;



