import React from 'react';
//@material-ui
import { makeStyles  } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
//redux
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => {
});
const LoadingScreen = (props) => {
    const {children, ...rest} = props;
    const loadingMask = useSelector(state => state.loadingMask.showIndicator)
    return (
        <>
            <Modal
                open={loadingMask}
                aria-labelledby="loading modal"
                aria-describedby="loading spinner"
                style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            >
                <CircularProgress />
            </Modal>
        </>
    );
}

export default LoadingScreen;