import React, { useState, useEffect, useRef } from "react";

//@material-ui components
import { Divider, IconButton, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// @react-icons/icons
import {
  MdArrowBackIos,
  MdDelete,
  MdSaveAlt,
  MdVisibility,
} from "react-icons/md";
// @react-pdf
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// core components
import Item from "components/Grid/GridItem.js";
import Container from "components/Grid/GridContainer.js";
//translations
import { useTranslation } from "react-i18next";
//styles
import styles from "./Contracts.styles";
import { useSelector, useDispatch } from "react-redux";
import { enqueueSuccessSnackbar } from "libs/Snackbar";

const useStyles = makeStyles(styles);

const CRLVView = ({ CRLV, clearCRLV, ...props }) => {
  const titleNotification = CRLV.plate;
  const dispatch = useDispatch();
  const { title, description } = useSelector(
    (state) => state.Notifications.infoNotification
  );
  const { isNotification, isViewDocumentNotification } = props;
  //styles
  const classes = useStyles();
  //translate
  const [t, i18n] = useTranslation("common");

  const paperRef = useRef(null);
  const [pdfWidth, setPdfWidth] = useState();

  useEffect(() => {
    function handleResize() {
      const paperWidth = paperRef.current.getBoundingClientRect().width;
      setPdfWidth(paperWidth - paperWidth * 0.24);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const viewCRLV = async () => {
    const base64 = await fetch(`data:application/pdf;base64,${CRLV.base64}`);
    const blob = await base64.blob();
    const fileURL = URL.createObjectURL(blob);
    window.open().location.href = fileURL;
  };

  const downloadCRLV = () => {
    const href = `data:application/pdf;base64,${CRLV.base64}`;
    const downloadLink = document.createElement("a");
    const fileName = isViewDocumentNotification
      ? `${CRLV.plate}`
      : `${CRLV.plate}.pdf`;
    downloadLink.href = href;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  //Delete Notification
  const deleteNotification = () => clearCRLV(true);

  return (
    <Paper elevation={3} ref={paperRef}>
      <Container item xs={12} direction="column">
        <Item xs={12} style={{ width: "100%" }}>
          <div style={{ padding: "2rem 2rem" }}>
            <MdArrowBackIos
              style={{ cursor: "pointer" }}
              onClick={() => clearCRLV()}
            />
          </div>
        </Item>
        <Item xs={12} style={{ width: "100%" }}>
          <div style={{ padding: "10px 10%", marginBottom: "10px" }}>
            {!isNotification && !isViewDocumentNotification && (
              <p
                style={{
                  color: "#050B7F",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                {" "}
                {t("contracts.CRLV.title")}{" "}
              </p>
            )}
            {isViewDocumentNotification && (
              <>
                <p
                  style={{
                    color: "#050B7F",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                  }}
                >
                  {title}
                </p>
                <p style={{ fontSize: "1rem", fontFamily: "Montserrat" }}>
                  {description}
                </p>
              </>
            )}
            {isNotification && (
              <p
                style={{
                  color: "#050B7F",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                }}
              >
                {titleNotification}
              </p>
            )}
            <div
              style={{
                display: "flex",
                gap: 20,
                padding: "20px 0",
                alignItems: "center",
              }}
            >
              <IconButton
                className={classes.viewCRLVStyle}
                color="primary"
                aria-label=""
                component="span"
                onClick={() => viewCRLV()}
              >
                <MdVisibility />
              </IconButton>
              <Divider orientation="vertical" flexItem />
              <IconButton
                className={classes.downloadCRLVStyle}
                color="primary"
                aria-label=""
                component="span"
                onClick={() => downloadCRLV()}
              >
                <MdSaveAlt />
              </IconButton>
              {isViewDocumentNotification && (
                <IconButton
                  className={classes.viewCRLVStyle}
                  color="primary"
                  style={{ position: "relative", left: "70%" }}
                  aria-label=""
                  component="span"
                  onClick={deleteNotification}
                >
                  <MdDelete />
                </IconButton>
              )}
            </div>
          </div>
        </Item>
        <Item
          xs={12}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Document file={`data:application/pdf;base64,${CRLV.base64}`}>
            <Page pageNumber={1} width={pdfWidth} />
          </Document>
        </Item>
      </Container>
    </Paper>
  );
};

export default CRLVView;
