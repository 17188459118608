/**
 * @fileoverview TlableFunctions.js - Functions for the table component.
 * @version 1.0.0
 * @since 13/10/2021
 * @requires {@link https://material-ui.com/ Material-UI}
 * @author Carlos Emilio Blanco Lopez
 */

/**
 * @title descendingComparator
 * @description Compare two values and return 1, -1 or 0.
 * @param {Object} a - First object to compare.
 * @param {Object} b - Second object to compare.
 * @param {String} orderBy - Order by.
 * @returns {Number} 1, -1 or 0.
 */

export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

/**
 * @title getComparator
 * @description Get the comparator.
 * @param {String} order - Order.
 * @param {String} orderBy - Order by.
 * @returns {Function} Comparator.
 */

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * @title stableSort
 * @description Sort the array.
 * @param {Array} array - Array to sort.
 * @param {Function} comparator - Comparator.
 * @returns {Array} Sorted array.
 */

export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}