import React, { useEffect, useState } from "react";
import Cookies from 'js-cookie';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TextField, Grid } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
//redux actions
import { useDispatch, useSelector } from 'react-redux'
import { showCircularProgress, closeCircularProgress, onShowAlertMessage } from 'redux/slice';
// @material-ui/icons
import { MdVisibility, MdVisibilityOff, MdExpandMore } from 'react-icons/md';

//Translations
import { useTranslation } from "react-i18next";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
//axios
import axios from 'libs/AxiosHelper';

//messages
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from 'libs/Snackbar';
//Styles 
import { grayColor } from "assets/jss/material-dashboard-react.js";
import styles from './UserProfile.style';
import { useFormik } from 'formik';
import * as Yup from 'yup';

var CryptoJS = require("crypto-js");

const useStyles = makeStyles(styles);

export default function UserSettings(p) {
  const classes = useStyles();
  let [t, i18n] = useTranslation('common');
  const [_Visibility, setvisibility] = useState({
    currentPassword: false,
    newPass: false,
    confirmPass: false
  });

  //redux
  const dispatch = useDispatch();
  const modules = useSelector(state => state.modules.modules);

  //state
  const [emails, setEmails] = useState(false);
  const [finishedServices, setFinishedServices] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [docs, setDocs] = useState(false);
  const [flagAlertsUpdate, setFlagAlertsUpdate] = useState(true);

  /**
   * Validaciones de Formik
   */

  const formSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required(t("resetSenha.formFieldsMessages.fieldRequired")),
    newPass: Yup.string()
      .min(8, t("resetSenha.formFieldsMessages.shortPass"))
      .max(15, t("resetSenha.formFieldsMessages.longPass"))
      .required(t("resetSenha.formFieldsMessages.fieldRequired"))
      .matches(/^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])^(?!.*['[\]\{\}\?¿\=\+\-_"\;\)(\|\/\\'])(?=.{8,15})/,
        t("resetSenha.formFieldsMessages.passwordCriteria")),
    confirmPass: Yup.string()
      .min(8, t("resetSenha.formFieldsMessages.shortPass"))
      .max(15, t("resetSenha.formFieldsMessages.longPass"))
      .required(t("resetSenha.formFieldsMessages.fieldRequired"))
      .matches(/^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])^(?!.*['[\]\{\}\?¿\=\+\-_"\;\)(\|\/\\'])(?=.{8,15})/,
        t("resetSenha.formFieldsMessages.passwordCriteria")),
  });

  /**
  * @State useFormik
  * @description Objeto Formik
  */

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPass: "",
      confirmPass: ""
    },
    validationSchema: formSchema,
    onSubmit: async (values) => {
      const credentials = JSON.parse(localStorage.getItem('userData'));
      const pass = CryptoJS.AES.decrypt(credentials.password, 'mizutsune').toString(CryptoJS.enc.Utf8);
      if(values.currentPassword !== pass){
        enqueueErrorSnackbar(dispatch, "No coincide tu credencial actual");
        return;
      }
      if(values.newPass !== values.confirmPass){
        enqueueErrorSnackbar(dispatch, "No coinciden las contraseñas");
        return;
      }
      onChangePassword({
        OldPassword: credentials.password,
        NewPassword: CryptoJS.AES.encrypt(values.newPass, 'mizutsune').toString()
      });
  }});

  useEffect(() => {
    dispatch(showCircularProgress());
    getAlertsData();
    dispatch(closeCircularProgress());
  }, [])

  /**
   * Función encargada de obtener la configuración de alertas del usuario
   * 
   */
  const getAlertsData = async () => {
    await axios.get('Alerts')
      .then(async response => {
        if (await response.data.success) {
          response.data.alerts.map(alert => {
            switch (alert.alertId) {
              case 0:
                setEmails(true);
                break;
              case 1:
                setFinishedServices(true);
                break;
              case 2:
                setDelivery(true);
                break;
              case 3:
                setDocs(true);
                break;
              default:
                break;
            }
          })
        }
      })
      .catch(error => {
        dispatch(onShowAlertMessage());
      });
  }

  /**
   * Función que solicita la actualización de la configuración de alertas del usuario
   */
  const onChangeAlerts = async () => {
    const dataToSend = [];
    if (finishedServices) {
      dataToSend.push(1);
    }
    if (delivery) {
      dataToSend.push(2);
    }
    if (docs) {
      dataToSend.push(3);
    }
    await axios.put('Alerts', {
      alertsId: dataToSend
    }).
      then(response => {
        if (response.data) {
          enqueueSuccessSnackbar(dispatch, t('profileConfiguration.alerts.success.0'));
        }
        else {
          enqueueErrorSnackbar(dispatch, t('profileConfiguration.alerts.errors.0'));
        }
      })
      .catch(error => {
        dispatch(onShowAlertMessage());
      });
    setEmails(false);
    setFinishedServices(false);
    setDelivery(false);
    setDocs(false);
    getAlertsData();
  }

  /**
   * @Function onChangePassword
   * @description Función que solicita el cambio de contraseña
   */

  const onChangePassword = (data) => {

        axios.put('UpdatePasswordFMV2', data)
          .then(response => {
            if (response.data.Success) {
              const credentials = JSON.parse(localStorage.getItem('userData'));
              const newCredentials = {
                ...credentials,
                password: data.NewPassword
              };
              localStorage.setItem('userData', JSON.stringify(newCredentials));
              enqueueSuccessSnackbar(dispatch, t('profileConfiguration.account.success.0'));
            }
            else {
              if(response.data.CodeNumber === 5){
                enqueueErrorSnackbar(dispatch, t('profileConfiguration.alerts.errors.3'));
                return;
              }
              enqueueErrorSnackbar(dispatch, t('profileConfiguration.account.errors.0'));
            }
          })
          .catch(error => {
            dispatch(onShowAlertMessage());
          })

  }

  return (
    <div>
      <GridContainer>
        {/* Sección de configuración de cuenta*/}
        <GridItem xs={12} md={12} lg={4} style={{ marginTop: 50 }}>
          <GridContainer spacing={0}>
            <GridItem xs={12} md={12} lg={12}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<MdExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p className={classes.titleSeccionText}> {t('profileConfiguration.account.title')} </p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.centerCardContent}>
                    <p style={{ color: "#828282" }}> {t('profileConfiguration.account.textDescription')} </p>
                    <form onSubmit={formik.handleSubmit}>
                      <Grid container spacing={1} style={{ marginTop: 15, justifyContent: "center" }}>
                        <Grid item xs={11}>
                          <TextField
                            id="currentPassword"
                            label={t('profileConfiguration.account.currentPassword')}
                            type={_Visibility.currentPassword ? "text" : "password"}
                            variant="standard"
                            fullWidth
                            InputLabelProps={{ style: { color: grayColor[3] } }}
                            value={formik.values.currentPassword}
                            onChange={formik.handleChange}
                            error={formik.errors.currentPassword}
                            helperText={formik.errors.currentPassword}
                            InputProps={
                              {
                                  endAdornment: _Visibility.currentPassword 
                                      ? <MdVisibility 
                                          fontSize="small" 
                                          style={{color: grayColor[3], cursor: "pointer"}}
                                          onClick={() => {
                                              setvisibility({..._Visibility, currentPassword: !_Visibility.currentPassword})
                                          }} />
                                      : <MdVisibilityOff 
                                          fontSize="small" 
                                          style={{color: grayColor[3], cursor: "pointer"}}
                                          onClick={() => {
                                            setvisibility({..._Visibility, currentPassword: !_Visibility.currentPassword})
                                        }} />,
                                  
                              }
                          }
                          />
                        </Grid>
                        <Grid item xs={11}>
                          <TextField
                            id="newPass"
                            label={t('profileConfiguration.account.password')}
                            type={_Visibility.newPass ? "text" : "password"}
                            variant="standard"
                            fullWidth
                            InputLabelProps={{ style: { color: grayColor[3] } }}
                            value={formik.values.newPass}
                            onChange={formik.handleChange}
                            error={formik.errors.newPass}
                            helperText={formik.errors.newPass}
                            InputProps={
                              {
                                  endAdornment: _Visibility.newPass 
                                      ? <MdVisibility 
                                          fontSize="small" 
                                          style={{color: grayColor[3], cursor: "pointer"}}
                                          onClick={() => {
                                              setvisibility({..._Visibility, newPass: !_Visibility.newPass})
                                          }} />
                                      : <MdVisibilityOff 
                                          fontSize="small" 
                                          style={{color: grayColor[3], cursor: "pointer"}}
                                          onClick={() => {
                                            setvisibility({..._Visibility, newPass: !_Visibility.newPass})
                                        }} />,
                              }}
                          />
                        </Grid>
                        <Grid item xs={11}>
                          <TextField
                            id="confirmPass"
                            label={t('profileConfiguration.account.confirmPassword')}
                            type={_Visibility.confirmPass ? "text" : "password"}
                            variant="standard"
                            fullWidth
                            InputLabelProps={{ style: { color: grayColor[3] } }}
                            value={formik.values.confirmPass}
                            onChange={formik.handleChange}
                            error={formik.errors.confirmPass}
                            helperText={formik.errors.confirmPass}
                            InputProps={
                              {
                                  endAdornment: _Visibility.confirmPass 
                                      ? <MdVisibility 
                                          fontSize="small" 
                                          style={{color: grayColor[3], cursor: "pointer"}}
                                          onClick={() => {
                                              setvisibility({..._Visibility, confirmPass: !_Visibility.confirmPass})
                                          }} />
                                      : <MdVisibilityOff 
                                          fontSize="small" 
                                          style={{color: grayColor[3], cursor: "pointer"}}
                                          onClick={() => {
                                            setvisibility({..._Visibility, confirmPass: !_Visibility.confirmPass})
                                        }} />,
                              }}
                          />
                        </Grid>
                        <Grid item sx={13}>
                          <Button className={classes.updateButton} aria-label="edit" type="submit">
                            {t('generalText.edit')}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>

                  </div>
                </AccordionDetails>
              </Accordion>
            </GridItem>

            {/* Sección de configuración de portal, dentro de configuración de cuenta*/}
          </GridContainer>
        </GridItem>

        {/* Sección de configuración de alertas */}
        {
          ((modules.find(e => e.id === 22) !== undefined) || (modules.length === 0)) &&
          <GridItem xs={12} md={12} lg={3} style={{ marginTop: 50 }}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<MdExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p className={classes.titleSeccionText}> {t('profileConfiguration.alerts.title')} </p>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.centerCardContent}>
                  <GridContainer alignItems="center" justify="center">
                    <GridItem xs={11}>
                      <p style={{ color: "#828282" }}> {t('profileConfiguration.alerts.textDescription')} </p>
                    </GridItem>
                    <GridItem xs={10}>
                      <FormControlLabel
                        control={<Checkbox checked={emails} onChange={(e) => { setEmails(!emails); setFlagAlertsUpdate(false); }} name="emails" color="primary" />}
                        label={t('profileConfiguration.alerts.checkbox')}
                      />
                    </GridItem>
                    <GridItem xs={10}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={finishedServices}
                            onChange={(e) => { setFinishedServices(!finishedServices); setFlagAlertsUpdate(false); }}
                            name="finishedServices"
                            color="primary"
                          />
                        }
                        label={t('profileConfiguration.alerts.slider1')}
                      />
                    </GridItem>
                    <GridItem xs={10}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={delivery}
                            onChange={(e) => { setDelivery(!delivery); setFlagAlertsUpdate(false); }}
                            name="delivery"
                            color="primary"
                          />
                        }
                        label={t('profileConfiguration.alerts.slider2')}
                      />
                    </GridItem>
                    <GridItem xs={10}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={docs}
                            onChange={() => { setDocs(!docs); setFlagAlertsUpdate(false); }}
                            name="documents"
                            color="primary"
                          />
                        }
                        label={t('profileConfiguration.alerts.slider3')}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer alingContent={"center"} justify="center">
                    <Button onClick={onChangeAlerts} className={classes.updateButton} aria-label="edit" disabled={flagAlertsUpdate}>
                      {t('generalText.edit')}
                    </Button>
                  </GridContainer>
                </div>
              </AccordionDetails>
            </Accordion>
          </GridItem>
        }

      </GridContainer>
    </div>
  );
}
