/**
 * @fileoverview AcceptCookies
 * @version 1.0
 * @author César Paul Hernández Camacho
 * @date 14/01/2021
 * @copyright 2021 RESSER S.A de C.V
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomModal from 'components/CustomModal/CustomModal';
import {
    Button,
    Typography,
    Link,
    Checkbox,
    FormControlLabel,
    Grid
} from '@material-ui/core';
import Item from 'components/Grid/GridItem';
import Container from 'components/Grid/GridContainer';
import { setCookies, setShowCookies, setFixedDrawer, logoutUser, setProfileInfo, deleteModules, setSignedPolicies } from 'redux/slice';
//Translations
import { useTranslation } from "react-i18next";
import axios from 'libs/AxiosHelper';
//style
import './AcceptCookies.css';

const AcceptCookies = ({ links }) => {

    //Translations
    const [t, i18n] = useTranslation('common');
    //redux
    const showCookies = useSelector(state => state.configurations.showCookies);
    const SignedPolicies = useSelector(state => state.auth.loginData.SignedPolicies);
    const regionId = useSelector(state => state.auth.loginData.regionId);
    const userRole = useSelector(state => state.auth.loginData.userRole);
    const dispatch = useDispatch();

    //state
    const [showModal, setShowModal] = useState();
    const [acceptPrivacy, setAcceptPrivacy] = useState(false);
    const [acceptCookies, setAcceptCookies] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);

    useEffect(() => {
        if (!SignedPolicies) {
            setTimeout(() => {
                setShowModal(true);
            }, 1000);
        }
    }, [])

    const onHideClick = () => {
        dispatch(setSignedPolicies(true));
        dispatch(setCookies());
        dispatch(setShowCookies(false));
        if (userRole === 25 || userRole === 28 || userRole === 9 || userRole === 8 || userRole === 14) {
            dispatch(setFixedDrawer(true));
        }
        axios.post('UserPoliciesAndTerms', null)
            .then(_response => {  })
            .catch(_error => { })
    }

    /**
     * Función encargada de setear y abrir la url de las poiliticas de privacidad en relación a la región del usuario.
     */
    
    const onlinckClick = (value) => {
        window.open(links[value]);
    }

    const onReturnClick = () => {
        dispatch(logoutUser());
        dispatch(setProfileInfo(false));
        dispatch(deleteModules());
        localStorage.removeItem('jwttokenmyald');
        localStorage.removeItem("userAccount");
    }

    const checkDisabledButton = () => {
        if (acceptCookies && acceptPrivacy && acceptTerms) {
            return false;
        }
        return true;
    }

    return (
        <>
            <CustomModal
                open={showModal}
                onClose={() => { }}
                type='AcceptCookies'
            >
                <Container direction="column" justify="center" alignItems="center" className='AcceptCookies_container'>
                    <br />
                    <h2 className='AcceptCookies_title'>{t('AcceptCookies.advice')}</h2>
                    <br />
                    <p className='AcceptCookies_subtitle'>{t('AcceptCookies.content')}</p>
                    <br />
                    <Grid container direction='column' justifyContent='center' style={{ width: 'auto' }}>
                        <Grid item xs={12} direction='row'>
                            <Checkbox checked={acceptPrivacy} onChange={(e) => { setAcceptPrivacy(e.target.checked) }} color="primary" />
                            <Link variant="body1" onClick={() => onlinckClick(0)} style={{ cursor: 'pointer' }}>
                                {t('PrivacyPolicy.privacy')}
                            </Link>
                        </Grid>

                        <Grid item xs={12} direction='row'>
                            <Checkbox checked={acceptTerms} onChange={(e) => { setAcceptTerms(e.target.checked) }} color="primary" />
                            <Link variant="body1" onClick={() => onlinckClick(1)} style={{ cursor: 'pointer' }}>
                                {t('PrivacyPolicy.cookies')}
                            </Link>
                        </Grid>
                        <Grid item xs={12} direction='row'>
                            <Checkbox checked={acceptCookies} onChange={(e) => { setAcceptCookies(e.target.checked) }} color="primary" />
                            <Link variant="body1" onClick={() => onlinckClick(2)} style={{ cursor: 'pointer' }}>
                                {t('PrivacyPolicy.terms')}
                            </Link>
                        </Grid>
                    </Grid>
                    <br />
                    <Item xs={12} style={{ margin: 10 }} container direction='row' justifyContent='space-around'>
                        <Button color="primary" size="large" variant="outlined" onClick={onReturnClick}>
                            {t("AcceptCookies.return")}
                        </Button>
                        <Button color="primary" size="large" variant="contained" disabled={checkDisabledButton()} onClick={() => {
                            setShowModal(false);
                            onHideClick()
                        }}>
                            {t("AcceptCookies.continue")}
                        </Button>
                    </Item>
                    <br />
                </Container>
            </CustomModal>
        </>
    )
}

export default AcceptCookies;
