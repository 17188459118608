/**
* @fileoverview CustomerDatePicker
* @version 1.0.0
* @author Carlos Emilio Blanco Lopez
* @date 22/03/2023
* @copyright 2023 Industrias RESSER S.A de C.V
*/

import React, { useState } from 'react';
import 'moment/locale/en-gb';
import { makeStyles } from "@material-ui/core/styles";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import moment from 'moment';
// core components
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import { FormControl } from '@mui/material';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(styles);

/**
 * BrowserInput
 * @param {*} props 
 * @returns Frament
 */

const BrowserInput = function BrowserInput(props) {
    const {
        inputProps,
        InputProps,
        ownerState,
        inputRef,
        error,
        formControlProps,
        labelText,
        success,
        id,
        labelProps,
        ...other } = props;

    return (
        <FormControl ref={InputProps?.ref}>
            <TextField
                {...inputProps}
                {...other}
                InputProps={
                    {
                        endAdornment: InputProps?.endAdornment
                    }
                }

            />
            <input {...inputProps} style={{ display: "none" }} />
        </FormControl>
    );
};

/**
 * CustomerDatePicker
 * @param {String} LabelText
 * @param {String} value
 * @param {function} onChange
 * @param {id} string
 * @returns DatePicker 
 */

const CustomDatePicker = function LocalizationMoment({ labelText, value, onChange, id }) {

    const classes = useStyles();
    const [date, setDate] = useState(new moment(value));

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-gb'}>
            <DatePicker
                className={classes.formControl}
                value={date}
                onChange={(newValue) => {
                    const date = newValue.month(1).format("YYYY-MM-DD");
                    setDate(newValue);
                    onChange(date);
                }}
                label={labelText}
                slotProps={{
                    textField: {
                        formControlProps: { fullWidth: true },
                        labelProps: { shrink: true },
                        success: true,
                        id: id
                    }
                }}
                slots={{
                    textField: BrowserInput,
                }}
            />
        </LocalizationProvider>
    );
}

export default CustomDatePicker;

/** PropTypes */

CustomDatePicker.propTypes = {
    id: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

BrowserInput.propTypes = {
    error: PropTypes.bool,
    inputProps: PropTypes.object,
    InputProps: PropTypes.object,
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]),
    ownerState: PropTypes.any,
};