/**
 * @fileoverview Notifications view styles
 * @version 1.0
 * @author Carlos Emilio Blanco Lopez
 * @date 09/12/2022
 * @copyright Industrias RESSER S.A de C.v
 */

const headerLinksStyle = theme => ({
    title: { 
        color: "#050B7F", 
        fontWeight: 'bold', 
        fontSize: '2rem', 
        marginLeft: 7 
    },
    description: { 
        color: "#828282", 
        fontWeight: '300', 
        fontSize: '1.0rem', 
        marginLeft: 5, 
        width: "90%" 
    },
    contentNotification: { 
        display: 'flex', 
        flexDirection: 'column', 
        width: '100%', 
        position: 'relative', 
        bottom: 15 
    },
    cancelButtonStyle: {
        backgroundColor: "#828282",
        paddingLeft: 10,
        paddingRight: 10,
        color: "white",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 15,
        minWidth: '8vw',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.7rem'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '0.87rem'
        },
        height: 30,
        marginBottom: 20
    },
    uploadDocumentButtonStyle: {
        backgroundColor: "#56BC23",
        paddingLeft: 10,
        paddingRight: 10,
        color: "white",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 20,
        minWidth: '10vw',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.7rem'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '0.87rem'
        },
        height: 30,
        marginBottom: 20
    },
    acceptButtonStyle: {
        backgroundColor: "#0BCBFB",
        paddingLeft: 10,
        paddingRight: 10,
        color: "#050B7F",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 15,
        minWidth: '8vw',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.7rem'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '0.87rem'
        },
        height: 30,
        marginBottom: 20
    },

    ButtonGo2DocumentsStyle: {
        backgroundColor: "#0BCBFB",
        paddingLeft: 10,
        paddingRight: 10,
        color: "white",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 10,
        height: 31,
        width: '18.5rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.5rem'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '0.918rem'
        },

    },

    ButtonGo2GlobeBoxStyle: {
        backgroundColor: "#050B7F",
        paddingLeft: 10,
        paddingRight: 10,
        color: "white",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 10,
        height: 31,
        width: '18.5rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.5rem'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '0.8rem'
        },
    },
    accordion: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center"
    },
    contractTable: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
    },
    containerModalUploadDocuments: {
        flexDirection: 'column',
        justifyContent: 'center',
        width: '95%',
        marginBottom: 10,
    },
    downloadButtonStyle: {
        backgroundColor: "#050B7F",
        color: "white",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 15,
        minWidth: 200,
        [theme.breakpoints.up("xl")]: {
            minWidth: 300
        },
    },
    uploadButtonStyle: {
        backgroundColor: "#56BC23",
        color: "white",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 15,
        minWidth: 200,
        [theme.breakpoints.up("xl")]: {
            minWidth: 300
        },
    },
    uploadGBButtonStyle: {
        backgroundColor: "#0BCBFB",
        color: "white",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 15,
        minWidth: 250, 
        height: 35,
        [theme.breakpoints.up("xl")]: {
            minWidth: 300
        },
    },
    uploadGBButtonStyleModal: {
        backgroundColor: "#44E1C3",
        color: "white",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        marginTop: 15,
        minWidth: 250, 
        height: 35,
        [theme.breakpoints.up("xl")]: {
            minWidth: 300
        },
    },
    SaveGBButtonStyle: {
        backgroundColor: "#0BCBFB",
        color: "#050B7F",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        minWidth: 200, 
        height: 35,
        marginTop: 35,
        [theme.breakpoints.up("xl")]: {
            minWidth: 250
        },
    },
    SaveGBButtonStyleModal: {
        backgroundColor: "#0BCBFB",
        color: "#050B7F",
        fontWeight: 'bold',
        borderRadius: '1.5rem',
        minWidth: 200, 
        height: 35,
        marginTop: 20,
        [theme.breakpoints.up("xl")]: {
            minWidth: 250
        },
    },
    LPBarColorPrimary: {
        backgroundColor: '#80B4FF',
    },
    LPBarColorError: {
        backgroundColor: '#FF303E',
    },
    LPBarColorUpload: {
        backgroundColor: '#56BC23',
    },
});

export default headerLinksStyle;