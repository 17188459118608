/**
 * @fileoverview UserProfile Style
 * Estilos de la vista de configuración perfil
 * @version 1.0.0
 * @author César Paul Hernández Camacho
 * @date 30/10/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 */

 const UserProfileStyles = theme => ({
    centerCardContent:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    titleSeccionText: {
        color: "#050B7F", 
        fontWeight: 'bold', 
        fontSize: '1.5rem'
    },
    updateButton: {
        backgroundColor: "#644AF3"
    }
 });

 export default UserProfileStyles;