/**
 * @fileoverview Table Styles
 * @version 1.1
 * @author César Paul Hernández Camacho
 * @date 23/11/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 * @update 30/11/2020
 */

import {
  warningColor,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  defaultFont,
  transition
} from "assets/jss/material-dashboard-react.js";

const tableStyle = theme => ({
  warningTableHeader: {
    color: warningColor[0]
  },
  primaryTableHeader: {
    color: primaryColor[0]
  },
  dangerTableHeader: {
    color: dangerColor[0]
  },
  successTableHeader: {
    color: successColor[0]
  },
  infoTableHeader: {
    color: infoColor[0]
  },
  roseTableHeader: {
    color: roseColor[0]
  },
  grayTableHeader: {
    color: grayColor[0]
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse"
  },
  tableHeadCell: {
    color: "inherit",
    width: 130,
    ...defaultFont,
    "&, &$tableCell": {
      fontWeight: 'bold',
      fontSize: "1em"
    }
  },
  tableCell: {
    ...defaultFont,
    lineHeight: "1.42857143",
    padding: "0px !important",
    verticalAlign: "middle",
    fontSize: "0.8125rem"
  },
  tableResponsive: {
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    overflowScrolling: "touch",
    display: 'flex',
    flexDirection: 'column',
    width: "100%",
    marginTop: theme.spacing(3),
    // overflowX: "auto"
  },
  tableHeadRow: {
    height: "30px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle"
  },
  tableBodyRow: {
    height: "30px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle"
  },
  pagination: {
    marginTop: 5,
    marginBottom: 5,
    alignSelf: 'flex-end'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  styleActions: { 
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'flex-start', 
    alignItems: 'flex-start' 
  },
  isNotification: {
    width: 125,
    height: 30,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#050b7f",
    color: "white",
    cursor: "pointer",
  },
  placeholder: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

export default tableStyle;
