/**
 * @fileoverview ContractTable
 * @version 1.0
 * @author César Paul Hernández Camacho
 * @date 18/11/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 * @update 30/11/2020
 */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
//@material-ui components
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import swal from 'sweetalert';
import Autocomplete from '@material-ui/lab/Autocomplete';
//@material-ui icons
import {MdFilterList, MdGetApp, MdPublish} from 'react-icons/md';
// core components
import Item from "components/Grid/GridItem.js";
import Container from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from 'components/CustomModal/CustomModal';
import Paper from '@material-ui/core/Paper';
import {DocumentsFilter} from 'components/Filter/Filter';
//translate
import { useTranslation } from 'react-i18next';
//axios
import axios from 'libs/AxiosHelper';
//React Table
import CustomTable from "components/Table/CustomTable";
//redux actions
import { showCircularProgress, closeCircularProgress, onShowAlertMessage } from 'redux/slice';
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from 'libs/Snackbar';
import { useSelector, useDispatch } from 'react-redux';
//styles
import styles from './Documents.styles';
import { Typography } from '@material-ui/core';
import Rssr from 'libs/Rssr';

var CryptoJS = require("crypto-js");

const useStyles = makeStyles(styles);

const filtersObj = {
    documentName: '',
    documentType: 1,
    documentCategory: '',
    customersId: []
}
const docsObj = {
    documentName: '',
    documentType: 'pdf',
    documentCategory: '',
    customersId: [],
    notes: ''
}

const ContractTable = forwardRef((props, ref) => {
    //styles
    const classes = useStyles();
    //redux
    const userRole = useSelector(state => state.auth.loginData.userRole);
    const dispatch = useDispatch();
    //translations
    const [t, i18n] = useTranslation('common');

    //State
    const [filters, setFilters] = useState(filtersObj);
    const [documentData, setDocumentData] = useState(docsObj)
    const [filterModal, setFilterModal] = useState(false);
    const [clients, setClients] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [totalDocs, setTotalDocs] = useState(0);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [step, setStep] = useState(100);
    const [postData, setPostData] = useState(true);
    const [defaultValues, setDefaultValues] = useState([]);
    const [currentDoc, setCurrentDoc] = useState({});

    /**
     * Function called once when component render for the first time and when the page state changes
     * @memberof ContractTable
     */
    useEffect(() => {
        getClients();
        getDocumets();
    }, [step])

    const cols = [
        { accessor: "type", header: t('documents.headers.0'), isDate: false },
        { accessor: "name", header: t('documents.headers.1'), isDate: false },
        { accessor: "category", header: t('documents.headers.2'), isDate: false },
        // {accessor: "shipmentData", header: t('documents.headers.3'), isDate: false},
        { accessor: "description", header: t('documents.headers.4'), isDate: false },
    ];

    useImperativeHandle(ref, () => ({

        getDocs() {
            getDocumets();
        }

    }));

    /**
     * Function that gets all the FleetInventory from the API
     * @memberof Contracts
     */
    const getDocumets = (filters = false) => {
        var url = `DocumentsFMV2/All?documentName=&documentType=&documentCategory=&start=${count}&limit=${step}`;
        if (filters) {
            url = `DocumentsFMV2/All?documentName=${filters.documentName}&documentType=${filters.documentType}&documentCategory=${filters.documentCategory}&start=${count}&limit=${step}`
        }
        dispatch(showCircularProgress());
        axios.get(url)
            .then(async response => {
                if (response.data.success) {
                    setDocuments(response.data.items);
                    setTotalDocs(response.data.total);
                }
                else {
                    enqueueErrorSnackbar(dispatch, t('documents.alertMessages.0'))
                    setDocuments([]);
                    setTotalDocs(0);
                }
                dispatch(closeCircularProgress());
            })
            .catch(error => {
                dispatch(closeCircularProgress());
                dispatch(onShowAlertMessage());
            })
    }

    /**
     * Function to get all clients depending if it's Admin or FM
     * @memberof Contracts
     */
    const getClients = () => {
        if (userRole === 7) {
            axios.get(`clientsFMV2/Catalog?customerName=&start=0&limit=12000`)
                .then(response => {
                    if (response.data.success) {
                        setClients(response.data.items);
                    }
                })
                .catch(error => {
                    dispatch(onShowAlertMessage());
                });
        }
        else {
            axios.get(`clientsFMV2`)
                .then(response => {
                    if (response.data.success) {
                        setClients(response.data.items);
                    }
                })
                .catch(error => {
                    dispatch(onShowAlertMessage());
                });
        }
    }

    /**
     * Function to handle when page is changed
     * @param {object} params object to handle when page is changed
     * @memberof ContractTable
     */
    const changePage = async (page, start) => {
        window.scrollTo(0, 0);
        await setPage(page);
        await setCount(start)
        await getDocumets();
    }

    /**
     * Function to handle limit change
     * @param {int} limit limit of contracts to get
     * @memberof ContractTable
     */
    const onHandleLimitChange = async (limit) => {
        await setPage(0);
        await setStep(limit);
    }

    /**
     * Function to change Filters data
     * @param {event} e Event of the input
     * @memberof Contracts
     */
    const onChangeFiltersData = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        })
    }

    /**
     * Function to change Filters data
     * @param {event} e Event of the input
     * @memberof Contracts
     */
    const onChangeDocumentData = (e) => {
        setDocumentData({
            ...documentData,
            [e.target.name]: e.target.value
        })
    }

    /**
     * Function to apply filters
     * @memberof Contracts
     */
    const onSetFilters = () => {
        getDocumets(filters);
        setFilterModal(false);
    }

    /**
     * Function to handle close modal
     * @memberof ContractTable
     */
    const onCloseModal = () => {
        setDocumentData(docsObj);
        props.onHideModal(false);
    }

    /**
     * Function to clean filters
     * @memberof ContractTable
     */
    const onCleanFilters = () => {
        setFilters(filtersObj);
        getDocumets();
        setFilterModal(false);
    }

    /**
     * Function to delete document
     * @param {objet} doc document object
     * @memberof DocumentsTable
     */
    const onDeleteAction = (doc) => {
        swal(`${t('documents.deleteMessage')}`, {
            buttons: {
                cancel: t('generalText.cancel'),
                delete: t('generalText.delete')
            },
        })
            .then((value) => {
                switch (value) {
                    case "delete":
                        axios.del(`DocumentsFMV2?documentId=${doc.documentId}`)
                            .then(response => {
                                enqueueSuccessSnackbar(dispatch, t('documents.alertMessages.1'));
                                getDocumets();
                            })
                        break;
                    default:
                        break;
                }
            });
    }

    /**
     * Function to changue the autocomplete value
     * @param {event} e event of the autocomplete
     * @param {array} data array of selected clients
     * @memberof DocumentsTable
     */
    const onChangeAutocomplete = (e, data) => {
        if (data.length > 0) {
            var aux = [];
            data.map(item => {
                aux.push(item.id);
            })
            setDocumentData({
                ...documentData,
                customersId: aux
            })
        }
    }

    /**
     * Function to validate the name of a file and its extension
     * @param {string} filename the name of the file to validate
     * @memberof DocumentsTable
     * @returns true if the filename it's in the security parameters
     */
    const validateFile = (filename, fileSize) => {
        const validate = Rssr.validateDocumentName(false, filename, fileSize);
        if (!validate.documentValid) 
            enqueueErrorSnackbar(dispatch, t('documents.alertMessages.2'));
        else if (!validate.expresionValid) 
            enqueueErrorSnackbar(dispatch, t('documents.alertMessages.6'));
        else if (!validate.extensionValid) 
            enqueueErrorSnackbar(dispatch, t('documents.alertMessages.4'));
        else if (!validate.sizeValid) 
            enqueueErrorSnackbar(dispatch, t('documents.alertMessages.7'));
        return validate.defaultValid;
    }

    const onSetDocuments = () => setDocumentData(docsObj);

    const onUploadFile = async () => {
        var sendData = false;
        if (documentData.customersId.length > 0) {
            if (postData) {
                sendData = validateFile(props.fileName, props.fileSize);
            }
            else {
                sendData = true;
            }
        }
        else {
            enqueueErrorSnackbar(dispatch, t('documents.alertMessages.3'));
            sendData = false;
        }
        if (sendData) {
            await props.onUploadFile(documentData, postData, getDocumets, onCloseModal, onSetDocuments);
        }
    }

    const onUpdateAction = (doc) => {
        setCurrentDoc(doc);
        axios.get(`DocumentsFMV2?documentId=${doc.documentId}`)
            .then(async response => {
                if (response.data.success) {
                    const result = clients.filter(client => response.data.documenInfo.customersId.includes(client.id));
                    setDefaultValues(result);
                    await setDocumentData({
                        documentId: doc.documentId,
                        documentName: doc.name,
                        documentType: doc.type,
                        documentCategory: doc.category,
                        customersId: response.data.documenInfo.customersId,
                        notes: doc.description
                    });
                    await props.onShowModal();
                    await setPostData(false);
                }
            })
    }

    /**
     * Function to download the selected document
     * @memberof component
     */
    const onDownloadDocument = () => {
        window.open(
            (CryptoJS.AES.decrypt(currentDoc.data, 'MyALD@Document')).toString(CryptoJS.enc.Utf8),
            '_blank', 'noopener,noreferrer'
        );
    }

    return (
        <div className={classes.contractTable} >
            <Container direction="row" item xs={12} >
                <Button
                    onClick={() => {
                        setPostData(true);
                        setDefaultValues([]);
                        setDocumentData(docsObj);
                        props.onShowModal();
                    }}
                    className={classes.uploadButtonStyle}
                    aria-label="edit"
                    size="xs"
                    startIcon={<MdPublish />}
                >
                    {t('documents.buttonTitle')}
                </Button>
                <IconButton
                    style={{ alignSelf: 'flex-end' }}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onClick={() => setFilterModal(true)}
                >
                    <MdFilterList />
                </IconButton>
            </Container>
            <CustomTable
                cols={cols}
                rows={documents}
                totalRows={totalDocs}
                actions
                delAction
                updateAction
                onEdit={(doc) => { onUpdateAction(doc) }}
                onDelete={(item) => onDeleteAction(item)}
                handleLimitChange={(limit) => { onHandleLimitChange(limit) }}
                handlePageChange={(page, start) => { changePage(page, start) }}
            />

            {/* Modal update documentos*/}
            <CustomModal
                open={props.showModal}
                onClose={onCloseModal}
            >
                <Paper elevation={0} className={classes.containerModalUploadDocuments}>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            onUploadFile();
                        }}
                    >
                        <Container alignItems="flex-start" justify="center">
                            <Item xs={12} md={10} alignSelf="flex-start" >
                                <p style={{ color: "#050B7F", fontWeight: 'bold', fontSize: '1.5rem' }}> {postData ? t('documents.modalTitle') : t('generalText.edit')} </p>
                            </Item>
                            <Item container xs={12} alignItems="center" justify="center" >
                                <Container item xs={12} alignItems="center" justify="center" >
                                    <Item xs={12} md={6}>
                                        <CustomInput
                                            labelText={t('documents.modal.0')}
                                            id="documentName"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                value: documentData.documentName,
                                                name: "documentName",
                                                required: true,
                                                onChange: (e) => { onChangeDocumentData(e); }
                                            }}
                                        />
                                    </Item>
                                    <Item xs={12} md={6}>
                                        <Autocomplete
                                            labelId="StatusLabel"
                                            id="client"
                                            multiple
                                            options={clients}
                                            getOptionLabel={(option) => (userRole === 7) ? option.clientName : option.client}
                                            defaultValue={defaultValues}
                                            onChange={(e, data) => { onChangeAutocomplete(e, data) }}
                                            fullWidth
                                            renderInput={(params) => <TextField {...params} name="customersId" label={t('contracts.filters.client')} />}
                                        />
                                    </Item>
                                </Container>
                                <Container item xs={12} direction="row" alignItems="center" justify="center" >
                                    <Container item xs={12} sm={12} alignItems="center" justify="center" >
                                        <Item xs={12} sm={6}>
                                            <TextField
                                                select label={t('documents.modal.1')}
                                                fullWidth
                                                labelId="StatusLabel"
                                                id="documentCategory"
                                                name="documentCategory"
                                                required
                                                value={documentData.documentCategory}
                                                onChange={(e) => { onChangeDocumentData(e) }}
                                            >
                                                <MenuItem value={8}>{t('documents.options.0')}</MenuItem>
                                                <MenuItem value={15}>{t('documents.options.1')}</MenuItem>
                                                <MenuItem value={2}>{t('documents.options.2')}</MenuItem>
                                            </TextField>
                                        </Item>
                                        <Item xs={12} sm={6} container direction="column" alignItems="flex-start" justify="flex-start">
                                            {!postData && <Button
                                                onClick={onDownloadDocument}
                                                className={classes.uploadButtonStyle}
                                                aria-label="edit"
                                                startIcon={<MdGetApp />}
                                            >
                                                {t('generalText.download')}
                                            </Button>}
                                            <Button
                                                onClick={props.onDocUpload}
                                                className={classes.uploadButtonStyle}
                                                aria-label="edit"
                                                startIcon={<MdPublish />}
                                            >
                                                {t('documents.buttonTitle')}
                                            </Button>
                                            {(props.fileName !== '') &&
                                                <Typography> {(Rssr.validateDocumentName(true, props.fileName).defaultValid) ? props.fileName : t('documents.alertMessages.8')} </Typography>
                                            }
                                        </Item>
                                    </Container>
                                    <Container item xs={12} sm={12} alignItems="center" justify="center" >
                                        <Item xs={12}>
                                            <CustomInput
                                                labelText={t('documents.modal.2')}
                                                id="plates"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value: documentData.notes,
                                                    name: "notes",
                                                    multiline: true,
                                                    rows: 5,
                                                    onChange: (e) => { onChangeDocumentData(e); }
                                                }}
                                            />
                                        </Item>
                                    </Container>
                                </Container>
                                <Container item xs={12} direction="row" alignItems="center" justify="center">
                                    <Item xs={12} md={6} container alignItems="center" justify="center">
                                        <Button
                                            onClick={onCloseModal}
                                            className={classes.cancelButtonStyle}
                                            size="lg"
                                        >
                                            {t('generalText.cancel')}
                                        </Button>
                                    </Item>
                                    <Item xs={12} md={6} container alignItems="center" justify="center">
                                        <Button
                                            className={classes.acceptButtonStyle}
                                            size="lg"
                                            type="submit"
                                        >
                                            {postData ? t('generalText.accept') : t('generalText.edit')}
                                        </Button>
                                    </Item>
                                </Container>
                            </Item>
                        </Container>
                    </form>
                </Paper>
            </CustomModal>

            {/* Modal de filtros */}
            <CustomModal
                open={filterModal}
                onClose={() => { setFilterModal(false) }}
            >
                <div style={{ width: '100%' }} >
                    <DocumentsFilter
                        filters={filters}
                        onChangeFiltersData={onChangeFiltersData}
                        onSetFilters={onSetFilters}
                        onCleanFilters={onCleanFilters}
                        />
                </div>
            </CustomModal>
        </div>
    );
})

export default ContractTable;