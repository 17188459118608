/**
 * @fileoverview Card Notification Styles
 * @version 1.0.0
 * @author Carlos Emilio Blanco Lopez
 * @date 09/04/2023
 * @copyright 2023 Industrias RESSER S.A de C.V
 */
import {
    blackColor,
    hexToRgb
} from "assets/jss/material-dashboard-react.js";

const CardStyles = theme => ({
    Body: {
        cursor: "pointer",
        display: "flex",
        height: "max-content",
        width: "100%",
        marginTop: 10,
        marginBottom: 10,
    },
    containerIcon: {
        height: "100%",
        width: "10%",
        display: "flex",
        justifyContent: "center"
    },
    headerCard: {
        fontFamily: 'Source Sans Pro',
        fontSize: "18px",
        color: "#80B4FF",
        marginTop: 0,
        [theme.breakpoints.down("lg")]: {
            fontSize: "16px",
        }
    },
    subtitleCard: {
        fontFamily: 'Montserrat',
        fontSize: 13,
        marginTop: -10,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down("lg")]: {
            marginTop: -12,
        }
    },
    tooltipDnD: {
        padding: "10px 15px",
        minWidth: "130px",
        lineHeight: "1.7em",
        border: "none",
        borderRadius: "3px",
        boxShadow:
            "0 8px 10px 1px rgba(" +
            hexToRgb(blackColor) +
            ", 0.14), 0 3px 14px 2px rgba(" +
            hexToRgb(blackColor) +
            ", 0.12), 0 5px 5px -3px rgba(" +
            hexToRgb(blackColor) +
            ", 0.2)",
        maxWidth: "400px",
        textAlign: "center",
        fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "400",
        textShadow: "none",
        textTransform: "none",
        letterSpacing: "normal",
        wordBreak: "normal",
        wordSpacing: "normal",
        wordWrap: "normal",
        whiteSpace: "normal",
        lineBreak: "auto",
    }
});

export default CardStyles;
