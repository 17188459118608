/**
 * @fileoverview Configuration Slice by Notifications
 * @version 1.0
 * @author Carlos Emilio Blanco Lopez
 * @date 15/08/2023
 * @copyright 2023 Industrias RESSER S.A de C.V
 **/

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showIndicator: false,
    showAlertMessage: false,
    showSidebarMask: false,
    showNotificationMask: false
};

const loadingMask = createSlice({
    name: 'loadingMask',
    initialState,
    reducers: {
        showCircularProgress: (state, action) => {
            state.showIndicator = true;
        },
        closeCircularProgress: (state, action) => {
            state.showIndicator = false;
        },
        onShowAlertMessage: (state, action) => {
            state.showAlertMessage = true;
        },
        onHideAlertMessage: (state, action) => {
            state.showAlertMessage = false;
        },
        showSidebarMask: (state, action) => {
            state.showSidebarMask = true;
        },
        hideSidebarMask: (state, action) => {
            state.showSidebarMask = false;
        },
        showNotificationMask: (state, action) => {
            state.showNotificationMask = true;
        },
        hideNotificationMask: (state, action) => {
            state.showNotificationMask = false;
        }
    }
});

export const {
    showCircularProgress,
    closeCircularProgress,
    onShowAlertMessage,
    onHideAlertMessage,
    showSidebarMask,
    hideSidebarMask,
    showNotificationMask,
    hideNotificationMask
} = loadingMask.actions;

export default loadingMask.reducer;