/**
 * @fileoverwiew: Rssr library functions.
 * Libreria de funciones basicas
 * @version: 1.3.0
 * @author: Martin Duran Anguiano, martin.duran@resser.com
 * @date 11/06/2020
 * @copyright: 2018 INDUSTRIAS RESSER S.A DE C.V
 */

//#region Funciones para validación de campos
/**
 * Función que valida si un string contine solo numeros
 * @param {*} Data
 * @return { bool } True/False
 * @memberof Rssr
 */
const validateStringOnlyNumbers = (Data) => {
    let isnum = /^\d+$/.test(Data);

    //Se valida que el dato ingresado solo contenga numeros y que este no sea un double
    if (isnum === false) return false;

    return true
}

/**
 * Función que valida si un CPF brindado por el usuario es valido
 * @param { string } userCpf
 * @returns { bool } True/False
 * @memberof Rssr
 */
const validateCPFBrasil = (userCpf) => {

    //Se valida que se brindo un CPF para trabajar
    if (userCpf === null || userCpf === undefined) return false;

    //Se valida que la longitud del CPF sea igual a 11.
    if (userCpf.length < 11 || userCpf.length > 11) return false;

    //Se valida que el dato ingresado solo contenga numeros y que este no sea un double
    if (!validateStringOnlyNumbers(userCpf)) return false;

    if (userCpf === '00000000000') return false;

    let cpf = userCpf;
    let cpfNumbers = "";

    //Se agregan espacios entre numeros para poder separarlos despues
    while (cpf.length > 0) {
        cpfNumbers = cpfNumbers + " " + cpf.substring(0, 1);
        cpf = cpf.substring(1)
    }

    let arrayCpf = cpfNumbers.split(" ");

    let firstTotal = 0;
    let firstCount = 10;
    let firstNumberValidate = parseInt(arrayCpf[10]);

    let secondTotal = 0;
    let secondCount = 11;
    let secondNumberValidate = parseInt(arrayCpf[11]);

    // Validación de primer numero
    for (let index = 1; index <= 9; index++) {
        firstTotal = firstTotal + arrayCpf[index] * firstCount;
        firstCount = firstCount - 1
    }
    firstTotal = firstTotal * 10 % 11

    //Validación de residuo en caso de que sea 10 u/o 11
    if (firstTotal === 10 || firstTotal === 11) {
        firstTotal = 0;
    }

    // Validación de segundo numero
    for (let index = 1; index <= 10; index++) {
        secondTotal = secondTotal + arrayCpf[index] * secondCount;
        secondCount = secondCount - 1;
    }
    secondTotal = secondTotal * 10 % 11

    //Validación de residuo en caso de que sea 10 u/o 11
    if (secondTotal === 10 || secondTotal === 11) {
        secondTotal = 0;
    }

    //Se valida que ambos numeros obtenidos correspondan a los ultimos datos del CPF
    if (firstTotal !== firstNumberValidate || secondTotal !== secondNumberValidate) {
        return false;
    } else {
        return true
    }
}

/**
 * Función para validar si un RFC de México es valido
 * @param {string} rfc
 * @returns { bool } true/false
 * @memberof Rssr
 */
const validateRFCMX = (rfc) => {
    if (rfc === "" || rfc === null) return false;
    const reg = /^([A-Z|a-z|&amp;]{3}\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)|([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9]{1})$|^([A-Z|a-z]{4}\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)|([02468][048]|[13579][26])0229)((\w{2})([A|a|0-9]{1})){0,3}$/;
    if (reg.test(rfc) === false) {
        return false
    } else {
        return true;
    }
}

/**
 * Función que valida si una placa brindada por el usuario es valida
 * tipos de placas disponibles a validar
 * 3 letras y 4 numeros,
 * 4 letras y 3 numeros
 * @param { string } Plate
 * @returns { bool } True/False
 * @memberof Rssr
 */
const validatePlateBrasil = (Plate) => {
    if (Plate === null || Plate === undefined) return false

    if (typeof Plate !== 'string') return false

    Plate = Plate.replace(/\s/g, '');
    const regex = /\d+/g;
    let platelong = Plate.length,
        PlateNumbersCount = "",
        strPlateNumbers;

    if (platelong > 7 || platelong === 0 || platelong < 7) return false

    strPlateNumbers = Plate.match(regex);
    if (strPlateNumbers === null) return false
    if (strPlateNumbers.length === 0) return false

    strPlateNumbers.forEach(number => {
        PlateNumbersCount = PlateNumbersCount + number
    });
    PlateNumbersCount = PlateNumbersCount.length
    if (PlateNumbersCount === 4 || PlateNumbersCount === 3) return true

    return false
}

/**
 * Función que valida si el formato de un correo es valido
 * @param { string } userEmail
 * @returns { bool } True/False
 * @memberof Rssr
 */
const validateEmailFormat = (userEmail) => {
    if (userEmail === "" || userEmail === null) return false;
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(userEmail) === false) {
        return false
    } else {
        return true;
    }
}

/**
 * Función que valida si la versión de MyALD instalada en el dispositivo es la mas actual.
 * @param {} installedVersion: Versión instalada en el dispositivo
 * @param {} latestVersionReleased : Versión publicada en tiendas
 * @returns True/False
 * @memberof Rssr
 */
const validateLatestVersion = (installedVersion, latestVersionReleased) => {
    let count = 0;
    installedVersion = installedVersion.split('.');
    latestVersionReleased = latestVersionReleased.split('.');

    let actualMaxVersion = parseInt(installedVersion[0]),
        actualMediumVersion = parseInt(installedVersion[1]),
        actualBugVersion = parseInt(installedVersion[2]);

    let lastReleasedMaxVersion = parseInt(latestVersionReleased[0]),
        lastReleasedMediumVersion = parseInt(latestVersionReleased[1]),
        lastReleasedBugVersion = parseInt(latestVersionReleased[2]);

    if (actualMaxVersion >= lastReleasedMaxVersion) count = count + 1;
    if (actualMediumVersion >= lastReleasedMediumVersion) count = count + 1;
    if (actualBugVersion >= lastReleasedBugVersion) count = count + 1

    if (count < 3) return false;
    return true;

}

/**
 * Función que valida si la fecha de selección para un servicio esta permitida
 * @param {Fecha seleccionada por el usuario} date
 * @param {Región del usuario 0(BR), 1(MX), 2(PR), 3(CL), 4(CLB)} country
 * @return {int 2: Servicio en fin de semana
 *              3: Servicio fuera de horarios laborales
 *              4: servicio anterior a 48 horas
 *              5: servicio en fechas pasadas
 *              true: Fecha valida }
 * @memberof Rssr
 */
const validateServiceDayRegion = (date, country) => {
    if (date === null || date === "" || date === false) return false;
    let dateSplit = date.split("T");
    dateSplit = dateSplit[0].split(" ");

    let dateS = dateSplit[0];

    let day = new Date(dateS).getDay();
    let hour = new Date(date).getHours();
    let minutes = new Date(date).getMinutes();

    let today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    // Validación de reglas de horarios de servicios
    switch (country) {
        case 0: //Brasil
            //Validación de servicios en fines de semana
            if (day === 5 || day === 6) return 2;

            //Validación de servicios realizados en viernes para que no seleccionen lunes
            if (today.getDay() === 5 && day === 0) return 4;

            //Validación de horarios de L - V
            if (day <= 4) {
                //Validación de inicio de horario de servicios
                if (hour < 7) return 3;

                //Validación de fin de horario de servicio
                else if (hour === 17 && minutes > 0) return 3

                else if (hour > 17) return 3;
            }
            break;

        case 1: //Mexico
        case 3: //Chile
            //Validaciones de servicios en fines de semana
            if (day === 5 || day === 6) return 2;

            //Validación de servicios realizados en viernes para que no seleccionen lunes
            if (today.getDay() === 5 && day === 0) return 4;

            //Validación de horarios de L - V
            if (day <= 4) {
                //Validación de inicio de horario de servicios
                if (hour < 8) return 3;

                //Validación de fin de horario de servicios
                else if (hour >= 20 && minutes >= 0) return 3;
            }
            break;

        case 2: //Perú
            //Validaciones de servicios en domingos
            if (day === 6) return 2;

            //Validaciones de L - V
            if (day <= 4) {

                //Validaciones de inicio de horario de servicio
                if (hour < 7) return 3

                //Validación de fin de horario de servicio
                else if (hour === 18 && minutes > 0) return 3;

                else if (hour > 18) return 3;
            }

            //Validaciones de sabados
            if (day === 5) {

                //Validaciones de inicio de horario de servicio 8:30
                if (hour === 8 && minutes < 30) return 3

                //Validaciones de fin de horario de servicio 13:00
                else if (hour >= 13 && minutes >= 0) return 3
            }


            break;

        case 4: //Colombia
            //Validaciones de servicios en domingos
            if (day === 6) return 2;
            //validación de horarios de servicios
            else if (hour > 18 || hour < 8) return 3;
            break;

        default:
            return false;
    }

    //Validaciones para regla de 48 horas y fechas pasadas.

    //Validación para el día actual solo por el día sin contemplar el tiempo.
    if (new Date(date).setHours(0, 0, 0, 0) === new Date(today).setHours(0, 0, 0, 0)) return 4;
    //if(picketDay === todayDay && pickerMonth === todayMoth && todayYear === pickerYear ) return 4;

    //Validación para el día siguiente al actual sin contemplar el tiempo
    if (new Date(date).setHours(0, 0, 0, 0) === new Date(tomorrow).setHours(0, 0, 0, 0)) return 4;

    //Validación para fechas pasadas
    if (country != 3) { //FIXME: Esta validación se debe remover cuando el controller de MAG pueda trabajar con las fechas de Chile como vacias o nulas
        if (new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) return 5;
    }

    return true;
}

//#endregion

//#region Funciones para la limpieza de datos
/**
 * Función que se encarga de remover acentos o cualquier caracter especial
 * @param {string} Email: Correo a limpiar
 * @returns Devuelve un correo limpio de caracteres especiales en caso de no enviar nada la función retorna un string vació.
 * @memberof Rssr
 */
const removeEspecialCharactersEmail = (Email) => {
    if (Email === '' || Email === null) return ''
    let EmailClean = Email.toLowerCase();

    EmailClean = EmailClean.replace(new RegExp("[àáâãäå]", 'g'), "a");
    EmailClean = EmailClean.replace(new RegExp("\\s", 'g'), "");
    EmailClean = EmailClean.replace(new RegExp("æ", 'g'), "ae");
    EmailClean = EmailClean.replace(new RegExp("ç", 'g'), "c");
    EmailClean = EmailClean.replace(new RegExp("[èéêë]", 'g'), "e");
    EmailClean = EmailClean.replace(new RegExp("[ìíîï]", 'g'), "i");
    EmailClean = EmailClean.replace(new RegExp("ñ", 'g'), "n");
    EmailClean = EmailClean.replace(new RegExp("[òóôõö]", 'g'), "o");
    EmailClean = EmailClean.replace(new RegExp("œ", 'g'), "oe");
    EmailClean = EmailClean.replace(new RegExp("[ùúûü]", 'g'), "u");
    EmailClean = EmailClean.replace(new RegExp("[ýÿ]", 'g'), "y");
    EmailClean = EmailClean.replace(/[&\/\\#,+()$~%!¡'":*´`?¿<>{}]/g, "");

    return EmailClean
}
//#endregion

//#region Funciones para formato de datos
/**
 * Función encargada de dar formatoa una fecha
 * @param {*} date Fecha a formatear
 * @param {*} formatType Tipo de formato requerido valores esperados 1 o 2
 * @returns formatType 1: => 14 Jul, 2020 at 13:00/formatType 2: => 14 Julio, 2020 at 13:00
 */
const formatingDate = (date, formatType, t) => {
    if (date == null || date == "") return false;

    let dateSplit = date.split("T");
    let monthString = '';

    //Dia
    let dateService = dateSplit[0];
    dateService = dateService.split('-');
    dateService = dateService[2] + " ";

    //Mes
    let monthNumber = parseInt(dateSplit[0].split('-')[1]) - 1;

    //Año
    let yearService = dateSplit[0];
    yearService = yearService.split('-');
    yearService = ", " + yearService[0];

    //Horas
    let hourService = dateSplit[1];
    hourService = hourService.split(':');
    hourService = hourService[0] + ":" + hourService[1];

    //En caso de requerir otro tipo de formato añadirlo en este switch.
    switch (formatType) {
        case 1:
            monthString = t(`Months.abreviatura.${monthNumber}`);
            return dateService + monthString + yearService + t(`generalText.at`) + hourService;
            break;

        case 2:
            monthString = t.t(`Months.completos.${monthNumber}`);
            return dateService + monthString + yearService + t(`generalText.at`) + hourService;
            break;

        default:
            return dateService + monthString + yearService + t(`generalText.at`) + hourService;
    }
}

/**
 * Función para solo remover el caracter T de una fecha
 * @param {La fecha a formatear debe tener este formato 2021-01-28T12:00} date
 * @return Fecha en el siguiente formato 2021-01-28 12:00
 *
 */
const formatingDateServices = (date) => {
    if (date === null || date === "") return false;

    let dateSplit = date.split("T");

    return dateSplit[0] + " " + dateSplit[1];
}
//#endregion

//#region Funciones para el manejo de fechas
/**
 * Función encargada de retornar la fecha actual en formato DD/MM/YYYY
 * @returns Fecha actual en formato DD/MM/YYYY
 * @memberof Rssr
 * @example
 * getTodayDate() => 28/01/2021
*/

const formatDate = (date) => {
    var aux = '';
    var res = '';
    aux = date.split('T')[0].split('-');
    if (aux[0] === "1900")
        return false;
    res = `${aux[2]}/${aux[1]}/${aux[0]}`;
    return res;
}
// #endregion

//#region Funciones para módulo mantenimientos
/**
 * Función encargada de retornar la fecha cuando pueden solicitarse servicios respetando 48H minimo de antelación.
 * @param {id de la region a la que corresponde el usuario} regionId
 */
const getMinRequestServiceDate = (regionId) => {
    var d = new Date(),
        month = '' + parseInt(d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        aux = d.getDay(),
        hour = "";


    switch (aux) {
        //Domingo - > Martes
        case 0:
            day = parseInt(day) + 2
            break;

        //Lunes -> Miercoles
        case 1:
            day = parseInt(day) + 2
            break;

        //Martes -> Jueves
        case 2:
            day = parseInt(day) + 2
            break;

        //Miercoles -> Viernes
        case 3:
            day = parseInt(day) + 2
            break;

        //Jueves -> sabados(CL y PR)  y Lunes(BR, MX, CHL)
        case 4:
            //Colombia es la unica región que puede agendar en sabados
            if (regionId === 4 || regionId === 2) {
                day = parseInt(day) + 2;
            } else {
                day = parseInt(day) + 4
            }
            break;

        //Viernes -> Martes
        case 5:
            day = parseInt(day) + 4
            break;

        //Sabado -> Martes
        case 6:
            day = parseInt(day) + 3
            break;

        default:
            break;
    }

    var days = new Date(year, month, 0).getDate();

    if (day >= days) {
        month = parseInt(month) + 1;
        day = parseInt(day) - days;
        day = (day === 0) ? 1 : day;
        if (month > 12) {
            month = 1;
            year = parseInt(year, 10) + 1;
        }
    }

    if (parseInt(month, 10) < 10) {
        month = `0${month}`;
    }
    if (parseInt(day, 10) < 10) {
        day = `0${day}`;
    }

    var date = [year, month, day].join('-');

    //Seteo de horario de apertura para solicitud de servicio
    switch (regionId) {
        case 0:
            hour = "07:00"
            break;
        case 1:
        case 2:
        case 3:
        case 4:
            hour = "08:00"
            break;
        default:
            hour = "08:00"
            break;
    }

    date = date + "T" + hour;
    date.toString();

    return date;
}
//#endregion

/**
 * Función que valida el nombre del documento
 * @param {} DocumentName: Nombre del documento
 * @returns True/False
 * @memberof Rssr
 */
const validateDocumentName = (
    onlyName,
    docName,
    docSize = 0,
) => {
    const regExpresion = /[\!\@\#\$\%\[\]\{\}\<\>\^\&\\\/\*\)\(+\=]/;
    const doc = docName.split('.');
    const result = {
        defaultValid: true,
        documentValid: true,
        expresionValid: true,
        extensionValid: true,
        sizeValid: true,
    }
    if (docName === '') {
        if (!onlyName)
            result.documentValid = false;
        result.defaultValid = false;
    }
    if (regExpresion.test(doc[0])) {
        if (!onlyName)
            result.expresionValid = false;
        result.defaultValid = false;
    }
    if (doc.length > 2) {
        if (!onlyName)
            result.extensionValid = false;
        result.defaultValid = false;
    }
    if (docSize / Math.pow(1024, 2) > 3) {
        if (!onlyName)
            result.sizeValid = false;
        result.defaultValid = false;
    }
    return result;
}

/**
 * Función encargada de formatear la fecha para modulo Notificaciones.
 * @param {} date: Nombre del documento
 * @returns [date, hour]
 * @memberof Rssr
 */

const formatDateNotifications = (date) => {
    if (date === undefined) {
        return [];
    }
    const arrayDate = date.split("T");
    return [arrayDate[0], arrayDate[1].slice(0, 5)];
}

/**
 * Función encargada de dar formato a un precio
 * @param {*} price
 * @returns Precio formateado
 * @memberof Rssr
 */

const formatPrice = (price) => {
    if (price === undefined) {
        return 0;
    }
    const formatoPrecioMexicano = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 2
    });

    return formatoPrecioMexicano.format(price);
}

const formatProtectedEmail = (mail) => {
    let chars = 3; // Cantidad de caracters visibles
    if (mail) {
        let res = mail.replace(/[a-z0-9\-_.]+@/ig, (c) => c.substr(0, chars) + c.split('').slice(chars, -1).map(v => '*').join('') + '@')
        return res;
    }
}

export default ({
    //Funciones para validaciones de campos.
    validateStringOnlyNumbers,
    validateCPFBrasil,
    validatePlateBrasil,
    validateEmailFormat,
    validateLatestVersion,
    validateRFCMX,
    validateServiceDayRegion,

    //Funciones para limpieza de datos.
    removeEspecialCharactersEmail,

    //Funciones para formato de datos
    formatDate,
    formatingDate,
    formatingDateServices,

    //Funciones generales para mantenimiento
    getMinRequestServiceDate,

    //Validacion Nombre Documento
    validateDocumentName,

    //FormatDates
    formatDateNotifications,
    formatPrice,

    //ofuscar email
    formatProtectedEmail,
});