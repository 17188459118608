/**
 * @fileoverview Configuration Reducer for Notifications
 * @version 1.0.0
 * @author Carlos Emilio Blanco Lopez
 * @date 13/12/2022
 * @copyright 2022 Industrias RESSER S.A de C.V
 */

// initial State

const initialState = {
    openModal: false,
    isLoad: false,
    isUpdate: false,
    dataUpdate: {},
    itemsDnD: [],
    CantItems: 0,
    FirstGet: true,
    Categories: [],
    UpdateFile: "",
    itemsID: []
}

// reducer

const reducer = (state, action) => {
    switch (action.type) {
        case "onModal":
            return { ...state, openModal: !state.openModal };
        case "ChangeLoad":
            return { ...state, isLoad: !state.isLoad };
        case "ChangeStatusUpdate":
            if (action?.payload === undefined) {
                return { ...state, isUpdate: !state.isUpdate };
            }
            else {
                return { ...state, isUpdate: action.payload };
            }
        case "ChangeDataUpdate":
            return { ...state, dataUpdate: action.payload };
        case "ChangeItemsDnD":
            return { ...state, itemsDnD: action.payload.items, CantItems: action.payload.CantItems };
        case "ChangeFirstGet":
            return { ...state, FirstGet: false };
        case "GetCategories":
            return { ...state, Categories: action.payload };
        case "GetUpdateFile":
            return { ...state, UpdateFile: action.payload };
        case "GetItemsID":
            return { ...state, itemsID: action.payload };
        default:
            break;
    }
}

export { initialState, reducer };